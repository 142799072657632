import React from 'react';

import WithWatsonTranslate from 'library/common/commonComponents/WithWatsonTranslate/version';
import Storage from 'library/utilities/storage';
import store from 'main/store/configureStore';
import { getNotificationsConfiguration } from 'library/api/notifications';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import { Trans } from 'react-i18next';
import { sanitize } from 'dompurify';
import * as moment from 'moment/moment';

const dynamicContents = [
  'POST',
  'COMMENT',
  'SURVEY',
  'EVENT',
  'MEDIA',
  'FILE',
  'GALLERY',
  'GALLERY_POST',
  'CALENDAR',
  'TASK',
];
function getContentIsDynamic(type) {
  return dynamicContents.includes(type);
}

export function getText(notification, t) {
  const { currentWatsonUserConsent } = store.getState().userReducer;
  const { actionBy, notificationEntity } = notification.payload;
  const notificationEntityType = notificationEntity.postType;
  const textStart =
    notificationEntityType === 'CALENDAR_REMINDER' ||
    notificationEntityType === 'FOOD_ORDER_REMINDER' ||
    notificationEntityType === 'BIRTHDAY_REMINDER' ? (
      ''
    ) : (
      <strong>{`${actionBy?.firstName} ${actionBy?.lastName}`}</strong>
    );

  const textBody = getTextBody(notification, notification.payload.type, t);

  const contentIsDynamic = getContentIsDynamic(notificationEntity.postType);

  if (notification.eventType === 'KID_CONTRACT_END') {
    return (
      <>
        <strong>{notification.payload.notificationEntity.subject}</strong>
        <p>{notification.payload.notificationEntity.message}</p>
      </>
    );
  }

  if (notification.eventType === 'GROUP_MEMBERSHIP_REQUEST') {
    return (
      <>
        {textStart}&nbsp;
        {t('Header.Topbar.Notifications.Send MembershipRequest For Group')}&nbsp;
        <strong>{notification.payload.notificationEntity.groupName}</strong>
      </>
    );
  }

  return textBody && notification.payload.notificationEntity.postType !== 'GALLERY_POST' ? (
    <>
      {textStart}
      {` ${getNotificationTypeText(notification, t)} `}
      <div
        style={{
          display: 'flex',
        }}
      >
        {(!['BIRTHDAY_REMINDER', 'GALLERY'].includes(notificationEntityType))  && (
          <>
            &quot;
          </>
        )} 
        {contentIsDynamic && currentWatsonUserConsent ? (
          <WithWatsonTranslate
            data={{ text: textBody, entityId: notification.id, entityType: '0' }}
            Component={({ html }) => <span dangerouslySetInnerHTML={{ __html: sanitize(html) }} />}
          />
        ) : (
          textBody
        )}
        {(!['BIRTHDAY_REMINDER', 'GALLERY'].includes(notificationEntityType)) && (
          <>
            &quot;
          </>
        )}
      </div>
      {getNotificationEndText(notification, t)}
    </>
  ) : (
    <>
      {textStart}
      {` ${getNotificationTypeText(notification, t)}`}
      {getNotificationEndText(notification, t)}
    </>
  );
}

export function getTextForDesktopNotification(notification, t) {
  const { actionBy, notificationEntity } = notification.payload;
  const notificationEntityType = notificationEntity.postType;
  const textStart =
    notificationEntityType === 'CALENDAR_REMINDER' ||
    notificationEntityType === 'FOOD_ORDER_REMINDER'
      ? ''
      : `${actionBy.firstName} ${actionBy.lastName}`;

  const textBody = getTextBody(notification, notification.payload.type, t);

  return (
    `${textStart}\n` +
    `${getNotificationTypeText(notification, t)}\n` +
    `${textBody ? ` "${textBody}"` : ''}\n` +
    `${getNotificationEndText(notification, t, true)}`
  );
}

export function getNotificationEndText(notification, t, isDesktopNotification) {
  const type =
    notification.payload.notificationEntity.taskEventType ||
    notification.payload.notificationEntity.newStatus ||
    notification.payload.type ||
    notification.eventType;

  const { postType } = notification.payload.notificationEntity;

  const InGroupEnding = isDesktopNotification ? (
    ` ${t('Header.Topbar.Notifications.in group')} ${
      notification.payload.notificationEntity.groupName
    }`
  ) : (
    <>
      {' '}
      {t('Header.Topbar.Notifications.in group')}{' '}
      <strong>{notification.payload.notificationEntity.groupName}</strong>
    </>
  );

  switch (type) {
    case 'super-admin-group-access':
      return Storage.getItem('locale') === 'de' ? ' zugegriffen' : '';
    case 'ASSIGNED_USERS':
      return InGroupEnding;
    case 'RESPONSIBLE_USERS':
      return isDesktopNotification ? (
        ` ${t('Header.Topbar.Notifications.from group')} ${
          notification.payload.notificationEntity.groupName
        }`
      ) : (
        <>
          {' '}
          {t('Header.Topbar.Notifications.from group')}{' '}
          <strong>{notification.payload.notificationEntity.groupName}</strong>
        </>
      );
    case 'STATUS_CHANGE': {
      const { newStatus } = notification.payload.notificationEntity;
      if (newStatus === 'in_progress') {
        return InGroupEnding;
      }

      if (newStatus === 'pending') {
        return InGroupEnding;
      }

      if (newStatus === 'pending_review') {
        return InGroupEnding;
      }

      if (newStatus === 'completed') {
        return isDesktopNotification ? (
          ` ${t('Header.Topbar.Notifications.in group')} ${
            notification.payload.notificationEntity.groupName
          } ${t('Header.Topbar.Notifications.as completed')}`
        ) : (
          <>
            {' '}
            {t('Header.Topbar.Notifications.in group')}{' '}
            <strong>{notification.payload.notificationEntity.groupName}</strong>{' '}
            {t('Header.Topbar.Notifications.as completed')}{' '}
          </>
        );
      }

      return '';
    }
    case 'PARENT_SURVEY_REMINDER':
    case 'SURVEY_REMINDER': {
      const div = document.createElement('div');
      div.innerHTML = notification.payload.notificationEntity.text;
      return (
        <>
          <strong>{div.textContent}</strong>&nbsp;
          {t('PARENT_SURVEY_END_AT')}{' '}
          {notification.payload.notificationEntity.reminderType}
        </>
      );
    }
    default:
      return '';
  }
}

export function getNotificationTypeText(notification, t) {
  if (notification.payload.notificationEntity.postType === 'GALLERY_POST') {
    return t('Header.Topbar.Notifications.Gallery post').replace(
      '{galleryName}',
      notification.payload.notificationEntity.text,
    );
  }

  if (notification.payload.notificationEntity.postType === 'CANCEL_FOOD_ORDER_NOTIFICATION') {
    const {notificationEntity} = notification.payload;
    return `${notificationEntity.childName} kid ${notificationEntity.text} ${t('Header.Topbar.Notifications.CanceledFoodOrder')} ${notificationEntity.cancelFoodDate}`;
  }

  return `${getNotificationActionType(notification, t)} ${getNotificationActionTarget(
    notification,
    t,
  )}`;
}

export function getNotificationActionType(notification, t) {
  const type =
    notification.payload.notificationEntity.taskEventType ||
    notification.payload.type ||
    notification.eventType;

  switch (type) {
    case 'COMMENT':
      return t('Header.Topbar.Notifications.commented');
    case 'LIKE':
      return t('Header.Topbar.Notifications.likes');
    case 'CREATED':
    case 'NEW_CONTENT':
    case 'post-created':
      return t('Header.Topbar.Notifications.created');
    case 'MENTIONING':
      return t('Header.Topbar.Notifications.mentioned you in');
    case 'super-admin-group-access':
      return t('Header.Topbar.Notifications.super-admin-group-access');
    case 'user-added-to-kita':
      return t('Header.Topbar.Notifications.user-added-to-kita');
    case 'group-deleted':
      return t('Header.Topbar.Notifications.group-deleted');
    case 'new-friendship-request':
      return t('Header.Topbar.Notifications.new-friendship-request');
    case 'friendship-request-accepted':
      return t('Header.Topbar.Notifications.friendship-request-accepted');
    case 'ASSIGNED_USERS':
      return t('Header.Topbar.Notifications.assigned-users');
    case 'RESPONSIBLE_USERS':
      return t('Header.Topbar.Notifications.assigned you as responsible person in');
    case 'kita-request-approved':
    case 'existing-user-added-to-kita':
      return t('Header.Topbar.Notifications.You were added to the institution', {
        kitaName: notification.payload.notificationEntity.kitaName,
      });
    case 'STATUS_CHANGE': {
      const { newStatus, oldStatus } = notification.payload.notificationEntity;
      if (newStatus === 'in_progress') {
        if (oldStatus === 'pending') {
          return t('Header.Topbar.Notifications.started working on');
        }
        if (oldStatus === 'pending_review') {
          return t('Header.Topbar.Notifications.rejected');
        }
      }

      if (newStatus === 'pending') {
        return t('Header.Topbar.Notifications.has re-opened');
      }

      if (newStatus === 'pending_review') {
        return t('Header.Topbar.Notifications.requests you to review');
      }

      if (newStatus === 'completed') {
        return t('Header.Topbar.Notifications.marked');
      }

      return '';
    }
    case 'CALENDAR':
      if (notification.payload.notificationEntity.postType === 'CALENDAR_REMINDER') {
        const { reminderType, reminderValue } = notification.payload.notificationEntity;
        const translationKey =
          'PUSH_CALENDAR_REMINDER_' +
          reminderType?.toUpperCase() +
          '_' +
          (reminderValue > 1 ? 'PLURAL' : 'SINGULAR');
        return t(translationKey, notification);
      }

      return '';
    case 'kid-check-in-out':
      return t('PUSH_KID_CHECK_IN_OUT_REMINDER', notification);
    case 'FOOD_ORDER_REMINDER':
      return t('PUSH_FOOD_ORDER_REMINDER', notification);
    case 'RETURN_RECEIPT_REMINDER':
      return t('Header.Topbar.Notifications.return-receipt');
    case 'calendar-entry-updated':
      return t('Header.Topbar.Notifications.updated');
    case 'calendar-entry-cancelled':
      return t('Header.Topbar.Notifications.cancelled');
    case 'PARENT_SURVEY_REMINDER':
      return t('Header.Topbar.Notifications.ParentSurvey');
    case 'SURVEY_REMINDER':
      return t('Header.Topbar.Notifications.Survey');
    default:
      return '';
  }
}

export function getNotificationActionTarget(notification, t) {
  switch (notification.payload.notificationEntity.postType) {
    case 'POST':
      return t('Header.Topbar.Notifications.post');
    case 'COMMENT':
      return t('Header.Topbar.Notifications.comment');
    case 'SURVEY':
      return t('Header.Topbar.Notifications.Question');
    case 'EVENT':
      return t('Header.Topbar.Notifications.Event');
    case 'MEDIA':
      return t('Header.Topbar.Notifications.Media');
    case 'FILE':
      return t('Header.Topbar.Notifications.File');
    case 'GALLERY':
      return t('Header.Topbar.Notifications.Gallery');
    case 'CALENDAR':
      return t('Header.Topbar.Notifications.Calendar');
    case 'TASK':
      return t('Header.Topbar.Notifications.Task');
    default:
      return '';
  }
}

export function notify(data, t, readNotification) {
  if (!('Notification' in window)) {
    return;
  }

  if (Notification.permission === 'granted') {
    createNotification(data, t, readNotification);
  } else if (Notification.permission !== 'denied') {
    Notification.requestPermission().then(function(permission) {
      if (permission === 'granted') {
        createNotification(data, t, readNotification);
      }
    });
  }
}

const notifications = [];
let activeNotification = null;

export function createNotification(...args) {
  const { id } = store.getState().userReducer;

  const groups = Storage.getItem(`groupsForNewContentNotifications/${id}`);
  if (
    args[0].eventType === 'NEW_CONTENT' &&
    groups &&
    groups.find(group => group.groupId === args[0].payload.notificationEntity.groupId) &&
    Storage.getItem(`canShowNotification/${id}`)
  ) {
    notifications.push(args);
  } else if (args[0].eventType !== 'NEW_CONTENT') {
    notifications.push(args);
  }
}

setInterval(() => {
  if (activeNotification) activeNotification.close();
  const args = notifications.shift();
  if (args) {
    const [data, t, readNotification] = args;
    activeNotification = new Notification(getTextForDesktopNotification(data, t));
    activeNotification.addEventListener('click', () => {
      window.focus();
      readNotification(data);
    });
  }
}, 3000);

export function updateNotificationsConfiguration(userId) {
  getNotificationsConfiguration()
    .then(({ data }) => {
      Storage.setItem(
        `canShowNotification/${userId}`,
        data.userConfiguration.desktopConfiguration.enabled,
      );
      Storage.setItem(
        `groupsForNewContentNotifications/${userId}`,
        data.userConfiguration.desktopConfiguration.groups,
      );
    })
    .catch(() => {
      store.dispatch(
        showBottomNotification(<Trans i18nKey='BottomNotifications.Something went wrong' />, {
          isFail: true,
        }),
      );
    });
}

function getTextBody(notification, type, t) {
  const { notificationEntity, actionBy } = notification.payload;

  const actionType =
    notificationEntity.taskEventType || notification.payload.type || notification.eventType;

  const postType = notification.payload.notificationEntity.postType || '';
  if (
    postType === 'CALENDAR_REMINDER' ||
    postType === 'PARENT_SURVEY_REMINDER' ||
    postType === 'SURVEY_REMINDER' ||
    postType === 'FOOD_ORDER_REMINDER'
  ) {
    return '';
  }

  const div = document.createElement('div');
  if (notificationEntity.text) {
    div.innerHTML = notificationEntity.text;

    if (actionType === 'NEW_CONTENT' && notificationEntity.postType === 'GALLERY') {
      const { groupName } = notificationEntity;

      if (groupName != null) {
        div.innerHTML = `"${div.innerHTML}" ${t('Header.Topbar.Notifications.in group')} ${groupName}`;
      }
    }

    if (postType === 'BIRTHDAY_REMINDER') {
      div.innerHTML = `${div.innerHTML} has a birthday today.`;
    }

    if (postType === 'CANCEL_FOOD_ORDER_NOTIFICATION') {
      div.innerHTML = ``;
    }
  } else if (
    typeof notificationEntity.originalPost !== 'undefined' &&
    notificationEntity.originalPost?.text
  ) {
    div.innerHTML = notificationEntity.originalPost?.text;
  } else {
    div.innerHTML = '';
  }
  if (type === 'global-notification') {
    return div.textContent;
  }
  return div.textContent.length > 256 ? div.textContent.slice(0, 256) + '...' : div.textContent;
}

import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Input from 'library/common/commonComponents/Inputs/Input';
import { getGroupKidWrapRows } from 'library/api/kidDevelopmentDiary';
import { getKidsForCurrentUserInSelectedKita } from 'library/api/observationSheet';
import ChildRow from './ChildRow';
import styles from './childesContainer.module.scss';
import { getCheckInOutConfig, getKidsByGroup } from '../../../../../api/kids';
import Button from 'library/common/commonComponents/Buttons/Button';
import { getKidGroupAbsence } from 'library/api/user';
import ExportDiaryPopup from './ExportDiaryPopup';

const TagebuchHistorieContainer = ({ groupId }) => {
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [showOnlyCheckedIn, setShowOnlyCheckedIn] = useState(true); // Add state for toggle

  const [wrapActive, setWrapActive] = useState(false);
  const [foodActive, setFoodActive] = useState(false);
  const [sleepActive, setSleepActive] = useState(false);
  const [pedagogicalObservationActive, setPedagogicalObservationActive] = useState(false);
  const [kidsAbsencesGroupedByKidId, setKidsAbsencesGroupedByKidId] = useState({});
  const filteredDataPlus =
    searchValue.length > 0
      ? filteredData.filter(item => item.name.toLowerCase().includes(searchValue.toLowerCase()))
      : filteredData;

  const [showExportPopup, setShowExportPopup] = useState(false);

  useEffect(() => {
    if (typeof groupId !== 'undefined') {
      getCheckInOutConfig(groupId).then(res => {
        setWrapActive(res.data.wrapActive);
        setFoodActive(res.data.foodActive);
        setSleepActive(res.data.sleepActive);
        setPedagogicalObservationActive(res.data.pedagogicalObservationActive);
      });
    }
  }, []);
  useEffect(() => {
    if (typeof groupId !== 'undefined') {
      getKidGroupAbsence(groupId).then(res => {
        const kidsAbsencesGroupedByKidId = res.data.reduce((acc, cur) => {
          if (!acc[cur.kid.id]) {
            acc[cur.kid.id] = [];
          }
          acc[cur.kid.id].push(cur);
          return acc;
        }, {});
        setKidsAbsencesGroupedByKidId(kidsAbsencesGroupedByKidId);
      });
      getKidsByGroup(groupId).then(res => {
        const newfilteredData =
          searchValue.length > 0
            ? res.data.filter(item => item.name.toLowerCase().includes(searchValue.toLowerCase()))
            : res.data;

        setFilteredData(newfilteredData);
        setAllData(newfilteredData);
      });
    } else {
      getKidsForCurrentUserInSelectedKita().then(data => {
        const newfilteredData =
          searchValue.length > 0
            ? data.data.filter(
                item =>
                  (item.firstName.toLowerCase() + ' ' + item.lastName.toLowerCase()).includes(
                    searchValue.toLowerCase(),
                  ) ||
                  (item.lastName.toLowerCase() + ' ' + item.firstName.toLowerCase()).includes(
                    searchValue.toLowerCase(),
                  ),
              )
            : data.data;
        setFilteredData(newfilteredData);
      });
    }
  }, [searchValue]);

  // Toggle event handler
  const handleToggleClick = () => {
    setShowOnlyCheckedIn(!showOnlyCheckedIn);
    setFilteredData(filteredChildren);
  };

  // Filter the data based on the current toggle state
  const filteredChildren = showOnlyCheckedIn
    ? filteredData.filter(child => child.checkInOutStatus === 'CHECKIN')
    : allData;
  return (
    <div>
      <ExportDiaryPopup
        isOpened={showExportPopup}
        closePopup={() => setShowExportPopup(false)}
        childRowData={filteredDataPlus.map(item => ({
          childObj: item,
          kidAbsences: kidsAbsencesGroupedByKidId[item.kidId],
          wrapActive,
          foodActive,
          sleepActive,
          pedagogicalObservationActive,
        }))}
      />

      <div className={styles.buttonRow}>
        <Button onClick={handleToggleClick} className={styles.button}>
          {showOnlyCheckedIn
            ? t('Checkinout.ShowCheckedInChildrenOnly')
            : t('Checkinout.ShowAllChildren')}
        </Button>
        <Button onClick={() => setShowExportPopup(true)}>{t('Checkinout.ExportDiary')}</Button>
      </div>
      <div className={styles.searchInputWrap}>
        <Input
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          placeholder={t('Checkinout.Search by name')}
        />
      </div>
      <div className={styles.header}>
        <div className={styles.name}>{t('Checkinout.Name')}</div>
      </div>

      {filteredDataPlus.map(item => {
        return (
          <ChildRow
            childObj={item}
            wrapActive={wrapActive}
            foodActive={foodActive}
            sleepActive={sleepActive}
            pedagogicalObservationActive={pedagogicalObservationActive}
            key={item.kidId}
            kidAbsences={kidsAbsencesGroupedByKidId[item.kidId]}
          />
        );
      })}
    </div>
  );
};

export default TagebuchHistorieContainer;

import axios from 'library/api/axios';
import config, { getPort } from 'main/config';

const URL = `${config.API_BASE_URI}${getPort(8095)}/api/notifications`;

export const getNotifications = (page, size = 10, filters) => {
  const payload = {
    page,
    size,
    sort: ['createdAt', 'desc'],
  };
  if (filters) payload.filter = getNotificationsFilters(filters);
  return axios.post(`${URL}/_page`, payload);
};

export const deleteNotifications = ids => {
  return axios.post(`${URL}/delete`, ids);
};

export const patchNotification = notifications => axios.patch(URL, notifications);

export const markAllAsRead = (isVirtualKita = false) =>
  axios.patch(`${URL}/mark-all-as-read`, null, { params: { isVirtualKita } });

export const getUnreadNotificationsCountPayload = kitaIds => ({
  method: 'get',
  url: `${URL}/unread-count`,
  settings: {
    params: {
      kitaIds: kitaIds && kitaIds.length ? kitaIds.reduce((f, s) => `${f},${s}`) : '',
    },
  },
});

export const getUnreadNotificationsCount = kitaIds =>
  axios.get(`${URL}/unread-count`, {
    params: {
      kitaIds: kitaIds && kitaIds.length ? kitaIds.reduce((f, s) => `${f},${s}`) : '',
    },
  });

export const sendGlobalNotification = (data, onlyAdminAndEmployee) =>
  axios.post(
    `${config.API_BASE_URI}${getPort(8080)}/api/globalNotification/send?text=${encodeURI(
      data,
    )}&onlyadminandemployee=${onlyAdminAndEmployee}`,
  );

export const sendReleaseNotesMail = releaseNotesReceiver =>
  axios.post(
    `${config.API_BASE_URI}${getPort(8080)}/api/releaseNotes/send/${releaseNotesReceiver}`,
  );

export function getNotificationsFilters(filters) {
  const resultedFilters = [];
  Object.keys(filters).forEach(key => {
    if (filters[key]) {
      if (resultedFilters.length > 0) {
        resultedFilters.push('or');
      }

      resultedFilters.push(`eventType:eq:${key}`);
    }
  });

  return resultedFilters;
}

export const getNotificationsConfiguration = () =>
  axios.get(`${config.API_BASE_URI}${getPort(8095)}/api/user/notificationconfigurations/`);


export const removeNotificationGroupsAWO = () => axios.post(`${config.API_BASE_URI}${getPort(8095)}/api/user/notificationconfigurations/removeAllGroupsFromNotification`);

export const addFavoritesToGroupsAWO = () => axios.post(`${config.API_BASE_URI}${getPort(8095)}/api/user/notificationconfigurations/addFavoriteGroupsToNotificationsOfAWO`);

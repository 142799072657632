import { combineReducers } from 'redux';

import UserReducer from 'library/common/commonReducers/userReducer';
import AuthReducer from 'library/common/commonReducers/authReducer';
import GroupReducer from 'library/common/commonReducers/groupReducer';
import KitaReducer from 'library/common/commonReducers/kitaReducer';
import InputErrorReducer from 'library/common/commonReducers/inputErrorReducer';
import ButtonDisableReducer from 'library/common/commonReducers/buttonDisableReducer';
import NotificationsReducer from 'library/common/commonReducers/notificationsReducer';
import LanguageReducer from 'library/common/commonReducers/changeLanguageReducer';
import ChatReducer from 'library/common/commonReducers/chatReducer';
import FileReducer from 'library/common/commonReducers/fileReducer';
import DisclaimerReducer from 'library/common/commonReducers/disclaimerReducer';
import EditorReducer from 'library/common/commonReducers/editorReducer';
import TenantReducer from 'library/common/commonReducers/tenantReducer';
import TranslationsReducer from 'library/common/commonReducers/translationsReducer';
import SignatureReducer from 'library/common/commonReducers/signatureReducer';
import ModuleSettingsReducer from '../library/common/commonReducers/moduleSettingsReducer';
import FormEditorReducer from '../library/common/commonReducers/formEditorReducer';
import AbsenceReportReducer from '../library/common/commonReducers/absenceReportReducer';
import ChatListPopupReducer from '../library/common/commonReducers/chatListPopupReducer';
import TimeRangeAbsenceReducer from '../library/common/commonReducers/timerangeAbsenceReducer';
import UnreadCountChatReducer from '../library/common/commonReducers/unreadCountChatReducer';

export default combine();

export function combine() {
  return combineReducers({
    authReducer: AuthReducer,
    buttonDisableReducer: ButtonDisableReducer,
    inputErrorReducer: InputErrorReducer,
    languageReducer: LanguageReducer,
    groupReducer: GroupReducer,
    notificationsReducer: NotificationsReducer,
    userReducer: UserReducer,
    kitaReducer: KitaReducer,
    chatReducer: ChatReducer,
    fileReducer: FileReducer,
    disclaimerReducer: DisclaimerReducer,
    editorReducer: EditorReducer,
    tenantReducer: TenantReducer,
    translationsReducer: TranslationsReducer,
    signatureReducer: SignatureReducer,
    moduleSettingsReducer: ModuleSettingsReducer,
    formEditorReducer: FormEditorReducer,
    absenceReportReducer: AbsenceReportReducer,
    chatListPopupReducer: ChatListPopupReducer,
    timeRangeAbsenceReducer: TimeRangeAbsenceReducer,
    unreadCountChatReducer: UnreadCountChatReducer,
  });
}

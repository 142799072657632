import { useState, useEffect } from 'react';

import { getGroupById, setGlobalPublicGroup, getKitaByById } from 'library/api/groups';
import { setUserKita } from 'library/api/kita';
import { defaultGroupState } from '../groupConstants';
import { getKitaModules } from 'library/api/modules';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

export default function useGroupLoading(id, setGroupDataInStore, history, activeKita) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [groupInfo, setGroupInfo] = useState(defaultGroupState);
  const [reloadGroupInfo, setReloadGroupInfo] = useState(false);
  const [oldGroupInfo, setOldGroupInfo] = useState('');
  const [kitaInfoByGroup, setKitaInfoByGroup] = useState(null);
  const [showPortfolio, setShowPortfolio] = useState(false);

  const location = useLocation();

  const [navigatedFromPortfolio, setNavigatedFromPortfolio] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getKitaByById(id).then(kitaRes => {
      const kitaInfo = kitaRes.data;
      setKitaInfoByGroup(kitaInfo);
    });

    getKitaModules().then((res) => {
      if (res && res.data) {
        let result = res.data;
        let whiteboardActive = result.some(module => module.moduleKey == "whiteboard" && module.activeStatus);
        setShowPortfolio(location.state && location.state.from === 'portfolio' && whiteboardActive)
      }
    })

    return () => {
      setGroupInfo(defaultGroupState);
    };
    // eslint-disable-next-line
  }, [id, reloadGroupInfo, navigatedFromPortfolio]);

  useEffect(() => {
    if (activeKita?.kitaId && kitaInfoByGroup?.kitaId) {
      if (activeKita?.kitaId !== kitaInfoByGroup?.kitaId) {
        setUserKita(kitaInfoByGroup.kitaId).then(res => {
          if (res && res.status === 200) {
            window.location.reload();
          }
        });
      } else {
        setGroupinfo();
      }
    } else {
      setGroupinfo();
    }
  }, [activeKita, kitaInfoByGroup, showPortfolio]);

  const setGroupinfo = () => {
    getGroupById(id)
      .then(res => {
        switch (res.status) {
          case 200:
            setGroupInfo({
              ...res.data,
              isPrivate: res.data.visibility,
              groupModuleList: showPortfolio
                ? [
                    ...res.data.groupModuleList,
                    res.data.groupModuleList.some(module => module.moduleKey === 'whiteboard')
                      ? {} 
                      : {
                          moduleName: 'Whiteboard',
                          moduleKey: 'whiteboard',
                          moduleDescription: 'Whiteboard',
                          activeStatus: true,
                          configureStatus: false,
                        },
                  ].filter(module => Object.keys(module).length > 0)
                : [...res.data.groupModuleList],
            });
            
            setGroupDataInStore(res.data);
            setIsLoading(false);
            break;
          case 203:
            setGroupInfo({
              ...defaultGroupState,
              error: 203,
            });
            setIsLoading(false);
            break;
          default:
        }
      })
      .catch(res => {
        if (res.response) {
          switch (res.response.status) {
            case 406:
              setGroupInfo({
                ...defaultGroupState,
                error: 406,
              });
              setIsLoading(false);
              break;
            case 404:
              setGroupInfo({
                ...defaultGroupState,
                error: 404,
              });
              setIsLoading(false);
              break;
            case 423:
              setIsShowPopup(true);
              setIsLoading(false);
              break;
            default:
              setGroupInfo({
                ...defaultGroupState,
              });
              setIsLoading(false);
          }
        }
      });
  };

  const closePopup = () => {
    setIsShowPopup(false);
    history.push('/dashboard');
  };

  const handleConfirm = async () => {
    try {
      setIsSubmitting(true);
      await setGlobalPublicGroup(id, { consentAccepted: true });
      window.location.reload();
    } catch (err) {
      closePopup();
    }
  };

  return {
    isLoading,
    isShowPopup,
    groupData: groupInfo,
    isSubmitting,
    closePopup,
    handleConfirm,
    reloadGroupInfo: () => setReloadGroupInfo(!reloadGroupInfo)
  };
}

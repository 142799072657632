import {
  SET_CREATE_KITA_MODE,
  SET_NEW_KITA,
  SET_ACTIVE_KITA,
  REMOVE_ACTIVE_KITA,
  SET_MODULES,
  SET_IS_OPENED,
  SET_KITAS,
  GO_TO_NEW_KITA_SIGNAL,
  GO_TO_KITA_SIGNAL, SET_KITA_CONFIG,
} from 'library/common/commonConstants/kitaConstants';

const initialState = {
  isCreateKitaMode: false,
  newKita: null,
  activeKita: {},
  kitaConfig: {},
  modules: [],
  isOpened: true,
  kitas: null,
  goToNewKita: {
    kitaName: 'initial',
    signal: false,
  },
  goToKita: {
    kitaId: null,
    signal: false,
  },
};

export default function KitaReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CREATE_KITA_MODE:
      return {
        ...state,
        isCreateKitaMode: action.payload,
      };
    case SET_NEW_KITA:
      return {
        ...state,
        newKita: action.payload,
      };
    case SET_ACTIVE_KITA:
      return {
        ...state,
        activeKita: action.payload,
      };
    case SET_MODULES:
      return {
        ...state,
        modules: action.modules,
      };
    case SET_IS_OPENED:
      return {
        ...state,
        isOpened: action.isOpened,
      };
    case SET_KITAS:
      return {
        ...state,
        kitas: action.kitas,
        activeKita:
          action.kitas.length > 0
            ? action.kitas.find(kita => kita?.kitaId === state.activeKita?.kitaId) ?? {}
            : initialState.activeKita,
      };
    case SET_KITA_CONFIG:
      return {
        ...state,
        kitaConfig: action.kitaConfig,
      };
    case GO_TO_NEW_KITA_SIGNAL:
      return {
        ...state,
        goToNewKita: {
          kitaName: action.kitaName,
          signal: !state.goToNewKita.signal,
        },
      };
    case GO_TO_KITA_SIGNAL:
      return {
        ...state,
        goToKita: {
          kitaId: action.kitaId,
          signal: !state.goToKita.signal,
        },
      };
    case REMOVE_ACTIVE_KITA:
      return { ...initialState };
    default:
      return state;
  }
}

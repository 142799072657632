import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getKitaReligion } from 'library/api/kita';

import Loader from 'library/common/commonComponents/Loader';
import DisableModulePopup from 'library/common/commonComponents/Popups/DisableModulePopup';
import SetDefaultModulePopup from 'library/common/commonComponents/Popups/SetDefaultModulePopup';
import ActivateCoronaTestModulePopup from 'library/common/commonComponents/Popups/ActivateCoronaTestModulePopup';
import ActivateEmployeeCheckInOutPopup from 'library/common/commonComponents/Popups/ActivateEmployeeCheckInOutPopup';
import calendarModule from 'resources/images/modules/calendar_module.png';
import filesModule from 'resources/images/modules/files_module.png';
import chatModule from 'resources/images/modules/chat_module.png';
import galleryModule from 'resources/images/modules/gallery_module.png';
import surveysModule from 'resources/images/modules/surveys_module.png';
import tasksModule from 'resources/images/modules/tasks_module.png';
import translationsModule from 'resources/images/modules/translations_module.png';
import suggestionBoxModule from 'resources/images/modules/suggestion_box_module.png';
import parentSurveyModule from 'resources/images/modules/parent_survey_module.png';
import bazaarModule from 'resources/images/modules/bazaar_module.png';
import taxConsultantModule from 'resources/images/modules/tax_consultant_module.png';
import getModuleText from 'library/utilities/getModuleText';
import imageDownloadModule from 'resources/images/modules/image_download_module.png';
import absenceModule from 'resources/images/modules/absence_module.png';
import portfolioModule from 'resources/images/modules/portfolio_module.png';
import checkInOutModule from 'resources/images/modules/checkinout_module.png';
import signatureModule from 'resources/images/modules/signature_module.png';
import foodOrderModule from 'resources/images/modules/food_order_module.png';
import wikiModule from 'resources/images/modules/wiki.png';
import coronaTestModule from 'resources/images/modules/corona_test.png';
import whiteboardModule from 'resources/images/modules/whiteboard_module.png';
import employeePortfolioModule from 'resources/images/modules/employee_portfolio_module.png';
import kitalinoInterfaceModule from 'resources/images/modules/kitalino_interface_module.png';
import tableTemplatesModule from 'resources/images/modules/table_templates_module.png';
import { isAwoWW } from 'library/api/tenantConfig';

import Module from './InstalledFrames/Module';

import styles from './installed.module.scss';

export default function Installed({
  isLoading,
  modules,
  onOpenSettings,
  onSetAsDefault,
  onChangeActivate,
}) {
  const { t } = useTranslation();

  const modulesInfo = [
    {
      moduleKey: 'absence',
      img: absenceModule,
      title: t('Administration.ModulesSection.Absence reporting'),
      // eslint-disable-next-line max-len
      description: t('Administration.ModulesSection.Absence module description'),
    },
    {
      moduleKey: 'image_download',
      img: imageDownloadModule,
      title: t('Administration.ModulesSection.Download images'),
      // eslint-disable-next-line max-len
      description: t(
        'Administration.ModulesSection.Gives all users an option to download images from gallery and stream',
      ),
    },
    {
      moduleKey: 'files',
      img: filesModule,
      title: t('Administration.ModulesSection.Files'),
      description: t('Administration.ModulesSection.File manager for groups or user profiles'),
    },
    {
      moduleKey: 'calendar',
      img: calendarModule,
      title: t('Administration.ModulesSection.Calendar'),
      description: t('Administration.ModulesSection.Calendar for groups or user profiles'),
    },
    {
      img: galleryModule,
      moduleKey: 'gallery',
      title: t('Administration.ModulesSection.Gallery'),
      description: t('Administration.ModulesSection.Gallery for groups or user profiles'),
    },
    {
      img: surveysModule,
      moduleKey: 'survey',
      title: t('Administration.ModulesSection.Surveys'),
      description: t('Administration.ModulesSection.Simple survey system for groups'),
    },
    {
      img: portfolioModule,
      moduleKey: 'portfolio',
      title: t('Administration.ModulesSection.Portfolio'),
      description: t('Administration.ModulesSection.Portfolio for the children'),
    },
    {
      img: tasksModule,
      moduleKey: 'tasks',
      title: t('Administration.ModulesSection.Tasks'),
      description: t('Administration.ModulesSection.Simple taskmanager for your groups'),
    },
    {
      img: translationsModule,
      moduleKey: 'translations',
      title: t('Administration.ModulesSection.Dynamic translator'),
      description: t(
        'Administration.ModulesSection.Allows all users to translate the dynamic content',
      ),
    },
    {
      img: suggestionBoxModule,
      moduleKey: 'suggestion_box',
      title: t('DashboardSuggestionBox.title'),
      description: t('DashboardSuggestionBox.description'),
    },
    {
      img: parentSurveyModule,
      moduleKey: 'parent_survey',
      title: t('ParentSurvey.MouleSelection.Parent Survey'),
      description: t('ParentSurvey.MouleSelection.Allows you to create parent surveys'),
    },
    {
      img: checkInOutModule,
      moduleKey: 'checkinout',
      title: t('CheckInOut.MouleSelection.CheckInOut'),
      description: t('CheckInOut.MouleSelection.Allows you to check in and out your children'),
    },
    {
      img: checkInOutModule,
      moduleKey: 'employee_checkinout',
      title: t('CheckInOut.MouleSelection.EmployeeCheckInOut'),
      description: t('CheckInOut.MouleSelection.Allows your employees to check in and out'),
    },
    {
      img: taxConsultantModule,
      moduleKey: 'tax_consultant',
      title: t('TaxConsultant.ModuleSelection.TaxConsultant'),
      description: t('TaxConsultant.ModuleSelection.Feature to upload receipts'),
    },
    {
      img: bazaarModule,
      moduleKey: 'bazaar',
      title: t('Bazaar.ModuleSelection.Title'),
      description: t('Bazaar.ModuleSelection.Description'),
    },
    {
      img: signatureModule,
      moduleKey: 'signature',
      title: t('Signature.ModulesSection.Signature'),
      description: t('Signature.ModulesSection.Allows to create posts with signable documents'),
    },
    {
      img: foodOrderModule,
      moduleKey: 'food_order',
      title: t('FoodOrder.ModulesSection.Signature'),
      description: t('FoodOrder.ModulesSection.Preorder food for the kids'),
    },
    {
      img: wikiModule,
      moduleKey: 'wiki',
      title: t('Wiki.ModulesSection.Wiki'),
      description: t('Wiki.ModulesSection.Allows to maintain a wiki'),
    },
    {
      img: coronaTestModule,
      moduleKey: 'corona_test',
      title: t('CoronaTest.Corona test'),
      description: t('CoronaTest.Allows parents to capture corona tests'),
    },
    {
      img: chatModule,
      moduleKey: 'chat',
      title: t('Chat.Chat'),
      description: t('Chat.Enables personal messages'),
    },
    {
      img: whiteboardModule,
      moduleKey: 'whiteboard',
      title: t('Whiteboard.Whiteboard'),
      description: t('Whiteboard.Enables whiteboard'),
    },
    {
      img: employeePortfolioModule,
      moduleKey: 'employee_portfolio',
      title: t('EmployeePortfolio.Employee portfolio'),
      description: t('EmployeePortfolio.Enables employee portfolio'),
    },
    {
      img: calendarModule,
      moduleKey: 'holiday_care',
      title: t('Administration.ModulesSection.Holiday care'),
      description: t('Administration.ModulesSection.Holiday care module description'),
    },
    {
      img: calendarModule,
      moduleKey: 'working_hours',
      title: t('Administration.ModulesSection.Dienstplan'),
      description: t('Administration.ModulesSection.DienstplanDescription'),
    },
    {
      img: kitalinoInterfaceModule,
      moduleKey: 'kitalino_interface',
      title: t('Administration.ModulesSection.Kitalino interface'),
      description: t('Administration.ModulesSection.Kitalino interface description'),
    },
    {
      img: tableTemplatesModule,
      moduleKey: 'table_templates',
      title: t('Administration.ModulesSection.Table templates'),
      description: t('Administration.ModulesSection.Table templates description'),
    },
  ];

  const [isOpened, setIsOpened] = useState(false);
  const [activeModule, setAciveModule] = useState({});

  const handleOpenPopup = (action, module) => {
    if (action === 'disable') {
      if (
        module.moduleKey === 'absence' ||
        module.moduleKey === 'image_download' ||
        module.moduleKey === 'employee_portfolio' ||
        module.moduleKey === 'chat' ||
        module.moduleKey === 'tax_consultant'
      ) {
        onChangeActivate({ ...module, activeStatus: false }, 'DEACTIVATE');
      } else {
        setAciveModule(module);
        setIsOpened(action);
      }
    } else {
      setAciveModule(module);
      setIsOpened(action);
    }
  };

  const handleClosePopup = () => {
    setAciveModule({});
    setIsOpened(false);
  };

  const handleConfirmDisable = async disableOption => {
    await onChangeActivate({ ...activeModule, activeStatus: false }, disableOption);
    handleClosePopup();
  };

  const handleSetAsDefault = async (groupDefaultOption, userProfileDefaultOption) => {
    await onSetAsDefault({
      ...activeModule,
      groupModuleStatus: groupDefaultOption,
      userModuleStatus: userProfileDefaultOption,
    });
    handleClosePopup();
  };

  const handleChangeActivate = module => {
    if (module.activeStatus) {
      handleOpenPopup('disable', module);
      return;
    }

    if (module.moduleKey === 'corona_test') {
      getKitaReligion().then(data => {
        if (data.data === null || data.data === '' || data.data === 0) {
          setAciveModule(module);
          setIsOpened('activateCoronaTestModule');
        } else {
          onChangeActivate({ ...module, activeStatus: true }, 'ENABLE');
        }
      });
      return;
    }

    if (module.moduleKey === 'employee_checkinout') {
      setAciveModule(module);
      setIsOpened('activateEmployeeCheckInOut');
      return;
    }

    onChangeActivate({ ...module, activeStatus: true }, 'ENABLE');
  };

  if (isAwoWW()) {
    modules = modules.filter(md => md.moduleKey !== 'absence' && md.moduleKey !== 'working_hours');
  }
  if (window.location.hostname.toLowerCase().includes('kita-app-awo-lv-sh.safe2connect.org')) {
    modules = modules.filter(
      md => md.moduleKey !== 'working_hours' && md.moduleKey !== 'image_download',
    );
  }

  if (window.location.hostname.toLowerCase().includes('ekitanet.safe2connect.org')) {
    modules = modules.filter(
      md => md.moduleKey !== 'files' && md.moduleKey !== 'image_download' && md.moduleKey !== 'tasks',
    );
  }

  if (window.location.hostname.toLowerCase().includes('jokikinderbetreuung.safe2connect.org')) {
    modules = modules.filter(
      md =>
        md.moduleKey === 'absence' ||
        md.moduleKey === 'calendar' ||
        md.moduleKey === 'parent_survey' ||
        md.moduleKey === 'files' ||
        md.moduleKey === 'image_download' ||
        md.moduleKey === 'survey' ||
        md.moduleKey === 'tasks',
    );
  }

  return isLoading ? (
    <Loader />
  ) : (
    <div className={styles.container}>
      {modules.length !== 0 &&
        modules.map(item => {
          let foundModule = modulesInfo.find(mod => mod.moduleKey === item.moduleKey);
          if (!foundModule) {
            foundModule = {
              img: tasksModule,
              moduleName: 'Unknown module',
              title: 'Unknown',
            };
          }
          return (
            <Module
              key={item.moduleName}
              moduleInfo={modulesInfo.find(mod => mod.moduleKey === item.moduleKey) || foundModule}
              module={item}
              onSetAsDefault={module => handleOpenPopup('default', module)}
              onChangeActivate={module => handleChangeActivate(module)}
              onOpenSettings={onOpenSettings}
            />
          );
        })}

      {isOpened === 'activateCoronaTestModule' && (
        <ActivateCoronaTestModulePopup
          setIsOpened={setIsOpened}
          isOpened={isOpened === 'activateCoronaTestModule'}
          onChangeActivate={onChangeActivate}
          module={activeModule}
          setAciveModule={setAciveModule}
        />
      )}
      {isOpened === 'activateEmployeeCheckInOut' && (
        <ActivateEmployeeCheckInOutPopup
          setIsOpened={setIsOpened}
          isOpened={isOpened === 'activateEmployeeCheckInOut'}
          onChangeActivate={onChangeActivate}
          module={activeModule}
          setAciveModule={setAciveModule}
        />
      )}
      {isOpened === 'disable' && activeModule.moduleName && (
        <DisableModulePopup
          text={getModuleText(activeModule)}
          closePopup={handleClosePopup}
          onConfirm={handleConfirmDisable}
          isOpened={isOpened === 'disable'}
          justDiactivate={activeModule.moduleKey === 'translations'}
        />
      )}
      {isOpened === 'default' && (
        <SetDefaultModulePopup
          module={activeModule}
          closePopup={handleClosePopup}
          onConfirm={handleSetAsDefault}
          isOpened={isOpened === 'default'}
        />
      )}
    </div>
  );
}

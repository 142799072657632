import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import InputErrorMsg from 'library/common/commonComponents/to-refactor/commonInputErrorMsg';
import CheckBox from 'library/common/commonComponents/Checkbox';
import SelectSpace from 'library/common/commonComponents/SelectSpace';
import Loader from 'library/common/commonComponents/Loader';
import cachedRequest from 'library/common/commonHooks/useCachedRequest';
import Storage from 'library/utilities/storage';

import {
  getNotificationConfiguration,
  saveNotificationConfiguration,
  updateDesktopNotificationUser,
  showBottomNotification,
  resetNotificationSettings,
} from 'library/common/commonActions/notificationsActions';
import { getGroupsPayload } from 'library/api/groups';
import styles from './notificationsFrame.module.scss';

export class NotificationsFrame extends Component {
  static getDerivedStateFromProps(props, state) {
    if (
      (!state.settingsLoading &&
        (props.user &&
          props.user.id &&
          (!props.admin && (!props.userConfiguration || !props.desktopNotificationUser)))) ||
      (props.admin &&
        (!props.globalConfiguration || !props.desktopNotificationAdmin) &&
        !state.settingsLoading)
    ) {
      props.getNotificationConfiguration({ admin: props.admin, user: props.user });
      return {
        settingsLoading: true,
      };
    } else if (!state.settingsLoaded && props.admin && props.globalConfiguration) {
      return {
        desktopNotifications: props.desktopNotificationAdmin
          ? props.desktopNotificationAdmin.enabled
          : false,
        globalConfiguration: props.globalConfiguration,
        groups: props.desktopNotificationAdmin && props.desktopNotificationAdmin.groups,
        settingsLoaded: true,
        settingsLoading: false,
      };
    } else if (!state.settingsLoaded && props.userConfiguration) {
      return {
        desktopNotifications: props.desktopNotificationUser
          ? props.desktopNotificationUser.enabled
          : false,
        globalConfiguration: props.globalConfiguration,
        groups: props.desktopNotificationUser && props.desktopNotificationUser.groups,
        userConfiguration: props.userConfiguration,
        settingsLoaded: true,
        settingsLoading: false,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.selectSpace = null;
    this.state = {
      desktopNotifications: false,
      loadingSave: false,
      message: '',
      messageError: '',
      globalConfiguration: null,
      userConfiguration: null,
      settingsLoading: false,
      settingLoaded: false,
      nativePushPermissionsGrantedOrUnclear: true,
    };
  }

  componentDidMount() {
    if (!this.props.admin) {
      document.title = this.props.t('Titles.NotificationSettings');
    }
    if (window.ReactNativeWebView) {
      [window, document].forEach(el =>
        el.addEventListener(
          'nativePushPermissionsGrantedOrUnclear',
          this.onNativePushPermissionFeedback,
          false,
        ),
      );
    }
  }

  componentWillUnmount() {
    this.props.resetNotificationSettings();
    if (window.ReactNativeWebView) {
      [window, document].forEach(el =>
        el.removeEventListener(
          'nativePushPermissionsGrantedOrUnclear',
          this.onNativePushPermissionFeedback,
          false,
        ),
      );
    }
  }

  onNativePushPermissionFeedback = e => {
    this.setState({ nativePushPermissionsGrantedOrUnclear: e.detail === false ? false : true });
  };

  getSpaces = async () => {
    const newSpaces = {};
    const { data } = await cachedRequest(getGroupsPayload);
    if (data) {
      data.forEach(space => {
        newSpaces[space.id] = space;
      });
    }
    return newSpaces;
  };

  setIsChecked = () => {
    this.setState(state => ({ desktopNotifications: !state.desktopNotifications }));
  };

  updateCheckbox = (type, notificationKey) => () => {
    let configurationKey = 'userConfiguration';
    if (this.props.admin) {
      configurationKey = 'globalConfiguration';
    }
    this.setState(state => ({
      [configurationKey]: {
        ...state[configurationKey],
        [notificationKey]: {
          ...state[configurationKey][notificationKey],
          [type]: !state[configurationKey][notificationKey][type],
        },
      },
    }));
  };

  requestAppNotificationPermission = permissions => {
    let atLeastSingleMobilePermissionIsActive = false;
    for (const [key, value] of Object.entries(permissions)) {
      if (value.mobile && value.mobile === true) {
        atLeastSingleMobilePermissionIsActive = true;
      }
    }
    if (atLeastSingleMobilePermissionIsActive) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: 'show_push_notification_permission_dialogue',
        }),
      );
    }
  };

  onSaveClick = () => {
    if (this.state.loadingSave) {
      return;
    }
    let groups = {};
    let configurationKey = 'globalConfiguration';
    let { desktopNotificationAdmin, desktopNotificationUser } = this.props;
    if (!this.props.admin) {
      if (this.selectSpace && this.selectSpace.getGroupsAndIds) {
        groups = this.selectSpace.getGroupsAndIds();
      }
      desktopNotificationUser = {
        ...desktopNotificationUser,
        enabled: this.state.desktopNotifications,
        groups: groups.groups,
      };
      configurationKey = 'userConfiguration';
    } else {
      desktopNotificationAdmin = {
        ...desktopNotificationAdmin,
        enabled: this.state.desktopNotifications,
      };
    }
    if (window.ReactNativeWebView) {
      this.requestAppNotificationPermission(this.state[configurationKey]);
    }

    const settings = [];
    if (this.state[configurationKey] && Object.keys(this.state[configurationKey]).length) {
      Object.keys(this.state[configurationKey]).map(key => {
        settings.push(this.state[configurationKey][key]);
        return false;
      });
      this.setState({ loadingSave: true }, () => {
        this.props
          .saveNotificationConfiguration({
            admin: this.props.admin,
            dataToSend: {
              desktopNotifications: this.state.desktopNotifications,
              groups: groups.ids,
              values: settings,
            },
            desktopNotificationAdmin,
            desktopNotificationUser,
            globalConfiguration: this.state.globalConfiguration,
            userConfiguration: this.state.userConfiguration,
          })
          .then(res => {
            if (res.success) {
              this.props.showBottomNotification(<Trans i18nKey='BottomNotifications.Saved' />);
              try {
                Storage.setItem(
                  `canShowNotification/${this.props.user.id}`,
                  this.state.desktopNotifications,
                );
                Storage.setItem(
                  `groupsForNewContentNotifications/${this.props.user.id}`,
                  this.props.desktopNotificationUser.groups,
                );
              } catch (ex) {
                console.error(ex);
              }
            } else {
              this.props.showBottomNotification(
                <Trans i18nKey='BottomNotifications.Something went wrong' />,
                { isFail: true },
              );
            }
            this.setState({ loadingSave: false });
          });
      });
    }
  };

  onResetClick = () => {
    this.setState({
      globalConfiguration: this.props.globalConfiguration,
      userConfiguration: this.props.globalConfiguration,
    });
  };

  onTryAgain = () => {
    this.setState({ settingsLoading: true }, () => {
      this.props.getNotificationConfiguration({
        admin: this.props.admin,
        user: this.props.user,
        cb: () => {
          this.setState({ settingsLoading: false });
        },
      });
    });
  };

  updateNotificationSettings = newGroups => {
    if (!this.props.admin) {
      const desktopNotificationUser = {
        ...this.props.desktopNotificationUser,
        enabled: this.state.desktopNotifications,
        groups: newGroups,
      };
      this.props.updateDesktopNotificationUser(desktopNotificationUser);
    }
  };

  checkEquality() {
    const { globalConfiguration, userConfiguration } = this.state;
    let equal = true;
    if (Object.keys(userConfiguration).length) {
      Object.keys(userConfiguration).forEach(key => {
        if (
          (globalConfiguration[key] &&
            globalConfiguration[key]?.web !== userConfiguration[key]?.web) ||
          globalConfiguration[key]?.email !== userConfiguration[key]?.email ||
          globalConfiguration[key]?.mobile !== userConfiguration[key]?.mobile
        ) {
          equal = false;
        }
      });
    }
    return equal;
  }

  render() {
    const {
      globalConfiguration,
      desktopNotifications,
      groups,
      loadingSave,
      message,
      messageError,
      userConfiguration,
      settingsLoading,
      settingsLoaded,
      nativePushPermissionsGrantedOrUnclear,
    } = this.state;
    const { admin, t } = this.props;

    let configurationKey = 'userConfiguration';
    let configuration = userConfiguration;
    let receiveDesktopNotificationsText =
      'AccountSettings.NotificationsSection.Receive desktop notifications when you are online - user';
    let descriptionText =
      'AccountSettings.NotificationsSection.This view allows you to configure - user';
    if (this.props.admin) {
      configurationKey = 'globalConfiguration';
      configuration = globalConfiguration;
      receiveDesktopNotificationsText =
        'AccountSettings.NotificationsSection.Receive desktop notifications when you are online - admin';
      descriptionText =
        'AccountSettings.NotificationsSection.This view allows you to configure - admin';
    }
    const spacesTitle =
      'AccountSettings.NotificationsSection.Receive New Content Notifications for the following spaces';

    if (settingsLoading || !settingsLoaded) {
      return (
        <div className={styles.container}>
          <div className={styles.loadingContainer}>
            <Loader />
          </div>
        </div>
      );
    }

    if (configuration && Object.keys(configuration).length) {
      console.log(Object.keys(configuration));
      return (
        <div className={styles.container}>
          <div className={styles.description}>
            <Trans i18nKey='AccountSettings.NotificationsSection.Notifications are sent instantly' />
          </div>
          <div className={styles.description}>
            <Trans i18nKey={descriptionText} />
          </div>
          <CheckBox
            data-test='desktop-notifications-checkbox'
            className={styles.checkbox}
            name={<Trans i18nKey={receiveDesktopNotificationsText} />}
            isChecked={desktopNotifications}
            onChange={this.setIsChecked}
          />
          {!admin && (
            <div className={styles.spacesContainer}>
              <strong>
                <Trans i18nKey={spacesTitle} />
              </strong>
              <SelectSpace
                ref={ref => {
                  this.selectSpace = ref;
                }}
                getSpaces={this.getSpaces}
                selectedGroups={groups}
                updateDesktopNotificationUser={this.updateNotificationSettings}
              />
            </div>
          )}

          <div className={styles.grid}>
            <div className={`${styles.gridRow} ${styles.gridRowTitle}`}>
              <div>
                <Trans i18nKey='AccountSettings.NotificationsSection.Type' />
              </div>
              {/* <div>
                <Trans i18nKey='AccountSettings.NotificationsSection.Web' />
              </div> */}
              <div>
                <Trans i18nKey='AccountSettings.NotificationsSection.Email' />
              </div>
              <div>
                <Trans i18nKey='AccountSettings.NotificationsSection.Mobile' />
              </div>
            </div>

            {configuration && Object.keys(configuration).length > 0 && (
              <>
                {Object.keys(configuration)
                  .sort((a, b) => a.localeCompare(b))
                  .filter(
                    configuration =>
                      !configuration.includes('FRIENDSHIP') &&
                      !configuration.includes('READ_REQUEST'),
                  )
                  .map((key, i) => {
                    if (configuration[key] && Object.keys(configuration[key]).length) {
                      return (
                        <div
                          key={key}
                          className={cn(
                            styles.gridRow,
                            Object.keys(configuration).length === i + 1 && styles.noBorder,
                          )}
                        >
                          <div className={styles.nameDescriptionContainer}>
                            <div className={styles.width100}>
                              <strong>
                                <Trans
                                  i18nKey={`AccountSettings.NotificationsSection.NotificationTypes.${key}.name`}
                                />
                              </strong>
                            </div>
                            <div className={styles.width100}>
                              <Trans
                                i18nKey={`AccountSettings.NotificationsSection.NotificationTypes.${key}.description`}
                              />
                            </div>
                          </div>
                          {/* <div className={styles.checkboxFlex}>
                            <CheckBox
                              checkboxTextClass={styles.checkboxTextClass}
                              data-test='notifications-checkbox-web'
                              isChecked={configuration[key].web}
                              isDisabled={
                                (configurationKey === 'userConfiguration' &&
                                  !globalConfiguration[key].web) ||
                                configuration[key].type === 'MESSAGE' ||
                                configuration[key].type === 'CONVERSATION' ||
                                (configuration[key].type === 'READ_REQUEST' &&
                                  !configuration['NEW_CONTENT']?.web &&
                                  this.props.user.administrationAccess)
                              }
                              onChange={() => {
                                this.updateCheckbox('web', key)();
                                if (
                                  configuration[key].type === 'NEW_CONTENT' &&
                                  configuration['READ_REQUEST'].web
                                ) {
                                  this.updateCheckbox('web', 'READ_REQUEST')();
                                }
                              }}
                            />
                          </div> */}
                          <div className={styles.checkboxFlex}>
                            <CheckBox
                              checkboxTextClass={styles.checkboxTextClass}
                              data-test='notifications-checkbox-email'
                              isChecked={configuration[key].email}
                              isDisabled={
                                configurationKey === 'userConfiguration' &&
                                !globalConfiguration[key]?.email
                              }
                              onChange={this.updateCheckbox('email', key)}
                            />
                          </div>
                          <div className={styles.checkboxFlex}>
                            <CheckBox
                              checkboxTextClass={styles.checkboxTextClass}
                              data-test='notifications-checkbox-mobile'
                              isChecked={configuration[key]?.mobile}
                              isDisabled={
                                (configurationKey === 'userConfiguration' &&
                                  !globalConfiguration[key]?.mobile) ||
                                (configuration[key].type === 'READ_REQUEST' &&
                                  !configuration['NEW_CONTENT']?.mobile &&
                                  this.props.user.administrationAccess)
                              }
                              onChange={() => {
                                this.updateCheckbox('mobile', key)();
                                if (
                                  configuration[key]?.type === 'NEW_CONTENT' &&
                                  configuration['READ_REQUEST'].mobile
                                ) {
                                  this.updateCheckbox('mobile', 'READ_REQUEST')();
                                }
                              }}
                            />
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })}
              </>
            )}
          </div>

          <br />
          {message && (
            <div className={styles.message}>
              <InputErrorMsg
                className={styles.successText}
                errorMsg={<Trans i18nKey='AccountSettings.NotificationsSection.Success' />}
              />
            </div>
          )}
          {messageError && (
            <div className={styles.message}>
              <InputErrorMsg
                errorMsg={<Trans i18nKey='AccountSettings.NotificationsSection.Error' />}
              />
            </div>
          )}
          {!nativePushPermissionsGrantedOrUnclear && (
            <div className={styles.message} style={{ color: 'blue' }}>
              <i className='fa fa-info-circle' style={{ color: 'blue' }} />{' '}
              {t(
                'AccountSettings.NotificationsSection.Error.Push notification permission not granted for app on device level. Mobile push will not work',
              )}
            </div>
          )}
          <div className={styles.buttonsContainer}>
            <ButtonWithLoader
              className={styles.buttonPrimary}
              data-test='button-save'
              type='primary'
              onClick={this.onSaveClick}
              isLoading={loadingSave}
            >
              <Trans i18nKey='AccountSettings.NotificationsSection.Save' />
            </ButtonWithLoader>
            {!admin && !this.checkEquality() && (
              <Button data-test='button-reset' type='button' onClick={this.onResetClick}>
                <Trans i18nKey='AccountSettings.NotificationsSection.Reset to default' />
              </Button>
            )}
          </div>
        </div>
      );
    }
    return (
      <div className={styles.container}>
        <div className={styles.loadingContainer}>
          <Trans i18nKey='AccountSettings.NotificationsSection.Data not found' />
          <Button
            className={styles.tryAgainButton}
            data-test='button-reset'
            type='button'
            onClick={this.onTryAgain}
          >
            <Trans i18nKey='AccountSettings.NotificationsSection.Try again' />
          </Button>
        </div>
      </div>
    );
  }
}

NotificationsFrame.defaultProps = {
  admin: false,
};

NotificationsFrame.propTypes = {
  admin: PropTypes.bool,
  saveNotificationConfiguration: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  desktopNotificationAdmin: state.notificationsReducer.desktopNotificationAdmin,
  desktopNotificationUser: state.notificationsReducer.desktopNotificationUser,
  userConfiguration: state.notificationsReducer.userConfiguration,
  globalConfiguration: state.notificationsReducer.globalConfiguration,
  user: state.userReducer,
});

export default connect(
  mapStateToProps,
  {
    getNotificationConfiguration,
    saveNotificationConfiguration,
    updateDesktopNotificationUser,
    showBottomNotification,
    resetNotificationSettings,
  },
)(withTranslation()(NotificationsFrame));

import React, { useEffect, useState } from 'react';
import TextArea from 'library/common/commonComponents/Inputs/TextArea';
import Button from 'library/common/commonComponents/Buttons/Button';
import Input from 'library/common/commonComponents/Inputs/Input';
import Select from 'library/common/commonComponents/Inputs/Select';
import store from 'main/store/configureStore';
import Dictaphone from 'library/common/commonComponents/Dictaphone';
import { sanitize } from 'dompurify';

import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import {
  getAllKidsFromSelectedKita,
  postGfzColleagueExchange,
  getGfzColleagueExchange,
  deleteGfzColleagueExchange,
} from 'library/api/observationSheet';
import { createPDFColleagueExchange } from 'library/utilities/files';
import { createKidPortfolioGroupIfNeeded } from 'library/api/kids';
import gfzLogo from 'resources/images/gfz/gfz_logo.png';
import { useTranslation } from "react-i18next";

export default function ColleagueExchange({ userId }) {
  const [progress, setProgress] = useState(0);
  const [selectedKid, setSelectedKid] = useState([]);
  const [kidsList, setKidsList] = useState([]);
  const [colleagueExchange, setColleagueExchange] = useState({
    date: '',
    presentPersons: '',
    variousObservations: '',
    commonObservations: '',
    nextSteps: '',
    storyAuthor: '',
    storyToldUntil: '',
    storyResponsible: '',
    storyTimeSpan: '',
    kidId: null,
  });

  const { t } = useTranslation();

  useEffect(() => {
    getAllKidsFromSelectedKita().then(data => {
      const unformattedKidsList = data.data;
      const formattedKidsList = unformattedKidsList.map(obj => {
        return { label: obj.firstName + ' ' + obj.lastName, id: obj.kidId, portfolioGroupId: obj.portfolioGroupId };
      });
      setKidsList(formattedKidsList);
    });
  }, []);

  function handleTextStateChange(newValue, property) {
    setColleagueExchange(prev => {
      return {
        ...prev,
        [property]: newValue,
      };
    });
  }

  function resetForm() {
    setColleagueExchange(
      {
        date: '',
        presentPersons: '',
        variousObservations: '',
        commonObservations: '',
        nextSteps: '',
        storyAuthor: '',
        storyToldUntil: '',
        storyResponsible: '',
        storyTimeSpan: '',
        kidId: null,
      });
    setSelectedKid([]);
  }

  function setVariousObservationsText(text) {
    setColleagueExchange(prev => {
      return { ...prev, variousObservations: `${prev.variousObservations} ${text}` };
    });
  }

  function setCommonObservationsText(text) {
    setColleagueExchange(prev => {
      return { ...prev, commonObservations: `${prev.commonObservations} ${text}` };
    });
  }

  function setNextStepsText(text) {
    setColleagueExchange(prev => {
      return { ...prev, nextSteps: `${prev.nextSteps} ${text}` };
    });
  }

  function setStoryAuthorText(text) {
    setColleagueExchange(prev => {
      return { ...prev, storyAuthor: `${prev.storyAuthor} ${text}` };
    });
  }

  function setStoryToldUntilText(text) {
    setColleagueExchange(prev => {
      return { ...prev, storyToldUntil: `${prev.storyToldUntil} ${text}` };
    });
  }

  function setStoryResponsibleText(text) {
    setColleagueExchange(prev => {
      return { ...prev, storyResponsible: `${prev.storyResponsible} ${text}` };
    });
  }

  function setStoryTimeSpanText(text) {
    setColleagueExchange(prev => {
      return { ...prev, storyTimeSpan: `${prev.storyTimeSpan} ${text}` };
    });
  }

  return (
    <>
      <img src={gfzLogo} style={{ width: '100%', maxWidth: '300px', height: 'auto' }} />
      <br />
      <br />
      <h2>
        {t('GFZEducationalWork.ColleagueExchange')}
      </h2>
      <br />
      <table>
        <tr>
          <td>
            {t('GFZEducationalWork.ColleagueExchangeKidName')}
          </td>
          <td>
            <Select
              options={kidsList}
              onSelect={e => {
                setSelectedKid(e);
                getGfzColleagueExchange(e.id).then(res => {
                  if (res.data != null) {
                    setColleagueExchange(res.data);
                  } else {
                    setColleagueExchange({
                      date: '',
                      presentPersons: '',
                      variousObservations: '',
                      commonObservations: '',
                      nextSteps: '',
                      storyAuthor: '',
                      storyToldUntil: '',
                      storyResponsible: '',
                      storyTimeSpan: '',
                      kidId: e.id,
                    });
                  }
                });
              }}
              selected={selectedKid}
              style={{ zIndex: '10000000000' }}
              autocomplete="new-password"
            />
          </td>
        </tr>
        <tr>
          <td>
            {t('GFZEducationalWork.ColleagueExchangeExchangeDate')}
          </td>
          <td>
            <Input
              type='date'
              value={colleagueExchange.date}
              onChange={({ target }) =>
                setColleagueExchange(prev => {
                  return {
                    ...prev,
                    date: target.value,
                  };
                })
              }
            />
          </td>
        </tr>
        <tr>
          <td>
            {t('GFZEducationalWork.ColleagueExchangePresents')}
          </td>
          <td>
            <Input
              type='text'
              maxLength={50}
              value={colleagueExchange.presentPersons}
              onChange={({ target }) =>
                setColleagueExchange(prev => {
                  return {
                    ...prev,
                    presentPersons: target.value,
                  };
                })
              }
            />
          </td>
        </tr>
      </table>
      <br />
      <table style={{ borderWidth: '1px', borderColor: '#aaaaaa', borderStyle: 'solid' }}>
        <tr>
          <td>
            <span style={{ fontWeight: 'bold' }}>{t('GFZEducationalWork.ColleagueExchangeObservationsAndImpressions')}</span>
            <br />
            {t('GFZEducationalWork.ColleagueExchangeInterestsOrTopics')}
            <br />
            {t('GFZEducationalWork.ColleagueExchangeHavingPartners')}
            <br />
            {t('GFZEducationalWork.ColleagueExchangeEverydayObservations')}
            <div style={{ position: 'relative', left: '5px', width: '55px' }}>
              <Dictaphone insText={setVariousObservationsText} />
            </div>
            <br />
            <br />
            <TextArea
              maxLength={255}
              value={colleagueExchange.variousObservations}
              onChange={e => handleTextStateChange(e.target.value, 'variousObservations')}
              type='text'
              placeholder={t('GFZEducationalWork.ColleagueExchangeTextAreaPlaceholder')}
            />
          </td>
        </tr>
        <tr>
          <td>
            <span style={{ fontWeight: 'bold' }}>
              {t('GFZEducationalWork.ColleagueExchangeCommonThread')}
            </span>
            <div style={{ position: 'relative', left: '5px', width: '55px' }}>
              <Dictaphone insText={setCommonObservationsText} />
            </div>
            <br />
            <br />
            <TextArea
              maxLength={255}
              value={colleagueExchange.commonObservations}
              onChange={e => handleTextStateChange(e.target.value, 'commonObservations')}
              type='text'
              placeholder={t('GFZEducationalWork.ColleagueExchangeTextAreaPlaceholder')}
            />
          </td>
        </tr>
        <tr>
          <td>
            <span style={{ fontWeight: 'bold' }}>{t('GFZEducationalWork.ColleagueExchangeNextStepIdeas')}</span>
            <br />
            {t('GFZEducationalWork.ColleagueExchangeReactedTo')}
            <br />
            {t('GFZEducationalWork.ColleagueExchangeSuggestion')}
            <br />
            {t('GFZEducationalWork.ColleagueExchangeContactPoints')}
            <br />
            {t('GFZEducationalWork.ColleagueExchangeLearningEnvironmentAdapting')}
            <br />
            {t('GFZEducationalWork.ColleagueExchangePedagogicalActions')}
            <div style={{ position: 'relative', left: '5px', width: '55px' }}>
              <Dictaphone insText={setNextStepsText} />
            </div>
            <br />
            <br />
            <TextArea
              maxLength={255}
              value={colleagueExchange.nextSteps}
              onChange={e => handleTextStateChange(e.target.value, 'nextSteps')}
              type='text'
              placeholder={t('GFZEducationalWork.ColleagueExchangeTextAreaPlaceholder')}
            />
          </td>
        </tr>
      </table>
      <br />
      <span style={{ fontWeight: 'bold' }}>{t('GFZEducationalWork.ColleagueExchangeLearningHistory')}</span>
      <br />
      <table style={{ borderWidth: '1px', borderColor: '#aaaaaa', borderStyle: 'solid' }}>
        <tr>
          <td>
            {t('GFZEducationalWork.ColleagueExchangeLearningStoryAuthor')}
            <div style={{ position: 'relative', left: '5px', width: '55px' }}>
              <Dictaphone insText={setStoryAuthorText} />
            </div>
            <br />
            <br />
            <TextArea
              maxLength={255}
              value={colleagueExchange.storyAuthor}
              onChange={e => handleTextStateChange(e.target.value, 'storyAuthor')}
              type='text'
              placeholder={t('GFZEducationalWork.ColleagueExchangeTextAreaPlaceholder')}
            />
          </td>
        </tr>
        <tr>
          <td>
            {t('GFZEducationalWork.ColleagueExchangeLearningStoryTime')}
            <div style={{ position: 'relative', left: '5px', width: '55px' }}>
              <Dictaphone insText={setStoryToldUntilText} />
            </div>
            <br />
            <br />
            <TextArea
              maxLength={255}
              value={colleagueExchange.storyToldUntil}
              onChange={e => handleTextStateChange(e.target.value, 'storyToldUntil')}
              type='text'
              placeholder={t('GFZEducationalWork.ColleagueExchangeTextAreaPlaceholder')}
            />
          </td>
        </tr>
        <tr>
          <td>
            {t('GFZEducationalWork.ColleagueExchangeResponsiblePerson')}
            <div style={{ position: 'relative', left: '5px', width: '55px' }}>
              <Dictaphone insText={setStoryResponsibleText} />
            </div>
            <br />
            <br />
            <TextArea
              maxLength={255}
              value={colleagueExchange.storyResponsible}
              onChange={e => handleTextStateChange(e.target.value, 'storyResponsible')}
              type='text'
              placeholder={t('GFZEducationalWork.ColleagueExchangeTextAreaPlaceholder')}
            />
          </td>
        </tr>
        <tr>
          <td>
            {t('GFZEducationalWork.ColleagueExchangeImplementationPeriod')}
            <div style={{ position: 'relative', left: '5px', width: '55px' }}>
              <Dictaphone insText={setStoryTimeSpanText} />
            </div>
            <br />
            <br />
            <TextArea
              maxLength={255}
              value={colleagueExchange.storyTimeSpan}
              onChange={e => handleTextStateChange(e.target.value, 'storyTimeSpan')}
              type='text'
              placeholder={t('GFZEducationalWork.ColleagueExchangeTextAreaPlaceholder')}
            />
          </td>
        </tr>
      </table>
      <br />
      <Button
        onClick={() => {
          postGfzColleagueExchange(colleagueExchange).then(() => {
            store.dispatch(showBottomNotification('Gespeichert', { isFail: false }));
          });
        }}
        type='primary'
      >
        {t('GFZEducationalWork.ColleagueExchangeSave')}
      </Button>
      {selectedKid.groups !== null && (
        <Button
          onClick={() => {
            const pdfSaveData = {
              colleagueExchange: colleagueExchange,
              selectedKid: selectedKid,
              setProgress: setProgress,
              userId: userId,
            }

            createKidPortfolioGroupIfNeeded(selectedKid.id).then(data => {
              createPDFColleagueExchange(pdfSaveData).then(() => {
                if (colleagueExchange.hasOwnProperty('id') && colleagueExchange.id !== null) {
                  deleteGfzColleagueExchange(colleagueExchange.id);
                }
                resetForm();
                store.dispatch(showBottomNotification('Gespeichert', { isFail: false }));
              });
            });
          }}
        >
          {t('GFZEducationalWork.ColleagueExchangeInKidPortfolioSave')}
        </Button>
      )}
      <br />
      <br />
      <div style={{ fontSize: '7px' }}>
        {t('GFZEducationalWork.ColleagueExchangeReference')}
      </div>
    </>
  );
}

import React, { useState, useEffect, useMemo } from 'react';
import cn from 'classnames';
import { useTranslation, Trans } from 'react-i18next';
import { sanitize } from 'dompurify';

import { isImage } from 'library/utilities/files';
import Input from 'library/common/commonComponents/Inputs/Input';
import MessageWithFiles from 'library/common/commonComponents/MessageWithFiles';
import Label from 'library/common/commonComponents/Label';
import Checkbox from 'library/common/commonComponents/Checkbox';
import Tooltip from 'library/common/commonComponents/Tooltip';
import RadioButton from 'library/common/commonComponents/RadioButton';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import UsersListPopup from 'library/common/commonComponents/Popups/UsersListPopup';
import DeletePopup from 'library/common/commonComponents/Popups/DeletePopup';
import {
  updateSurvey,
  voteForSurvey,
  addEditSurveySuggestion,
  deleteSurveySuggestion,
} from 'library/api/surveys';
import WithWatsonTranslate from 'library/common/commonComponents/WithWatsonTranslate/version';
import { changeOrderIfSurvey, sortSurveySuggestionsBySequence } from 'library/utilities/surveys';
import FeedItemSurveyEditing from './feedItemSurveyFrames/FeedItemSurveyEditing';
import FeedItemSurveyOptionEditing from './feedItemSurveyFrames/FeedItemSurveyOptionEditing';

import styles from './feedItemSurvey.module.scss';

export default function FeedItemSurvey({
  isEditing,
  setIsEditing,
  showBottomNotification,
  surveyOpenStatus,
  isPublic,
  anonymousVotesStatus,
  anonymousVotesForAllStatus,
  allowUserAddSuggestionStatus,
  endTimeDate,
  endTimeStatus,
  startTimeDate,
  startTimeStatus,
  reminders,
  multipleAnswerStatus,
  hideResultStatus,
  displayRandomOrderStatus,
  question,
  surveyOptions,
  survey,
  id,
  updateFeedById,
  files,
  canUserVote,
  setCanUserVote,
  groupMemberStatus,
  isAuthoredByCurrentUser,
  user,
  activeKita,
  isTranslationAllowed,
}) {
  const { t } = useTranslation();
  const [isSubmiting, setIsSubmiting] = useState(false);

  let allImages;
  if (typeof files === 'undefined' || files === null) {
    allImages = null;
  } else {
    allImages = files.filter(file => isImage(file.fileId));
  }

  if (canUserVote) {
    if (endTimeStatus === true && endTimeDate !== null && Date.now() > endTimeDate) {
      canUserVote = false;
    }
    if (startTimeStatus === true && startTimeDate !== null && Date.now() < startTimeDate) {
      canUserVote = false;
    }
  } else {
    if (
      startTimeStatus === true &&
      startTimeDate !== null &&
      Date.now() > startTimeDate &&
      (!endTimeStatus || endTimeDate === null || Date.now() < endTimeDate)
    ) {
      canUserVote = true;
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (canUserVote) {
        if (endTimeStatus === true && endTimeDate !== null && Date.now() > endTimeDate) {
          setCanUserVote(false);
        }
        if (startTimeStatus === true && startTimeDate !== null && Date.now() < startTimeDate) {
          setCanUserVote(false);
        }
      } else {
        if (
          startTimeStatus === true &&
          startTimeDate !== null &&
          Date.now() > startTimeDate &&
          (!endTimeStatus || endTimeDate === null || Date.now() < endTimeDate)
        ) {
          setCanUserVote(true);
        }
      }
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        (!surveyOpenStatus &&
          startTimeStatus === true &&
          startTimeDate !== null &&
          Date.now() > startTimeDate) ||
        (surveyOpenStatus &&
          endTimeDate != null &&
          endTimeStatus === true &&
          endTimeDate < Date.now())
      ) {
        updateSurvey(id, {
          ...survey,
          surveyOpenStatus: !surveyOpenStatus,
        });
      }
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  const admin = user.administrationAccess || user.employee || user.superAdmin;
  const shouldShowResults = 
    (!surveyOpenStatus || !hideResultStatus) &&
    (!anonymousVotesForAllStatus) &&
    (anonymousVotesStatus && admin) 

  const [userVotes, setUserVotes] = useState({});
  useEffect(() => {
    setUserVotes({});
  }, [surveyOptions]);
  const changeVote = answerId =>
    changeVoteHandler({ answerId, userVotes, setUserVotes, multipleAnswerStatus });
  const onVoteBtnClick = () => {
    if (isSubmiting) {
      return;
    }
    onVoteBtnClickHandler({
      surveyId: id,
      userVotes,
      showBottomNotification,
      t,
      updateFeedById,
      setIsSubmiting,
    });

    setCanUserVote(false);
  };
  const getWidth = index => getWidthHandler({ index, surveyOptions });

  const [isOpened, setIsOpened] = useState(false);
  const [popupAnswer, setPopupAnswer] = useState(surveyOptions[0]);

  const onEdit = payload => {
    if (isSubmiting) {
      return;
    }
    onEditHandler({
      updateFeedById,
      id,
      payload,
      setIsEditing,
      isPublic,
      surveyOpenStatus,
      setIsSubmiting,
    });
  };

  const [newSuggestionValue, setNewSuggestionValue] = useState('');
  const [inputError, setInputError] = useState(null);
  const addSuggestion = () => {
    if (isSubmiting) {
      return;
    }
    addSuggestionHandler({
      surveyId: id,
      newSuggestionValue,
      setNewSuggestionValue,
      setInputError,
      updateFeedById,
      t,
      setIsSubmiting,
    });
  };

  const [deletionId, setDeletionId] = useState(null);
  const deleteSuggestion = suggestionId => {
    if (isSubmiting) {
      return;
    }
    deleteSuggestionHandler({ surveyId: id, id: suggestionId, updateFeedById, setIsSubmiting });
  };

  const [editingFields, setEditingFields] = useState([]);
  const onOptionEdit = (suggestionId, newValue) => {
    if (isSubmiting) {
      return;
    }
    onOptionEditHandler({
      surveyId: id,
      id: suggestionId,
      newValue,
      updateFeedById,
      editingFields,
      setEditingFields,
      setIsSubmiting,
    });
  };

  const sortedSurveyOptions = useMemo(() => {
    return sortSurveySuggestionsBySequence(displayRandomOrderStatus, surveyOptions);
  }, [displayRandomOrderStatus, surveyOptions]);

  return isEditing ? (
    <div>
      <FeedItemSurveyEditing
        onEdit={onEdit}
        setIsEditing={setIsEditing}
        question={question}
        surveyOptions={sortedSurveyOptions}
        multipleAnswerStatus={multipleAnswerStatus}
        anonymousVotesStatus={anonymousVotesStatus}
        anonymousVotesForAllStatus={anonymousVotesForAllStatus}
        hideResultStatus={hideResultStatus}
        displayRandomOrderStatus={displayRandomOrderStatus}
        allowUserAddSuggestionStatus={allowUserAddSuggestionStatus}
        isSubmiting={isSubmiting}
        endTimeDate={endTimeDate}
        endTimeStatus={endTimeStatus}
        startTimeDate={startTimeDate}
        startTimeStatus={startTimeStatus}
        reminders={reminders}
      />
    </div>
  ) : (
    <div className={styles.container}>
      <div className={styles.labels}>
        {endTimeDate != null && endTimeDate > Date.now() && (
          <div className={styles.label}>
            <Label type='danger'>
              {t('Survey.Expiry')} {new Date(endTimeDate).toLocaleDateString('de-DE')}{' '}
              {new Date(endTimeDate).toLocaleTimeString('de-DE').replace(/(.*)\D\d+/, '$1')}
            </Label>
          </div>
        )}
        {anonymousVotesStatus && (
          <div className={styles.label}>
            <Label type='success'>{t('Surveys.Anonymous')}</Label>
          </div>
        )}
        {anonymousVotesForAllStatus && (
          <div className={styles.label}>
            <Label type='success'>{t('Surveys.For all anonymous')}</Label>
          </div>
        )}
        {!surveyOpenStatus && (
          <div className={styles.label}>
            <Label type='danger'>{t('Surveys.Closed')}</Label>
          </div>
        )}
      </div>

      <div className={styles.question}>
        {isTranslationAllowed ? (
          <WithWatsonTranslate
            data={{ text: question, entityId: id, entityType: 'survey' }}
            Component={({ html }) => <span dangerouslySetInnerHTML={{ __html: sanitize(html) }} />}
          />
        ) : (
          <span dangerouslySetInnerHTML={{ __html: sanitize(question) }} />
        )}
      </div>

      <UsersListPopup
        isOpened={isOpened}
        title={
          <>
            {t('Surveys.Users voted for')} <strong>{popupAnswer?.value}</strong>
          </>
        }
        closePopup={() => setIsOpened(false)}
        users={popupAnswer?.surveyOptionVotes}
      />

      <DeletePopup
        isOpened={deletionId}
        closePopup={() => setDeletionId(null)}
        onDeleteBtnClick={() => {
          deleteSuggestion(deletionId);
        }}
        headerText={<Trans i18nKey='Surveys.Confirm suggestion delition.Title' />}
        bodyText={t('Surveys.Confirm suggestion delition.Text')}
        isSubmiting={isSubmiting}
      />

      {surveyOptions.map((surveyOption, index) => (
        <div className={styles.answerWrapper} key={surveyOption.id}>
          {editingFields.find(fieldId => surveyOption.id === fieldId) ? (
            <FeedItemSurveyOptionEditing
              value={surveyOption.optionName}
              id={surveyOption.id}
              onEdit={onOptionEdit}
              isSubmiting={isSubmiting}
            />
          ) : (
            <div className={styles.answerContainer}>
              {canUserVote && (
                <div
                  className={cn(styles.answerCheckbox, {
                    [styles.answerRadio]: !multipleAnswerStatus,
                  })}
                >
                  {multipleAnswerStatus ? (
                    <Checkbox
                      isChecked={userVotes[surveyOption.id]}
                      onChange={() => changeVote(surveyOption.id)}
                    />
                  ) : (
                    <RadioButton
                      checked={userVotes[surveyOption.id]}
                      onClick={() => changeVote(surveyOption.id)}
                    />
                  )}
                </div>
              )}

              <div
                className={
                  surveyOption.surveyOptionVotes?.find(item => item.userId === user.id) &&
                  surveyOpenStatus
                    ? styles.hiddenGreen
                    : styles.answerTextContainer
                }
              >
                <div className={styles.answerText}>
                  {isTranslationAllowed ? (
                    <WithWatsonTranslate
                      data={{
                        text: surveyOption.optionName,
                        entityId: id,
                        entityType: 'survey answer',
                      }}
                      Component={({ html }) => (
                        <span dangerouslySetInnerHTML={{ __html: sanitize(html) }} />
                      )}
                    />
                  ) : (
                    <span dangerouslySetInnerHTML={{ __html: sanitize(surveyOption.optionName) }} />
                  )}
                </div>
                {(!surveyOpenStatus || !hideResultStatus) && (
                  <div className={styles.answerProgress}>
                    <div
                      className={styles.answerProgressValue}
                      style={{ width: getWidth(index) }}
                    />
                  </div>
                )}
              </div>
              {(!surveyOpenStatus || !hideResultStatus) && (
                <Tooltip
                  isVisibleCondition={shouldShowResults == true && surveyOption.surveyOptionVotes.length}
                  text={
                    <span className='notranslate'>
                      {getTooltipText(surveyOption.surveyOptionVotes)}
                    </span>
                  }
                >
                  <div
                    onClick={() => {
                      if (
                        surveyOption.surveyOptionVotes.length && shouldShowResults
                      ) {
                        setIsOpened(true);
                        setPopupAnswer(surveyOption);
                      }
                    }}
                    className={cn(styles.answerVotes, {
                      [styles.answerVotesClickable]:
                        surveyOption.surveyOptionVotes.length && shouldShowResults
                    })}
                  >
                    {surveyOption.surveyOptionVotes.length}
                    <span> {t('Surveys.N votes')}</span>
                  </div>
                </Tooltip>
              )}
            </div>
          )}
          {(!activeKita || (activeKita && !activeKita.virtual)) &&
            surveyOption.suggestionAdded &&
            (isAuthoredByCurrentUser || user.id === surveyOption.createdBy) &&
            !editingFields.find(fieldId => surveyOption.id === fieldId) &&
            surveyOpenStatus &&
            user.id === surveyOption.createdBy && (
              <div className={styles.suggestionControls}>
                <Button
                  className={styles.suggestionControlButton}
                  size='sm'
                  onClick={() => setDeletionId(surveyOption.id)}
                >
                  <i className='fa fa-trash' />
                </Button>
                <Button
                  className={styles.suggestionControlButton}
                  size='sm'
                  onClick={() => setEditingFields(editingFields.concat(surveyOption.id))}
                >
                  <i className='fa fa-pencil' />
                </Button>
              </div>
            )}
        </div>
      ))}
      {surveyOpenStatus && groupMemberStatus && allowUserAddSuggestionStatus && (
        <>
          <div className={styles.addSuggestionContainer}>
            <Input
              value={newSuggestionValue}
              placeholder={t('Surveys.Add answer')}
              className={styles.addSuggestionInput}
              onChange={e => setNewSuggestionValue(e.target.value)}
            />
            <ButtonWithLoader
              type='default'
              size='sm'
              className={styles.addSuggestionButton}
              onClick={addSuggestion}
              isLoading={isSubmiting}
            >
              {t('Post.Save')}
            </ButtonWithLoader>
          </div>
          {inputError && <div className={styles.error}>{inputError}</div>}
        </>
      )}
      {surveyOpenStatus && hideResultStatus && (
        <div className={cn(styles.hiddenNotify, { [styles.hiddenNotifyMargin]: !canUserVote })}>
          <Trans i18nKey='Surveys.Note The result is hidden until the survey is closed by a moderator' />
        </div>
      )}
      {canUserVote && (
        <ButtonWithLoader
          type='primary'
          className={styles.voteButton}
          onClick={onVoteBtnClick}
          isLoading={isSubmiting}
        >
          {t('Surveys.Vote')}
        </ButtonWithLoader>
      )}

      {files.length > 0 && (
        <MessageWithFiles
          files={files}
          allImages={allImages}
          isTranslationAllowed={isTranslationAllowed}
        />
      )}
    </div>
  );
}

export function changeVoteHandler({ answerId, userVotes, setUserVotes, multipleAnswerStatus }) {
  setUserVotes(
    multipleAnswerStatus
      ? { ...userVotes, [answerId]: !userVotes[answerId] }
      : { [answerId]: !userVotes[answerId] },
  );
}

export function getWidthHandler({ index, surveyOptions }) {
  const maxAnswersCount = surveyOptions.reduce(
    (prev, next) => (next.surveyOptionVotes.length > prev ? next.surveyOptionVotes.length : prev),
    0,
  );
  return maxAnswersCount > 0
    ? (surveyOptions[index].surveyOptionVotes.length / maxAnswersCount) * 100 + '%'
    : 0;
}

export function getTooltipText(users) {
  if (users.length <= 2) {
    return users.map(user => (
      <React.Fragment key={user.id}>
        {`${user.firstName} ${user.lastName}`} <br />
      </React.Fragment>
    ));
  } else {
    return (
      <>
        {`${users[users.length - 1].firstName} ${users[users.length - 1].lastName}`}
        <br />
        and others
      </>
    );
  }
}

export async function onVoteBtnClickHandler({
  surveyId,
  userVotes,
  showBottomNotification,
  t,
  updateFeedById,
  setIsSubmiting,
}) {
  setIsSubmiting(true);
  const voteIds = Object.keys(userVotes).reduce(
    (prev, next) => userVotes[next] && prev.concat(Number(next)),
    [],
  );
  if (voteIds.length === 0) {
    setIsSubmiting(false);
    return showBottomNotification(t('Surveys.At least one answer is required'), {
      isWarning: true,
    });
  }

  const { data } = await voteForSurvey(surveyId, voteIds);
  updateFeedById(surveyId, changeOrderIfSurvey(data), { withoutRequest: true });
  setIsSubmiting(false);
}

export async function onEditHandler({
  updateFeedById,
  id,
  payload,
  setIsEditing,
  isPublic,
  surveyOpenStatus,
  setIsSubmiting,
}) {
  setIsSubmiting(true);
  const { data } = await updateSurvey(id, {
    postType: 4,
    ...payload,
    privatePostStatus: !isPublic,
    surveyOpenStatus,
  });
  updateFeedById(id, changeOrderIfSurvey(data), { withoutRequest: true });
  setIsSubmiting(false);
  setIsEditing(false);
}

export async function addSuggestionHandler({
  surveyId,
  newSuggestionValue,
  setNewSuggestionValue,
  setInputError,
  updateFeedById,
  t,
  setIsSubmiting,
}) {
  setIsSubmiting(true);
  if (newSuggestionValue.trim().length === 0) {
    setIsSubmiting(false);
    return setInputError(t('Surveys.Answer field cannot be blank'));
  }

  setInputError(null);
  setNewSuggestionValue('');
  const { data } = await addEditSurveySuggestion(surveyId, 0, newSuggestionValue);
  updateFeedById(surveyId, changeOrderIfSurvey(data), { withoutRequest: true });
  setIsSubmiting(false);
}

export async function deleteSuggestionHandler({ surveyId, id, updateFeedById, setIsSubmiting }) {
  setIsSubmiting(true);
  const { data } = await deleteSurveySuggestion(surveyId, id);
  updateFeedById(surveyId, changeOrderIfSurvey(data), { withoutRequest: true });
  setIsSubmiting(false);
}

export async function onOptionEditHandler({
  surveyId,
  id,
  newValue,
  updateFeedById,
  editingFields,
  setEditingFields,
  setIsSubmiting,
}) {
  setIsSubmiting(true);
  const { data } = await addEditSurveySuggestion(surveyId, id, newValue);
  updateFeedById(surveyId, changeOrderIfSurvey(data), { withoutRequest: true });
  setIsSubmiting(false);
  setEditingFields(editingFields.filter(fieldId => fieldId !== id));
}

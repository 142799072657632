import JSZip from 'jszip';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

import { getToken } from 'library/utilities/token';
import { convertDateToLocal } from 'library/utilities/taxConsultant';
import { formatTimeToLanguage, getCreateAtAccountDte } from 'library/utilities/date';
import { isoDateStringToGerman } from 'library/utilities/dateMoment';
import { uploadFilesToGroup } from 'library/api/files';
import { mapAddDayTypeToPrice } from './GFZ';
import {
  formatMilisToTimeString,
  getTotalCheckoutTimeInMilis,
  getTotalManualBreakTimeInMilis,
  getTotalTimeOfAbsenceCheckInOutsInMilis,
} from 'library/utilities/employeeCheckInOut';
import { EMPLOYEE_ABSENCE_TYPES } from 'library/common/commonConstants/employee';

const PAGE_MAX_HEIGHT = 297;
const SIGNATURE_IMAGE_WIDTH = 91;
const SIGNATURE_IMAGE_HEIGHT = 30;

export function getFileExtension(fileName) {
  const dotIndex = fileName.lastIndexOf('.');
  return dotIndex !== -1 ? fileName.slice(dotIndex + 1).toLowerCase() : '';
}

export function isImage(fileName) {
  const extention = getFileExtension(fileName);
  return extention === 'png' || extention === 'jpg' || extention === 'jpeg';
}

export function isVideo(fileName) {
  const extention = getFileExtension(fileName);
  return (
    extention === 'mov' ||
    extention === 'webm' ||
    extention === 'ogg' ||
    extention === 'avi' ||
    extention === 'mp4' ||
    extention === 'mpeg' ||
    extention === 'ogv' ||
    extention === 'ogg'
  );
}

export function isAudio(fileName) {
  const extention = getFileExtension(fileName);
  return extention === 'mp3' || extention === 'wav';
}

export function getFileIconByName(fileName) {
  switch (getFileExtension(fileName)) {
    case 'pdf':
      return 'fa-file-pdf-o';
    case 'xls':
    case 'xlsx':
      return 'fa-file-excel-o';
    case 'ppt':
    case 'pptx':
      return 'fa-file-powerpoint-o';
    case 'doc':
    case 'docx':
      return 'fa-file-word-o';
    case 'txt':
      return 'fa-file-text-o';
    case 'zip':
      return 'fa-file-archive-o';
    case 'jpg':
    case 'jpeg':
    case 'png':
      return 'fa-file-image-o';
    case 'mp4':
    case 'mov':
      return 'fa-file-video-o';
    case 'mp3':
    case 'wav':
      return 'fa-file-audio-o';
    default:
      return 'fa-file-o';
  }
}

export function isMatchesToMediaType(fileMediaType, mediaType, fileName) {
  if (mediaType === '*') {
    return true;
  }

  const slashAllIndex = mediaType.indexOf('/*');
  if (slashAllIndex !== -1) {
    return fileMediaType?.indexOf(mediaType.slice(0, slashAllIndex)) !== -1;
  }

  return fileMediaType === mediaType || fileName?.includes(mediaType);
}

export function isMatchesToMediaTypeArray(fileMediaType, mediaTypes, fileName) {
  return (
    mediaTypes.filter(mediaType => isMatchesToMediaType(fileMediaType, mediaType, fileName))
      .length > 0
  );
}

export async function isNotEncrypted(file, Decryptor = JSZip) {
  return new Decryptor()
    .loadAsync(file)
    .then(() => true)
    .catch(() => false);
}

export const getTypeForReactNativePreview = (fileName, withDownload) => {
  const ext = getFileExtension(fileName);
  switch (ext) {
    case 'pdf':
      return 'pdf';
    case 'doc':
    case 'docx':
    case 'ppt':
    case 'pptx':
    case 'txt':
    case 'rtf':
      return 'preview';
    default:
      return withDownload ? 'file_download' : 'preview';
  }
};

export const previewOrDownloadFile = ({ fileId, path, id }) => {
  const shouldPreviewFile =
    ['.pdf', '.docx', '.doc', '.ppt', '.pptx', '.txt', '.rtf'].filter(extension =>
      fileId.includes(extension),
    ).length > 0;

  if (window.outerWidth >= 1024 || !shouldPreviewFile) {
    window.open(path, '_blank');
  } else {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: getTypeForReactNativePreview(fileId),
          file: fileId,
          id,
          path,
          token: getToken().access_token,
        }),
      );
      return null;
    }
    window.open(path, '_blank');
  }
};

export function getDataUri(url) {
  return new Promise(resolve => {
    const image = new Image();
    image.setAttribute('crossOrigin', 'anonymous'); // getting images from external domain

    image.onload = function() {
      const canvas = document.createElement('canvas');
      canvas.width = this.naturalWidth;
      canvas.height = this.naturalHeight;

      // next three lines for white background in case png has a transparent background
      const ctx = canvas.getContext('2d');
      ctx.fillStyle = '#fff'; // / set white fill style
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      canvas.getContext('2d').drawImage(this, 0, 0);

      resolve(canvas.toDataURL('image/jpeg'));
    };

    image.src = url;
  });
}

function getImageMeta(src) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve({ height: img.height, width: img.width });
    img.onerror = reject;
    img.src = src;
  });
}
function getHeight(height, width, relation) {
  const newHeight = width * relation;

  if (newHeight >= height) {
    width -= 1;
    return getHeight(height, width, relation);
  } else {
    return { height: newHeight, width };
  }
}

export const createPDF = async (path, taxReceipt, fileName = 'download.jpg') => {
  return new Promise(async (resolve, reject) => {
    const doc = new jsPDF();
    doc.setFontSize(9);
    const logo = await getDataUri(path);
    const meta = await getImageMeta(path);
    const relation = meta.height / meta.width;
    const width = doc.internal.pageSize.getWidth();
    const height = doc.internal.pageSize.getHeight();

    let receiptInfosHeightSum = 0;
    if (taxReceipt && taxReceipt !== null) {
      const { receiptType, date, persons, reason } = taxReceipt;

      const arrayWithReceiptInfos = [];

      if (persons != null && reason != null) {
        arrayWithReceiptInfos.push('Personen: ' + persons);
        arrayWithReceiptInfos.push('Anlass: ' + reason);
      }
      arrayWithReceiptInfos.push('Datum: ' + convertDateToLocal(date));
      arrayWithReceiptInfos.push('Typ: ' + receiptType);

      for (let i = 0; i < arrayWithReceiptInfos.length; i++) {
        const sizedText = doc.splitTextToSize(arrayWithReceiptInfos[i], width - 20);
        const textHeight = Math.ceil(doc.getTextDimensions(sizedText).h);
        receiptInfosHeightSum += textHeight + 2;
        doc.text(sizedText, 10, height - receiptInfosHeightSum);
      }
    }

    // Add image from file module to the pdf. The image keeps its relations.
    // Max width: 100% of document widht. Max height: document height minus height of receipt info block
    const newSizes = getHeight(height - (receiptInfosHeightSum + 5), width, relation);
    doc.addImage(logo, 'PNG', 0, 0, newSizes.width, newSizes.height);

    // Remove file extension and replace it with .pdf

    /* doc.save(
      fileName
        .split('.')
        .slice(0, -1)
        .join('.') + '.pdf',
    ); */

    resolve(doc.output('blob'));
  });
};

export const createPDFAbsence = async (result, groupName, kitaName) => {
  return new Promise(async (resolve, reject) => {
    const doc = new jsPDF();
    const formattedArray = [];

    result.forEach(function(item) {
      formattedArray.push([
        item.firstName + ' ' + item.lastName,
        item.reason,
        item.sickness,
        formatTimeToLanguage(item.reportedDate, 'de'),
        item.reportedBy,
        formatTimeToLanguage(item.startDate, 'de'),
        formatTimeToLanguage(item.endDate, 'de'),
      ]);
    });
    doc.text(14.8, 16, 'Abwesenheit / Krankmeldungen von ' + groupName + ' | ' + kitaName);
    doc.autoTable({ html: '#absenceTable' });
    doc.autoTable({
      showHead: 'everyPage',
      head: [['Name', 'Grund', 'Krankheit', 'Gemeldet', 'Berichtet von', 'Beginn', 'Ende']],
      body: formattedArray,
    });

    // add page footer
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.text(14.8, 284, 'Abwesenheit / Krankmeldungen von ' + groupName + ' | ' + kitaName);
      doc.text(14.8, 288, 'Seite ' + String(i) + ' von ' + String(pageCount));
    }

    doc.save('Abwesenheiten_' + groupName + '_' + kitaName + '.pdf');
  });
};

export const createPDFCheckinout = async result => {
  return new Promise(async (resolve, reject) => {
    const doc = new jsPDF();
    const formattedArray = [];

    result.forEach(function(item) {
      formattedArray.push([
        item.name,
        item.parent1Name + (item.parent2Name ? ', ' + item.parent2Name : ''),
        item.initiator,
        item.checkInOutStatus,
        getCreateAtAccountDte(item.actionDate),
      ]);
    });
    doc.autoTable({
      head: [['Name', 'Eltern', 'Ausführende Person', 'Aktion', 'Datum']],
      body: formattedArray,
    });
    doc.save('Checkinout.pdf');
  });
};

export const createPDFEmployeeCheckinout = async (
  employeeWorkingDays,
  futureAbsences,
  futurePublicHolidays,
  user,
  employeeInfo,
  exportMonthDate,
  employeeKitaAbsenceTypes,
) => {
  return new Promise(async (resolve, reject) => {
    const doc = new jsPDF();

    const dateString = exportMonthDate.toLocaleString('de-DE', {
      month: 'numeric',
      year: 'numeric',
    });

    // get time of date in the format e.g. 4:30
    const getFormatedTimeOfDate = dateString => {
      const date = new Date(dateString);
      return date.toLocaleString('de-DE', { hour: '2-digit', minute: '2-digit' });
    };

    const employeeWorkingDayByDate = employeeWorkingDays.reduce((acc, curr) => {
      const date = new Date(curr.date).toLocaleDateString('de-DE');
      acc[date] = curr;
      return acc;
    }, {});

    // create one row for each day
    const allDaysOfMonth = [];
    const to = new Date(exportMonthDate.getFullYear(), exportMonthDate.getMonth() + 1, 0);
    for (let date = exportMonthDate; date <= to; date.setDate(date.getDate() + 1)) {
      allDaysOfMonth.push(new Date(date.valueOf()).toLocaleDateString('de-DE'));
    }

    // add placeholder working days for future absences and public holidays (employee working days are only created for past days)
    const futureAbsenceByDate = {};
    futureAbsences.forEach(absence => {
      // iterate from startDate to endDate  of absence and add a placeholder working day for each day
      for (
        let date = new Date(absence.startDate);
        date <= new Date(absence.endDate);
        date.setDate(date.getDate() + 1)
      ) {
        futureAbsenceByDate[date.toLocaleDateString('de-DE')] = absence;
      }
    });

    const futurePublicHolidayByDate = {};
    futurePublicHolidays.forEach(holiday => {
      const dateString = new Date(holiday.date).toLocaleDateString('de-DE');
      futurePublicHolidayByDate[dateString] = holiday;
    });

    // iterate over allDaysOfMonth and add abesence or public holiday if present
    allDaysOfMonth.forEach(date => {
      if (futureAbsenceByDate[date] || futurePublicHolidayByDate[date]) {
        employeeWorkingDayByDate[date] = {
          checkInOuts: [],
          overtime: '',
          timeWorked: '',
          employeeAbsence: futureAbsenceByDate[date] ? futureAbsenceByDate[date] : null,
          publicHoliday: futurePublicHolidayByDate[date] ? futurePublicHolidayByDate[date] : null,
        };
      }
    });

    const formattedArray = [];

    const absenceArray = [];

    let overtimeTotal = 0;
    let absence = '';

    for (let i = 0; i < allDaysOfMonth.length; i++) {
      const dateString = allDaysOfMonth[i];

      // depending if there are checkinouts, workingdayobject or nothing, add one row to the table
      if (!employeeWorkingDayByDate[dateString]) {
        // no employeeWorkingDay for this date, only add date row
        formattedArray.push([dateString, '', '', '', '', '']);
      } else {
        // filter out manual checkinouts that are not approved
        const checkInOuts = employeeWorkingDayByDate[dateString].checkInOuts.filter(checkinout => {
          return (
            checkinout.checkInOutCategory !== 'MANUAL' || checkinout.reviewStatus === 'APPROVED'
          );
        });

        overtimeTotal += employeeWorkingDayByDate[dateString].overtime;

        absenceArray[i] = null;
        if (employeeWorkingDayByDate[dateString].employeeAbsence != null) {
          // if absence is present, add absence type to absenceArray
          absenceArray[i] = employeeWorkingDayByDate[dateString].employeeAbsence.absenceType;
        }
        if (employeeWorkingDayByDate[dateString].publicHoliday != null) {
          absenceArray[i] = 'Feiertag';
        }

        // set the absence name
        let absence = '';
        switch (absenceArray[i]) {
          case EMPLOYEE_ABSENCE_TYPES.HOLIDAY:
            absence = 'Urlaub';
            break;
          case EMPLOYEE_ABSENCE_TYPES.SICK:
            absence = 'Krank';
            break;
          case EMPLOYEE_ABSENCE_TYPES.HOLIDAY_ON_OVERTIME:
            absence = 'Frei auf Überstunden';
            break;
          case EMPLOYEE_ABSENCE_TYPES.ABSENCE_ONE:
            absence = employeeKitaAbsenceTypes?.[0]?.name || 'Abwesenheit 1';
            break;
          case EMPLOYEE_ABSENCE_TYPES.ABSENCE_TWO:
            absence = employeeKitaAbsenceTypes?.[1]?.name || 'Abwesenheit 2';
            break;
          case EMPLOYEE_ABSENCE_TYPES.ABSENCE_THREE:
            absence = employeeKitaAbsenceTypes?.[2]?.name || 'Abwesenheit 3';
            break;
          case EMPLOYEE_ABSENCE_TYPES.ABSENCE_FOUR:
            absence = employeeKitaAbsenceTypes?.[3]?.name || 'Abwesenheit 4';
            break;
          case EMPLOYEE_ABSENCE_TYPES.ABSENCE_FIVE:
            absence = employeeKitaAbsenceTypes?.[4]?.name || 'Abwesenheit 5';
            break;
          case 'Feiertag':
            absence = 'Feiertag';
            break;
          default:
            absence = '';
            break;
        }
        const dateStringCell = absence ? `${dateString} (${absence})` : dateString;
        if (checkInOuts && checkInOuts.length > 0) {
          const totalBreakTimeInMilis = getTotalManualBreakTimeInMilis(checkInOuts);
          const totalCheckoutTimeInMilis = getTotalCheckoutTimeInMilis(checkInOuts);

          formattedArray.push([
            dateStringCell,
            `${getFormatedTimeOfDate(checkInOuts[0].actionDate)} - ${getFormatedTimeOfDate(
              checkInOuts[checkInOuts.length - 1].actionDate,
            )}`,
            formatMilisToTimeString(totalBreakTimeInMilis),
            formatMilisToTimeString(totalCheckoutTimeInMilis),
            formatMilisToTimeString(
              employeeWorkingDayByDate[dateString].timeWorked +
                getTotalTimeOfAbsenceCheckInOutsInMilis(checkInOuts),
            ),
            formatMilisToTimeString(employeeWorkingDayByDate[dateString].overtime),
          ]);
        } else {
          formattedArray.push([
            dateStringCell,
            '',
            '',
            '',
            employeeWorkingDayByDate[dateString].timeWorked
              ? formatMilisToTimeString(employeeWorkingDayByDate[dateString].timeWorked)
              : '',
            employeeWorkingDayByDate[dateString].overtime
              ? formatMilisToTimeString(employeeWorkingDayByDate[dateString].overtime)
              : '',
          ]);
        }
      }
    }

    doc.text(`Arbeitszeiten ${dateString} von ${user.firstName} ${user.lastName}`, 14.8, 12);
    doc.autoTable({
      startY: 15,
      head: [
        [
          'Datum',
          'Checkin - Checkout',
          'Manuelle Pause',
          'Ausgecheckte Zeit',
          'Arbeitszeit',
          'Überstunden',
        ],
      ],
      body: formattedArray,
      bodyStyles: { fontSize: 9 },
      didParseCell: function(data) {
        // check if there is an absence on the row but don't color the head row
        if (
          data.section !== 'head' &&
          absenceArray[data.row.index] !== null &&
          data.column.index === 0
        ) {
          switch (absenceArray[data.row.index]) {
            case EMPLOYEE_ABSENCE_TYPES.HOLIDAY:
              data.cell.styles.fillColor = '#d9b3e6';
              break;
            case EMPLOYEE_ABSENCE_TYPES.SICK:
              data.cell.styles.fillColor = '#ffd699';
              break;
            case EMPLOYEE_ABSENCE_TYPES.HOLIDAY_ON_OVERTIME:
              data.cell.styles.fillColor = '#e6b3e6';
              break;
            case EMPLOYEE_ABSENCE_TYPES.ABSENCE_ONE:
              data.cell.styles.fillColor = '#f2b3e6';
              break;
            case EMPLOYEE_ABSENCE_TYPES.ABSENCE_TWO:
              data.cell.styles.fillColor = '#b3e6b3';
              break;
            case EMPLOYEE_ABSENCE_TYPES.ABSENCE_THREE:
              data.cell.styles.fillColor = '#e6e6b3';
              break;
            case EMPLOYEE_ABSENCE_TYPES.ABSENCE_FOUR:
              data.cell.styles.fillColor = '#b3d1ff';
              break;
            case EMPLOYEE_ABSENCE_TYPES.ABSENCE_FIVE:
              data.cell.styles.fillColor = '#e6b3c2';
              break;
            case 'Feiertag':
              data.cell.styles.fillColor = '#99e6e0';
              break;
            default:
              data.cell.styles.fillColor = '#fff';
              break;
          }
        }
      },
    });

    const monthlyTakenHolidays = absenceArray.filter(
      absence => absence === EMPLOYEE_ABSENCE_TYPES.HOLIDAY,
    ).length;
    const {
      daysTaken: holidaysTaken,
      daysTotal: holidaysTotal,
    } = employeeInfo.employeeAbsenceDays?.find(
      absenceDays => absenceDays.absenceType === EMPLOYEE_ABSENCE_TYPES.HOLIDAY,
    ) || { daysTaken: 0, daysTotal: 0 };

    const monthlySickLeave = absenceArray.filter(absence => absence === EMPLOYEE_ABSENCE_TYPES.SICK)
      .length;
    // conclusion row/table
    const finalY = doc.lastAutoTable.finalY;
    doc.autoTable({
      startY: finalY + 2,
      head: [['Urlaubstage', 'Krankheitstage', 'Überstunden im Monat']],
      body: [
        [
          `Genommen: ${monthlyTakenHolidays}, Verbleibend: ${holidaysTotal -
            holidaysTaken}/${holidaysTotal}`,
          monthlySickLeave,
          `${formatMilisToTimeString(overtimeTotal)}, Gesamt: ${formatMilisToTimeString(
            employeeInfo.overtime,
          )}`,
        ],
      ],
      headStyles: {
        fillColor: [54, 54, 58],
      },
    });

    doc.save(
      `Arbeitszeiten-${user.firstName}-${user.lastName}-${dateString.replace(/\//g, '-')}.pdf`,
    );
  });
};

// if (window.ReactNativeWebView) check required before using this function
export const downloadBase64FileIfIsInAppView = (fileName, mimeType, payload) => {
  window.ReactNativeWebView.postMessage(
    JSON.stringify({
      type: 'download_base64',
      fileName,
      mimeType,
      payload,
    }),
  );
};

const setText = (oldHeight, text, doc, left = 10) => {
  const width = doc.internal.pageSize.getWidth();
  const sizedText = doc.splitTextToSize(text, width - (10 + left));
  const textHeight = Math.ceil(doc.getTextDimensions(sizedText).h);
  doc.text(sizedText, left, oldHeight);
  if (oldHeight > PAGE_MAX_HEIGHT) {
    doc.addPage();
    const calculatedNewPageHeight = oldHeight - 20;
    return calculatedNewPageHeight * -1;
  }
  return textHeight;
};

const setOnlyText = (oldHeight, text, doc, left = 10) => {
  const width = doc.internal.pageSize.getWidth();
  const sizedText = doc.splitTextToSize(text, width - (10 + left));
  const textHeight = Math.ceil(doc.getTextDimensions(sizedText).h);
  doc.text(sizedText, left, oldHeight);
  if (oldHeight > PAGE_MAX_HEIGHT) {
    doc.addPage();
    const calculatedNewPageHeight = oldHeight - 20;
    return {
      height: calculatedNewPageHeight * -1,
      newPageAdded: true,
    };
  }
  return {
    height: textHeight,
    newPageAdded: false,
  };
};

const setTextWithLink = (oldHeight, text, doc, left = 10, link) => {
  const width = doc.internal.pageSize.getWidth();
  const sizedText = doc.splitTextToSize(text, width - (10 + left));
  const textHeight = Math.ceil(doc.getTextDimensions(sizedText).h);

  doc.setTextColor(0, 0, 255);

  sizedText.forEach((line, index) => {
    const lineHeight = oldHeight + (index * 10);
    doc.textWithLink(line, left, lineHeight, { url: link });
    const textWidth = doc.getTextWidth(line);
    const underlineY = lineHeight + 1; // Adjust position slightly for underline
    doc.line(left, underlineY, left + textWidth, underlineY); // Draw the underline
  });
  doc.setTextColor(0);

  if (oldHeight > PAGE_MAX_HEIGHT) {
    doc.addPage();
    const calculatedNewPageHeight = oldHeight - 20;
    return {
      height: calculatedNewPageHeight * -1,
      newPageAdded: true,
    };
  }
  return {
    height: textHeight,
    newPageAdded: false,
  };
};

const calculateTextHeight = (text, doc) => {
  const sizedText = doc.splitTextToSize(text, doc.internal.pageSize.getWidth() - 25);
  return Math.ceil(doc.getTextDimensions(sizedText).h);
};

const addSignatureImage = (oldHeight, dateText, signatureData, signatureText, doc) => {
  let sectionHeight = oldHeight;

  const dateTextHeight = calculateTextHeight(dateText, doc);
  const signatureTextHeight = calculateTextHeight(signatureText, doc);
  const totalHeightNeeded = dateTextHeight + signatureTextHeight + SIGNATURE_IMAGE_HEIGHT + 10;

  if (oldHeight + totalHeightNeeded > PAGE_MAX_HEIGHT) {
    doc.addPage();
    sectionHeight = 20;
    sectionHeight += setText(sectionHeight, dateText, doc, 15);
    sectionHeight += 10;

    sectionHeight += setText(sectionHeight, signatureText, doc, 15);

    sectionHeight += addImage(sectionHeight, signatureData, doc);
    return -1 * (oldHeight - sectionHeight);
  }
  sectionHeight += setText(sectionHeight, dateText, doc, 15);
  sectionHeight += 10;

  sectionHeight += setText(sectionHeight, signatureText, doc, 15);

  addImage(sectionHeight, signatureData, doc);

  return totalHeightNeeded;
}

const addImage = (oldHeight, imageData, doc, left = 10) => {
  const width = doc.internal.pageSize.getWidth();
  doc.addImage(imageData, 'png', 10, oldHeight, /*width - 20*/ SIGNATURE_IMAGE_WIDTH, SIGNATURE_IMAGE_HEIGHT);
  if (oldHeight > PAGE_MAX_HEIGHT) {
    doc.addPage();
    const calculatedNewPageHeight = oldHeight - 20;
    return calculatedNewPageHeight * -1;
  }
  return SIGNATURE_IMAGE_HEIGHT;
};

export const createPDFObservationSheet = pdfSaveData => {
  const observationSheet = pdfSaveData.observationSheet;
  const kidName = pdfSaveData.selectedKid.label;
  let kidPortfolioGroupId = null;
  if (pdfSaveData.selectedKid.portfolioGroupId) {
    kidPortfolioGroupId = pdfSaveData.selectedKid.portfolioGroupId;
  }
  const currentUserId = pdfSaveData.userId;

  let height = 20;
  const doc = new jsPDF();
  doc.autoTable({ html: '#observationSheetTable' });
  doc.autoTable({
    showHead: 'never',
    body: [
      ['Name des Kindes', kidName],
      ['Uhrzeit: (von - bis):', observationSheet.time],
      ['Beobachtende Person:', observationSheet.observingPerson],
      [
        observationSheet.tookImages ? 'Fotos gemacht: Ja' : 'Fotos gemacht: Nein',
        observationSheet.tookFilm ? 'Film gemacht: Ja' : 'Film gemacht: Nein',
      ],
    ],
    didDrawPage: d => (height = Math.ceil(d.cursor.y) + 10),
  });

  doc.setFontSize(10);
  height += setText(height, 'Beobachtungsdauer: ca. 5-10 Minuten', doc, 15);
  height += 5;
  height += setText(height, 'Beobachtung:', doc, 15);
  doc.line(15, height, doc.internal.pageSize.getWidth() - 15, height);
  height += 5;
  height += setText(height, 'Beschreibung der Ausgangslage:', doc, 15);
  height += setText(
    height,
    'Wo spielt das Kind? Mit wem spielt das Kind? Wie sind die äusseren Bedingungen? Gruppenzusammensetzung, Anzahl Kinder, Räumlichkeiten, Lautstärke, Spaziergang, Ausflug, Wetter…',
    doc,
    15,
  );
  height += 5;
  height += setText(height, observationSheet.startingPosition, doc, 15);
  doc.line(15, height, doc.internal.pageSize.getWidth() - 15, height);
  height += 5;
  height += setText(height, 'Beschreibung des Handlungsverlaufs:', doc, 15);
  height += setText(
    height,
    'Was geschieht? Was tut das Kind (Handlungen inklusive Feinzeichen)? Was sagt das Kind (verbal/nonverbal, wie z. B. Mimik und Gestik etc.)? (Die Beschreibung des Handlungsverlaufs enthält keine Interpretationen)',
    doc,
    15,
  );
  height += 5;
  height += setText(height, observationSheet.courseOfAction, doc, 15);
  doc.line(15, height, doc.internal.pageSize.getWidth() - 15, height);
  height += 5;
  height += setText(height, 'Sichtweise des Kindes:', doc, 15);
  height += setText(
    height,
    'Wie hat das Kind auf die Beobachtung der Erzieherin reagiert? Hat sich das Kind zur Situation geäussert? Was hat es gesagt? Hat die Erzieherin offene Fragen gestellt?',
    doc,
    15,
  );
  height += 5;
  height += setText(height, observationSheet.pointOfView, doc, 15);

  const blobPDF = doc.output('blob');

  const formData = new FormData();
  formData.append(
    'files',
    new File(
      [blobPDF],
      `Beobachtungsbogen_${kidName}_${new Date().toLocaleDateString('de-DE')}.pdf`,
    ),
  );
  formData.append('userId', currentUserId);
  formData.append('privatePost', true);
  formData.append('groupId', kidPortfolioGroupId);

  return new Promise((resolve, reject) => {
    uploadFilesToGroup(formData, pdfSaveData.setProgress)
      .then(() => {
        formData.set('groupId', 0);
        uploadFilesToGroup(formData, pdfSaveData.setProgress)
          .then(() => {
            resolve('PDF successfully uploaded');
          })
          .catch(() => {
            reject(new Error('Failed to upload pdf'));
          });
      })
      .catch(() => {
        reject(new Error('Failed to upload pdf'));
      });
  });
};

export const createPDFParentTalk = pdfSaveData => {
  const parentsTalk = pdfSaveData.parentsTalk;
  const kidName = pdfSaveData.selectedKid.label;
  let kidPortfolioGroupId = null;
  if (pdfSaveData.selectedKid.portfolioGroupId) {
    kidPortfolioGroupId = pdfSaveData.selectedKid.portfolioGroupId;
  }
  const currentUserId = pdfSaveData.userId;

  let height = 20;
  const doc = new jsPDF();
  doc.autoTable({ html: '#parentTalkTable' });
  doc.autoTable({
    showHead: 'never',
    body: [
      ['Name des Kindes', kidName],
      ['Beobachtungszeitraum:', parentsTalk.observationPeriod],
      ['Gesprächstermin:', parentsTalk.appointment],
      ['Gesprächsführung', parentsTalk.interviewing],
      ['Teilnehmende Personen', parentsTalk.participatingPersons],
    ],
    didDrawPage: d => (height = Math.ceil(d.cursor.y) + 10),
  });

  doc.setFontSize(10);
  height += setText(
    height,
    'Nr. Gesprächsinhalte (Beobachtungen der Eltern schriftlich festhalten)',
    doc,
    15,
  );
  doc.line(15, height, doc.internal.pageSize.getWidth() - 15, height);
  height += 5;
  height += setText(height, '1. Was ist das Interesse des Kindes? Woran erkenne ich das?', doc, 15);
  height += 5;
  height += setText(height, parentsTalk.kidsInterest, doc, 15);
  doc.line(15, height, doc.internal.pageSize.getWidth() - 15, height);
  height += 5;
  height += setText(height, '2. Woran erkenne ich das Engagement des Kindes?', doc, 15);
  height += 5;
  height += setText(height, parentsTalk.kidsEngagement, doc, 15);
  doc.line(15, height, doc.internal.pageSize.getWidth() - 15, height);
  height += 5;
  height += setText(height, '3. Woran erkenne ich das Standhalten des Kindes?', doc, 15);
  height += 5;
  height += setText(height, parentsTalk.kidsWithstand, doc, 15);
  doc.line(15, height, doc.internal.pageSize.getWidth() - 15, height);
  height += 5;
  height += setText(height, '4. Wie drückt sich das Kind aus und wie teilt es sich mit?', doc, 15);
  height += 5;
  height += setText(height, parentsTalk.kidsExpressing, doc, 15);
  doc.line(15, height, doc.internal.pageSize.getWidth() - 15, height);
  height += 5;
  height += setText(
    height,
    '5. Woran erkenne ich, dass das Kind an einer Lerngemeinschaft mitwirkt und Verantwortung übernimmt?',
    doc,
    15,
  );
  height += 5;
  height += setText(height, parentsTalk.kidsResponsibility, doc, 15);
  doc.line(15, height, doc.internal.pageSize.getWidth() - 15, height);
  height += 5;
  height += setText(
    height,
    '6. Gesundheit und Pflege (Gesundheit, Essen, Wickeln, Schlafen…)',
    doc,
    15,
  );
  height += 5;
  height += setText(height, parentsTalk.kidsHealth, doc, 15);
  doc.line(15, height, doc.internal.pageSize.getWidth() - 15, height);
  height += 5;
  height += setText(
    height,
    '7. Aktuelle „nächste Schritte“(Für das Kind, für das eigene pädagogische Handeln, für die Lernumgebung...)',
    doc,
    15,
  );
  height += 5;
  height += setText(height, parentsTalk.nextSteps, doc, 15);
  doc.line(15, height, doc.internal.pageSize.getWidth() - 15, height);
  height += 5;
  height += setText(
    height,
    '8. Sonstiges (Wünsche auch in Bezug auf die Erziehungspartnerschaft, Anregungen, Rückmeldungen…)',
    doc,
    15,
  );
  height += 5;
  height += setText(height, parentsTalk.others, doc, 15);
  doc.line(15, height, doc.internal.pageSize.getWidth() - 15, height);
  height += 5;
  height += setText(height, '9. Nächstes Gespräch', doc, 15);
  height += 5;
  height += setText(height, parentsTalk.nextConversation, doc, 15);
  doc.line(15, height, doc.internal.pageSize.getWidth() - 15, height);

  const blobPDF = doc.output('blob');

  const formData = new FormData();
  formData.append(
    'files',
    new File([blobPDF], `Elterngespräch_${kidName}_${new Date().toLocaleDateString('de-DE')}.pdf`),
  );
  formData.append('userId', currentUserId);
  formData.append('privatePost', true);
  formData.append('groupId', kidPortfolioGroupId);

  return new Promise((resolve, reject) => {
    uploadFilesToGroup(formData, pdfSaveData.setProgress)
      .then(() => {
        formData.set('groupId', 0);
        uploadFilesToGroup(formData, pdfSaveData.setProgress)
          .then(() => {
            resolve('PDF successfully uploaded');
          })
          .catch(() => {
            reject(new Error('Failed to upload pdf'));
          });
      })
      .catch(() => {
        reject(new Error('Failed to upload pdf'));
      });
  });
};

export function createTaskDetailListPDF(data, kitaName) {
  const doc = new jsPDF();
  let height = 10,
    page = 1;
  addPDFFooter(doc, page, 12, kitaName);

  data.forEach(taskList => {
    doc.line(10, height, doc.internal.pageSize.getWidth() - 10, height);
    const taskListTitle = `${taskList.taskListTitle} (${
      taskList.isCompleted ? 'Completed' : 'Not completed'
    })`;
    doc.setFontSize(16);
    doc.setFont(undefined, 'bold');

    height += 10;
    let result = setOnlyText(height, taskListTitle, doc, 10);
    height += result.height;
    if (result.newPageAdded) {
      page++;
      addPDFFooter(doc, page, 12, kitaName);
    }

    doc.setFontSize(14);
    doc.setFont(undefined, 'normal');

    if (taskList.tasks.length === 0) {
      height += 3;
      result = setOnlyText(height, 'No tasks', doc, 20);
      height += result.height;
      if (result.newPageAdded) {
        page++;
        addPDFFooter(doc, page, 12, kitaName);
      }
    }

    doc.setFontSize(12);
    taskList.tasks.forEach((task, index) => {
      const taskTitle = `${index + 1}. ${task.title} (${task.taskStatus})`;
      height += 2;
      result = setOnlyText(height, taskTitle, doc, 20);
      height += result.height;
      if (result.newPageAdded) {
        page++;
        addPDFFooter(doc, page, 12, kitaName);
      }

      if (task.description) {
        height += 1;
        result = setOnlyText(height, task.description ?? 'No description', doc, 20);
        height += result.height;
        if (result.newPageAdded) {
          page++;
          addPDFFooter(doc, page, 12, kitaName);
        }
      }

      height += 3;
      result = setOnlyText(height, `- Scheduling: ${task.isScheduling ? 'Yes' : 'No'}`, doc, 20);
      height += result.height;
      if (result.newPageAdded) {
        page++;
        addPDFFooter(doc, page, 12, kitaName);
      }

      if (task.isScheduling) {
        height += 1;
        result = setOnlyText(
          height,
          `${new Date(task.start).toLocaleString()} ~ ${new Date(task.end).toLocaleString()}`,
          doc,
          25,
        );
        height += result.height;
        if (result.newPageAdded) {
          page++;
          addPDFFooter(doc, page, 12, kitaName);
        }
      }

      if (task.responsibleUsersList.length > 0) {
        height += 2;
        result = setOnlyText(height, '- Responsible users', doc, 20);
        height += result.height;
        if (result.newPageAdded) {
          page++;
          addPDFFooter(doc, page, 12, kitaName);
        }
        task.responsibleUsersList.map(user => {
          height += 1;
          result = setOnlyText(height, `${user.firstName} ${user.lastName}`, doc, 25);
          height += result.height;
          if (result.newPageAdded) {
            page++;
            addPDFFooter(doc, page, 12, kitaName);
          }
        });
      }

      if (task.assignedUsersList.length > 0) {
        height += 2;
        result = setOnlyText(height, '- Assigned users', doc, 20);
        height += result.height;
        if (result.newPageAdded) {
          page++;
          addPDFFooter(doc, page, 12, kitaName);
        }
        task.assignedUsersList.map(user => {
          height += 1;
          result = setOnlyText(height, `${user.firstName} ${user.lastName}`, doc, 25);
          height += result.height;
          if (result.newPageAdded) {
            page++;
            addPDFFooter(doc, page, 12, kitaName);
          }
        });
      }
    });
  });

  doc.save('Tasks.pdf');
}

export function createAllTaskListsWithPersonPDF(data, kitaName, t) {
  const doc = new jsPDF();
  let height = 10,
    page = 1;
  addPDFFooter(doc, page, 12, kitaName);

  data.forEach((taskList, index) => {
    doc.line(10, height, doc.internal.pageSize.getWidth() - 10, height);
    const taskListTitle = `${index + 1}. ${taskList.taskListTitle}`;
    doc.setFontSize(16);
    doc.setFont(undefined, 'bold');

    height += 10;
    let result = setOnlyText(height, taskListTitle, doc, 10);
    height += result.height;
    if (result.newPageAdded) {
      page++;
      addPDFFooter(doc, page, 12, kitaName);
    }

    doc.setFontSize(14);
    doc.setFont(undefined, 'normal');

    if (taskList.tasks.length === 0) {
      height += 3;
      result = setOnlyText(height, 'No tasks', doc, 20);
      height += result.height;
      if (result.newPageAdded) {
        page++;
        addPDFFooter(doc, page, 12, kitaName);
      }
    }

    doc.setFontSize(12);
    taskList.tasks.forEach((task, index) => {
      const taskTitle = `${String.fromCharCode(97 + index)}. task ${index + 1} - ${task.title} `;
      const users =
        task.assignedUsersList.length === 0
          ? `- ${t('TaskList.No person who has taken this task')}`
          : `- ${task.assignedUsersList
              .map(user => `${user.firstName} ${user.lastName}`)
              .join(', ')}`;
      height += 2;
      result = setOnlyText(height, taskTitle + users, doc, 20);
      height += result.height;
      if (result.newPageAdded) {
        page++;
        addPDFFooter(doc, page, 12, kitaName);
      }
    });
  });

  doc.save('Tasks.pdf');
}

export function createOneTaskListWithPersonPDF(taskList, kitaName, t) {
  const doc = new jsPDF();
  let height = 10,
    page = 1;
  addPDFFooter(doc, page, 12, kitaName);

  doc.line(10, height, doc.internal.pageSize.getWidth() - 10, height);
  const taskListTitle = `${taskList.taskListTitle}`;
  doc.setFontSize(16);
  doc.setFont(undefined, 'bold');

  height += 10;
  let result = setOnlyText(height, taskListTitle, doc, 10);
  height += result.height;
  if (result.newPageAdded) {
    page++;
    addPDFFooter(doc, page, 12, kitaName);
  }

  doc.setFontSize(14);
  doc.setFont(undefined, 'normal');

  if (taskList.tasks.length === 0) {
    height += 3;
    result = setOnlyText(height, 'No tasks', doc, 20);
    height += result.height;
    if (result.newPageAdded) {
      page++;
      addPDFFooter(doc, page, 12, kitaName);
    }
  }

  doc.setFontSize(12);
  taskList.tasks.forEach((task, index) => {
    const taskTitle = `${String.fromCharCode(97 + index)}. ${task.title} `;
    const users =
      task.assignedUsersList.length === 0
        ? `- ${t('TaskList.No person who has taken this task')}`
        : `- ${task.assignedUsersList
            .map(user => `${user.firstName} ${user.lastName}`)
            .join(', ')}`;
    height += 2;
    result = setOnlyText(height, taskTitle + users, doc, 20);
    height += result.height;
    if (result.newPageAdded) {
      page++;
      addPDFFooter(doc, page, 12, kitaName);
    }
  });

  doc.save('Tasks.pdf');
}

export function createSignatureListPDF(groupedSignatures, kitaName) {
  const doc = new jsPDF();
  let height = 10;
  let page = 1;

  // Function to add footer
  const addPDFFooter = (doc, page, fontSize, kitaName) => {
    doc.setFontSize(fontSize);
    doc.text(`Page ${page}`, doc.internal.pageSize.getWidth() - 20, doc.internal.pageSize.getHeight() - 10);
    doc.text(`Kita: ${kitaName}`, 10, doc.internal.pageSize.getHeight() - 10);
  };

  // Add footer to the first page
  addPDFFooter(doc, page, 12, kitaName);

  // Iterate over grouped signatures
  groupedSignatures.forEach((group, index) => {
    // Draw line for each fileId section
    doc.line(10, height, doc.internal.pageSize.getWidth() - 10, height);
    const fileOriginalPath = group.fileOriginalPath;

    const fileTitle = `Signature File: ${group.fileName}`;
    doc.setFontSize(16);
    doc.setFont(undefined, 'bold');

    height += 10;
    let result = setTextWithLink(height, fileTitle, doc, 10, fileOriginalPath);
    height += result.height;
    if (result.newPageAdded) {
      page++;
      addPDFFooter(doc, page, 12, kitaName);
    }

    doc.setFontSize(14);
    doc.setFont(undefined, 'normal');

    if (group.users.length === 0) {
      height += 3;
      doc.text('No users found', 20, height);
      height += 10;
      if (result.newPageAdded) {
        page++;
        addPDFFooter(doc, page, 12, kitaName);
      }
    } else {
      doc.setFontSize(12);
      group.users.forEach((user, userIndex) => {
        const userStatus = `${userIndex + 1}. ${user.user.firstName} ${user.user.lastName}: ${user.signStatus}`;
        height += 2; // Adjust height for spacing
        let result = setOnlyText(height, userStatus, doc, 20);
        height += result.height;
        if (result.newPageAdded) {
          page++;
          addPDFFooter(doc, page, 12, kitaName);
        }

        // Add kids information if available
        if (user.kids.length > 0) {
          const kidsNames = user.kids.map(kid => `${kid.firstName} ${kid.lastName}`).join(', ');
          doc.setFontSize(11);
          let result = setOnlyText(height, `  Kids: ${kidsNames}`, doc, 30);
          height += result.height;
          if (result.newPageAdded) {
            page++;
            addPDFFooter(doc, page, 12, kitaName);
          }
        }
      });
    }

    // Check if we need to add a new page
    if (height > doc.internal.pageSize.getHeight() - 20) {
      doc.addPage();
      page++;
      height = 10; // Reset height for new page
      addPDFFooter(doc, page, 12, kitaName);
    }
  });

  // Save the PDF
  doc.save('SignatureList.pdf');
}

export function createUserSignatureListPDF(kidsSignatures, kitaName, fileId, t) {
  const doc = new jsPDF();
  let height = 10;
  let page = 1;

  // Function to add header
  const addPDFHeader = (doc, title, date) => {
    doc.setFontSize(14);
    doc.text(title, 10, 10);
    doc.text(date, doc.internal.pageSize.getWidth() - 50, 10);
  };

  // Function to add footer
  const addPDFFooter = (doc, page, fontSize, kitaName) => {
    doc.setFontSize(fontSize);
    doc.setFont(undefined, 'normal');
    doc.text(`Page ${page}`, doc.internal.pageSize.getWidth() - 20, doc.internal.pageSize.getHeight() - 10);
    doc.text(`Kita: ${kitaName}`, 10, doc.internal.pageSize.getHeight() - 10);
  };

  // Set title and date
  const currentDate = new Date().toLocaleDateString('de-DE'); // Format date in German

  // Add header to the first page
  addPDFHeader(doc, fileId, currentDate);
  addPDFFooter(doc, page, 12, kitaName);

  // Draw table headers
  const gapX = 5;
  const headers = [
    t('Kid.Child Name'),
    t('Kid.Group'),
    t('Kid.User Name'),
    t('Kid.Signature Date')
  ];
  const cellWidth = (doc.internal.pageSize.getWidth() - 2 * gapX) / headers.length;
  height += 10; // Adjust height for headers

  doc.setFont(undefined, 'bold');

  // Draw header row
  headers.forEach((header, index) => {
    doc.rect(index * cellWidth + gapX, height, cellWidth, 10); // Draw cell
    doc.text(header, index * cellWidth + gapX + 5, height + 7); // Add text
  });

  height += 10; // Move down for data rows

  doc.setFontSize(11);
  doc.setFont(undefined, 'normal');

  // Iterate over grouped signatures
  kidsSignatures.forEach((kidData) => {
    let kidPrintData;
    const signatureDate = kidData.hasSignature ? kidData.signatureDate : t('Post.not signed yet');

    kidPrintData = [
      `${kidData.firstName} ${kidData.lastName}`,
      kidData.groupName ?? '',
      kidData.userName,
      signatureDate
    ];

    // Draw each cell in the row
    kidPrintData.forEach((data, index) => {
      doc.rect(index * cellWidth + gapX, height, cellWidth, 10); // Draw cell
      doc.text(data, index * cellWidth + gapX + 5, height + 7); // Add text
    });

    height += 10; // Move down for the next row

    // Check if we need to add a new page
    if (height > doc.internal.pageSize.getHeight() - 20) {
      doc.addPage();
      page++;
      height = 20; // Reset height for new page
      addPDFHeader(doc, fileId, currentDate); // Add header to new page
      addPDFFooter(doc, page, 12, kitaName);
    }
  });

  // Save the PDF
  doc.save('SignatureList.pdf');
}

export function createKidContactsPDF(kidContacts, kitaName, t) {
  const doc = new jsPDF();
  let height = 10;
  let page = 1;

  // Draw table headers
  const gapX = 5;
  const headers = [
    t('Kid.Child Name'),
    t('Kid.Parent Name'),
    t('Kid.ContactInfo'),
  ];
  const cellWidth = (doc.internal.pageSize.getWidth() - 2 * gapX) / headers.length;
  height += 10; // Adjust height for headers

  doc.setFontSize(12);
  doc.setFont(undefined, 'bold');

  // Draw header row
  headers.forEach((header, index) => {
    const x = index * cellWidth + gapX;
    const y = height;

    // Set fill color for header background
    doc.setFillColor(220, 220, 220); // Light gray color
    doc.rect(x, y, cellWidth, 10, 'F'); // Draw filled cell

    // Draw border for the header cell
    doc.setDrawColor(0, 0, 0); // Set border color to black
    doc.rect(x, y, cellWidth, 10); // Draw cell border

    // Add text
    doc.text(header, x + 5, y + 7); // Add text
  });

  height += 10; // Move down for data rows

  doc.setFontSize(11);
  doc.setFont(undefined, 'normal');

  // Iterate over kid contact data
  kidContacts.forEach((kidData) => {
    // Draw parent 1 row
    const parent1RowHeight = drawParentRow(
      doc,
      kidData,
      kidData.parent1Name,
      kidData.parent1Contact,
      height,
      cellWidth,
      gapX,
      t
    );

    height += parent1RowHeight; // Update height for parent 1 row

    // Check if parent 2 exists and draw their row
    if (kidData.parent2Name) {
      const parent2RowHeight = drawParentRow(
        doc,
        kidData,
        kidData.parent2Name,
        kidData.parent2Contact,
        height,
        cellWidth,
        gapX,
        t,
        true
      );

      height += parent2RowHeight; // Update height for parent 2 row
    }

    // Check if we need to add a new page
    if (height > doc.internal.pageSize.getHeight() - 20) {
      doc.addPage();
      page++;
      height = 20; // Reset height for new page
    }
  });

  // Save the PDF
  doc.save('KidEmergencyContact.pdf');
}

// Function to draw a parent row
function drawParentRow(
  doc,
  kidData,
  parentName,
  contactInfo,
  height,
  cellWidth,
  gapX,
  t,
  isSecondParent = false) {
  const kidName = isSecondParent ? '' : `${kidData.firstName} ${kidData.lastName}`;

  const contactLines = getContactInfo(contactInfo, t);
  const rowHeight = calculateRowHeight(doc, contactLines, cellWidth);

  let kidPrintData = [
    `${kidName}`,
    parentName,
    contactLines,
  ];

  kidPrintData.forEach((data, index) => {
    doc.rect(index * cellWidth + gapX, height, cellWidth, rowHeight); // Draw cell
    doc.text(data, index * cellWidth + gapX + 5, height + 7); // Add text
  });

  return rowHeight;
}

// Function to get formatted contact information
function getContactInfo(contactInfo, t) {
  const contactInfoLines = [];

  if (contactInfo.phone) {
    contactInfoLines.push(`${contactInfo.phone}(${t('Kid.Phone')})`);
  }
  if (contactInfo.mobile) {
    contactInfoLines.push(`${contactInfo.mobile}(${t('Kid.Mobile')})`);
  }
  contactInfoLines.push(`${contactInfo.email}`);

  return contactInfoLines.join('\n\n'); // Join lines with newline character
}

function calculateRowHeight(doc, text, cellWidth) {
  const lines = doc.splitTextToSize(text, cellWidth - 10); // Subtract padding
  return ((lines.length + 1) / 2) * 10; // Assuming each line takes up 10 units of height
}

export const createPDFAcclimatizationDocumentation = pdfSaveData => {
  const acclimatizationDocumentation = pdfSaveData.acclimatizationDocumentation;
  const kidName = pdfSaveData.selectedKid.label;
  let kidPortfolioGroupId = null;
  if (pdfSaveData.selectedKid.portfolioGroupId) {
    kidPortfolioGroupId = pdfSaveData.selectedKid.portfolioGroupId;
  }
  const currentUserId = pdfSaveData.userId;

  let height = 20;
  const doc = new jsPDF();
  doc.autoTable({ html: '#acclimatizationDocumentationTable' });
  doc.autoTable({
    showHead: 'never',
    body: [['Name des Kindes', kidName]],
    didDrawPage: d => (height = Math.ceil(d.cursor.y) + 10),
  });

  doc.setFontSize(10);
  height += 5;
  height += setText(height, 'Tag 1-3', doc, 15);
  height += 5;
  height += setText(height, 'Interessiert sein', doc, 15);
  height += setText(
    height,
    'An welchen Tätigkeiten, Themen, Materialien/Spielsachen und Personen ist das Kind interessiert?',
    doc,
    15,
  );
  height += 5;
  height += setText(height, acclimatizationDocumentation.kidsInterest, doc, 15);
  height += 5;
  height += setText(height, 'Engagiert sein', doc, 15);
  height += setText(
    height,
    'Woran ist das Engagement erkennbar? (z.B. setzt sich aktiv mit Dingen und Personen auseinander, konzentriert sich auf die Aktivität, zeigt Freude bei der Beschäftigung, entwickelt neue Ideen etc.)',
    doc,
    15,
  );
  height += 5;
  height += setText(height, acclimatizationDocumentation.kidsEngagement, doc, 15);
  height += 5;
  height += setText(height, 'Standhalten bei Herausforderungen', doc, 15);
  height += setText(
    height,
    'Wie geht das Kind mit Herausforderungen und Misserfolgen um? (z.B. probiert es weiter trotz Misserfolg, bittet um Hilfe, kehrt nach einer Pause zurück, verteidigt eigene Interessen, ist hartnäckig)',
    doc,
    15,
  );
  height += 5;
  height += setText(height, acclimatizationDocumentation.kidsWithstand, doc, 15);
  height += 5;
  height += setText(height, 'Sich ausdrücken und mitteilen', doc, 15);
  height += setText(
    height,
    'Wie drückt sich das Kind aus und wie teilt es sich mit? (z.B. macht Lautäusserungen, spricht in Worten/Sätzen, beantwortet Fragen, zeigt nonverbale Signale/Feinzeichen, äussert Gefühle, äussert eigene Ideen, macht sich mit schupsen oder wegnehmen bemerkbar)',
    doc,
    15,
  );
  height += 5;
  height += setText(height, acclimatizationDocumentation.kidsExpressing, doc, 15);
  height += 5;
  height += setText(
    height,
    'An einer Lerngemeinschaft mitwirken und Verantwortung übernehmen',
    doc,
    15,
  );
  height += setText(
    height,
    'Wie wirkt das Kind an der Lerngemeinschaft mit? (z.B. interessiert sich für das Tun der anderen Kinder, nachahmen, entwickelt etwas gemeinsam mit andern Kindern weiter, teilt, hilft, zeigt Kompromissbereitschaft, nimmt Rücksicht)',
    doc,
    15,
  );
  height += 5;
  height += setText(height, acclimatizationDocumentation.kidsResponsibility, doc, 15);
  height += 5;
  height += setText(height, 'Sonstiges', doc, 15);
  height += setText(
    height,
    '(Verhalten des Kindes gegenüber der Bezugsperson, Verhalten und Befindlichkeit elterliche Bezugsperson, Ziele für den nächsten Tag)',
    doc,
    15,
  );
  height += 5;
  height += setText(height, acclimatizationDocumentation.others, doc, 15);
  height += 5;
  height += setText(height, 'Tag 3', doc, 15);
  height += 5;
  height += setText(
    height,
    'Austausch mit den Eltern über einen Trennungsversuch am 4. Tag. Treffen folgende Kriterien mehrheitlich zu, kann der 1. Trennungsversuch am 4. Tag erfolgen:',
    doc,
    15,
  );
  height += 5;

  doc.autoTable({
    startY: height,
    head: [['Merkmal', 'Trifft zu', 'Trifft teilweise zu', 'Trifft nicht zu', 'Nicht beobachtbar']],
    body: [
      [
        'Das Kind zeigt Interesse für die neue Umgebung, z. B. beobachtet andere Kinder oder lässt sich auf ein Spiel ohne aktive Teilnahme der Eltern ein.',
        acclimatizationDocumentation.checkBox1 ? 'X' : '',
        acclimatizationDocumentation.checkBox2 ? 'X' : '',
        acclimatizationDocumentation.checkBox3 ? 'X' : '',
        acclimatizationDocumentation.checkBox4 ? 'X' : '',
      ],
      [
        'Das Kind lässt sich trösten.',
        acclimatizationDocumentation.checkBox5 ? 'X' : '',
        acclimatizationDocumentation.checkBox6 ? 'X' : '',
        acclimatizationDocumentation.checkBox7 ? 'X' : '',
        acclimatizationDocumentation.checkBox8 ? 'X' : '',
      ],
      [
        'Das Kind fühlt sich bei der betreuenden Bezugsperson wohl (z. B. richtet den Blick zu ihr bei Verunsicherung, sucht Körperkontakt, ist entspannt im Arm der Erzieherin).',
        acclimatizationDocumentation.checkBox9 ? 'X' : '',
        acclimatizationDocumentation.checkBox10 ? 'X' : '',
        acclimatizationDocumentation.checkBox11 ? 'X' : '',
        acclimatizationDocumentation.checkBox12 ? 'X' : '',
      ],
      [
        'Die Betreuungsperson kennt die wichtigsten Gewohnheiten des Kindes und versteht erste Signale von ihm.',
        acclimatizationDocumentation.checkBox13 ? 'X' : '',
        acclimatizationDocumentation.checkBox14 ? 'X' : '',
        acclimatizationDocumentation.checkBox15 ? 'X' : '',
        acclimatizationDocumentation.checkBox16 ? 'X' : '',
      ],
      [
        'Die Eltern haben Vertrauen in die Betreuungsperson gefasst und bringen dies zum Ausdruck.',
        acclimatizationDocumentation.checkBox17 ? 'X' : '',
        acclimatizationDocumentation.checkBox18 ? 'X' : '',
        acclimatizationDocumentation.checkBox19 ? 'X' : '',
        acclimatizationDocumentation.checkBox20 ? 'X' : '',
      ],
    ],
    didDrawPage: d => (height = Math.ceil(d.cursor.y) + 10),
  });
  height += 5;
  height += setText(height, 'Ergänzende Bemerkungen:', doc, 15);
  height += 5;
  height += setText(height, acclimatizationDocumentation.additionalComments, doc, 15);
  height += 5;
  height += setText(height, 'Ab Tag 4', doc, 15);
  height += 5;
  height += setText(height, 'Trennung', doc, 15);
  height += setText(
    height,
    'Wie verhält sich das Kind während der Trennung von der elterlichen Bezugsperson? (z.B. äussert Gefühle, lässt sich von der betreuenden Bezugsperson trösten, braucht Nähe etc. - Feinzeichen beachten)',
    doc,
    15,
  );
  height += 5;
  height += setText(height, 'Tag 4:', doc, 15);
  height += 5;
  height += setText(height, acclimatizationDocumentation.separationDay4, doc, 15);
  height += 5;
  height += setText(height, 'Tag 5:', doc, 15);
  height += 5;
  height += setText(height, acclimatizationDocumentation.separationDay5, doc, 15);
  height += 5;
  height += setText(height, 'Tag 6:', doc, 15);
  height += 5;
  height += setText(height, acclimatizationDocumentation.separationDay6, doc, 15);
  height += 5;
  height += setText(height, 'Tag 7:', doc, 15);
  height += 5;
  height += setText(height, acclimatizationDocumentation.separationDay7, doc, 15);
  height += 5;
  height += setText(
    height,
    'Weitere Beobachtungen zu den Lerndispositionen und Sonstigem',
    doc,
    15,
  );
  height += 5;
  height += setText(height, acclimatizationDocumentation.furtherObservation, doc, 15);
  height += 5;
  height += setText(height, 'Gesundheit und Pflege', doc, 15);
  height += setText(
    height,
    'Wie verhält sich das Kind bei den Mahlzeiten, beim Wickeln, beim Schlafen?',
    doc,
    15,
  );
  height += 5;
  height += setText(height, acclimatizationDocumentation.kidsHealth, doc, 15);

  doc.autoTable({
    startY: height,
    showHead: 'never',
    body: [['Abschluss der Eingewöhnung am:', acclimatizationDocumentation.acclimatizationEnd]],
    didDrawPage: d => (height = Math.ceil(d.cursor.y) + 10),
  });
  height += 5;
  height += setText(height, '(Mehrere Merkmale sollten gegeben sein)', doc, 15);

  doc.autoTable({
    startY: height,
    head: [['Merkmal', 'Trifft zu', 'Trifft teilweise zu', 'Trifft nicht zu', 'Nicht beobachtbar']],
    body: [
      [
        'Das Kind zeigt Interesse für neue Umgebung',
        acclimatizationDocumentation.checkBox22 ? 'X' : '',
        acclimatizationDocumentation.checkBox23 ? 'X' : '',
        acclimatizationDocumentation.checkBox24 ? 'X' : '',
        acclimatizationDocumentation.checkBox25 ? 'X' : '',
      ],
      [
        'Das Kind lässt sich trösten',
        acclimatizationDocumentation.checkBox26 ? 'X' : '',
        acclimatizationDocumentation.checkBox27 ? 'X' : '',
        acclimatizationDocumentation.checkBox28 ? 'X' : '',
        acclimatizationDocumentation.checkBox29 ? 'X' : '',
      ],
      [
        'Das Kind spielt und erkundet die Umwelt',
        acclimatizationDocumentation.checkBox30 ? 'X' : '',
        acclimatizationDocumentation.checkBox31 ? 'X' : '',
        acclimatizationDocumentation.checkBox32 ? 'X' : '',
        acclimatizationDocumentation.checkBox33 ? 'X' : '',
      ],
      [
        'Das Kind isst und schläft in der Kita.',
        acclimatizationDocumentation.checkBox34 ? 'X' : '',
        acclimatizationDocumentation.checkBox35 ? 'X' : '',
        acclimatizationDocumentation.checkBox36 ? 'X' : '',
        acclimatizationDocumentation.checkBox37 ? 'X' : '',
      ],
      [
        'Das Kind nimmt aktiv am Tagesgeschehen teil',
        acclimatizationDocumentation.checkBox38 ? 'X' : '',
        acclimatizationDocumentation.checkBox39 ? 'X' : '',
        acclimatizationDocumentation.checkBox40 ? 'X' : '',
        acclimatizationDocumentation.checkBox41 ? 'X' : '',
      ],
      [
        'Das Kind holt sich Hilfe und Unterstützung bei den Betreuungspersonen',
        acclimatizationDocumentation.checkBox42 ? 'X' : '',
        acclimatizationDocumentation.checkBox43 ? 'X' : '',
        acclimatizationDocumentation.checkBox44 ? 'X' : '',
        acclimatizationDocumentation.checkBox45 ? 'X' : '',
      ],
      [
        'Das Kind nimmt Kontakt mit anderen Kindern auf',
        acclimatizationDocumentation.checkBox46 ? 'X' : '',
        acclimatizationDocumentation.checkBox47 ? 'X' : '',
        acclimatizationDocumentation.checkBox48 ? 'X' : '',
        acclimatizationDocumentation.checkBox49 ? 'X' : '',
      ],
      [
        'Die Eltern lassen das Kind spüren, dass es gut aufgehoben ist',
        acclimatizationDocumentation.checkBox50 ? 'X' : '',
        acclimatizationDocumentation.checkBox51 ? 'X' : '',
        acclimatizationDocumentation.checkBox52 ? 'X' : '',
        acclimatizationDocumentation.checkBox53 ? 'X' : '',
      ],
      [
        'Die Eltern haben Vertrauen in die Betreuungspersonen gefasst und bringen dies zum Ausdruck',
        acclimatizationDocumentation.checkBox54 ? 'X' : '',
        acclimatizationDocumentation.checkBox55 ? 'X' : '',
        acclimatizationDocumentation.checkBox56 ? 'X' : '',
        acclimatizationDocumentation.checkBox57 ? 'X' : '',
      ],
      [
        'Die Betreuungspersonen verstehen die Signale des Kindes',
        acclimatizationDocumentation.checkBox58 ? 'X' : '',
        acclimatizationDocumentation.checkBox59 ? 'X' : '',
        acclimatizationDocumentation.checkBox60 ? 'X' : '',
        acclimatizationDocumentation.checkBox61 ? 'X' : '',
      ],
    ],
    didDrawPage: d => (height = Math.ceil(d.cursor.y) + 10),
  });
  height += setText(height, 'Zutreffendes bitte ankreuzen', doc, 15);
  height += 5;
  height += setText(height, 'Weitere Schritte:', doc, 15);
  height += 5;
  height += setText(
    height,
    'Bei einzelnen unterjährigen Eintritten führen die Erziehenden im Anschluss an die Eingewöhnung den kollegialen Austausch durch und eine betreuende Bezugsperson verfasst die erste Lerngeschichte.',
    doc,
    15,
  );
  height += 5;
  height += setText(
    height,
    'In eingewöhnungsintensiven Zeiten tauscht sich die Erziehende mit der zweiten involvierten Bezugsperson über die „nächsten Schritte“ aus (im Rahmen der Eingewöhnung). Die betreuende Bezugsperson verfasst im Anschluss an die Eingewöhnung die erste Lerngeschichte oder hält Magic Moments fest. Ist nur eine Bezugsperson in die Eingewöhnung involviert entfällt der Austausch. Die Lerngeschichte/Magic Moments wird von der Bezugsperson verfasst.',
    doc,
    15,
  );

  const blobPDF = doc.output('blob');

  const formData = new FormData();
  formData.append(
    'files',
    new File(
      [blobPDF],
      `Eingewöhnungsdokumentation_${kidName}_${new Date().toLocaleDateString('de-DE')}.pdf`,
    ),
  );
  formData.append('userId', currentUserId);
  formData.append('privatePost', true);
  formData.append('groupId', kidPortfolioGroupId);
  return new Promise((resolve, reject) => {
    uploadFilesToGroup(formData, pdfSaveData.setProgress)
      .then(() => {
        formData.set('groupId', 0);
        uploadFilesToGroup(formData, pdfSaveData.setProgress)
          .then(() => {
            resolve('PDF successfully uploaded');
          })
          .catch(() => {
            reject(new Error('Failed to upload pdf'));
          });
      })
      .catch(() => {
        reject(new Error('Failed to upload pdf'));
      });
  });
};

export const createPDFObservationAnalysis = pdfSaveData => {
  const observationAnalysis = pdfSaveData.observationAnalysis;
  const kidName = pdfSaveData.selectedKid.label;
  let kidPortfolioGroupId = null;
  if (pdfSaveData.selectedKid.portfolioGroupId) {
    kidPortfolioGroupId = pdfSaveData.selectedKid.portfolioGroupId;
  }
  const currentUserId = pdfSaveData.userId;

  let height = 20;
  const doc = new jsPDF();
  doc.autoTable({ html: '#observationAnalysisTable' });
  doc.autoTable({
    showHead: 'never',
    body: [
      ['Name des Kindes', kidName],
      ['Datum der Analyse:', convertDateToLocal(observationAnalysis.date)],
      ['Beobachtende Person:', observationAnalysis.observingPerson],
    ],
    didDrawPage: d => (height = Math.ceil(d.cursor.y) + 10),
  });

  doc.setFontSize(10);
  height += setText(height, 'Zeit für die Analyse: ca. 10-15 Minuten', doc, 15);
  height += 5;
  height += setText(
    height,
    'Wie sehen die allgemeinen Rahmenbedingungen aus? (Ich Botschaften verwenden)',
    doc,
    15,
  );
  height += 5;
  doc.line(15, height, doc.internal.pageSize.getWidth() - 15, height);
  height += 5;
  height += setText(
    height,
    'Wie ist das Wohlbefinden des Kindes (Laune des Kindes, müde, krank, hungrig etc. Welche Feinzeichen habe ich wahrgenommen und wie interpretiere ich diese)?',
    doc,
    15,
  );
  height += 5;
  height += setText(height, observationAnalysis.kidWellBeing, doc, 15);
  height += 5;
  doc.line(15, height, doc.internal.pageSize.getWidth() - 15, height);
  height += 5;
  height += setText(
    height,
    'Gibt es Besonderheiten in der Gruppen-dynamik (z.B. „Freund/Freundin“ in den Ferien, Kinder krank, Kinder von anderen Gruppen auf Besuch, Interaktionen…)?',
    doc,
    15,
  );
  height += 5;
  height += setText(height, observationAnalysis.groupDynamics, doc, 15);
  height += 5;
  doc.line(15, height, doc.internal.pageSize.getWidth() - 15, height);
  height += 5;
  height += setText(height, 'Ist die Bezugsperson anwesend?', doc, 15);
  height += setText(
    height,
    'Wie ist das Verhältnis zwischen der beobachtenden Person und dem Kind?',
    doc,
    15,
  );
  height += 5;
  height += setText(height, observationAnalysis.attachmentFigure, doc, 15);
  height += 5;
  doc.line(15, height, doc.internal.pageSize.getWidth() - 15, height);
  height += 5;
  height += setText(
    height,
    'Wie ist die Stimmung während der Beobachtungssituation (stressig, laut, gemütlich, harmonisch, angespannt, gelöst etc.)?',
    doc,
    15,
  );
  height += 5;
  height += setText(height, observationAnalysis.ambienceDuringObservation, doc, 15);
  height += 5;
  doc.line(15, height, doc.internal.pageSize.getWidth() - 15, height);
  height += 10;
  height += setText(
    height,
    'Wie sieht die Analyse nach den Lerndispositionen aus? (siehe Orientierungshilfe MMI)',
    doc,
    15,
  );
  height += 5;
  doc.setFont(undefined, 'bold');
  height += setText(height, 'Interessiert sein', doc, 15);
  doc.setFont(undefined, 'normal');
  height += 5;
  height += setText(height, 'Was ist das Interesse des Kindes? Woran erkenne ich es?', doc, 15);
  height += 5;
  height += setText(height, observationAnalysis.kidInterested, doc, 15);
  height += 5;
  doc.line(15, height, doc.internal.pageSize.getWidth() - 15, height);
  height += 5;
  doc.setFont(undefined, 'bold');
  height += setText(height, 'Engagiert sein', doc, 15);
  doc.setFont(undefined, 'normal');
  height += 5;
  height += setText(height, 'Woran erkenne ich das Engagement des Kindes?', doc, 15);
  height += 5;
  height += setText(height, observationAnalysis.kidCommitted, doc, 15);
  height += 5;
  doc.line(15, height, doc.internal.pageSize.getWidth() - 15, height);
  height += 5;
  doc.setFont(undefined, 'bold');
  height += setText(height, 'Standhalten bei Herausforderungen und Schwierigkeiten', doc, 15);
  doc.setFont(undefined, 'normal');
  height += 5;
  height += setText(height, 'Woran erkenne ich das Standhalten des Kindes?', doc, 15);
  height += 5;
  height += setText(height, observationAnalysis.kidWithstand, doc, 15);
  height += 5;
  doc.line(15, height, doc.internal.pageSize.getWidth() - 15, height);
  height += 5;
  doc.setFont(undefined, 'bold');
  height += setText(height, 'Sich ausdrücken und mitteilen', doc, 15);
  doc.setFont(undefined, 'normal');
  height += 5;
  height += setText(height, 'Wie drückt sich das Kind aus und wie teilt es sich mit?', doc, 15);
  height += 5;
  height += setText(height, observationAnalysis.kidExpression, doc, 15);
  height += 5;
  doc.line(15, height, doc.internal.pageSize.getWidth() - 15, height);
  height += 5;
  doc.setTextColor(255, 0, 0);
  height += setText(
    height,
    'Nur ausfüllen bei Videosequenz anstelle von der schriftlichen Beobachtung:',
    doc,
    15,
  );
  doc.setTextColor(0);
  height += 5;
  doc.setFont(undefined, 'bold');
  height += setText(height, 'Sichtweise des Kindes', doc, 15);
  doc.setFont(undefined, 'normal');
  height += 5;
  height += setText(
    height,
    'Wie hat das Kind auf die Beobachtung der Erziehenden reagiert? Hat sich das Kind zur Situation geäussert? Was hat es gesagt? Hat die Erziehende offene Fragen gestellt?',
    doc,
    15,
  );
  height += 5;
  height += setText(height, observationAnalysis.kidView, doc, 15);
  height += 5;
  doc.line(15, height, doc.internal.pageSize.getWidth() - 15, height);
  height += 5;
  doc.setFont(undefined, 'bold');
  height += setText(
    height,
    'An einer Lerngemeinschaft mitwirken und Verantwortung übernehmen',
    doc,
    15,
  );
  doc.setFont(undefined, 'normal');
  height += 5;
  height += setText(
    height,
    'Woran erkenne ich, dass das Kind an einer Lerngemein-schaft mitwirkt und Verantwortung übernimmt?',
    doc,
    15,
  );
  height += 5;
  height += setText(height, observationAnalysis.kidCollaborateAndTakeResponsibility, doc, 15);
  height += 5;
  doc.line(15, height, doc.internal.pageSize.getWidth() - 15, height);
  height += 5;
  height += setText(height, 'Fokussierung mit Begründung', doc, 15);
  height += setText(height, 'Um welches Lernen geht es hier?', doc, 15);
  height += setText(
    height,
    '(Sinneswahrnehmung, Experimentieren, Nachahmen, Grobmotorik, Feinmotorik, Sprache, Musik, Natur und Technik, Umgang mit anderen Menschen',
    doc,
    15,
  );
  height += 5;
  height += setText(height, observationAnalysis.learningType, doc, 15);
  height += 5;
  doc.line(15, height, doc.internal.pageSize.getWidth() - 15, height);
  height += 5;
  height += setText(height, 'Alltagsbeobachtungen', doc, 15);
  height += setText(
    height,
    'Zeigt das Kind diese oder ähnliche Beobachtungen auch im Alltag?',
    doc,
    15,
  );
  height += 5;
  height += setText(height, observationAnalysis.kidDailyObservations, doc, 15);
  height += 5;
  height += setText(height, 'Ideen für „nächste Schritte"', doc, 15);
  height += setText(height, 'Habe ich spontan Ideen für die „nächsten Schritte“?', doc, 15);
  height += setText(
    height,
    '(für das Kind, für das eigene pädagogische Handeln, für die Lernumgebung)',
    doc,
    15,
  );
  height += 5;
  height += setText(height, observationAnalysis.nextSteps, doc, 15);

  const blobPDF = doc.output('blob');

  const formData = new FormData();
  formData.append(
    'files',
    new File(
      [blobPDF],
      `Beobachtungsanalyse_${kidName}_${new Date().toLocaleDateString('de-DE')}.pdf`,
    ),
  );
  formData.append('userId', currentUserId);
  formData.append('privatePost', true);
  formData.append('groupId', kidPortfolioGroupId);
  return new Promise((resolve, reject) => {
    uploadFilesToGroup(formData, pdfSaveData.setProgress)
      .then(() => {
        formData.set('groupId', 0);
        uploadFilesToGroup(formData, pdfSaveData.setProgress)
          .then(() => {
            resolve('PDF successfully uploaded');
          })
          .catch(() => {
            reject(new Error('Failed to upload pdf'));
          });
      })
      .catch(() => {
        reject(new Error('Failed to upload pdf'));
      });
  });
};

export const createPDFColleagueExchange = pdfSaveData => {
  const colleagueExchange = pdfSaveData.colleagueExchange;
  const kidName = pdfSaveData.selectedKid.label;
  let kidPortfolioGroupId = null;
  if (pdfSaveData.selectedKid.portfolioGroupId) {
    kidPortfolioGroupId = pdfSaveData.selectedKid.portfolioGroupId;
  }
  const currentUserId = pdfSaveData.userId;
  let height = 20;
  const doc = new jsPDF();
  doc.autoTable({ html: '#colleagueExchangeTable' });
  doc.autoTable({
    showHead: 'never',
    body: [
      ['Name des Kindes', kidName],
      ['Datum des Austausches:', convertDateToLocal(colleagueExchange.date)],
      ['Anwesende:', colleagueExchange.presentPersons],
    ],
    didDrawPage: d => (height = Math.ceil(d.cursor.y) + 10),
  });

  doc.setFontSize(10);
  doc.setFont(undefined, 'bold');
  height += setText(height, 'Verschiedene Beobachtungen und Eindrücke', doc, 15);
  doc.setFont(undefined, 'normal');
  height += setText(
    height,
    'Hat das Kind Spielpartner/Spielpartnerinnen, welche es bevorzugt?',
    doc,
    15,
  );
  height += setText(height, 'Welche Alltagsbeobachtungen gibt es darüber hinaus?', doc, 15);
  height += 5;
  height += setText(height, colleagueExchange.variousObservations, doc, 15);
  height += 5;
  doc.line(15, height, doc.internal.pageSize.getWidth() - 15, height);
  height += 5;
  doc.setFont(undefined, 'bold');
  height += setText(
    height,
    'Zeigt sich ein roter Faden über mehrere Beobachtungen anhand der Lerndispositionen?',
    doc,
    15,
  );
  doc.setFont(undefined, 'normal');
  height += 5;
  height += setText(height, colleagueExchange.commonObservations, doc, 15);
  height += 5;
  doc.line(15, height, doc.internal.pageSize.getWidth() - 15, height);
  height += 5;
  doc.setFont(undefined, 'bold');
  height += setText(height, 'Ideen für „nächste Schritte“', doc, 15);
  doc.setFont(undefined, 'normal');
  height += setText(height, 'Worauf haben wir bereits reagiert?', doc, 15);
  height += setText(height, 'Was könnten wir anregen und/oder ausprobieren?', doc, 15);
  height += setText(height, 'Gibt es Anknüpfungspunkte zu Interessen anderer Kinder?', doc, 15);
  height += setText(
    height,
    'Wie könnten wir die Lernumgebung den Bedürfnissen/Interessen des Kindes anpassen?',
    doc,
    15,
  );
  height += setText(height, 'Wie könnten wir unser pädagogisches Handeln ggf. anpassen?', doc, 15);
  height += 5;
  height += setText(height, colleagueExchange.nextSteps, doc, 15);
  height += 5;
  doc.line(15, height, doc.internal.pageSize.getWidth() - 15, height);
  height += 10;
  doc.setFont(undefined, 'bold');
  height += setText(height, 'Lerngeschichte', doc, 15);
  doc.setFont(undefined, 'normal');
  height += setText(height, 'Die Lerngeschichte wird geschrieben von:', doc, 15);
  height += 5;
  height += setText(height, colleagueExchange.storyAuthor, doc, 15);
  height += 5;
  doc.line(15, height, doc.internal.pageSize.getWidth() - 15, height);
  height += 5;
  height += setText(height, 'Die Lerngeschichte wird dem Kind bis wann erzählt:', doc, 15);
  height += 5;
  height += setText(height, colleagueExchange.storyToldUntil, doc, 15);
  height += 5;
  doc.line(15, height, doc.internal.pageSize.getWidth() - 15, height);
  height += 5;
  height += setText(
    height,
    'Verantwortliche Person für die Umsetzung der „nächsten Schritte“:',
    doc,
    15,
  );
  height += 5;
  height += setText(height, colleagueExchange.storyResponsible, doc, 15);
  height += 5;
  doc.line(15, height, doc.internal.pageSize.getWidth() - 15, height);
  height += 5;
  height += setText(height, 'Zeitraum der Umsetzung:', doc, 15);
  height += 5;
  height += setText(height, colleagueExchange.storyTimeSpan, doc, 15);

  const blobPDF = doc.output('blob');

  const formData = new FormData();
  formData.append(
    'files',
    new File(
      [blobPDF],
      `KollegialerAustausch_${kidName}_${new Date().toLocaleDateString('de-DE')}.pdf`,
    ),
  );
  formData.append('userId', currentUserId);
  formData.append('privatePost', true);
  formData.append('groupId', kidPortfolioGroupId);
  return new Promise((resolve, reject) => {
    uploadFilesToGroup(formData, pdfSaveData.setProgress)
      .then(() => {
        formData.set('groupId', 0);
        uploadFilesToGroup(formData, pdfSaveData.setProgress)
          .then(() => {
            resolve('PDF successfully uploaded');
          })
          .catch(() => {
            reject(new Error('Failed to upload pdf'));
          });
      })
      .catch(() => {
        reject(new Error('Failed to upload pdf'));
      });
  });
};

export const createPDFMedicalAuthorization = pdfSaveData => {
  const medicalAuthorization = pdfSaveData.medicalAuthorization;
  const kidName = pdfSaveData.selectedKid.label;
  let kidPortfolioGroupId = null;
  if (pdfSaveData.selectedKid.portfolioGroupId) {
    kidPortfolioGroupId = pdfSaveData.selectedKid.portfolioGroupId;
  }
  const currentUserId = pdfSaveData.userId;
  let height = 20;
  const doc = new jsPDF();


  height += setText(
    height,
    'Vollmacht zur Medikamentenabgabe durch diplomiertes Kita-Personal',
    doc,
    15,
  );

  doc.setFontSize(10);
  doc.text(pdfSaveData.kitaName, 15, 10);

  doc.setFontSize(10);

  height += 10;
  doc.autoTable({ html: '#medicalAuthorization' });
  doc.autoTable({
    showHead: 'never',
    body: [
      ['Name des Kindes', kidName],
      ['Name des Medikaments', medicalAuthorization.drugName],
      ['Start der Medikamentenabgabe', isoDateStringToGerman(medicalAuthorization.timeFrom)],
      ['Ende der Medikamentenabgabe', isoDateStringToGerman(medicalAuthorization.timeTo)],
    ],
    didDrawPage: d => (height = Math.ceil(d.cursor.y) + 10),
  });
  height += 10;
  height += setText(
    height,
    'Nach Ablauf des definierten Abgabezeitraums wird das Medikament wieder an die Eltern zurückgegeben.',
    doc,
    15,
  );
  height += 10;
  height += setText(height, 'Abgabe und Dosierung des Medikaments:', doc, 15);
  doc.autoTable({
    startY: height,
    head: [['', 'vor dem Essen', 'mit dem Essen', 'nach dem Essen', 'Dosierung']],
    body: [
      [
        'Morgen',
        medicalAuthorization.checkboxMorningBefore ? 'X' : '',
        medicalAuthorization.checkboxMorningDuring ? 'X' : '',
        medicalAuthorization.checkboxMorningAfter ? 'X' : '',
        medicalAuthorization.morningDosage,
      ],
      [
        'Mittag',
        medicalAuthorization.checkboxNoonBefore ? 'X' : '',
        medicalAuthorization.checkboxNoonDuring ? 'X' : '',
        medicalAuthorization.checkboxNoonAfter ? 'X' : '',
        medicalAuthorization.noonDosage,
      ],
      [
        'Nachmittag',
        medicalAuthorization.checkboxAfternoonBefore ? 'X' : '',
        medicalAuthorization.checkboxAfternoonDuring ? 'X' : '',
        medicalAuthorization.checkboxAfternoonAfter ? 'X' : '',
        medicalAuthorization.afternoonDosage,
      ],
      [
        'Abend',
        medicalAuthorization.checkboxEveningBefore ? 'X' : '',
        medicalAuthorization.checkboxEveningDuring ? 'X' : '',
        medicalAuthorization.checkboxEveningAfter ? 'X' : '',
        medicalAuthorization.eveningDosage,
      ],
    ],
    didDrawPage: d => (height = Math.ceil(d.cursor.y) + 10),
  });

  height += 10;
  height += setText(height, 'Besonderes', doc, 15);
  height += setText(height, medicalAuthorization.notes, doc, 15);
  height += 10;
  height += setText(
    height,
    'Hiermit bevollmächtige ich das diplomierte Personal in der Kita, meinem Kind das oben genannte Medikament zu verabreichen. Die Angaben zur Dosierung sind korrekt.',
    doc,
    15,
  );
  height += 10;
  height += addSignatureImage(
    height,
    'Datum: ' + isoDateStringToGerman(medicalAuthorization.parentSignatureDate),
    pdfSaveData.parentSignature,
    'Unterschrift Eltern:',
    doc
  );
  height += 10;
  height += addSignatureImage(
    height,
    'Datum: ' + isoDateStringToGerman(medicalAuthorization.kitaSignatureDate),
    pdfSaveData.kitaSignature,
    'Unterschrift Kita:',
    doc
  );

  const blobPDF = doc.output('blob');

  const formData = new FormData();
  formData.append(
    'files',
    new File(
      [blobPDF],
      `Medikamentenabgabe_${kidName}_${new Date().toLocaleDateString('de-DE')}.pdf`,
    ),
  );
  formData.append('userId', currentUserId);
  formData.append('groupId', kidPortfolioGroupId);
  formData.append('privatePost', false);
  uploadFilesToGroup(formData, pdfSaveData.setProgress).then(() => {
    formData.set('groupId', 0);
    formData.set('privatePost', true);
    uploadFilesToGroup(formData, pdfSaveData.setProgress);
  });

  return blobPDF;
};

export const createPDFKidLeaving = pdfSaveData => {
  const kidLeaving = pdfSaveData.kidLeaving;
  const kidName = pdfSaveData.selectedKid.label;
  let kidPortfolioGroupId = null;
  if (pdfSaveData.selectedKid.portfolioGroupId) {
    kidPortfolioGroupId = pdfSaveData.selectedKid.portfolioGroupId;
  }
  const currentUserId = pdfSaveData.userId;

  let height = 20;
  const doc = new jsPDF();

  doc.setFontSize(10);
  doc.text(pdfSaveData.kitaName, 15, 10);

  height += setText(height, 'Austritt eines Kindes', doc, 15);

  doc.setFontSize(10);

  doc.autoTable({ html: '#kidLeaving' });
  doc.autoTable({
    showHead: 'never',
    body: [
      ['Name des Kindes', kidName],
      ['Kita', kidLeaving.kitaName],
      ['Gruppe', kidLeaving.groupName],
      ['Datum', isoDateStringToGerman(kidLeaving.todayDate)],
    ],
    didDrawPage: d => (height = Math.ceil(d.cursor.y) + 10),
  });

  doc.autoTable({
    head: [['', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag']],
    body: [
      [
        'Bestehende Betreuungstage:',
        kidLeaving.monday ? 'X' : '',
        kidLeaving.tuesday ? 'X' : '',
        kidLeaving.wednesday ? 'X' : '',
        kidLeaving.thursday ? 'X' : '',
        kidLeaving.friday ? 'X' : '',
      ],
    ],
    didDrawPage: d => (height = Math.ceil(d.cursor.y) + 10),
  });

  doc.autoTable({
    head: [['', 'subv.', 'privat']],
    body: [
      [
        'Vertrag:',
        kidLeaving.contract === 'subv' ? 'X' : '',
        kidLeaving.contract === 'priv' ? 'X' : '',
      ],
    ],
    didDrawPage: d => (height = Math.ceil(d.cursor.y) + 10),
  });

  doc.autoTable({
    showHead: 'never',
    body: [['Austrittsdatum:', isoDateStringToGerman(kidLeaving.leaveDate)]],
    didDrawPage: d => (height = Math.ceil(d.cursor.y) + 10),
  });
  height += 10;
  height += setText(
    height,
    'Diese Unterlagen müssen nach einer Änderung umgehend an die AV weiter geleitet werden.',
    doc,
    15,
  );
  height += 10;
  height += addSignatureImage(
    height,
    'Datum: ' + isoDateStringToGerman(kidLeaving.parentSignatureDate),
    pdfSaveData.parentSignature,
    'Unterschrift Eltern:',
    doc
  );
  height += 10;
  height += addSignatureImage(
    height,
    'Datum: ' + isoDateStringToGerman(kidLeaving.kitaSignatureDate),
    pdfSaveData.kitaSignature,
    'Unterschrift Kita:',
    doc
  );

  const blobPDF = doc.output('blob');

  const formData = new FormData();
  formData.append(
    'files',
    new File([blobPDF], `Kindaustritt_${kidName}_${new Date().toLocaleDateString('de-DE')}.pdf`),
  );
  formData.append('userId', currentUserId);
  formData.append('groupId', kidPortfolioGroupId);
  formData.append('privatePost', false);
  uploadFilesToGroup(formData, pdfSaveData.setProgress).then(() => {
    formData.set('groupId', 0);
    formData.set('privatePost', true);
    uploadFilesToGroup(formData, pdfSaveData.setProgress);
  });

  return blobPDF;
};

export const createPDFContractChanges = pdfSaveData => {
  const contractChanges = pdfSaveData.contractChanges;
  const kidName = pdfSaveData.selectedKid.label;
  let kidPortfolioGroupId = null;
  if (pdfSaveData.selectedKid.portfolioGroupId) {
    kidPortfolioGroupId = pdfSaveData.selectedKid.portfolioGroupId;
  }
  const currentUserId = pdfSaveData.userId;
  let height = 20;
  const doc = new jsPDF();
  doc.setFontSize(10);
  doc.text(pdfSaveData.kitaName, 15, 10);

  height += setText(height, 'Vertragsänderungen', doc, 15);
  height += 10;

  doc.setFontSize(10);

  doc.autoTable({ html: '#contractChanges' });
  doc.autoTable({
    showHead: 'never',
    body: [
      ['Name des Kindes:', kidName],
      ['Kita:', contractChanges.kitaName],
      ['Datum:', isoDateStringToGerman(contractChanges.date)],
      ['Gültigkeit ab:', isoDateStringToGerman(contractChanges.validFrom)],
    ],
    didDrawPage: d => (height = Math.ceil(d.cursor.y) + 10),
  });

  doc.autoTable({
    showHead: 'never',
    body: [
      ['Änderung subventioniert/privat', contractChanges.changeSubvPriv ? 'X' : '', '', '', '', ''],
      [
        'S',
        contractChanges.mondaySubvPriv === 'subv' ? 'X' : '',
        contractChanges.tuesdaySubvPriv === 'subv' ? 'X' : '',
        contractChanges.wednesdaySubvPriv === 'subv' ? 'X' : '',
        contractChanges.thursdaySubvPriv === 'subv' ? 'X' : '',
        contractChanges.fridaySubvPriv === 'subv' ? 'X' : '',
      ],
      [
        'P',
        contractChanges.mondaySubvPriv === 'priv' ? 'X' : '',
        contractChanges.tuesdaySubvPriv === 'priv' ? 'X' : '',
        contractChanges.wednesdaySubvPriv === 'priv' ? 'X' : '',
        contractChanges.thursdaySubvPriv === 'priv' ? 'X' : '',
        contractChanges.fridaySubvPriv === 'priv' ? 'X' : '',
      ],
    ],
    didDrawPage: d => (height = Math.ceil(d.cursor.y) + 10),
  });

  doc.autoTable({
    showHead: 'never',
    body: [
      ['Änderung der Tage', contractChanges.daysChange ? 'X' : '', '', '', '', ''],
      ['', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag'],
      [
        'Neue Betreuungstage',
        contractChanges.monday ? 'X' : '',
        contractChanges.tuesday ? 'X' : '',
        contractChanges.wednesday ? 'X' : '',
        contractChanges.thursday ? 'X' : '',
        contractChanges.friday ? 'X' : '',
      ],
      [
        'Vormittag',
        contractChanges.mondayMorning ? 'X' : '',
        contractChanges.tuesdayMorning ? 'X' : '',
        contractChanges.wednesdayMorning ? 'X' : '',
        contractChanges.thursdayMorning ? 'X' : '',
        contractChanges.fridayMorning ? 'X' : '',
      ],
      [
        'Mittag',
        contractChanges.mondayNoon ? 'X' : '',
        contractChanges.tuesdayNoon ? 'X' : '',
        contractChanges.wednesdayNoon ? 'X' : '',
        contractChanges.thursdayNoon ? 'X' : '',
        contractChanges.fridayNoon ? 'X' : '',
      ],
      [
        'Nachmittag',
        contractChanges.mondayAfternoon ? 'X' : '',
        contractChanges.tuesdayAfternoon ? 'X' : '',
        contractChanges.wednesdayAfternoon ? 'X' : '',
        contractChanges.thursdayAfternoon ? 'X' : '',
        contractChanges.fridayAfternoon ? 'X' : '',
      ],
      [
        'Total in %',
        contractChanges.mondayTotal,
        contractChanges.tuesdayTotal,
        contractChanges.wednesdayTotal,
        contractChanges.thursdayTotal,
        contractChanges.fridayTotal,
      ],
    ],
    didDrawPage: d => (height = Math.ceil(d.cursor.y) + 10),
  });

  doc.autoTable({
    showHead: 'never',
    body: [
      ['Gruppenwechsel', contractChanges.groupChange ? 'X' : ''],
      ['Bestehende Gruppe:', contractChanges.currentGroupName],
      ['Zukünftige Gruppe:', contractChanges.futureGroupName],
    ],
    didDrawPage: d => (height = Math.ceil(d.cursor.y) + 10),
  });
  height += 10;
  height += setText(
    height,
    'Diese Unterlagen müssen nach einer Änderung umgehend an die AV weiter geleitet werden.',
    doc,
    15,
  );
  height += 10;
  height += addSignatureImage(
    height,
    'Datum: ' + isoDateStringToGerman(contractChanges.parentSignatureDate),
    pdfSaveData.parentSignature,
    'Unterschrift Eltern:',
    doc
  );
  height += 10;
  height += addSignatureImage(
    height,
    'Datum: ' + isoDateStringToGerman(contractChanges.kitaSignatureDate),
    pdfSaveData.kitaSignature,
    'Unterschrift Kita:',
    doc
  );

  const blobPDF = doc.output('blob');

  const formData = new FormData();
  formData.append(
    'files',
    new File(
      [blobPDF],
      `Vertragsänderung_${kidName}_${new Date().toLocaleDateString('de-DE')}.pdf`,
    ),
  );
  formData.append('userId', currentUserId);
  formData.append('groupId', kidPortfolioGroupId);
  formData.append('privatePost', false);
  uploadFilesToGroup(formData, pdfSaveData.setProgress).then(() => {
    formData.set('groupId', 0);
    formData.set('privatePost', true);
    uploadFilesToGroup(formData, pdfSaveData.setProgress);
  });

  return blobPDF;
};

export const createPDFAddDays = (pdfSaveData, t) => {
  const addDays = pdfSaveData.addDays;
  const kidName = pdfSaveData.selectedKid.label;
  let kidPortfolioGroupId = null;
  if (pdfSaveData.selectedKid.portfolioGroupId) {
    kidPortfolioGroupId = pdfSaveData.selectedKid.portfolioGroupId;
  }
  const currentUserId = pdfSaveData.userId;

  let height = 20;
  const doc = new jsPDF();
  doc.setFontSize(10);
  doc.text(pdfSaveData.kitaName, 15, 10);
  height += setText(height, 'Zusatztage/Zusatzstunden Kita', doc, 15);
  height += 10;

  doc.setFontSize(10);

  doc.autoTable({ html: '#addDaysOverview' });
  doc.autoTable({
    showHead: 'never',
    body: [
      ['Name des Kindes:', kidName],
      ['Kita:', addDays.kitaName],
      ['Gruppe:', addDays.groupName],
      ['Datum:', isoDateStringToGerman(addDays.date)],
    ],
    didDrawPage: d => (height = Math.ceil(d.cursor.y) + 10),
  });

  const dayPriceSum = addDays.additionalDays.reduce(
    (acc, x) => acc + mapAddDayTypeToPrice(x.type),
    0,
  );

  const mappedDays = addDays.additionalDays.map(obj => {
    return [
      isoDateStringToGerman(obj.date),
      `${mapAddDayTypeToPrice(obj.type)} Fr.`,
      mapVariableNameToText(obj.type, t),
    ];
  });
  if (addDays.additionalDays.length > 0) {
    mappedDays.push(['Summe', `${dayPriceSum} Fr.`, '']);
  }

  doc.autoTable({
    head: [['Zusatztage', '', ''], ['Datum', 'Preis', '']],
    body: mappedDays,
    didDrawPage: d => (height = Math.ceil(d.cursor.y) + 10),
  });

  const hoursSum = addDays.additionalHours.reduce((acc, x) => parseInt(x.total) + acc, 0);
  const hoursPriceSum = hoursSum * 20;

  const mappedHours = addDays.additionalHours.map(obj => {
    return [isoDateStringToGerman(obj.date), obj.fromTime, obj.toTime, obj.total, obj.reason];
  });
  if (addDays.additionalHours.length > 0 && !isNaN(hoursSum)) {
    mappedHours.push(['Summe', '', '', hoursSum, '']);
  }

  height += 10;
  height += setText(height, 'Zusatzstunden à je 20.00 Fr. pro Stunde', doc, 15);
  doc.autoTable({
    head: [
      ['Zusatzstunden à je 20.00 Fr. pro Stunde', '', '', '', ''],
      ['Datum', 'von', 'bis', 'Total', 'Begründung'],
    ],
    body: mappedHours,
    didDrawPage: d => (height = Math.ceil(d.cursor.y) + 10),
  });

  height += setText(height, `Gesamtpreis: ${hoursPriceSum + dayPriceSum} Fr.`, doc, 15);
  height += 10;
  height += setText(
    height,
    'Diese Unterlagen müssen nach einer Änderung umgehend an die AV weiter geleitet werden.',
    doc,
    15,
  );
  height += 10;
  height += addSignatureImage(
    height,
    'Datum: ' + isoDateStringToGerman(addDays.parentSignatureDate),
    pdfSaveData.parentSignature,
    'Unterschrift Eltern:',
    doc
  );
  height += 10;
  height += addSignatureImage(
    height,
    'Datum: ' + isoDateStringToGerman(addDays.kitaSignatureDate),
    pdfSaveData.kitaSignature,
    'Unterschrift Kita:',
    doc
  );

  const blobPDF = doc.output('blob');

  const formData = new FormData();
  formData.append(
    'files',
    new File(
      [blobPDF],
      `Zusatztage_Stunden_${kidName}_${new Date().toLocaleDateString('de-DE')}.pdf`,
    ),
  );
  formData.append('userId', currentUserId);
  formData.append('groupId', kidPortfolioGroupId);
  formData.append('privatePost', false);
  uploadFilesToGroup(formData, pdfSaveData.setProgress).then(() => {
    formData.set('groupId', 0);
    formData.set('privatePost', true);
    uploadFilesToGroup(formData, pdfSaveData.setProgress);
  });

  return blobPDF;
};

export const createPDFNannyContractAdjustments = (pdfSaveData, weeklyHours) => {
  const { nannyContractAdjustments } = pdfSaveData;
  const kidName = pdfSaveData.selectedKid.label;
  let kidPortfolioGroupId = null;
  if (pdfSaveData.selectedKid.groups) {
    kidPortfolioGroupId = pdfSaveData.selectedKid.groups.id;
  }
  const currentUserId = pdfSaveData.userId;

  let height = 20;
  const doc = new jsPDF();
  height += setText(height, 'Vertragsanpassung', doc, 15);
  doc.setFontSize(10);
  height += 10;

  doc.setFontSize(10);

  doc.autoTable({ html: '#nannyContractAdjustmentsOverview' });
  doc.autoTable({
    showHead: 'never',
    body: [
      ['Name des Kindes:', kidName],
      ['Betreuungsperson:', nannyContractAdjustments.responsiblePerson],
      ['Stadtkreis:', nannyContractAdjustments.cityDistrict],
      ['Gültig ab:', isoDateStringToGerman(nannyContractAdjustments.validFrom)],
      [
        'befristet/unbefristet:',
        nannyContractAdjustments.limited
          ? `befristet auf ${isoDateStringToGerman(nannyContractAdjustments.limitedUntil)}`
          : 'unbefristet',
      ],
    ],
    didDrawPage: d => (height = Math.ceil(d.cursor.y) + 10),
  });

  doc.autoTable({
    head: [['Betreuungsumfang', 'regelmäßig', 'unregelmäßig']],
    body: [
      [
        '',
        nannyContractAdjustments.regularCareTimes ? 'X' : '',
        nannyContractAdjustments.regularCareTimes ? '' : 'X',
      ],
    ],
    didDrawPage: d => (height = Math.ceil(d.cursor.y) + 10),
  });

  if (nannyContractAdjustments.regularCareTimes) {
    const regularCareTimesTable = [];

    nannyContractAdjustments.careTimes.forEach(ct => {
      regularCareTimesTable.push([
        'von',
        ct.mondayFrom,
        ct.tuesdayFrom,
        ct.wednesdayFrom,
        ct.thursdayFrom,
        ct.fridayFrom,
        ct.saturdayFrom,
        ct.sundayFrom,
      ]);

      regularCareTimesTable.push([
        'bis',
        ct.mondayTo,
        ct.tuesdayTo,
        ct.wednesdayTo,
        ct.thursdayTo,
        ct.fridayTo,
        ct.saturdayTo,
        ct.sundayTo,
      ]);
    });

    doc.autoTable({
      head: [['', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag']],
      body: regularCareTimesTable,
      didDrawPage: d => (height = Math.ceil(d.cursor.y) + 10),
    });
  }

  const weeklyHoursTable = [
    [
      'Anzahl Stunden pro Woche',
      nannyContractAdjustments.regularCareTimes
        ? weeklyHours
        : nannyContractAdjustments.irregularCareTimesWeeklyHours,
    ],
  ];

  if (!nannyContractAdjustments.regularCareTimes) {
    weeklyHoursTable.push(['Bemerkungen', nannyContractAdjustments.irregularCareTimesText]);
  }

  doc.autoTable({
    showHead: 'never',
    body: weeklyHoursTable,
    didDrawPage: d => (height = Math.ceil(d.cursor.y) + 10),
  });

  doc.autoTable({
    head: [['Aufsichtspflicht durch Krippenaufsicht (ab 25 Std./Woche)', 'ja', 'nein']],
    body: [
      [
        '',
        nannyContractAdjustments.dutyOfSupervision ? 'X' : '',
        nannyContractAdjustments.dutyOfSupervision ? '' : 'X',
      ],
    ],
    didDrawPage: d => (height = Math.ceil(d.cursor.y) + 10),
  });

  height += setText(height, 'Regelung Schulferien:', doc, 15);
  height += 5;
  height += setText(
    height,
    nannyContractAdjustments.specialNotes === '' ? '-' : nannyContractAdjustments.specialNotes,
    doc,
    15,
  );

  height += 10;
  height += addSignatureImage(
    height,
    'Datum: ' + isoDateStringToGerman(nannyContractAdjustments.parent1SignatureDate),
    pdfSaveData.parent1Signature,
    'Unterschrift Elternteil 1:',
    doc
  );
  height += 10;
  height += addSignatureImage(
    height,
    'Datum: ' + isoDateStringToGerman(nannyContractAdjustments.parent2SignatureDate),
    pdfSaveData.parent1Signature,
    'Unterschrift Elternteil 2:',
    doc
  );
  height += 10;
  height += addSignatureImage(
    height,
    'Datum: ' + isoDateStringToGerman(nannyContractAdjustments.kitaSignatureDate),
    pdfSaveData.parent1Signature,
    'Unterschrift Vermittlerin Tagesfamilien:',
    doc
  );

  const blobPDF = doc.output('blob');

  const formData = new FormData();
  formData.append(
    'files',
    new File(
      [blobPDF],
      `Vertragsanpassung_${kidName}_${new Date().toLocaleDateString('de-DE')}.pdf`,
    ),
  );
  formData.append('userId', currentUserId);
  formData.append('groupId', kidPortfolioGroupId);
  formData.append('privatePost', false);
  uploadFilesToGroup(formData, pdfSaveData.setProgress).then(() => {
    formData.set('groupId', 0);
    formData.set('privatePost', true);
    uploadFilesToGroup(formData, pdfSaveData.setProgress);
  });

  return blobPDF;
};

function addPDFFooter(doc, page, fontSize, kitaName) {
  doc.text(page + '', 195, 285);

  doc.setFontSize(fontSize - 2);
  doc.setFont(undefined, 'italic');
  doc.text(kitaName, 10, 280);
  doc.text('generated by Link IT isi GmbH', 10, 285);
  doc.setFont(undefined, 'normal');
  doc.setFontSize(fontSize);
}

function mapVariableNameToText(name, t) {
  if (name === 'wholeDay') {
    return t('GFZForms.AddDaysWholeDay');
  }
  if (name === 'morningWithFood') {
    return t('GFZForms.AddDaysVMwithFood');
  }
  if (name === 'afternoonWithoutFood') {
    return t('GFZForms.AddDaysNMwithoutFood');
  }
  if (name === 'morningWithoutFood') {
    return t('GFZForms.AddDaysVMwithoutFood');
  }
  if (name === 'afternoonWithFood') {
    return t('GFZForms.AddDaysNMwithFood');
  }
  if (name === 'wholeDayAlt') {
    return t('GFZForms.AddDaysWholeDayAlt');
  }
  if (name === 'morningWithFoodAlt') {
    return t('GFZForms.AddDaysVMwithFoodAlt');
  }
  if (name === 'afternoonWithoutFoodAlt') {
    return t('GFZForms.AddDaysNMwithoutFoodAlt');
  }
  if (name === 'morningWithoutFoodAlt') {
    return t('GFZForms.AddDaysVMwithoutFoodAlt');
  }
  if (name === 'afternoonWithFoodAlt') {
    return t('GFZForms.AddDaysNMwithFoodAlt');
  }
  return '';
}

export const getBase64 = file => {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function() {
    console.log(reader.result);
  };
  reader.onerror = function(error) {
    console.log('Error: ', error);
  };
};

export const downloadFile = (res, filename, type) => {
  const url = window.URL.createObjectURL(new Blob([res.data], { type: type }));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
};

import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import RecordRTC from 'recordrtc';
import cn from 'classnames';

import styles from './recordButton.module.scss';
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import { handleRNMessageHelper } from 'library/utilities/handleRNMessageHelper';
import moment from "moment";


RecordButton.defaultProps = {
  allowedExtensions: ['*'],
};

const formatTime = (seconds) => moment.utc(seconds * 1000).format("mm:ss");

export default function RecordButton({ uploadFile, insText, voiceForPost, isCommentPage, onListeningChange }) {
  const { t } = useTranslation();

  const [isListening, setIsListening] = useState(false);
  const [currentRecordRTC, setCurrentRecordRTC] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const timerRef = useRef(null);
  const [wakeLock, setWakeLock] = useState(null);


  // Notify parent when isListening changes
  useEffect(() => {
    if (onListeningChange) {
      onListeningChange(isListening);
    }
  }, [isListening, onListeningChange]);

  const requestWakeLock = async () => {
    if ('wakeLock' in navigator) {
      try {
        const lock = await navigator.wakeLock.request('screen');
        setWakeLock(lock);
      } catch (err) {
        console.error('Wake lock request failed:', err);
      }
    }
  };

  const releaseWakeLock = async () => {
    if (wakeLock) {
      await wakeLock.release();
      setWakeLock(null);
    }
  };


  const startStopSpeechRecognitionInApp = () => {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: 'voice_recorder'
      }),
    );
  };

  const start = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(stream => {
        setIsListening(true);
        setElapsedTime(0);

        const StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
        const recordRTC = new StereoAudioRecorder(stream, {
          mimeType: 'audio/wav',
          numberOfAudioChannels: 1,
        });
        recordRTC.record();
        setCurrentRecordRTC(recordRTC);

        timerRef.current = setInterval(() => {
          setElapsedTime((prevTime) => prevTime + 1);
        }, 1000);
      })
      .catch(error => {
        store.dispatch(
          showBottomNotification(t('BottomNotification.AccessMicrophoneDenied'), { isFail: true }),
        );
      });
  };

  const nativeAppStop = e => {
    if (!e || !e.data) {
      return;
    }

    let parts = e.data.split(';');
    let mime = parts[0].split(':')[1];
    let data = parts[1].split(',')[1];
    let bytes = window.atob(data);
    let buff = new ArrayBuffer(bytes.length);
    let arr = new Uint8Array(buff);

    for (let i = 0; i < bytes.length; i++) {
      arr[i] = bytes.charCodeAt(i);
    }

    onStop(new Blob([arr], { type: mime }));

    [window, document].forEach(el => {
      el.removeEventListener('message', handleRNMessageHelper('native.voicerecorder', nativeAppStop));
    });
  };

  const handleStart = () => {
    if (window.ReactNativeWebView) {
      [window, document].forEach(el => el.addEventListener('message', handleRNMessageHelper('native.voicerecorder', nativeAppStop)));
      startStopSpeechRecognitionInApp();
      setIsListening(true);
      setElapsedTime(0);
      timerRef.current = setInterval(() => {
        setElapsedTime((prevTime) => prevTime + 1);
      }, 1000);
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'keep_awake', value: true }));
    } else {
      start();
      requestWakeLock();
    }
  };

  const handleStop = () => {
    if (window.ReactNativeWebView) {
      setIsListening(false);
      startStopSpeechRecognitionInApp();
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'keep_awake', value: false }));
    } else {
      setIsListening(false);
      currentRecordRTC.stop(onStop.bind(this));
      releaseWakeLock();
    }
    clearInterval(timerRef.current);
    setElapsedTime(0);
  };

  const onStop = async blob => {
    const fileName = `Sprachnachricht_${new Date().getTime()}.mp3`;
    const file = new File([blob], fileName, {
      type: blob.type,
    });

    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(file);

    const files = dataTransfer.files;
    uploadFile(files);
    if (!voiceForPost) {
      insText('Sprachnachricht');
    }
  };


  return (
    <div style={voiceForPost ? null : { position: 'absolute', right: '10px' }}>
      {isListening === false ? (
        <div
          className={cn(styles.upload, voiceForPost ? styles.voiceForPost : null, isCommentPage ? styles.isCommentPage : null)}
          onClick={handleStart}
        >
          <label>
            <i style={{ display: 'block' }} className='fa fa-microphone' />
          </label>
        </div>
      ) : (
        <div
          className={cn(styles.upload, voiceForPost ? styles.voiceForPost : null, isCommentPage ? styles.isCommentPage : null)}
          style={{ color: 'red' }}
          onClick={handleStop}
        >
          <label className={styles.pulsate}>
            <i className='fa fa-circle' />
          </label>
          <span className={styles.elapsedTime}>{formatTime(elapsedTime)}</span>
        </div>
      )}
    </div>
  );
}

import axios from 'library/api/axios';
import config, { getPort } from 'main/config';

const URL = `${config.API_BASE_URI}${getPort(8091)}/api/foodorder/`;

export const getSettings = groupId => {
  return axios.get(`${URL}config/${groupId}`);
};

export const getSettingsByKid = kidId => {
  return axios.get(`${URL}config/kid/${kidId}`);
};

export const saveSettings = ({
  groupId,
  lastFoodOrderTime,
  lastCancelFoodOrderTime,
  defaultPrice,
  useOnlinePayment,
  usePreorder,
  allowGroupIndependentOrder,
  sicknessCancelsOrders,
  useWeeklyPreorder,
  enableCancellation,
  enableRepetition,
  noOfRepetition,
  orderTimeToday,
  orderTimeYesterday,
  orderTimeTwoDaysAgo,
  cancelTimeToday,
  cancelTimeYesterday,
  cancelTimeTwoDaysAgo,
  mailOnSubmitting,
  monthlyMail,
  useAutoFoodOrder,
}) => {
  const payload = {
    lastFoodOrderTime,
    lastCancelFoodOrderTime,
    defaultPrice,
    useOnlinePayment,
    usePreorder,
    allowGroupIndependentOrder,
    sicknessCancelsOrders,
    useWeeklyPreorder,
    enableCancellation,
    enableRepetition,
    noOfRepetition,
    orderTimeToday,
    orderTimeYesterday,
    orderTimeTwoDaysAgo,
    cancelTimeToday,
    cancelTimeYesterday,
    cancelTimeTwoDaysAgo,
    sendMailOnSubmitting: mailOnSubmitting,
    sendMonthlyMail: monthlyMail,
    useAutoFoodOrder,
  };
  return axios.post(`${URL}config/${groupId}`, payload);
};

export const saveSettingsPayload = (groupId, payload) => {
  return axios.post(`${URL}config/${groupId}`, payload);
};

export const getMenu = (groupId, from, to) => {
  return axios.get(`${URL}menu/${groupId}?from=${from}&to=${to}`);
};

export const getMenuByKid = (kidId, from, to) => {
  return axios.get(`${URL}menu/kid/${kidId}?from=${from}&to=${to}`);
};

export const getMenuWithOrders = (groupId, from, to, childId) => {
  return axios.get(`${URL}parents/menu/${groupId}/${childId}?from=${from}&to=${to}`);
};

export const getMenuWithOrdersForAnyGroup = (from, to, childId) => {
  return axios.get(`${URL}parents/menu/anygroup/${childId}?from=${from}&to=${to}`);
};

export const saveMenu = (menus, groupId) => {
  return axios.post(`${URL}menu/${groupId}`, menus);
};

export const kitchenGetOrders = (groupId, from, to) => {
  return axios.get(`${URL}kitchen/orders/${groupId}?from=${from}&to=${to}`);
};

export const kitaGetOrdersWeekly = (groupId, from, to) => {
  return axios.get(`${URL}/ordersweek/${groupId}?from=${from}&to=${to}`);
};

export const getUsersForManualPayment = (from, to) => {
  return axios.get(`${URL}getUsersForManualPayment?from=${from}&to=${to}`);
};

export const downloadMonthlyFoodOrdersCSV = (groupId, year, month) => {
  return axios.get(`${URL}group/${groupId}/foodorders/monthlyexport/${year}/${month}`).then(res => {
    if (res.status === 200) {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `monatliche_bestellungen_${month}_${year}.csv`);
      document.body.appendChild(link);
      link.click();
    }
  });
};

export const doPaymentForUser = data => {
  return axios.post(`${URL}doPaymentForUser`, data);
};

export const kitaGetOrders = (groupId, date) => {
  return axios.get(`${URL}orders/${groupId}?date=${date}`);
};

export const kitaGetMonthlyBill = (groupId, date) => {
  return axios.get(`${URL}bill/${groupId}?date=${date}`);
};

export const parentsGetMonthlyBill = (groupId, kidId, date) => {
  return axios.get(`${URL}bill/${groupId}/${kidId}?month=${date}`);
};

export const parentsFoodOrder = (groupId, childId, menuItems) => {
  return axios.post(`${URL}parents/menu/${groupId}/${childId}`, menuItems);
};

export const parentsFoodOrderPreparePayment = (groupId, childId, menuItems) => {
  return axios.post(`${URL}parents/payment/prepare/${groupId}/${childId}`, {
    foodOrders: menuItems,
  });
};

export const sendParentsFoodOrderConfirmationMail = invoices => {
  return axios.post(`${URL}parents/payment/sendConfirmation`, invoices);
};

export const uploadFoodFile = (file, groupId) => {
  return axios.post(`${URL}uploadfoodfile/${groupId}`, file);
};

export const parentsFoodSummaryGetCredit = (groupId, childId, mealIds) => {
  return axios.get(
    `${URL}${groupId}/${childId}/parents/payment/creditinformation?${mealIds.map(
      id => `mealIds[]=${id}`,
    )}`,
  );
};

export const getUserIdsThatPayedButDidNotStorePaymentData = () => {
  return axios.get(`${URL}getUsersThatPayedButDidNotStorePaymentData`);
};

export const cancelAllFoodsByMonthAndKid = (groupId, month, kidId) => {
  return axios.post(`${URL}${groupId}/orders/cancel/${month}/${kidId}`);
};

export const cancelFoodByDateAndKid = (groupId, date, kidId) => {
  return axios.post(`${URL}${groupId}/orders/cancelbyday/${date}/${kidId}`);
};

export const parentsFoodOrderGetCreditAmount = groupId => {
  return axios.get(`${URL}${groupId}/parents/payment/creditamount`);
};

export const getIngredients = groupId => axios.get(`${URL}/ingredients/` + groupId);

export const saveIngredients = (groupId, ingredients) =>
  axios.post(`${URL}/ingredients/${groupId}`, ingredients);

export const deleteAllDuplicateFoodOrders = () => axios.get(`${URL}/deleteallduplicatefoodorders`);

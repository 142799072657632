import { useReducer, useEffect, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import deepcopy from 'deepcopy';

import Storage from 'library/utilities/storage';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import {
  getPosts,
  updatePost,
  deletePost,
  toggleHidePost,
  reportToSuperAdmin,
  unsetReportedPost,
  setPostPrivate,
} from 'library/api/posts';
import { reportPost } from 'library/api/groups';
import store from 'main/store/configureStore';
import { changeOrderIfSurvey } from 'library/utilities/surveys';
import {
  checkScrollLoading,
  useLoadingOnScroll,
} from 'library/common/commonHooks/useScrollLoading';
import { isAwoWW } from 'library/api/tenantConfig';

export const initialState = { feeds: [], isLoading: false };

export function reducer(state, action) {
  switch (action.type) {
    case 'startLoading':
      return { ...state, isLoading: true };
    case 'stopLoading':
      return { ...state, isLoading: false };
    case 'addFeeds':
      return { ...state, feeds: state.feeds.concat(action.feeds), isLoading: false };
    case 'addFeed':
      return { ...state, feeds: [action.feed].concat(state.feeds) };
    case 'updateFeedById':
      return {
        ...state,
        feeds: state.feeds.map(feed =>
          feed.id === action.id
            ? {
                ...feed,
                ...action.newValues,
              }
            : feed,
        ),
      };
    case 'deleteFeedById':
    case 'hideFeedById':
      return {
        ...state,
        feeds: state.feeds.filter(feed => feed.id !== action.id),
      };
    case 'removeFeeds':
      return {
        ...state,
        feeds: [],
      };
    default:
      return state;
  }
}

/**
 *
 * @param {Object} options
 * @param {Object} [options.group] - The group where the feed is displayed.
 * @returns
 */
export default function useFeedsLoading({
  user,
  filters,
  isOnDashboard,
  canLoadFeeds,
  isInProfileStream,
  isInPublicPage,
  selectedKitas,
  selectedGroups,
  loadOnScroll = true,
  reloadFeed,
  filterByFavorite,
  ...loadingOptions
}) {
  const { t } = useTranslation();

  const [{ feeds, isLoading }, dispatch] = useReducer(reducer, initialState);
  const currentPaginationPage = useRef(1);
  const isFeedsLoading = useRef(false);
  const isFeedsEnded = useRef(false);
  const postId = useRef(loadingOptions.postId);
  useEffect(() => {
    postId.current = loadingOptions.postId;
  }, [loadingOptions.postId]);

  const { visibility, showCancelMembership, groupAdminStatus, defaultGroup } = loadingOptions.group || {};

  const hasJoinGroup = visibility ? showCancelMembership : showCancelMembership || groupAdminStatus;
  const shouldLoadPublicPostGroup = !hasJoinGroup && isAwoWW() && defaultGroup;

  const getFeedsPayload = {
    isFeedsLoading,
    isFeedsEnded,
    dispatch,
    filters,
    currentPaginationPage,
    loadingOptions: { ...loadingOptions, isOnDashboard },
    postId,
    isInProfileStream,
    isInPublicPage,
    selectedKitas,
    selectedGroups,
    userId: user.id,
    user: user,
    group: loadingOptions.group,
    shouldLoadPublicPostGroup,
  };

  useEffect(() => {
    if (canLoadFeeds && user?.id) {
      dispatch({ type: 'removeFeeds' });
      currentPaginationPage.current = 1;
      getFeeds({ ...getFeedsPayload, filterFavorite: filterByFavorite }).then(() => {
        if (availableFeeds.length <= 5) {
          // if fewer than 5 feeds were loaded there might not be a scrollbar to trigger the loading of more feeds, therefore we load more feeds automatically
          getFeeds({ ...getFeedsPayload, filterFavorite: filterByFavorite });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters,
    canLoadFeeds,
    loadingOptions.postId,
    loadingOptions.searchValue,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    loadingOptions.searchGroups && loadingOptions.searchGroups.length,
    user?.id,
    reloadFeed,
  ]);

  useLoadingOnScroll(() => {
    if (!loadOnScroll) return;
    checkScrollLoading(
      () => getFeeds({ ...getFeedsPayload, filterFavorite: filterByFavorite }),
      1.5,
    );
  }, [feeds]);

  const addFeed = newFeed => addFeedHandler({ dispatch, newFeed });

  const updateFeedById = async (id, newValues, options = {}) =>
    updateFeedByIdHandler({ options, id, newValues, dispatch, t });

  const deleteFeedById = id => deleteFeedByIdHandler({ id, dispatch, t });

  const hideFeedById = (id, status) => hideFeedByIdHandler({ id, status, dispatch, t });

  const reportToAdmin = (id, groupID, name, kitaId) =>
    onReportToAdmin({ id, groupID, name, kitaId, dispatch, t });

  const unsetReported = id => onUnsetReported({ id, dispatch, t });

  let availableFeeds = useMemo(() => {
    if (!user.administrationAccess && !user.superAdminStatus) {
      // filters out all events where user is not a participant (if INVITED_PARTICIPANTS_ONLY) or the creator of the event
      return feeds.filter(e => {
        // return true;
        if (e.userId === user.id) return true;
        if (e.participationMode === 'INVITED_PARTICIPANTS_ONLY') {
          return (
            e.parentParticipants &&
            e.parentParticipants.filter(p => p.userId === user.id).length > 0
          );
        }
        return true;
      });
    } else return feeds;
  }, [user, feeds]);

  return {
    feeds: isOnDashboard ? availableFeeds : getSortedFeedsByPinned(availableFeeds, filters),
    addFeed,
    isLoading,
    isFeedsEnded,
    updateFeedById,
    deleteFeedById,
    getFeeds,
    hideFeedById,
    newFeeds: () => getFeeds({ ...getFeedsPayload, filterFavorite: filterByFavorite }),
    reportToAdmin,
    unsetReported,
  };
}

export function getSortedFeedsByPinned(feeds, filters = {}) {
  const sortingFilters = filters.sortingFilters || [true];
  const newFeeds = deepcopy(feeds);
  if (sortingFilters[0]) {
    newFeeds.sort((a, b) => b.pinned - a.pinned || b.visibleOrCreatedAt - a.visibleOrCreatedAt);
  } else {
    newFeeds.sort((a, b) => b.pinned - a.pinned || b.visibleOrEditedAt - a.visibleOrEditedAt);
  }

  return newFeeds;
}

/**
 *
 * @param {Object} options
 * @param {Object} [options.group] - The group where the feed is displayed.
 * @returns
 */
export async function getFeeds({
  isFeedsLoading,
  isFeedsEnded,
  dispatch,
  currentPaginationPage,
  loadingOptions,
  postId,
  isInProfileStream,
  isInPublicPage,
  selectedKitas,
  selectedGroups,
  userId,
  user,
  group,
  filterFavorite,
  shouldLoadPublicPostGroup,
}) {
  const filters = Storage.getItem('currentFilters');
  if (isFeedsLoading.current || isFeedsEnded.current) {
    return;
  }
  isFeedsLoading.current = true;

  dispatch({ type: 'startLoading' });
  await loadPosts({
    filters,
    currentPaginationPage,
    loadingOptions: { ...loadingOptions, postId: postId.current },
    isFeedsEnded,
    dispatch,
    isInProfileStream,
    isInPublicPage,
    selectedKitas,
    selectedGroups,
    userId,
    user,
    groupAdminStatus: group?.groupAdminStatus,
    filterFavorite,
    shouldLoadPublicPostGroup,
  });

  isFeedsLoading.current = false;
}

/**
 *
 * @param {Object} options
 * @param {Object} [options.groupAdminStatus]
 * @param {Object} options.user
 */
export async function loadPosts({
  filters,
  currentPaginationPage,
  loadingOptions,
  isFeedsEnded,
  dispatch,
  isInProfileStream,
  isInPublicPage,
  selectedKitas,
  selectedGroups,
  userId,
  user,
  groupAdminStatus,
  filterFavorite,
  shouldLoadPublicPostGroup,
}) {
  try {
    if (!user || !user.id) {
      return;
    }
    const result = (await getPosts({
      page: currentPaginationPage.current,
      ...loadingOptions,
      filters,
      isInProfileStream,
      isInPublicPage,
      selectedKitas,
      selectedGroups,
      userId,
      user,
      groupAdminStatus,
      filterFavorite,
      shouldLoadPublicPostGroup,
    })).map(changeOrderIfSurvey);

    if (result.length > 0) {
      dispatch({
        type: 'addFeeds',
        feeds: result,
      });
    } else if (result.length < 10) {
      isFeedsEnded.current = true;
    }

    if (result.length === 0) {
      dispatch({
        type: 'stopLoading',
      });
    }
    currentPaginationPage.current += 1;
  } catch (ex) {
    // eslint-disable-next-line
    console.error(ex);
  }
}

export async function updateFeedByIdHandler({ options, id, newValues, dispatch, t }) {
  try {
    if (!options.withoutRequest) {
      if (options.onlyUpdatePrivatePost) {
        await setPostPrivate(id, newValues.privatePost);
      } else {
        const { data } = await updatePost(id, { ...newValues });
        newValues = {
          ...newValues,
          files: data.files,
        };
      }
    }

    dispatch({
      type: 'updateFeedById',
      id,
      newValues: {
        ...newValues,
        editedAt: new Date().getTime(),
      },
    });

    store.dispatch(showBottomNotification(t('BottomNotifications.Saved')));
  } catch (ex) {
    // eslint-disable-next-line
    console.log(ex);
  }
}

export function deleteFeedByIdHandler({ id, dispatch, t }) {
  const userId = store.getState().userReducer.id;
  return deletePost(id, userId)
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: 'deleteFeedById',
          id,
        });
        store.dispatch(
          showBottomNotification(t('BottomNotifications.The content has been deleted')),
        );
        return {
          success: true,
          error: null,
        };
      }
      showBottomNotification(t('BottomNotifications.Something went wrong'), {
        isFail: true,
      });
      return {
        success: false,
        error: res.data,
      };
    })
    .catch(err => {
      showBottomNotification(t('BottomNotifications.Something went wrong'), {
        isFail: true,
      });
      console.log(err);
      return {
        success: false,
        error: err,
      };
    });
}

export function addFeedHandler({ dispatch, newFeed }) {
  dispatch({
    type: 'addFeed',
    feed: newFeed,
  });
}

export function hideFeedByIdHandler({ id, status, dispatch, t }) {
  return toggleHidePost(id)
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: 'hideFeedById',
          id,
        });
        if (status) {
          store.dispatch(showBottomNotification(t('BottomNotifications.ThePostIsShow')));
        } else {
          store.dispatch(showBottomNotification(t('BottomNotifications.ThePostIsHide')));
        }

        return {
          success: true,
          error: null,
        };
      }
      showBottomNotification(t('BottomNotifications.Something went wrong'), {
        isFail: true,
      });
      return {
        success: false,
        error: res.data,
      };
    })
    .catch(err => {
      showBottomNotification(t('BottomNotifications.Something went wrong'), {
        isFail: true,
      });
      console.log(err);
      return {
        success: false,
        error: err,
      };
    });
}

export function onReportToAdmin({ id, groupID, name, kitaId, dispatch, t }) {
  return reportToSuperAdmin(id, groupID, name).then(() => {
    return reportPost(id, groupID, kitaId)
      .then(res => {
        if (res.status === 200) {
          store.dispatch(showBottomNotification(t('PostTemplatePopup.Reported to Kita-Admin')));
          return {
            success: true,
            error: null,
          };
        }
        showBottomNotification(t('BottomNotifications.Something went wrong'), {
          isFail: true,
        });
        return {
          success: false,
          error: res.data,
        };
      })
      .catch(err => {
        showBottomNotification(t('BottomNotifications.Something went wrong'), {
          isFail: true,
        });
        return {
          success: false,
          error: err,
        };
      });
  });
}

export function onUnsetReported({ id, dispatch, t }) {
  return unsetReportedPost(id).then(() => {
    dispatch({
      type: 'updateFeedById',
      id,
      newValues: {
        isReported: false,
      },
    });
    store.dispatch(showBottomNotification(t('BottomNotifications.ThePostIsUnset')));
    return {
      success: true,
      error: null,
    };
  });
}

import React, { useState, useEffect } from 'react';
import TextArea from 'library/common/commonComponents/Inputs/TextArea';
import Button from 'library/common/commonComponents/Buttons/Button';
import Input from 'library/common/commonComponents/Inputs/Input';
import Dictaphone from 'library/common/commonComponents/Dictaphone';
import Select from 'library/common/commonComponents/Inputs/Select';
import CheckBox from 'library/common/commonComponents/Checkbox';
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import {
  getAllKidsFromSelectedKita,
  postGfzAcclimatizationDocumentation,
  getGfzAcclimatizationDocumentation,
  deleteGfzAcclimatizationDocumentation,
} from 'library/api/observationSheet';
import { createPDFAcclimatizationDocumentation } from 'library/utilities/files';
import { createKidPortfolioGroupIfNeeded } from 'library/api/kids';
import { useTranslation } from "react-i18next";

export default function AcclimatizationDocumentation({ userId }) {

  const [progress, setProgress] = useState(0);
  const [selectedKid, setSelectedKid] = useState([]);
  const [kidsList, setKidsList] = useState([]);
  const [acclimatizationDocumentation, setAcclimatizationDocumentation] = useState({
    kidsInterest: '',
    kidsEngagement: '',
    kidsWithstand: '',
    kidsExpressing: '',
    kidsResponsibility: '',
    others: '',
    checkBox1: false,
    checkBox2: false,
    checkBox3: false,
    checkBox4: false,
    checkBox5: false,
    checkBox6: false,
    checkBox7: false,
    checkBox8: false,
    checkBox9: false,
    checkBox10: false,
    checkBox11: false,
    checkBox12: false,
    checkBox13: false,
    checkBox14: false,
    checkBox15: false,
    checkBox16: false,
    checkBox17: false,
    checkBox18: false,
    checkBox19: false,
    checkBox20: false,
    checkBox21: false,
    additionalComments: '',
    separationDay4: '',
    separationDay5: '',
    separationDay6: '',
    separationDay7: '',
    furtherObservation: '',
    kidsHealth: '',
    acclimatizationEnd: '',
    checkBox22: false,
    checkBox23: false,
    checkBox24: false,
    checkBox25: false,
    checkBox26: false,
    checkBox27: false,
    checkBox28: false,
    checkBox29: false,
    checkBox30: false,
    checkBox31: false,
    checkBox32: false,
    checkBox33: false,
    checkBox34: false,
    checkBox35: false,
    checkBox36: false,
    checkBox37: false,
    checkBox38: false,
    checkBox39: false,
    checkBox40: false,
    checkBox41: false,
    checkBox42: false,
    checkBox43: false,
    checkBox44: false,
    checkBox45: false,
    checkBox46: false,
    checkBox47: false,
    checkBox48: false,
    checkBox49: false,
    checkBox50: false,
    checkBox51: false,
    checkBox52: false,
    checkBox53: false,
    checkBox54: false,
    checkBox55: false,
    checkBox56: false,
    checkBox57: false,
    checkBox58: false,
    checkBox59: false,
    checkBox60: false,
    checkBox61: false,
    kidId: null,
  });

  const { t } = useTranslation();

  useEffect(() => {
    getAllKidsFromSelectedKita().then(data => {
      const unformattedKidsList = data.data;

      const formattedKidsList = unformattedKidsList.map(obj => {
        return { label: obj.firstName + ' ' + obj.lastName, id: obj.kidId, portfolioGroupId: obj.portfolioGroupId };
      });
      setKidsList(formattedKidsList);
    });
  }, []);

  function handleTextStateChange(newValue, property) {
    setAcclimatizationDocumentation(prev => {
      return {
        ...prev,
        [property]: newValue,
      };
    });
  }

  function resetForm() {
    setAcclimatizationDocumentation(
      {
        kidsInterest: '',
        kidsEngagement: '',
        kidsWithstand: '',
        kidsExpressing: '',
        kidsResponsibility: '',
        others: '',
        checkBox1: false,
        checkBox2: false,
        checkBox3: false,
        checkBox4: false,
        checkBox5: false,
        checkBox6: false,
        checkBox7: false,
        checkBox8: false,
        checkBox9: false,
        checkBox10: false,
        checkBox11: false,
        checkBox12: false,
        checkBox13: false,
        checkBox14: false,
        checkBox15: false,
        checkBox16: false,
        checkBox17: false,
        checkBox18: false,
        checkBox19: false,
        checkBox20: false,
        checkBox21: false,
        additionalComments: '',
        separationDay4: '',
        separationDay5: '',
        separationDay6: '',
        separationDay7: '',
        furtherObservation: '',
        kidsHealth: '',
        acclimatizationEnd: '',
        checkBox22: false,
        checkBox23: false,
        checkBox24: false,
        checkBox25: false,
        checkBox26: false,
        checkBox27: false,
        checkBox28: false,
        checkBox29: false,
        checkBox30: false,
        checkBox31: false,
        checkBox32: false,
        checkBox33: false,
        checkBox34: false,
        checkBox35: false,
        checkBox36: false,
        checkBox37: false,
        checkBox38: false,
        checkBox39: false,
        checkBox40: false,
        checkBox41: false,
        checkBox42: false,
        checkBox43: false,
        checkBox44: false,
        checkBox45: false,
        checkBox46: false,
        checkBox47: false,
        checkBox48: false,
        checkBox49: false,
        checkBox50: false,
        checkBox51: false,
        checkBox52: false,
        checkBox53: false,
        checkBox54: false,
        checkBox55: false,
        checkBox56: false,
        checkBox57: false,
        checkBox58: false,
        checkBox59: false,
        checkBox60: false,
        checkBox61: false,
        kidId: null,
      });
    setSelectedKid([]);
  }

  function setKidsInterestText(text) {
    setAcclimatizationDocumentation(prev => {
      return { ...prev, kidsInterest: `${prev.kidsInterest} ${text}` };
    });
  }

  function setKidsEngagementText(text) {
    setAcclimatizationDocumentation(prev => {
      return { ...prev, kidsEngagement: `${prev.kidsEngagement} ${text}` };
    });
  }

  function setKidsWithstandText(text) {
    setAcclimatizationDocumentation(prev => {
      return { ...prev, kidsWithstand: `${prev.kidsWithstand} ${text}` };
    });
  }

  function setKidsExpressingText(text) {
    setAcclimatizationDocumentation(prev => {
      return { ...prev, kidsExpressing: `${prev.kidsExpressing} ${text}` };
    });
  }

  function setKidsResponsibilityText(text) {
    setAcclimatizationDocumentation(prev => {
      return { ...prev, kidsResponsibility: `${prev.kidsResponsibility} ${text}` };
    });
  }

  function setOthersText(text) {
    setAcclimatizationDocumentation(prev => {
      return { ...prev, others: `${prev.others} ${text}` };
    });
  }

  function setAdditionalCommentsText(text) {
    setAcclimatizationDocumentation(prev => {
      return { ...prev, additionalComments: `${prev.additionalComments} ${text}` };
    });
  }

  function setSeparationDay4Text(text) {
    setAcclimatizationDocumentation(prev => {
      return { ...prev, separationDay4: `${prev.separationDay4} ${text}` };
    });
  }

  function setSeparationDay5Text(text) {
    setAcclimatizationDocumentation(prev => {
      return { ...prev, separationDay5: `${prev.separationDay5} ${text}` };
    });
  }

  function setSeparationDay6Text(text) {
    setAcclimatizationDocumentation(prev => {
      return { ...prev, separationDay6: `${prev.separationDay6} ${text}` };
    });
  }

  function setSeparationDay7Text(text) {
    setAcclimatizationDocumentation(prev => {
      return { ...prev, separationDay7: `${prev.separationDay7} ${text}` };
    });
  }

  function setFurtherObservationText(text) {
    setAcclimatizationDocumentation(prev => {
      return { ...prev, furtherObservation: `${prev.furtherObservation} ${text}` };
    });
  }

  function setKidsHealthText(text) {
    setAcclimatizationDocumentation(prev => {
      return { ...prev, kidsHealth: `${prev.kidsHealth} ${text}` };
    });
  }

  return (
    <>
      <table>
        <tr>
          <td>{t('GFZEducationalWork.AcclimatizationDocumentationKidName')}</td>
          <td>
            <Select
              options={kidsList}
              onSelect={e => {
                setSelectedKid(e);
                getGfzAcclimatizationDocumentation(e.id).then(res => {
                  if (res.data != null) {
                    setAcclimatizationDocumentation(res.data);
                  } else {
                    setAcclimatizationDocumentation({
                      kidsInterest: '',
                      kidsEngagement: '',
                      kidsWithstand: '',
                      kidsExpressing: '',
                      kidsResponsibility: '',
                      others: '',
                      checkBox1: false,
                      checkBox2: false,
                      checkBox3: false,
                      checkBox4: false,
                      checkBox5: false,
                      checkBox6: false,
                      checkBox7: false,
                      checkBox8: false,
                      checkBox9: false,
                      checkBox10: false,
                      checkBox11: false,
                      checkBox12: false,
                      checkBox13: false,
                      checkBox14: false,
                      checkBox15: false,
                      checkBox16: false,
                      checkBox17: false,
                      checkBox18: false,
                      checkBox19: false,
                      checkBox20: false,
                      checkBox21: false,
                      additionalComments: '',
                      separationDay4: '',
                      separationDay5: '',
                      separationDay6: '',
                      separationDay7: '',
                      furtherObservation: '',
                      kidsHealth: '',
                      acclimatizationEnd: '',
                      checkBox22: false,
                      checkBox23: false,
                      checkBox24: false,
                      checkBox25: false,
                      checkBox26: false,
                      checkBox27: false,
                      checkBox28: false,
                      checkBox29: false,
                      checkBox30: false,
                      checkBox31: false,
                      checkBox32: false,
                      checkBox33: false,
                      checkBox34: false,
                      checkBox35: false,
                      checkBox36: false,
                      checkBox37: false,
                      checkBox38: false,
                      checkBox39: false,
                      checkBox40: false,
                      checkBox41: false,
                      checkBox42: false,
                      checkBox43: false,
                      checkBox44: false,
                      checkBox45: false,
                      checkBox46: false,
                      checkBox47: false,
                      checkBox48: false,
                      checkBox49: false,
                      checkBox50: false,
                      checkBox51: false,
                      checkBox52: false,
                      checkBox53: false,
                      checkBox54: false,
                      checkBox55: false,
                      checkBox56: false,
                      checkBox57: false,
                      checkBox58: false,
                      checkBox59: false,
                      checkBox60: false,
                      checkBox61: false,
                      kidId: e.id,
                    });
                  }
                });
              }}
              selected={selectedKid}
              style={{ zIndex: '10000000000' }}
              autocomplete="new-password"
            />
          </td>
        </tr>
      </table>
      <h2>{t('GFZEducationalWork.AcclimatizationDocumentationDay1-3')}</h2>
      <br />
      <table style={{ borderWidth: '1px', borderColor: '#aaaaaa', borderStyle: 'solid' }}>
        <tr style={{ backgroundColor: '#F2F2F2' }}>
          <td>
            <b>{t('GFZEducationalWork.AcclimatizationDocumentationInterested')}</b>
            <br />
            {t('GFZEducationalWork.AcclimatizationDocumentationKidInterests')}
          </td>
        </tr>
        <tr>
          <td>
            <div style={{ position: 'relative', left: '5px', width: '55px' }}>
              <Dictaphone insText={setKidsInterestText} />
            </div>
            <br />
            <br />
            <TextArea
              maxLength={255}
              value={acclimatizationDocumentation.kidsInterest}
              onChange={e => handleTextStateChange(e.target.value, 'kidsInterest')}
              type='text'
              placeholder={t('GFZEducationalWork.AcclimatizationDocumentationTextAreaPlaceholder')}
            />
          </td>
        </tr>
        <tr style={{ backgroundColor: '#F2F2F2' }}>
          <td>
            <b>{t('GFZEducationalWork.AcclimatizationDocumentationCommited')}</b>
            <br />
            {t('GFZEducationalWork.AcclimatizationDocumentationRecognizeEngagement')}
          </td>
        </tr>
        <tr>
          <td>
            <div style={{ position: 'relative', left: '5px', width: '55px' }}>
              <Dictaphone insText={setKidsEngagementText} />
            </div>
            <br />
            <br />
            <TextArea
              maxLength={255}
              value={acclimatizationDocumentation.kidsEngagement}
              onChange={e => handleTextStateChange(e.target.value, 'kidsEngagement')}
              type='text'
              placeholder={t('GFZEducationalWork.AcclimatizationDocumentationTextAreaPlaceholder')}
            />
          </td>
        </tr>
        <tr style={{ backgroundColor: '#F2F2F2' }}>
          <td>
            <b>{t('GFZEducationalWork.AcclimatizationDocumentationStandingChallenges')}</b>
            <br />
            {t('GFZEducationalWork.AcclimatizationDocumentationKidChallenges')}
          </td>
        </tr>
        <tr>
          <td>
            <div style={{ position: 'relative', left: '5px', width: '55px' }}>
              <Dictaphone insText={setKidsWithstandText} />
            </div>
            <br />
            <br />
            <TextArea
              maxLength={255}
              value={acclimatizationDocumentation.kidsWithstand}
              onChange={e => handleTextStateChange(e.target.value, 'kidsWithstand')}
              type='text'
              placeholder={t('GFZEducationalWork.AcclimatizationDocumentationTextAreaPlaceholder')}
            />
          </td>
        </tr>
        <tr style={{ backgroundColor: '#F2F2F2' }}>
          <td>
            <b>{t('GFZEducationalWork.AcclimatizationDocumentationExpressAndCommunicate')}</b>
            <br />
            {t('GFZEducationalWork.AcclimatizationDocumentationChildExpress')}
          </td>
        </tr>
        <tr>
          <td>
            <div style={{ position: 'relative', left: '5px', width: '55px' }}>
              <Dictaphone insText={setKidsExpressingText} />
            </div>
            <br />
            <br />
            <TextArea
              maxLength={255}
              value={acclimatizationDocumentation.kidsExpressing}
              onChange={e => handleTextStateChange(e.target.value, 'kidsExpressing')}
              type='text'
              placeholder={t('GFZEducationalWork.AcclimatizationDocumentationTextAreaPlaceholder')}
            />
          </td>
        </tr>
        <tr style={{ backgroundColor: '#F2F2F2' }}>
          <td>
            <b>{t('GFZEducationalWork.AcclimatizationDocumentationLearningCommunityParticipation')}</b>
            <br />
            {t('GFZEducationalWork.AcclimatizationDocumentationWayOfLearningCommunityParticipation')}
          </td>
        </tr>
        <tr>
          <td>
            <div style={{ position: 'relative', left: '5px', width: '55px' }}>
              <Dictaphone insText={setKidsResponsibilityText} />
            </div>
            <br />
            <br />
            <TextArea
              maxLength={255}
              value={acclimatizationDocumentation.kidsResponsibility}
              onChange={e => handleTextStateChange(e.target.value, 'kidsResponsibility')}
              type='text'
              placeholder={t('GFZEducationalWork.AcclimatizationDocumentationTextAreaPlaceholder')}
            />
          </td>
        </tr>
        <tr style={{ backgroundColor: '#F2F2F2' }}>
          <td>
            <b>{t('GFZEducationalWork.AcclimatizationDocumentationMiscellaneous')}</b>
            <br />
            {t('GFZEducationalWork.AcclimatizationDocumentationCaregiverChildBehaviour')}
          </td>
        </tr>
        <tr>
          <td>
            <div style={{ position: 'relative', left: '5px', width: '55px' }}>
              <Dictaphone insText={setOthersText} />
            </div>
            <br />
            <br />
            <TextArea
              maxLength={255}
              value={acclimatizationDocumentation.others}
              onChange={e => handleTextStateChange(e.target.value, 'others')}
              type='text'
              placeholder={t('GFZEducationalWork.AcclimatizationDocumentationTextAreaPlaceholder')}
            />
          </td>
        </tr>
      </table>
      <br />
      <h2>{t('GFZEducationalWork.AcclimatizationDocumentationDay3')}</h2>
      <br />
      {t('GFZEducationalWork.AcclimatizationDocumentation4thDaySeperation')} <br />
      <br />
      <table style={{ borderWidth: '1px', borderColor: '#aaaaaa', borderStyle: 'solid' }}>
        <tr style={{ backgroundColor: '#F2F2F2', fontWeight: 'bold' }}>
          <td style={{ minWidth: "80px" }}>{t('GFZEducationalWork.AcclimatizationDocumentationFeature')}</td>
          <td>{t('GFZEducationalWork.AcclimatizationDocumentationTRUE')}</td>
          <td>{t('GFZEducationalWork.AcclimatizationDocumentationPartiallyTrue')}</td>
          <td>{t('GFZEducationalWork.AcclimatizationDocumentationNotTrue')}</td>
          <td style={{ minWidth: "120px" }}>{t('GFZEducationalWork.AcclimatizationDocumentationUnobservable')}</td>
        </tr>
        <tr>
          <td>
            {t('GFZEducationalWork.AcclimatizationDocumentationNewEnvironmentInterest')}{' '}
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox1}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox1: !prev.checkBox1,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox2}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox2: !prev.checkBox2,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox3}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox3: !prev.checkBox3,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox4}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox4: !prev.checkBox4,
                  };
                });
              }}
            />
          </td>
        </tr>
        <tr>
          <td>{t('GFZEducationalWork.AcclimatizationDocumentationComforted')}</td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox5}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox5: !prev.checkBox5,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox6}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox6: !prev.checkBox6,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox7}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox7: !prev.checkBox7,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox8}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox8: !prev.checkBox8,
                  };
                });
              }}
            />
          </td>
        </tr>
        <tr>
          <td>
            {t('GFZEducationalWork.AcclimatizationDocumentationFeelingComfort')}
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox9}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox9: !prev.checkBox9,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox10}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox10: !prev.checkBox10,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox11}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox11: !prev.checkBox11,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox12}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox12: !prev.checkBox12,
                  };
                });
              }}
            />
          </td>
        </tr>
        <tr>
          <td>
            {t('GFZEducationalWork.AcclimatizationDocumentationKidFirstSignals')}
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox13}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox13: !prev.checkBox13,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox14}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox14: !prev.checkBox14,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox15}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox15: !prev.checkBox15,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox16}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox16: !prev.checkBox16,
                  };
                });
              }}
            />
          </td>
        </tr>
        <tr>
          <td>
            {t('GFZEducationalWork.AcclimatizationDocumentationGainTrust')}
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox17}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox17: !prev.checkBox17,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox18}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox18: !prev.checkBox18,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox19}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox19: !prev.checkBox19,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox20}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox20: !prev.checkBox20,
                  };
                });
              }}
            />
          </td>
        </tr>
      </table>
      <br />
      {t('GFZEducationalWork.AcclimatizationDocumentationAdditionalRemarks')}
      <br />
      <div style={{ position: 'relative', left: '5px', width: '55px' }}>
        <Dictaphone insText={setAdditionalCommentsText} />
      </div>
      <br />
      <br />
      <TextArea
        maxLength={255}
        value={acclimatizationDocumentation.additionalComments}
        onChange={e => handleTextStateChange(e.target.value, 'additionalComments')}
        type='text'
        placeholder={t('GFZEducationalWork.AcclimatizationDocumentationTextAreaPlaceholder')}
      />
      <br />
      <h2>{t('GFZEducationalWork.AcclimatizationDocumentationFromDay4')}</h2>
      <br />
      <table style={{ borderWidth: '1px', borderColor: '#aaaaaa', borderStyle: 'solid' }}>
        <tr style={{ backgroundColor: '#F2F2F2' }}>
          <td>
            <b>{t('GFZEducationalWork.AcclimatizationDocumentationSeparation')}</b>
            <br />
            {t('GFZEducationalWork.AcclimatizationDocumentationParentalCaregiverSeparation')}
          </td>
        </tr>
        <tr>
          <td>
            {t('GFZEducationalWork.AcclimatizationDocumentationDay4')}
            <div style={{ position: 'relative', left: '5px', width: '55px' }}>
              <Dictaphone insText={setSeparationDay4Text} />
            </div>
            <br />
            <br />
            <TextArea
              maxLength={255}
              value={acclimatizationDocumentation.separationDay4}
              onChange={e => handleTextStateChange(e.target.value, 'separationDay4')}
              type='text'
              placeholder={t('GFZEducationalWork.AcclimatizationDocumentationTextAreaPlaceholder')}
            />
          </td>
        </tr>
        <tr>
          <td>
            {t('GFZEducationalWork.AcclimatizationDocumentationDay5')}
            <div style={{ position: 'relative', left: '5px', width: '55px' }}>
              <Dictaphone insText={setSeparationDay5Text} />
            </div>
            <br />
            <br />
            <TextArea
              maxLength={255}
              value={acclimatizationDocumentation.separationDay5}
              onChange={e => handleTextStateChange(e.target.value, 'separationDay5')}
              type='text'
              placeholder={t('GFZEducationalWork.AcclimatizationDocumentationTextAreaPlaceholder')}
            />
          </td>
        </tr>
        <tr>
          <td>
            {t('GFZEducationalWork.AcclimatizationDocumentationDay6')}
            <div style={{ position: 'relative', left: '5px', width: '55px' }}>
              <Dictaphone insText={setSeparationDay6Text} />
            </div>
            <br />
            <br />
            <TextArea
              maxLength={255}
              value={acclimatizationDocumentation.separationDay6}
              onChange={e => handleTextStateChange(e.target.value, 'separationDay6')}
              type='text'
              placeholder={t('GFZEducationalWork.AcclimatizationDocumentationTextAreaPlaceholder')}
            />
          </td>
        </tr>
        <tr>
          <td>
            {t('GFZEducationalWork.AcclimatizationDocumentationDay7')}
            <div style={{ position: 'relative', left: '5px', width: '55px' }}>
              <Dictaphone insText={setSeparationDay7Text} />
            </div>
            <br />
            <br />
            <TextArea
              maxLength={255}
              value={acclimatizationDocumentation.separationDay7}
              onChange={e => handleTextStateChange(e.target.value, 'separationDay7')}
              type='text'
              placeholder={t('GFZEducationalWork.AcclimatizationDocumentationTextAreaPlaceholder')}
            />
          </td>
        </tr>
        <tr style={{ backgroundColor: '#F2F2F2' }}>
          <td>
            <b>{t('GFZEducationalWork.AcclimatizationDocumentationFurtherObservations')}</b>
          </td>
        </tr>
        <tr>
          <td>
            <div style={{ position: 'relative', left: '5px', width: '55px' }}>
              <Dictaphone insText={setFurtherObservationText} />
            </div>
            <br />
            <br />
            <TextArea
              maxLength={255}
              value={acclimatizationDocumentation.furtherObservation}
              onChange={e => handleTextStateChange(e.target.value, 'furtherObservation')}
              type='text'
              placeholder={t('GFZEducationalWork.AcclimatizationDocumentationTextAreaPlaceholder')}
            />
          </td>
        </tr>
        <tr style={{ backgroundColor: '#F2F2F2' }}>
          <td>
            <b>{t('GFZEducationalWork.AcclimatizationDocumentationHealthAndCare')}</b>
            <br />
            {t('GFZEducationalWork.AcclimatizationDocumentationChildBehavioural')}
          </td>
        </tr>
        <tr>
          <td>
            <div style={{ position: 'relative', left: '5px', width: '55px' }}>
              <Dictaphone insText={setKidsHealthText} />
            </div>
            <br />
            <br />
            <TextArea
              maxLength={255}
              value={acclimatizationDocumentation.kidsHealth}
              onChange={e => handleTextStateChange(e.target.value, 'kidsHealth')}
              type='text'
              placeholder={t('GFZEducationalWork.AcclimatizationDocumentationTextAreaPlaceholder')}
            />
          </td>
        </tr>
      </table>
      <br />
      <table>
        <tr>
          <td>
            <b>{t('GFZEducationalWork.AcclimatizationDocumentationAcclimatizationCompletion')}</b>
          </td>
          <td>
            <Input
              type='text'
              maxLength={50}
              value={acclimatizationDocumentation.acclimatizationEnd}
              onChange={({ target }) =>
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    acclimatizationEnd: target.value,
                  };
                })
              }
            />
          </td>
        </tr>
      </table>
      <br />
      {t('GFZEducationalWork.AcclimatizationDocumentationGivenCharacteristics')}
      <br />
      <table style={{ borderWidth: '1px', borderColor: '#aaaaaa', borderStyle: 'solid' }}>
        <tr style={{ backgroundColor: '#F2F2F2', fontWeight: 'bold' }}>
          <td style={{ minWidth: "80px" }}>Merkmal</td>
          <td>{t('GFZEducationalWork.AcclimatizationDocumentationTRUE')}</td>
          <td>{t('GFZEducationalWork.AcclimatizationDocumentationPartiallyTrue')}</td>
          <td>{t('GFZEducationalWork.AcclimatizationDocumentationNotTrue')}</td>
          <td style={{ minWidth: "120px" }}>{t('GFZEducationalWork.AcclimatizationDocumentationUnobservable')}</td>
        </tr>
        <tr>
          <td>{t('GFZEducationalWork.AcclimatizationDocumentationSorroundingsInterest')}</td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox22}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox22: !prev.checkBox22,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox23}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox23: !prev.checkBox23,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox24}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox24: !prev.checkBox24,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox25}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox25: !prev.checkBox25,
                  };
                });
              }}
            />
          </td>
        </tr>
        <tr>
          <td>{t('GFZEducationalWork.AcclimatizationDocumentationComforted')}</td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox26}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox26: !prev.checkBox26,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox27}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox27: !prev.checkBox27,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox28}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox28: !prev.checkBox28,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox29}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox29: !prev.checkBox29,
                  };
                });
              }}
            />
          </td>
        </tr>
        <tr>
          <td>{t('GFZEducationalWork.AcclimatizationDocumentationEnvironmentExploration')}</td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox30}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox30: !prev.checkBox30,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox31}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox31: !prev.checkBox31,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox32}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox32: !prev.checkBox32,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox33}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox33: !prev.checkBox33,
                  };
                });
              }}
            />
          </td>
        </tr>
        <tr>
          <td>{t('GFZEducationalWork.AcclimatizationDocumentationEatAndSleep')}</td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox34}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox34: !prev.checkBox34,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox35}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox35: !prev.checkBox35,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox36}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox36: !prev.checkBox36,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox37}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox37: !prev.checkBox37,
                  };
                });
              }}
            />
          </td>
        </tr>
        <tr>
          <td>{t('GFZEducationalWork.AcclimatizationDocumentationActiveChild')}</td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox38}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox38: !prev.checkBox38,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox39}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox39: !prev.checkBox39,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox40}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox40: !prev.checkBox40,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox41}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox41: !prev.checkBox41,
                  };
                });
              }}
            />
          </td>
        </tr>
        <tr>
          <td>{t('GFZEducationalWork.AcclimatizationDocumentationHelpAndSupport')}</td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox42}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox42: !prev.checkBox42,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox43}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox43: !prev.checkBox43,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox44}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox44: !prev.checkBox44,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox45}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox45: !prev.checkBox45,
                  };
                });
              }}
            />
          </td>
        </tr>
        <tr>
          <td>{t('GFZEducationalWork.AcclimatizationDocumentationKidInteraction')}</td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox46}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox46: !prev.checkBox46,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox47}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox47: !prev.checkBox47,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox48}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox48: !prev.checkBox48,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox49}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox49: !prev.checkBox49,
                  };
                });
              }}
            />
          </td>
        </tr>
        <tr>
          <td>{t('GFZEducationalWork.AcclimatizationDocumentationFeelGood')}</td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox50}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox50: !prev.checkBox50,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox51}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox51: !prev.checkBox51,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox52}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox52: !prev.checkBox52,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox53}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox53: !prev.checkBox53,
                  };
                });
              }}
            />
          </td>
        </tr>
        <tr>
          <td>
            {t('GFZEducationalWork.AcclimatizationDocumentationGainTrust')}
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox54}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox54: !prev.checkBox54,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox55}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox55: !prev.checkBox55,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox56}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox56: !prev.checkBox56,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox57}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox57: !prev.checkBox57,
                  };
                });
              }}
            />
          </td>
        </tr>
        <tr>
          <td>{t('GFZEducationalWork.AcclimatizationDocumentationKidSignals')}</td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox58}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox58: !prev.checkBox58,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox59}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox59: !prev.checkBox59,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox60}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox60: !prev.checkBox60,
                  };
                });
              }}
            />
          </td>
          <td>
            <CheckBox
              isChecked={acclimatizationDocumentation.checkBox61}
              onChange={() => {
                setAcclimatizationDocumentation(prev => {
                  return {
                    ...prev,
                    checkBox61: !prev.checkBox61,
                  };
                });
              }}
            />
          </td>
        </tr>
      </table>
      {t('GFZEducationalWork.AcclimatizationDocumentationCheckTheBoxes')}
      <br />
      <br />
      {t('GFZEducationalWork.AcclimatizationDocumentationFurtherSteps')}
      <br />
      <br />
      {t('GFZEducationalWork.AcclimatizationDocumentationCollegialExchangeAfterAcclimatization')}
      <br />
      <br />
      {t('GFZEducationalWork.AcclimatizationDocumentationIdeaExchangeAndFamiliarization')}
      <br />
      <br />
      <Button
        onClick={() => {
          postGfzAcclimatizationDocumentation(acclimatizationDocumentation).then(() => {
            store.dispatch(showBottomNotification('Gespeichert', { isFail: false }));
          });
        }}
        type='primary'
      >
        {t('GFZEducationalWork.AcclimatizationDocumentationSave')}
      </Button>
      {selectedKid.portfolioGroupId !== null && (
        <Button
          onClick={() => {
            const pdfSaveData = {
              acclimatizationDocumentation: acclimatizationDocumentation,
              selectedKid: selectedKid,
              setProgress: setProgress,
              userId: userId,
            }
            createKidPortfolioGroupIfNeeded(selectedKid.id).then(data => {
              createPDFAcclimatizationDocumentation(pdfSaveData).then(() => {
                if (acclimatizationDocumentation.hasOwnProperty('id') && acclimatizationDocumentation.id !== null) {
                  deleteGfzAcclimatizationDocumentation(acclimatizationDocumentation.id);
                }
                resetForm();
                store.dispatch(showBottomNotification('Gespeichert', { isFail: false }));
              });
            });

          }}
        >
          {t('GFZEducationalWork.AcclimatizationDocumentationInKidPortfolioSave')}
        </Button>
      )}
    </>
  );
}

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import store from 'main/store/configureStore';
import PT from 'prop-types';
import Input from 'library/common/commonComponents/Inputs/Input';
import Button from 'library/common/commonComponents/Buttons/Button';
import CheckInOutTimePopup from '../../TimePopup/CheckInOutTimePopup';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import { formatDateObjectToTimeString } from '../../../../../../utilities/dateMoment';
import { createKidDevelopmentDiaryEntry } from 'library/api/kidDevelopmentDiary';
import defaultAvatar from 'resources/images/user/default-avatar.jpg';
import styles from './childRow.module.scss';

const ChildRow = ({ childObj, prepareDelete, prepareEdit }) => {
  const { t } = useTranslation();

  const [todayComments, setTodayComments] = useState(childObj.pedagogicalObservationTodayComments);
  const [timePopupOpened, setTimePopupOpened] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleSubmitFromTimePopup = (time, text) => {
    setIsSaving(true);
    createKidDevelopmentDiaryEntry(childObj.kidId, 'pedagogicalObservation', text, time)
      .then(res => {
        const sortedTodayComments = [...todayComments, res.data];
        sortedTodayComments.sort(function(a, b) {
          return new Date(a.date) - new Date(b.date);
        });
        setTodayComments(sortedTodayComments);
        store.dispatch(
          showBottomNotification(t('BottomNotification.SuccessfullySaved'), {
            isFail: false,
          }),
        );
      })
      .catch(() => {
        store.dispatch(
          showBottomNotification(t('BottomNotification.ErrorSavingTryAgain'), {
            isFail: true,
          }),
        );
      })
      .finally(() => {
        setIsSaving(false);
        setTimePopupOpened(false);
      });
  };

  const observation = () => {
    return (
      <>
        {todayComments && todayComments.length > 0 && (
          <div>
            <h5>{t('Checkinout.TodaysComments')}</h5>
            <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
              {todayComments.map(c => {
                return (
                  <li>
                    {formatDateObjectToTimeString(c.date)} "{c.message}"{' '}
                    <a
                      onClick={() =>
                        prepareDelete(c.id, () => {
                          setTodayComments(todayComments.filter(c2 => c2.id !== c.id));
                        })
                      }
                    >
                      <i className='fa fa-trash' />
                    </a>
                    <a
                      onClick={() =>
                        prepareEdit(c, (newText, newDate) => {
                          const sortedTodayComments = todayComments.map(c2 => {
                            if (c2.id === c.id) {
                              return {
                                ...c2,
                                message: newText,
                                date: newDate,
                              };
                            } else return c2;
                          });
                          sortedTodayComments.sort(function(a, b) {
                            return new Date(a.date) - new Date(b.date);
                          });
                          setTodayComments(sortedTodayComments);
                        })
                      }
                      style={{ marginLeft: '5px' }}
                    >
                      <i className='fa fa-pencil' />
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </>
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.photo}>
        <img src={childObj.groupLogoUrl || defaultAvatar} alt='' />
      </div>
      <div className={styles.name}>
        {childObj.name}
        {childObj.parent1Name !== null && (
          <span className={styles.parent}>{childObj.parent1Name}</span>
        )}
        {childObj.parent2Name !== null && (
          <span className={styles.parent}>{childObj.parent2Name}</span>
        )}
      </div>
      <CheckInOutTimePopup
        submit={handleSubmitFromTimePopup}
        isSaving={isSaving}
        handleCloseTimePopup={() => setTimePopupOpened(false)}
        timePopupOpened={timePopupOpened}
      />
      <div className={styles.btnWrapper}>
        <Button onClick={() => setTimePopupOpened(true)}>{t('Checkinout.AddNote')}</Button>
      </div>
      <div className={styles.statusWrap}>{observation()}</div>
    </div>
  );
};

ChildRow.propTypes = {
  childObj: PT.shape({
    kidId: PT.number.isRequired,
    name: PT.string.isRequired,
    photo: PT.string,
    parent1Name: PT.string,
    parent2Name: PT.string,
    checkInOutStatus: PT.string.isRequired,
  }).isRequired,
};

export default ChildRow;

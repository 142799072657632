import React, { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import {
  formatDateOnlyTime,
  returnTimeDifferenceInMinutes,
  formatDateOnlyTimeGermanTimezone,
  now,
} from 'library/utilities/dateMoment';
import { adjustTimeZone } from 'library/utilities/date';

import styles from './dateRow.module.scss';
import { Document, Page, StyleSheet, pdf, Text, View } from '@react-pdf/renderer';

/**
 *
 * @param {Object} param0
 * @param {Boolean} param0.returnPDF is used to generate the pdf version of this component
 * @returns
 */
const DateRow = ({ date, groupedHistory, absences, wraps, returnPDF = false }) => {
  const [dateRowCollapsed, setDateRowCollapsed] = useState(true);

  const foodOrder = useMemo(() => groupedHistory.filter(obj => obj.action.includes('food-order')), [
    groupedHistory,
  ]);
  const eat = useMemo(() => groupedHistory.filter(obj => obj.action === 'eat'), [groupedHistory]);
  const eatUndoCount = useMemo(
    () => groupedHistory.filter(obj => obj.action === 'eat-undo').length,
    [groupedHistory],
  );
  const sleep = useMemo(() => groupedHistory.filter(obj => obj.action === 'sleep'), [
    groupedHistory,
  ]);
  const leaving = useMemo(() => groupedHistory.filter(obj => obj.action === 'leavingTime'), [
    groupedHistory,
  ]);
  const presenceTime = useMemo(
    () => groupedHistory.filter(obj => obj.action.includes('presence')),
    [groupedHistory],
  );
  const checkin = useMemo(
    () => groupedHistory.filter(obj => obj.action === 'CHECKIN' || obj.action === 'CHECKOUT'),
    [groupedHistory],
  );
  const checkinout = useMemo(() => groupedHistory.filter(obj => obj.action === 'checkinout'), [
    groupedHistory,
  ]);
  const pedagogicalObservation = useMemo(
    () => groupedHistory.filter(obj => obj.action === 'pedagogicalObservation'),
    [groupedHistory],
  );
  const sleepTime = useMemo(() => calculateTime(sleep), [sleep]);
  const eatTime = useMemo(() => calculateTime(eat), [eat]);
  const checkInOutTime = useMemo(() => calculateTimeCheckInOut(checkin), [checkin]);

  const { t } = useTranslation();

  function formatMinutesToString(minutes) {
    if (minutes < 60) return minutes + ' min';
    else return Math.floor(minutes / 60) + ' h ' + (minutes % 60) + ' min';
  }

  function formatDateToString(date) {
    return (
      date
        .getDate()
        .toString()
        .padStart(2, '0') +
      '.' +
      (date.getMonth() + 1).toString().padStart(2, '0') +
      '.' +
      date.getFullYear() +
      ' ' +
      extractTime(date)
    );
  }

  function getSmileyIcon(smileyStatus) {
    let smileyIcon = '';
    switch (smileyStatus) {
      case 'like':
        smileyIcon = 'fa fa-smile-o';
        break;
      case 'normal':
        smileyIcon = 'fa fa-meh-o';
        break;
      case 'dislike':
        smileyIcon = 'fa fa-frown-o';
        break;
      default:
        break;
    }
    return smileyIcon;
  }

  function extractTime(dateStr) {
    const date = new Date(dateStr);
    const hours = date
      .getHours()
      .toString()
      .padStart(2, '0'); // Use padStart to add leading zero if necessary
    const minutes = date
      .getMinutes()
      .toString()
      .padStart(2, '0');
    return `${hours}:${minutes}`;
  }

  function calculateTimeCheckInOut(checkInOutArray) {
    if (checkInOutArray.length > 0) {
      if (checkInOutArray.length % 2 === 0) {
        for (let i = 0; i < checkInOutArray.length; i++) {
          if (i % 2 !== 0) {
            const startSleepTime = formatDateOnlyTime(
              adjustTimeZone(`${checkInOutArray[i - 1].date}:00`),
            );
            const endSleepTime = formatDateOnlyTime(
              adjustTimeZone(`${checkInOutArray[i].date}:00`),
            );
            const difference = returnTimeDifferenceInMinutes(startSleepTime, endSleepTime);
            return {
              duration: formatMinutesToString(difference),
              start: startSleepTime,
              end: endSleepTime,
              personToPickUpChild:
                checkInOutArray[i].personToPickUpChild ??
                checkInOutArray[i - 1].personToPickUpChild ??
                undefined,
            };
          }
        }
      } else {
        const startSleepTime = formatDateOnlyTimeGermanTimezone(checkInOutArray[0].date);

        return {
          duration: 0,
          start: startSleepTime,
          personToPickUpChild: checkInOutArray[0].personToPickUpChild ?? undefined,
        };
      }
    }
    return { duration: 0 };
  }

  function calculateTime(sleepArray) {
    const onlyActions = sleepArray.filter(obj => obj.additionalTextMessage === null);
    if (onlyActions.length > 0) {
      if (onlyActions.length % 2 === 0) {
        for (let i = 0; i < onlyActions.length; i++) {
          if (i % 2 !== 0) {
            const startSleepTime = formatDateOnlyTime(adjustTimeZone(onlyActions[i - 1].date));
            const endSleepTime = formatDateOnlyTime(adjustTimeZone(onlyActions[i].date));
            const difference = returnTimeDifferenceInMinutes(startSleepTime, endSleepTime);
            return {
              duration: formatMinutesToString(difference),
              start: startSleepTime,
              end: endSleepTime,
            };
          }
        }
      } else {
        const startSleepTime = formatDateOnlyTimeGermanTimezone(onlyActions[0].date);

        return {
          duration: 0,
          start: startSleepTime,
        };
      }
    }
    return { duration: 0 };
  }

  return returnPDF ? (
    <PDFVersionDateRow
      date={date}
      absences={absences}
      checkin={checkin}
      checkInOutTime={checkInOutTime}
      presenceTime={presenceTime}
      eat={eat}
      eatTime={eatTime}
      eatUndoCount={eatUndoCount}
      leaving={leaving}
      checkinout={checkinout}
      pedagogicalObservation={pedagogicalObservation}
      sleep={sleep}
      sleepTime={sleepTime}
      wraps={wraps}
      foodOrder={foodOrder}
      t={t}
      now={now}
      formatDateToString={formatDateToString}
      formatDateOnlyTime={formatDateOnlyTime}
      formatMinutesToString={formatMinutesToString}
      adjustTimeZone={adjustTimeZone}
      returnTimeDifferenceInMinutes={returnTimeDifferenceInMinutes}
      getSmileyIcon={getSmileyIcon}
    />
  ) : (
    <>
      <div
        onClick={() => {
          setDateRowCollapsed(!dateRowCollapsed);
        }}
        className={styles.dateRowWrapper}
      >
        <div style={{ color: 'white', verticalAlign: 'middle' }}>
          <b>{date}</b>
          <i
            className={dateRowCollapsed ? 'fa fa-arrow-down' : 'fa fa-arrow-up'}
            style={{ color: 'white' }}
          />
        </div>
      </div>
      <br />

      {!dateRowCollapsed && (
        <>
          {absences.map(absence => {
            return (
              <>
                <div className={styles.rowItem}>
                  <div className={styles.rowItemTitle}>
                    <h3>{t('Checkinout.Time checked out')}</h3>
                  </div>
                  <div>
                    <div>
                      <>
                        <b>{t('Checkinout.Reason')}: </b>
                        {absence.reason === 'krankehit' ? 'Krankheit' : absence.reason}

                        <p>
                          Von {formatDateToString(new Date(absence.startDate))} bis{' '}
                          {formatDateToString(new Date(absence.endDate))}
                        </p>
                      </>
                    </div>
                  </div>
                </div>
                <br />
              </>
            );
          })}
          {checkin.length > 0 && (
            <>
              <div className={styles.rowItem}>
                <div className={styles.rowItemTitle}>
                  <h3>{t('Checkinout.Check in or out')}</h3>
                </div>
                <div>
                  <div>
                    {checkInOutTime.end ? (
                      <>
                        <b>{t('Checkinout.Time checked in')}: </b>
                        {checkInOutTime.duration}
                        <p>
                          Von {checkInOutTime.start} bis {checkInOutTime.end}
                        </p>
                        {checkInOutTime.personToPickUpChild && (
                          <p>
                            <b>{t('Checkinout.PickupPerson')}:</b>{' '}
                            {checkInOutTime.personToPickUpChild}
                          </p>
                        )}
                      </>
                    ) : (
                      <>
                        <b>Checkin Zeit: </b>
                        {checkInOutTime.start}
                        <p>
                          <b>eingecheckt seit: </b>
                          {formatMinutesToString(
                            returnTimeDifferenceInMinutes(
                              checkInOutTime.start,
                              now().format('HH:mm'),
                            ),
                          )}
                        </p>
                        {checkInOutTime.personToPickUpChild && (
                          <p>
                            <b>{t('Checkinout.PickupPerson')}:</b>{' '}
                            {checkInOutTime.personToPickUpChild}
                          </p>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <br />
            </>
          )}
          {presenceTime.length > 0 && (
            <>
              <div className={styles.rowItem}>
                <div className={styles.rowItemTitle}>
                  <h3>{t('Checkinout.PresenceTime')}</h3>
                </div>
                <div>
                  {presenceTime.map(obj => {
                    return (
                      <>
                        <div>
                          <b>
                            {obj.action.includes('checkin')
                              ? t('Presence.Checked In')
                              : t('Presence.Checked out')}
                            :{' '}
                          </b>{' '}
                          &nbsp;
                          <b>{formatDateOnlyTime(adjustTimeZone(obj.date))}</b>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
              <br />
            </>
          )}
          {eat.length > 0 && (
            <>
              <div className={styles.rowItem}>
                <div className={styles.rowItemTitle}>
                  <h3>{t('Checkinout.Eat')}</h3>
                </div>
                <div>
                  <div>
                    <b>Essenzeit: </b>
                    {eatTime.start}
                  </div>
                  <div>
                    <b>{t('Checkinout.Portions eaten')}: </b>
                    {eat.filter(obj => !obj.additionalTextMessage && !obj.smileyStatus).length -
                      eatUndoCount}
                  </div>
                  {eat
                    .filter(obj => obj.additionalTextMessage !== null && !obj.smileyStatus)
                    .map(obj => {
                      return (
                        <>
                          <span>
                            &nbsp;<b>{formatDateOnlyTime(adjustTimeZone(obj.date))}</b>
                          </span>
                          &nbsp;
                          <span>{obj.additionalTextMessage}</span>
                          <br />
                        </>
                      );
                    })}
                  {eat
                    .filter(obj => obj.smileyStatus !== null && !obj.additionalTextMessage)
                    .map(obj => {
                      return (
                        <div className={styles.smileyStatus}>
                          <span>
                            &nbsp;<b>{formatDateOnlyTime(adjustTimeZone(obj.date))}</b>
                          </span>
                          &nbsp;
                          <span className={styles.smileyIcon}>
                            <i className={getSmileyIcon(obj.smileyStatus)} />
                          </span>
                          <br />
                        </div>
                      );
                    })}
                </div>
              </div>
              <br />
            </>
          )}
          {leaving.length > 0 && (
            <>
              <div className={styles.rowItem}>
                <div className={styles.rowItemTitle}>
                  <h3>{t('Checkinout.LeavingTime')}</h3>
                </div>
                <div>
                  {leaving
                    .filter(obj => obj.additionalTextMessage !== null)
                    .map(obj => {
                      return (
                        <>
                          <div>
                            <b>{t('Checkinout.LeavingTime')}: </b> &nbsp;
                            <b>{formatDateOnlyTime(obj.date)}</b>
                          </div>
                          <div>
                            <b>{t('Checkinout.Note')}: </b>
                            <span>{obj.additionalTextMessage}</span>
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
              <br />
            </>
          )}
          {checkinout.length > 0 && (
            <>
              <div className={styles.rowItem}>
                <div className={styles.rowItemTitle}>
                  <h3>{t('Checkinout.Note')}</h3>
                </div>
                <div>
                  {checkinout
                    .filter(obj => obj.additionalTextMessage !== null)
                    .map(obj => {
                      return (
                        <>
                          <span>
                            &nbsp;<b>{formatDateOnlyTime(adjustTimeZone(obj.date))}</b>
                          </span>
                          &nbsp;
                          <span>{obj.additionalTextMessage}</span>
                          <br />
                        </>
                      );
                    })}
                </div>
              </div>
              <br />
            </>
          )}
          {pedagogicalObservation.length > 0 && (
            <>
              <div className={styles.rowItem}>
                <div className={styles.rowItemTitle}>
                  <h3>{t('Checkinout.Pedagogical observation')}</h3>
                </div>
                <div>
                  {pedagogicalObservation
                    .filter(obj => obj.additionalTextMessage !== null)
                    .map(obj => {
                      return (
                        <>
                          <span>
                            &nbsp;<b>{formatDateOnlyTime(adjustTimeZone(obj.date))}</b>
                          </span>
                          &nbsp;
                          <span>{obj.additionalTextMessage}</span>
                          <br />
                        </>
                      );
                    })}
                </div>
              </div>
              <br />
            </>
          )}
          {sleep.length > 0 && (
            <>
              <div className={styles.rowItem}>
                <div className={styles.rowItemTitle}>
                  <h3>{t('Checkinout.Sleep')}</h3>
                </div>
                <div>
                  <div>
                    {sleepTime.end ? (
                      <>
                        <b>{t('Checkinout.Minutes asleep')}: </b>
                        {sleepTime.duration}
                        <p>
                          Von {sleepTime.start} bis {sleepTime.end}
                        </p>
                      </>
                    ) : (
                      <>
                        <b>Einschlafzeit: </b>
                        {sleepTime.start}
                        <p>
                          <b>schläft seit: </b>
                          {formatMinutesToString(
                            returnTimeDifferenceInMinutes(sleepTime.start, now().format('HH:mm')),
                          )}
                        </p>
                      </>
                    )}
                  </div>

                  {sleep
                    .filter(obj => obj.additionalTextMessage !== null)
                    .map(obj => {
                      return (
                        <div key={obj.date}>
                          <span>
                            &nbsp;<b>{formatDateOnlyTime(adjustTimeZone(obj.date))}</b>
                          </span>
                          &nbsp;
                          <span>{obj.additionalTextMessage}</span>
                          <br />
                        </div>
                      );
                    })}
                </div>
              </div>
              <br />
            </>
          )}
          {wraps.reverse().map(wrapEntry => {
            if (wrapEntry.additionalTextMessage) {
              return (
                <>
                  <div className={styles.rowItem}>
                    <div className={styles.rowItemTitle}>
                      <h3>{t('Checkinout.Wrap Notice')}</h3>
                    </div>
                    <div>
                      <div>
                        <b>Hinweis: </b>
                        {wrapEntry.additionalTextMessage}
                      </div>
                      <div>
                        <b>Zeit: </b>
                        {formatDateOnlyTime(wrapEntry.date)}
                      </div>
                    </div>
                  </div>
                  <br />
                </>
              );
            } else {
              return (
                <>
                  <div className={styles.rowItem}>
                    <div className={styles.rowItemTitle}>
                      <h3>{t('Checkinout.Wrap')}</h3>
                    </div>
                    <div>
                      <div>
                        <b>Wickelzeit: </b>
                        {formatDateOnlyTime(wrapEntry.date)}
                      </div>
                    </div>
                  </div>
                  <br />
                </>
              );
            }
          })}
          {foodOrder.length > 0 && (
            <>
              <div className={styles.rowItem}>
                <div className={styles.rowItemTitle}>
                  <h3>{t('FoodOrder.Food orders')}</h3>
                </div>
                <div>
                  {foodOrder
                    .filter(obj => obj.additionalTextMessage !== null)
                    .map(obj => {
                      return (
                        <>
                          <span>
                            {t('FoodOrder.Food order for')}
                            &nbsp;{obj.additionalTextMessage}
                            &nbsp;
                            {obj.action.includes('cancel')
                              ? t('FoodOrder.Canceled')
                              : t('FoodOrder.Created')}
                          </span>
                          <br />
                        </>
                      );
                    })}
                </div>
              </div>
              <br />
            </>
          )}
        </>
      )}
    </>
  );
};

const pdfStyles = StyleSheet.create({
  rowItem: {
    borderWidth: 1,
    borderColor: 'rgb(237,237,237)',
    borderRadius: 5,
    marginBottom: 10,
    padding: 5,
  },
  dateHeader: {
    backgroundColor: '#808080',
    color: 'white',
    fontWeight: 'bold',
    fontSize: 12,
    padding: 10,
    borderRadius: 5,
    textAlign: 'left',
    marginBottom: 10,
  },
  rowItemTitle: {
    backgroundColor: 'rgb(237,237,237)',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    textAlign: 'center',
    padding: 5,
    fontSize: 12,
    marginBottom: 5,
  },
  smileyStatus: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 6,
  },
  bold: {
    fontWeight: 'bold',
  },
  text: {
    marginBottom: 2,
    fontSize: 12,
  },
  page: {
    padding: 10,
  },
});

// is used to generate the pdf version of this component
const PDFVersionDateRow = ({
  date,
  absences,
  checkin,
  checkInOutTime,
  presenceTime,
  eat,
  eatTime,
  eatUndoCount,
  leaving,
  checkinout,
  pedagogicalObservation,
  sleep,
  sleepTime,
  wraps,
  foodOrder,
  t,
  now,
  formatDateToString,
  formatDateOnlyTime,
  formatMinutesToString,
  adjustTimeZone,
  returnTimeDifferenceInMinutes,
  getSmileyIcon,
}) => (
  <>
    <View style={pdfStyles.dateHeader}>
      <Text>{date}</Text>
    </View>
    {/* Absences */}
    {absences &&
      absences.map((absence, index) => (
        <View key={`absence-${index}`} style={pdfStyles.rowItem}>
          <View style={pdfStyles.rowItemTitle}>
            <Text>{t('Checkinout.Time checked out')}</Text>
          </View>
          <View>
            <Text style={pdfStyles.text}>
              <Text style={pdfStyles.bold}>{t('Checkinout.Reason')}: </Text>
              {absence.reason === 'krankehit' ? 'Krankheit' : absence.reason}
            </Text>
            <Text style={pdfStyles.text}>
              Von {formatDateToString(new Date(absence.startDate))} bis{' '}
              {formatDateToString(new Date(absence.endDate))}
            </Text>
          </View>
        </View>
      ))}

    {/* Checkin/Checkout */}
    {checkin && checkin.length > 0 && (
      <View style={pdfStyles.rowItem}>
        <View style={pdfStyles.rowItemTitle}>
          <Text>{t('Checkinout.Check in or out')}</Text>
        </View>
        <View>
          {checkInOutTime.end ? (
            <View>
              <Text style={pdfStyles.text}>
                <Text style={pdfStyles.bold}>{t('Checkinout.Time checked in')}: </Text>
                {checkInOutTime.duration}
              </Text>
              <Text style={pdfStyles.text}>
                Von {checkInOutTime.start} bis {checkInOutTime.end}
              </Text>
              {checkInOutTime.personToPickUpChild && (
                <Text style={pdfStyles.text}>
                  <Text style={pdfStyles.bold}>{t('Checkinout.PickupPerson')}: </Text>
                  {checkInOutTime.personToPickUpChild}
                </Text>
              )}
            </View>
          ) : (
            <View>
              <Text style={pdfStyles.text}>
                <Text style={pdfStyles.bold}>Checkin Zeit: </Text>
                {checkInOutTime.start}
              </Text>
              <Text style={pdfStyles.text}>
                <Text style={pdfStyles.bold}>eingecheckt seit: </Text>
                {formatMinutesToString(
                  returnTimeDifferenceInMinutes(checkInOutTime.start, now().format('HH:mm')),
                )}
              </Text>
              {checkInOutTime.personToPickUpChild && (
                <Text style={pdfStyles.text}>
                  <Text style={pdfStyles.bold}>{t('Checkinout.PickupPerson')}: </Text>
                  {checkInOutTime.personToPickUpChild}
                </Text>
              )}
            </View>
          )}
        </View>
      </View>
    )}

    {/* Presence Time */}
    {presenceTime && presenceTime.length > 0 && (
      <View style={pdfStyles.rowItem}>
        <View style={pdfStyles.rowItemTitle}>
          <Text>{t('Checkinout.PresenceTime')}</Text>
        </View>
        <View>
          {presenceTime.map((obj, index) => (
            <View key={`presence-${index}`}>
              <Text style={pdfStyles.text}>
                <Text style={pdfStyles.bold}>
                  {obj.action.includes('checkin')
                    ? t('Presence.Checked In')
                    : t('Presence.Checked out')}
                  :
                </Text>
                &nbsp;
                <Text style={pdfStyles.bold}>{formatDateOnlyTime(adjustTimeZone(obj.date))}</Text>
              </Text>
            </View>
          ))}
        </View>
      </View>
    )}

    {/* Eat */}
    {eat && eat.length > 0 && (
      <View style={pdfStyles.rowItem}>
        <View style={pdfStyles.rowItemTitle}>
          <Text>{t('Checkinout.Eat')}</Text>
        </View>
        <View>
          <View>
            <Text style={pdfStyles.text}>
              <Text style={pdfStyles.bold}>Essenzeit: </Text>
              {eatTime.start}
            </Text>
          </View>
          <View>
            <Text style={pdfStyles.text}>
              <Text style={pdfStyles.bold}>{t('Checkinout.Portions eaten')}: </Text>
              {eat.filter(obj => !obj.additionalTextMessage && !obj.smileyStatus).length -
                eatUndoCount}
            </Text>
          </View>
          {eat
            .filter(obj => obj.additionalTextMessage !== null && !obj.smileyStatus)
            .map((obj, index) => (
              <View key={`eat-msg-${index}`}>
                <Text style={pdfStyles.text}>
                  &nbsp;
                  <Text style={pdfStyles.bold}>{formatDateOnlyTime(adjustTimeZone(obj.date))}</Text>
                  &nbsp;{obj.additionalTextMessage}
                </Text>
              </View>
            ))}
          {eat
            .filter(obj => obj.smileyStatus !== null && !obj.additionalTextMessage)
            .map((obj, index) => (
              <View key={`eat-smiley-${index}`} style={pdfStyles.smileyStatus}>
                <Text style={pdfStyles.text}>
                  &nbsp;
                  <Text style={pdfStyles.bold}>{formatDateOnlyTime(adjustTimeZone(obj.date))}</Text>
                  &nbsp;
                  <Text>{t('ExportDiaryPDF.SmileyStatus.' + obj.smileyStatus)}</Text>
                </Text>
              </View>
            ))}
        </View>
      </View>
    )}

    {/* Leaving Time */}
    {leaving && leaving.length > 0 && (
      <View style={pdfStyles.rowItem}>
        <View style={pdfStyles.rowItemTitle}>
          <Text>{t('Checkinout.LeavingTime')}</Text>
        </View>
        <View>
          {leaving
            .filter(obj => obj.additionalTextMessage !== null)
            .map((obj, index) => (
              <View key={`leaving-${index}`}>
                <Text style={pdfStyles.text}>
                  <Text style={pdfStyles.bold}>{t('Checkinout.LeavingTime')}: </Text>
                  &nbsp;
                  <Text style={pdfStyles.bold}>{formatDateOnlyTime(obj.date)}</Text>
                </Text>
                <Text style={pdfStyles.text}>
                  <Text style={pdfStyles.bold}>{t('Checkinout.Note')}: </Text>
                  {obj.additionalTextMessage}
                </Text>
              </View>
            ))}
        </View>
      </View>
    )}

    {/* Checkin/Out Notes */}
    {checkinout && checkinout.length > 0 && (
      <View style={pdfStyles.rowItem}>
        <View style={pdfStyles.rowItemTitle}>
          <Text>{t('Checkinout.Note')}</Text>
        </View>
        <View>
          {checkinout
            .filter(obj => obj.additionalTextMessage !== null)
            .map((obj, index) => (
              <View key={`checkinout-${index}`}>
                <Text style={pdfStyles.text}>
                  &nbsp;
                  <Text style={pdfStyles.bold}>{formatDateOnlyTime(adjustTimeZone(obj.date))}</Text>
                  &nbsp;{obj.additionalTextMessage}
                </Text>
              </View>
            ))}
        </View>
      </View>
    )}

    {/* Pedagogical Observation */}
    {pedagogicalObservation && pedagogicalObservation.length > 0 && (
      <View style={pdfStyles.rowItem}>
        <View style={pdfStyles.rowItemTitle}>
          <Text>{t('Checkinout.Pedagogical observation')}</Text>
        </View>
        <View>
          {pedagogicalObservation
            .filter(obj => obj.additionalTextMessage !== null)
            .map((obj, index) => (
              <View key={`pedagogical-${index}`}>
                <Text style={pdfStyles.text}>
                  &nbsp;
                  <Text style={pdfStyles.bold}>{formatDateOnlyTime(adjustTimeZone(obj.date))}</Text>
                  &nbsp;{obj.additionalTextMessage}
                </Text>
              </View>
            ))}
        </View>
      </View>
    )}

    {/* Sleep */}
    {sleep && sleep.length > 0 && (
      <View style={pdfStyles.rowItem}>
        <View style={pdfStyles.rowItemTitle}>
          <Text>{t('Checkinout.Sleep')}</Text>
        </View>
        <View>
          <View>
            {sleepTime.end ? (
              <View>
                <Text style={pdfStyles.text}>
                  <Text style={pdfStyles.bold}>{t('Checkinout.Minutes asleep')}: </Text>
                  {sleepTime.duration}
                </Text>
                <Text style={pdfStyles.text}>
                  Von {sleepTime.start} bis {sleepTime.end}
                </Text>
              </View>
            ) : (
              <View>
                <Text style={pdfStyles.text}>
                  <Text style={pdfStyles.bold}>Einschlafzeit: </Text>
                  {sleepTime.start}
                </Text>
                <Text style={pdfStyles.text}>
                  <Text style={pdfStyles.bold}>schläft seit: </Text>
                  {formatMinutesToString(
                    returnTimeDifferenceInMinutes(sleepTime.start, now().format('HH:mm')),
                  )}
                </Text>
              </View>
            )}
          </View>
          {sleep
            .filter(obj => obj.additionalTextMessage !== null)
            .map((obj, index) => (
              <View key={`sleep-${index}`}>
                <Text style={pdfStyles.text}>
                  &nbsp;
                  <Text style={pdfStyles.bold}>{formatDateOnlyTime(adjustTimeZone(obj.date))}</Text>
                  &nbsp;{obj.additionalTextMessage}
                </Text>
              </View>
            ))}
        </View>
      </View>
    )}

    {/* Wraps */}
    {wraps &&
      wraps
        .slice()
        .reverse()
        .map((wrapEntry, index) =>
          wrapEntry.additionalTextMessage ? (
            <View key={`wrap-${index}`} style={pdfStyles.rowItem}>
              <View style={pdfStyles.rowItemTitle}>
                <Text>{t('Checkinout.Wrap Notice')}</Text>
              </View>
              <View>
                <Text style={pdfStyles.text}>
                  <Text style={pdfStyles.bold}>Hinweis: </Text>
                  {wrapEntry.additionalTextMessage}
                </Text>
                <Text style={pdfStyles.text}>
                  <Text style={pdfStyles.bold}>Zeit: </Text>
                  {formatDateOnlyTime(wrapEntry.date)}
                </Text>
              </View>
            </View>
          ) : (
            <View key={`wrap-${index}`} style={pdfStyles.rowItem}>
              <View style={pdfStyles.rowItemTitle}>
                <Text>{t('Checkinout.Wrap')}</Text>
              </View>
              <View>
                <Text style={pdfStyles.text}>
                  <Text style={pdfStyles.bold}>Wickelzeit: </Text>
                  {formatDateOnlyTime(wrapEntry.date)}
                </Text>
              </View>
            </View>
          ),
        )}

    {/* Food Orders */}
    {foodOrder && foodOrder.length > 0 && (
      <View style={pdfStyles.rowItem}>
        <View style={pdfStyles.rowItemTitle}>
          <Text>{t('FoodOrder.Food orders')}</Text>
        </View>
        <View>
          {foodOrder
            .filter(obj => obj.additionalTextMessage !== null)
            .map((obj, index) => (
              <View key={`foodOrder-${index}`}>
                <Text style={pdfStyles.text}>
                  {t('FoodOrder.Food order for')}&nbsp;{obj.additionalTextMessage}&nbsp;
                  {obj.action.includes('cancel') ? t('FoodOrder.Canceled') : t('FoodOrder.Created')}
                </Text>
              </View>
            ))}
        </View>
      </View>
    )}
  </>
);

export default DateRow;

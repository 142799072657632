import axios from 'library/api/axios';
import { URL } from './kids';

export const saveOrUpdateKitaPortalSettings = formData => {
  return axios.post(`${URL}/kitaportal/savesettings`, formData);
}

export const importKitaportalForCurrentKita = (selectedKids, emailFlag) => {
  return axios.post(`${URL}/kitaportal/importforcurrentkita/${emailFlag}`, selectedKids);
}

export const getKitaPortalSettings = formData => {
  return axios.get(`${URL}/kitaportal/getsettings`, formData);
}

export const getKitaPortalKidsToImport = () => {
  return axios.get(`${URL}/kitaportal/getkidstoimport`);
}

export const deleteKitaPortalSettings = () => {
  return axios.delete(`${URL}/kitaportal/deletesettings`);
}



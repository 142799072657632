import {
  SET_UNREAD_COUNTS_CHAT,
  SET_SELECTED_TAB_CHAT,
} from 'library/common/commonConstants/unreadCountChatConstant';

export const setUnreadCountChat = data => dispatch => {
  dispatch({
    type: SET_UNREAD_COUNTS_CHAT,
    payload: data,
  });
};

export const setSelectedTabChat = data => dispatch => {
  dispatch({
    type: SET_SELECTED_TAB_CHAT,
    payload: data,
  });
};

import React, { useRef, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { getToken } from 'library/utilities/token';
import { isAuthPage } from 'library/utilities/router';
import { getConfig, setUserKita } from 'library/api/kita';
import { getKitaModules } from 'library/api/modules';
import SidePanel from 'library/common/commonComponents/SidePanel';
import AddNewKitaPopup from 'library/common/commonComponents/Popups/AddNewKitaPopup';
import KitaList from './kitaSidePanelFrames/KitaList';
import useGetUserKitas from './hooks/useGetUserKitas';
import useAddNewKita from './hooks/useAddNewKita';
import { getNoScreenshotsStatus } from 'library/api/user';

export default function KitaSidePanel({
  history,
  isAdmin,
  superAdminStatus,
  isLoggedIn,
  loadUserProfile,
  location,
  newKita,
  user,
  activeKita,
  setActiveKita,
  setKitaModules,
  isOpened,
  setIsOpened,
  setKitas,
  setKitaConfig,
  unreadNotificationsCount,
  unreadMessagesCountsKitas,
  setUserPermissions,
  showBottomNotification,
  goToNewKita,
  goToKita,
  unreadTaxReceiptCount,
  setUnreadMessagesCountsKitas,
}) {
  const dispatch = useDispatch();

  const searchParams = new URLSearchParams(location.search);

  const [showEmailVerification, setShowEmailVerification] = useState(false);
  const [activeKitaState, setActiveKitaState] = useState(null);

  const setKita = id => {
    setUserKita(id).then(res => {
      if (res && res.status === 200) {
        const userToken = getToken();
        loadUserProfile(userToken);

        getModules();
      }
    });
  };

  const selectKita = function (kita) {
    setActiveKitaState(kita.kitaId);
  };

  const {
    kitaList,
    isLoading,
    patchKita,
    searchKitaList,
    reloadAndGoToKita,
    reloadAndGoToKitaById,
    originalKitaList,
  } = useGetUserKitas(isLoggedIn, newKita, setKitas, history, selectKita, user);

  useEffect(() => {
    if (!isLoading) {
      if (kitaList.length > 1) {
        setIsOpened(true);
      } else if (kitaList.length === 1) {
        setIsOpened(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, activeKita]);

  const getModules = () => {
    if (!isLoggedIn) return;
    getKitaModules()
      .then(({ data, status }) => {
        if (status === 200) {
          setKitaModules(data);
        }
      })
      .catch(err => {
        // eslint-disable-next-line
        console.log(err);
      });
  };

  const getScreenshotsStatus = async () => {
    try {
      if (window.ReactNativeWebView) {
        const isActive = (await getNoScreenshotsStatus()).data;
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: 'screenshot_status',
            active: isActive,
          }),
        );
      }
    } catch (error) {
      console.log(error);
    }

  };

  useEffect(() => {
    if (goToNewKita.kitaName !== 'initial') {
      reloadAndGoToKita(goToNewKita.kitaName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goToNewKita.signal]);

  useEffect(() => {
    if (goToKita.kitaId !== null) {
      reloadAndGoToKitaById(goToKita.kitaId);
    }
  }, [goToKita.signal]);

  useEffect(() => {
    getModules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    const connectedKitaId = Number(searchParams.get('connectedKitaId'));
    if (connectedKitaId) {
      setKita(connectedKitaId);
      reloadAndGoToKitaById(connectedKitaId);
    }
  }, []);

  useEffect(() => {
    const kitaId = Number(searchParams.get('kitaId'));
    if (kitaId) {
      reloadAndGoToKitaById(kitaId);
    }
  }, []);

  useEffect(() => {
    getConfig().then(res => {
      const kitaConfig = res.data;
      setKitaConfig(kitaConfig);
    });
  }, []);

  const { openPopup, ...addNewKitaPopupProps } = useAddNewKita({
    location,
    isLoggedIn,
    showBottomNotification,
  });

  // Only for rerendering purposes
  const [, setRenderedKitaList] = useState(kitaList);
  const containerRef = useRef(null);

  return (
    !isAuthPage(window.location.pathname) && (
      <>
        <SidePanel
          isOpened={isOpened}
          onOpen={setIsOpened}
          containerRef={containerRef}
          searchKitaList={searchKitaList}
          originalKitaList={originalKitaList}
        >
          <KitaList
            history={history}
            kitaList={kitaList}
            isOpened={isOpened}
            isAdmin={isAdmin}
            superAdminStatus={superAdminStatus}
            addNewKita={openPopup}
            setKita={setKita}
            isLoading={isLoading}
            activeKitaFromParent={activeKitaState}
            setActiveKitaFromParent={setActiveKitaState}
            setActiveKitaFromProps={setActiveKita}
            height={containerRef.current && containerRef.current.offsetHeight + 'px'}
            patchKita={patchKita}
            unreadNotificationsCount={unreadNotificationsCount}
            unreadTaxReceiptCount={unreadTaxReceiptCount}
            setUserPermissions={setUserPermissions}
            setRenderedKitaList={setRenderedKitaList}
            setUnreadMessagesCountsKitas={setUnreadMessagesCountsKitas}
            unreadMessagesCountsKitas={unreadMessagesCountsKitas}
          />
          <AddNewKitaPopup {...addNewKitaPopupProps} />
        </SidePanel>
      </>
    )
  );
}

import React, { Component } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './pagination.module.scss';

class Pagination extends Component {
  onClick = page => () => {
    const { history, numberOfPages, onPageClick, withoutRouting, showOnlyAdmin } = this.props;
    if (page > 0 && page <= numberOfPages) {
      if (onPageClick) {
        onPageClick(page);
        if (withoutRouting) return;
      }
      if (showOnlyAdmin) {
        const pathSegments = history.location.pathname.split("/");
        const lastSegment = pathSegments[pathSegments.length - 1];

        if (!isNaN(Number(lastSegment))) {
          pathSegments.pop();
        }

        history.push(`${pathSegments.join("/")}/${page}`);
      }
      else {
        history.push(
          `${history.location.pathname
            .split('/')
            .slice(0, -1)
            .join('/')}/${page}`,
        );
      }
    }
  };

  renderPages() {
    const { currentPage, numberOfPages } = this.props;
    const firstDisplaydPage =
      currentPage > 1
        ? currentPage < numberOfPages
          ? currentPage - 1
          : currentPage - 2
        : +currentPage;

    const pages =
      numberOfPages > 1
        ? numberOfPages === 2
          ? [1, 2]
          : [firstDisplaydPage, firstDisplaydPage + 1, firstDisplaydPage + 2]
        : [1];
    return pages;
  }

  render() {
    const { currentPage, numberOfPages, className, hiddenOnDesktop, hiddenOnMobile } = this.props;
    return (
      <div
        className={cn(styles.pagination, {
          [styles.hiddenOnDesktop]: hiddenOnDesktop,
          [styles.hiddenOnMobile]: hiddenOnMobile,
        })}
      >
        <div className={cn(styles.paginationContainer, className)}>
          <div className={cn(styles.page, styles.first)} onClick={this.onClick(1)}>
            <i className='fa fa-backward' />
          </div>
          <div className={cn(styles.page, styles.prev)} onClick={this.onClick(currentPage - 1)}>
            <i className='fa fa-step-backward' />
          </div>
          {this.renderPages().map(item => (
            <div
              key={`pagination${item}`}
              className={cn(styles.page, currentPage === item && styles.current)}
              onClick={this.onClick(item)}
            >
              {item}
            </div>
          ))}
          <div className={cn(styles.page, styles.next)} onClick={this.onClick(currentPage + 1)}>
            <i className='fa fa-step-forward' />
          </div>
          <div className={cn(styles.page, styles.last)} onClick={this.onClick(numberOfPages)}>
            <i className='fa fa-forward' />
          </div>
        </div>
      </div>
    );
  }
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  history: PropTypes.shape({}).isRequired,
  numberOfPages: PropTypes.number.isRequired,
};

export default Pagination;

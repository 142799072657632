import React, { useContext, useState, useEffect } from 'react';
import { Link , useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import CloseKidsModePopup from 'library/common/commonComponents/Popups/CloseKidsModePopup';
import { getKidsByGroupPortfolio } from 'library/api/kids';


import { GroupContext } from 'modules/Group/groupModule';
import defaultAvatar from 'resources/images/user/default-avatar.jpg';

import styles from './portfolioKid.module.scss';

export default function PortfolioKid({ userEmail, isNotInKidsMode }) {
  const { t } = useTranslation();
  const [kids, setKids] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const groupContext = useContext(GroupContext);
  const { groupInfo } = groupContext;
  const history = useHistory();
  
  useEffect(() => {
    getKidsByGroupPortfolio(groupInfo.groupId).then(res => {
      const sorted = res.data.sort((a, b) => a.firstName.localeCompare(b.firstName));
      setKids(sorted);
    });
  }, []);

  return (
    <>
      <h1>{t('Portfolio.Childrens portfolio')}</h1>
      <center>
        <div
          className={cn(styles.toolBarIcon)}
          onClick={() => {
            if (isNotInKidsMode) {
              history.push({
                pathname: window.location.pathname,
                search: '?mode=kids',
              });
            } else {
              setIsPopupOpen(true);
            }
          }}
        >
          <i className={isNotInKidsMode ? 'fa fa-lock' : 'fa fa-unlock'} />
        </div>
      </center>
      <br />
      <table>
        <tr>
          <td className={cn(styles.headerText, styles.headerTextWithThePadding)}>
            <div className={styles.headerTextWrapper}>{t('Portfolio.Name')}</div>
          </td>
          <td className={cn(styles.headerText, styles.headerText)}>
            <div className={styles.headerTextWrapper}>{t('Portfolio.Birthday')}</div>
          </td>
          <td className={cn(styles.headerText, styles.headerText)}>
            <div className={styles.headerTextWrapper}>{t('Portfolio.Parents')}</div>
          </td>
        </tr>

        {kids.map(kid => {
          const kidBDay = new Date(parseInt(`${kid.birthday}`));
          const kidBirthday = kidBDay.toLocaleString('de-DE', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          });

          return (
            <React.Fragment>
              <tr>
                <td>
                  <Link
                    to={{
                      pathname : kid.portfolioGroupId !== null
                        ? isNotInKidsMode
                          ? `/groups/${kid.portfolioGroupId}/whiteboard`
                          : `/groups/${kid.portfolioGroupId}/whiteboard?mode=kids`
                        : null,
                        state: { from: 'portfolio' },
                    }}
                    key={kid.portfolioGroupId}
                    className={styles.item}
                  >
                    <div className={styles.groupBox}>
                      <div className={styles.itemAvatar}>
                        <img src={kid.groupLogoUrl || defaultAvatar} style={{ width: '40px' }} />
                      </div>
                      <div className={cn(styles.itemName, 'notranslate')}>
                        {kid.name || `${kid.firstName} ${kid.lastName}`}
                      </div>
                    </div>
                  </Link>
                </td>
                <td className={styles.itemInfo}>
                  {kid.birthday !== null && (
                    <div className={cn(styles.itemName, 'notranslate')}>{kidBirthday}</div>
                  )}
                </td>
                <td className={styles.itemInfo}>
                  <div className={cn(styles.itemName, 'notranslate')}>
                    {`${kid.parent1FirstName} ${kid.parent1LastName}`}
                    {kid.parent2FirstName !== null && (
                      <>
                        <br />
                        {`${kid.parent2FirstName} ${kid.parent2LastName}`}
                      </>
                    )}
                  </div>
                </td>
              </tr>
            </React.Fragment>
          );
        })}
      </table>
      <CloseKidsModePopup
        isPopupOpen={isPopupOpen}
        setIsPopupOpen={setIsPopupOpen}
        userEmail={userEmail}
      />
    </>
  );
}

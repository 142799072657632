import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';

import styles from './confirmPopup.module.scss';

export default function ConfirmPopup({ isOpened, closePopup, onConfirm, text, yesText, noText, headerText, onConfirmNo, body }) {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingNo, setIsSubmittingNo] = useState(false);

  const handleConfirm = async () => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    await onConfirm();
    setIsSubmitting(false);
  };

  const handleConfirmNo = async () => {
    if(!onConfirmNo) {
      closePopup();
      return;
    }
    if (isSubmittingNo) {
      return;
    }
    setIsSubmittingNo(true);
    await onConfirmNo();
    setIsSubmittingNo(false);
  };

  return (
    <Popup
      isOpened={isOpened}
      closePopup={closePopup}
      size='extraSmall'
      header={headerText || <Trans i18nKey='Confirm.Confirm Action' />}
      footer={
        <div className={styles.footerNavigation}>
          <ButtonWithLoader isLoading={isSubmittingNo} onClick={handleConfirmNo}>{noText || t('Confirm.Cancel')}</ButtonWithLoader>
          <ButtonWithLoader onClick={handleConfirm} type='primary' isLoading={isSubmitting}>
            {yesText || t('Confirm.Confirm')}
          </ButtonWithLoader>
        </div>
      }
    >
      {
        body ? body : <div className={styles.container}>{text}</div>
      }
    </Popup>
  );
}

import React, { useState } from 'react';
import { Trans } from 'react-i18next';

import Card from 'library/common/commonComponents/Card';
import Tabs from 'library/common/commonComponents/Tabs';

import BasicFrame from './GroupBodySettingsFrames/BasicFrame';
import DeleteFrame from './GroupBodySettingsFrames/DeleteFrame';
import GroupHolidayCareFrame from './GroupBodySettingsFrames/GroupHolidayCareFrame';

import styles from './groupBodySettings.module.scss';

export default function GroupBodySettings() {
  const [activeTab, setActiveTab] = useState(0);
  const [deleteType, setDeleteType] = useState();

  const setDeleteMode = type => {
    setDeleteType(type);
    setActiveTab(tabs.length - 1);
  };

  let tabs = [
    {
      title: <Trans i18nKey='GroupBody.Settings.Basic' />,
      path: 'basic',
      component: <BasicFrame setDeleteMode={setDeleteMode} />,
    },
    {
      // title: <Trans i18nKey='GroupBody.Settings.HolidayCares' />, // replace title with this after adding GroupBody.Settings.HolidayCares key to translation table in BE
      title: 'Group Holiday Care',
      path: 'groupholidaycare',
      component: <GroupHolidayCareFrame />
    },
    {
      title: '',
      path: 'delete',
      component: <DeleteFrame closeDeleteMode={() => setActiveTab(0)} type={deleteType} />,
    },
  ];

  if (window.location.hostname.toLowerCase().includes('sbmasterclass.safe2connect.org') || window.location.hostname.toLowerCase().includes('indag.safe2connect.org')) {
    tabs = tabs.filter(tab => tab.path !== 'groupholidaycare');
  }

  return (
    <Card title={<Trans i18nKey='GroupBody.Settings.Title' />} className={styles.body}>
      <Tabs
        tabs={tabs}
        onChange={(_, index) => setActiveTab(index)}
        currentTab={tabs[activeTab].path}
      />
    </Card>
  );
}

import {
  SET_UNREAD_COUNTS_CHAT,
  SET_SELECTED_TAB_CHAT,
} from '../commonConstants/unreadCountChatConstant';

const initialState = {
  news: 0,
  absence: 0,
  selectedTab: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_UNREAD_COUNTS_CHAT:
      return {
        ...state,
        news: action.payload.news,
        absence: action.payload.absence,
      };
    case SET_SELECTED_TAB_CHAT:
      return {
        ...state,
        selectedTab: action.payload,
      };
    default:
      return state;
  }
};

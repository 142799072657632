import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';

import { getPublicGroupList, getPublicGroupListOutside } from 'library/api/groups';
import Card from 'library/common/commonComponents/Card';
import KitaPanelWrapper from 'library/common/commonComponents/KitaPanelWrapper';
import Loader from 'library/common/commonComponents/Loader';
import Wrapper from 'library/common/commonComponents/Wrapper';
import useSiteTitle from 'library/common/commonHooks/useSiteTitle';

import styles from './awoDashboardOverview.module.scss';

import { useHistory } from 'react-router-dom';
import Button from 'library/common/commonComponents/Buttons/Button';
import Checkbox from 'library/common/commonComponents/Checkbox';

const GroupNode = ({ city, kitaId, group, selectedItems, setSelectedItems }) => {
  const groupChecked = selectedItems[city]?.kitas[kitaId]?.groups[group.id] || false;

  const toggleGroupCheckbox = () => {
    setSelectedItems(prevSelected => {
      const prevCity = prevSelected[city] || { isChecked: false, kitas: {} };
      const prevKita = prevCity.kitas[kitaId] || { isChecked: false, groups: {} };
      const prevGroupChecked = prevKita.groups[group.id];
      const newGroupChecked = !prevGroupChecked;

      const newGroups = {
        ...prevKita.groups,
        [group.id]: newGroupChecked,
      };

      const allGroupsChecked = Object.values(newGroups).every(Boolean);

      const newKita = {
        ...prevKita,
        isChecked: allGroupsChecked,
        groups: newGroups,
      };

      const allKitasChecked = Object.values({
        ...prevCity.kitas,
        [kitaId]: newKita,
      }).every(kita => kita.isChecked);

      return {
        ...prevSelected,
        [city]: {
          ...prevCity,
          isChecked: allKitasChecked,
          kitas: {
            ...prevCity.kitas,
            [kitaId]: newKita,
          },
        },
      };
    });
  };

  return (
    <div className={styles.groupItem}>
      <Checkbox type='checkbox' isChecked={groupChecked} onChange={toggleGroupCheckbox} />
      <Link className={styles.groupLink} to={`/groups/${group.id}`}>
        {group.groupName}
      </Link>
    </div>
  );
};

const KitaNode = ({ city, kitaId, kita, groups, selectedItems, setSelectedItems, publicUser }) => {
  const [isOpen, setIsOpen] = useState(false);

  const kitaSelection = selectedItems[city]?.kitas[kitaId] || { isChecked: false, groups: {} };
  const isChecked = kitaSelection.isChecked;
  const groupSelections = kitaSelection.groups;

  useEffect(() => {
    if (Object.values(groupSelections).find(group => group == true) || kita === 'AWO Westliches Westfalen') {
      setIsOpen(true);
    }
  }, [groupSelections, kita]);

  const allGroupsSelected = Object.values(groupSelections).every(Boolean);
  const someGroupsSelected = Object.values(groupSelections).some(Boolean);
  const isIndeterminate = someGroupsSelected && !allGroupsSelected;

  const toggleKitaCheckbox = () => {
    setSelectedItems(prevSelected => {
      const prevCity = prevSelected[city];
      const prevKita = prevCity.kitas[kitaId];
      const newKitaChecked = !prevKita.isChecked;

      const newGroups = Object.keys(prevKita.groups).reduce((acc, groupId) => {
        acc[groupId] = newKitaChecked;
        return acc;
      }, {});

      const newKita = {
        ...prevKita,
        isChecked: newKitaChecked,
        groups: newGroups,
      };

      const allKitasChecked = Object.values({
        ...prevCity.kitas,
        [kitaId]: newKita,
      }).every(kita => kita.isChecked);

      return {
        ...prevSelected,
        [city]: {
          ...prevCity,
          isChecked: allKitasChecked,
          kitas: {
            ...prevCity.kitas,
            [kitaId]: newKita,
          },
        },
      };
    });
  };

  return (
    <>
      {kita !== 'AWO Westliches Westfalen' && (
        <div className={styles.kitaItem}>
          <Checkbox
            type='checkbox'
            name={kita}
            isChecked={isChecked}
            indeterminate={isIndeterminate}
            className={styles.checkbox}
            onChange={toggleKitaCheckbox}
          />
          {window.location.pathname == '/dashboard-overview' && !publicUser && (
            <div onClick={() => setIsOpen(!isOpen)}>
              <i className={isOpen ? 'fa fa-minus' : 'fa fa-plus'} />
            </div>
          )}
        </div>
      )}
      {window.location.pathname == '/dashboard-overview' && !publicUser && isOpen && (
        <div className={styles.contentPanel}>
          {groups.map(group => (
            <GroupNode
              key={group.id}
              city={city}
              kitaId={kitaId}
              group={group}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
            />
          ))}
        </div>
      )}
    </>
  );
};

const CityNode = ({ city, institutions, kitas, selectedItems, setSelectedItems, publicUser }) => {
  const [isOpen, setIsOpen] = useState(false);

  const citySelection = selectedItems[city] || { isChecked: false, kitas: {} };
  const cityKitas = citySelection.kitas;

  const allKitasSelected = Object.values(cityKitas).every(kita => kita.isChecked);
  const someKitasSelected = Object.values(cityKitas).some(
    kita => kita.isChecked || Object.values(kita.groups).some(Boolean),
  );
  const isIndeterminate = someKitasSelected && !allKitasSelected;

  const toggleAllKitas = () => {
    const newCityChecked = !citySelection.isChecked;

    const newKitas = Object.keys(cityKitas).reduce((acc, kitaId) => {
      const kita = cityKitas[kitaId];
      const newGroups = Object.keys(kita.groups).reduce((groupAcc, groupId) => {
        groupAcc[groupId] = newCityChecked;
        return groupAcc;
      }, {});
      acc[kitaId] = {
        ...kita,
        isChecked: newCityChecked,
        groups: newGroups,
      };
      return acc;
    }, {});

    setSelectedItems(prevSelected => ({
      ...prevSelected,
      [city]: {
        isChecked: newCityChecked,
        kitas: newKitas,
      },
    }));
  };

  useEffect(() => {
    if (!selectedItems[city]) {
      let selectedKitas = [];
      let selectedGroups = [];
      try {
        selectedKitas = JSON.parse(localStorage.getItem('selectedKitas') ?? '[]');
        selectedGroups = JSON.parse(localStorage.getItem('selectedGroups') ?? '[]');
      } catch (e) {
        console.warn('Failed trying to parse localStorage item:', e);
      }

      const initialKitas = Object.keys(institutions).reduce((kitaAcc, kita) => {
        const kitaId = getkitaId(kita);
        const groups = institutions[kita];
        const initialGroups = groups?.reduce((groupAcc, group) => {
          groupAcc[group?.id] =
            selectedGroups.includes(group.id.toString()) ||
            selectedKitas.includes(kitaId.toString());
          return groupAcc;
        }, {});
        kitaAcc[kitaId] = {
          isChecked: selectedKitas.includes(kitaId.toString()),
          groups: initialGroups,
        };
        return kitaAcc;
      }, {});
      setSelectedItems(prev => ({
        ...prev,
        [city]: {
          isChecked: false,
          kitas: initialKitas,
        },
      }));
    }
  }, [city, institutions]);

  const getkitaId = kitaName => {
    const kita = kitas.find(item => item.name === kitaName);
    return kita ? kita.id : null;
  };

  return (
    <div className='city-node'>
      <div className={styles.nodeHeader} onClick={() => setIsOpen(!isOpen)}>
        <i className={isOpen ? 'fa fa-minus' : 'fa fa-plus'} />
        {city}
        {someKitasSelected && <i className={cn('fa fa-filter', styles.fa)} />}
      </div>
      {isOpen && (
        <div className={styles.contentPanel}>
          <div className={styles.allKitas}>
            <Checkbox
              type='checkbox'
              name={`Alles aus ${city}`}
              isChecked={allKitasSelected}
              indeterminate={isIndeterminate}
              onChange={toggleAllKitas}
            />
            <label className={styles.levelText} />
          </div>
          {Object.keys(institutions).map(kita => {
            const kitaId = getkitaId(kita);
            return (
              <KitaNode
                key={kita}
                city={city}
                kitaId={kitaId}
                kita={kita}
                groups={institutions[kita]}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                publicUser={publicUser}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

const GroupTreeView = ({ groups, publicUser, setSelectedItems, t }) => {
  const history = useHistory();
  const { withCity } = organizeAndSortGroups(groups);
  const kitas = groups.map(group => ({ id: group.kitaId, name: group.kitaName }));
  const [selectedItems, setSelectedItemsState] = useState({});
  const [deletedFilter, setDeletedFilter] = useState({});

  const handleProceedClick = () => {
    const selectedKitaIds = [];
    const selectedGroupIds = [];

    Object.keys(selectedItems).forEach(city => {
      const cityData = selectedItems[city];
      Object.entries(cityData.kitas).forEach(([kitaId, kita]) => {
        if (kita.isChecked) {
          selectedKitaIds.push(kitaId);
        } else {
          Object.entries(kita.groups).forEach(([groupId, isChecked]) => {
            if (isChecked) {
              selectedGroupIds.push(groupId);
            }
          });
        }
      });
    });

    localStorage.setItem('selectedKitas', JSON.stringify(selectedKitaIds));
    localStorage.setItem('selectedGroups', JSON.stringify(selectedGroupIds));
    history.push('/dashboard');
  };

  const anyfilterActive = Object.values(selectedItems).some(
    city =>
      city.isChecked ||
      Object.values(city.kitas).some(
        kita => kita.isChecked || Object.values(kita.groups).some(Boolean),
      ),
  );

  const handleDeleteFilter = () => {
    setDeletedFilter(selectedItems);
    setSelectedItemsState({});
    localStorage.removeItem('selectedKitas');
    localStorage.removeItem('selectedGroups');
  };

  const handelRestoreFilter = () => {
    setSelectedItemsState(deletedFilter);
    setDeletedFilter({});
  };

  // Update the parent `selectedItems` state whenever it changes locally
  useEffect(() => {
    if (setSelectedItems != null) {
      setSelectedItems(selectedItems);
    }
  }, [selectedItems]);

  return (
    <div className='public-group-tree-view'>
      {!!Object.keys(withCity).length && (
        <Card className={styles.cardContainer}>
          <div className={styles.cardHeader}>
            <p className={styles.groupListTitle}>{t('AwoDashboardOverview.KitaOverview')}</p>
          </div>
          <br />
          {window.location.pathname == '/dashboard-overview' && (
            <>
              <Button type='awoww' onClick={handleProceedClick}>
                {t('AwoDashboardOverview.ApplyFilter')}
              </Button>
              {anyfilterActive && (
                <Button
                  type='awoww'
                  onClick={handleDeleteFilter}
                  className={styles.deleteFilterButton}
                >
                  {t('AwoDashboardOverview.DeleteFilter')}
                </Button>
              )}
              {Object.keys(deletedFilter).length > 0 && (
                <Button
                  type='awoww'
                  onClick={handelRestoreFilter}
                  className={styles.deleteFilterButton}
                >
                  {t('AwoDashboardOverview.RestoreFilter')}
                </Button>
              )}
            </>
          )}
          <br />
          <br />
          <div>
            {Object.keys(withCity).map(city => (
              <CityNode
                key={city}
                city={city}
                institutions={withCity[city]}
                kitas={kitas}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItemsState}
                publicUser={publicUser}
              />
            ))}
          </div>
          <br />
          {window.location.pathname == '/dashboard-overview' && (
            <>
              <Button type='awoww' onClick={handleProceedClick}>
                {t('AwoDashboardOverview.ApplyFilter')}
              </Button>
              {anyfilterActive && (
                <Button
                  type='awoww'
                  onClick={handleDeleteFilter}
                  className={styles.deleteFilterButton}
                >
                  {t('AwoDashboardOverview.DeleteFilter')}
                </Button>
              )}
              {Object.keys(deletedFilter).length > 0 && (
                <Button
                  type='awoww'
                  onClick={handelRestoreFilter}
                  className={styles.deleteFilterButton}
                >
                  {t('AwoDashboardOverview.RestoreFilter')}
                </Button>
              )}
            </>
          )}
        </Card>
      )}
    </div>
  );
};

const organizeAndSortGroups = groups => {
  const organized = {
    withCity: {},
    withoutCity: {},
    only: [],
  };

  // Organize groups into the appropriate categories
  groups.forEach(group => {
    const { kitaName, kitaBelongedCity } = group;

    if (kitaBelongedCity) {
      if (kitaName != null) {
        if (!organized.withCity[kitaBelongedCity]) {
          organized.withCity[kitaBelongedCity] = {};
        }
        if (!organized.withCity[kitaBelongedCity][kitaName]) {
          organized.withCity[kitaBelongedCity][kitaName] = [];
        }
        organized.withCity[kitaBelongedCity][kitaName].push(group);
      }
    }
  });

  // Sort groups with city: sort cities first, then kitas within cities, and then groups within kitas
  const sortedWithCity = Object.keys(organized.withCity)
    .sort((a, b) => a.localeCompare(b)) // Sort cities alphabetically
    .reduce((acc, city) => {
      // Sort kita names using a custom comparator:
      // Split the kita name into first name and the remaining part
      const sortedKitaNames = Object.keys(organized.withCity[city]).sort((a, b) => {
        const [firstA, ...restA] = a.split(" ");
        const [firstB, ...restB] = b.split(" ");
        const secondA = restA.join(" ");
        const secondB = restB.join(" ");
        const firstComparison = firstA.localeCompare(firstB);
        return firstComparison !== 0 ? firstComparison : secondA.localeCompare(secondB);
      });

      // Build the sorted kitas object
      const sortedKitas = sortedKitaNames.reduce((kitaAcc, kitaName) => {
        // Sort groups within each kita by groupName
        kitaAcc[kitaName] = organized.withCity[city][kitaName].sort((a, b) =>
          a.groupName.localeCompare(b.groupName)
        );
        return kitaAcc;
      }, {});

      acc[city] = sortedKitas;
      return acc;
    }, {});

  return {
    withCity: sortedWithCity,
    withoutCity: organized.withoutCity,
    only: organized.only,
  };
};

export default function AWODashboardOverview({
  publicUser,
  showBottomNotification,
  user,
  activeKita,
  setSelectedItems, // Updated prop
}) {
  const { t } = useTranslation();

  useSiteTitle('AwoWW profile Overview');
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const publicUserAWO = params.get('publicUserAWO');

  const publicUserAwo = publicUserAWO?.toLowerCase() === 'true';

  const [groups, setGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchPublicGroupList = useCallback(() => {
    setIsLoading(true);
    if (publicUser) {
      getPublicGroupListOutside().then(res => {
        const groups = res.data;
        setGroups(groups?.filter(item => item.privacy == false || item.privacy == null));
        setIsLoading(false);
      });
    } else {
      getPublicGroupList().then(res => {
        const groups = res.data;
        setGroups(groups?.filter(item => item.privacy == false || item.privacy == null));
        setIsLoading(false);
      });
    }
  }, [publicUser]);

  useEffect(() => {
    fetchPublicGroupList();
  }, [fetchPublicGroupList]);

  return (
    <KitaPanelWrapper>
      <Wrapper>
        <div className='public-group-list-page'>
          {isLoading ? (
            <Loader />
          ) : (
            <GroupTreeView
              groups={groups}
              publicUser={publicUserAwo}
              setSelectedItems={setSelectedItems}
              t={t} // Pass setSelectedItems
            />
          )}
        </div>
      </Wrapper>
    </KitaPanelWrapper>
  );
}

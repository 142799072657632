import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import style from './TablesEditor.module.scss';
import { saveTable } from 'library/api/tableEditor';
import { ActiveTable } from 'active-table-react';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import Button from 'library/common/commonComponents/Buttons/Button';
import Label from 'library/common/commonComponents/Label';
import Input from 'library/common/commonComponents/Inputs/Input';
import Popup from 'library/common/commonComponents/Popups/Popup';

export default function TablesEditor({ editTable, cancelEdit, reloadTables }) {
  const { t } = useTranslation();

  const [table, setTable] = useState(editTable);
  const [errors, setErrors] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isSavingAndClosing, setIsSavingAndClosing] = useState(false);

  // New states for Generate Results popup
  const [showGeneratePopup, setShowGeneratePopup] = useState(false);
  const [SOR, setSOR] = useState('');
  const [EOR, setEOR] = useState('');
  const [SOS, setSOS] = useState('');
  // EOS state removed; using the last column automatically
  const [resultsData, setResultsData] = useState([]); // each row: [Item, Score, "punkte", Platz]
  const [showResultsTable, setShowResultsTable] = useState(false);

  const tableRef = useRef(null);

  const save = close => {
    const curErrors = [];
    if (table.name.length === 0) {
      curErrors.push(t('FormsEditor.EmptyNameError'));
    }
    setErrors(curErrors);

    if (curErrors.length > 0) {
      return;
    }

    if (close) {
      setIsSavingAndClosing(true);
    } else {
      setIsSaving(true);
    }

    const data = {
      ...table,
      templateData: JSON.stringify(tableRef.current.getData()),
      columnsDetails: JSON.stringify(tableRef.current.getColumnsDetails()),
      template: false
    };

    saveTable(data)
      .then(res => {
        let templateData = JSON.parse(res.data.templateData);
        if (typeof templateData === 'string') {
          templateData = JSON.parse(templateData);
        }
        setTable({
          ...editTable,
          ...res.data,
          templateData,
        });
        store.dispatch(showBottomNotification(t('FormsEditor.SaveSuccess'), { isFail: false }));
        reloadTables();
        if (close) {
          cancelEdit();
        }
      })
      .catch(err => {
        store.dispatch(showBottomNotification(t('FormsEditor.SaveError'), { isFail: true }));
      })
      .finally(() => {
        setIsSavingAndClosing(false);
        setIsSaving(false);
      });
  };

  // Build columns settings for the main ActiveTable (unchanged)
  const cols =
    table.templateData[0]?.map((_, idx) => {
      const col = (table.columnsDetails && table.columnsDetails[idx]) ?? {};
      return {
        defaultColumnTypeName: col.typeName ?? '',
        cellStyle: col?.width ? { width: col.width } : null,
        headerName: table.templateData[0][idx] ?? '',
      };
    }) ?? [];

  const generateResults = () => {
    const startRecord = parseInt(SOR, 10);
    const endRecord = parseInt(EOR, 10);
    const startScore = parseInt(SOS, 10);

    if (isNaN(startRecord) || isNaN(endRecord) || isNaN(startScore)) {
      store.dispatch(showBottomNotification(t('Administration.InvalidInputError'), { isFail: true }));
      return;
    }
    const computedResults = [];
    // Loop over the rows from SOR to EOR (adjust the loop bounds as needed)
    for (let i = startRecord; i <= (endRecord + 1); i++) {
      const rowData = editTable.templateData[i - 1];
      const firstColumn = rowData ? rowData[0] : '';

      let scoreSum = 0;
      if (rowData) {
        // Sum from SOS to the last column of the row
        for (let j = startScore; j <= rowData.length; j++) {
          const cellValue = Number(rowData[j - 1]);
          if (!isNaN(cellValue)) {
            scoreSum += cellValue;
          }
        }
      }
      // Build row as [Item, Score, "punkte"]
      computedResults.push([firstColumn, scoreSum, "punkte"]);
    }
    // Sort results in descending order by the score (index 1)
    computedResults.sort((a, b) => b[1] - a[1]);

    // Dense ranking (Platz):
    let currentRank = 1;
    let previousScore = null;
    for (let i = 0; i < computedResults.length; i++) {
      const score = computedResults[i][1];
      if (previousScore === null || score < previousScore) {
        computedResults[i].push(currentRank);
        previousScore = score;
        currentRank++;
      } else {
        // score equals previousScore, assign same rank as previous row
        computedResults[i].push(currentRank - 1);
      }
    }
    // Now, computedResults rows are [Item, Score, "punkte", Platz]
    setResultsData(computedResults);
    setShowResultsTable(true);
  };

  // Reset the popup inputs when closing
  const closePopup = () => {
    setShowGeneratePopup(false);
    setEOR('');
    setSOR('');
    setSOS('');
    setShowResultsTable(false);
  };

  return (
    <div className={style.container}>
      <h1>{t('Administration.TableEditorTitle')}</h1>
      <div className={style.buttonsTopRight}>
        <Button onClick={cancelEdit}>
          <i className='fa fa-arrow-left' /> {t('Administration.TableEditorBackButton')}
        </Button>
        <ButtonWithLoader onClick={() => save(false)} isLoading={isSaving}>
          <i className='fa fa-floppy-o' /> {t('Administration.TableEditorSaveButton')}
        </ButtonWithLoader>
        <ButtonWithLoader onClick={() => save(true)} isLoading={isSavingAndClosing}>
          <i className='fa fa-floppy-o' /> {t('Administration.TableEditorSaveAndCloseButton')}
        </ButtonWithLoader>
        <Button onClick={() => setShowGeneratePopup(true)}>
          <i className='fa fa-calculator' /> {t('Administration.GenerateResultsButton')}
        </Button>
      </div>
      <div className={style.formDataContainer}>
        <Label type='input'>{t('Administration.TableEditorNameInputLabel')}</Label>
        <Input
          value={table.name}
          onChange={e =>
            setTable({
              ...table,
              name: e.target.value,
            })
          }
          className={style.formNameInput}
          placeholder={t('Administration.TableEditorNameInputPlaceholder')}
        />
        {errors.length > 0 && (
          <ul>
            {errors.map((e, idx) => (
              <li key={idx} className={style.errorMsg}>{e}</li>
            ))}
          </ul>
        )}
      </div>

      <div className={style.wrapper}>
        <ActiveTable
          data={editTable.templateData}
          customColumnsSettings={cols}
          headerStyles={{ default: { backgroundColor: '#d6d6d630' } }}
          ref={tableRef}
        />
      </div>

      <div className={style.bottomButtonsWrapper}>
        <ButtonWithLoader
          onClick={() => save(false)}
          isLoading={isSaving}
          className={style.bottomButton}
        >
          <i className='fa fa-floppy-o' /> {t('Administration.TableEditorSaveButton')}
        </ButtonWithLoader>
        <ButtonWithLoader
          onClick={() => save(true)}
          isLoading={isSavingAndClosing}
          className={style.bottomButton}
        >
          <i className='fa fa-floppy-o' /> {t('Administration.TableEditorSaveAndCloseButton')}
        </ButtonWithLoader>
      </div>

      <Popup
        isOpened={showGeneratePopup}
        closePopup={closePopup}
        size='medium'
        header={t('Administration.GenerateResultsTitle')}
        footer={
          !showResultsTable && (
            <div className={style.buttonsContainer}>
              <Button onClick={closePopup}>{t('Confirm.Cancel')}</Button>
              <ButtonWithLoader onClick={generateResults} type='primary'>
                {t('Confirm.Confirm')}
              </ButtonWithLoader>
            </div>
          )
        }
      >
        <div className={style.modalOverlay}>
          <div className={style.modalContent}>
            {!showResultsTable && (
              <>
                <div className={style.firstInputSection}>
                  <div className={style.inputRow}>
                    <Label>{t('Administration.StartOfRecordsLabel')} (SOR)</Label>
                    <Input
                      value={SOR}
                      className={style.inputItem}
                      onChange={e => setSOR(e.target.value)}
                      placeholder="e.g. 11"
                    />
                  </div>
                  <div className={style.inputRow}>
                    <Label>{t('Administration.EndOfRecordsLabel')} (EOR)</Label>
                    <Input
                      value={EOR}
                      className={style.inputItem}
                      onChange={e => setEOR(e.target.value)}
                      placeholder="e.g. 26"
                    />
                  </div>
                </div>
                <div className={style.firstInputSection}>
                  <div className={style.inputRow}>
                    <Label>{t('Administration.StartOfScoresLabel')} (SOS)</Label>
                    <Input
                      value={SOS}
                      className={style.inputItem}
                      onChange={e => setSOS(e.target.value)}
                      placeholder="e.g. 4"
                    />
                  </div>
                  {/* EOS input removed; summing until the row's last column */}
                </div>
              </>
            )}
            {resultsData.length > 0 && showResultsTable && (
              <div className={style.resultsTable}>
                {/* Build a computed table with an explicit header row */}
                <ActiveTable
                  data={[
                    [
                      t('Administration.ResultItemColumn'),
                      t('Administration.ResultScoreColumn'),
                      'Unit',
                      'Platz'
                    ],
                    ...resultsData
                  ]}
                  headerStyles={{ default: { backgroundColor: '#d6d6d630' } }}
                  isCellTextEditable={false}
                  />
              </div>
            )}
          </div>
        </div>
      </Popup>
    </div>
  );
}

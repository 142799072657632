import { Link } from 'react-router-dom';
import cn from 'classnames';
import React, { useState, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import { getUnpaidFoodOrders } from 'library/api/finAPI';
import {
  getMenuWithOrders,
  getSettings,
  parentsFoodOrder,
  saveSettingsPayload,
} from 'library/api/foodOrder';
import { sendEmail } from 'library/api/user';
import CheckBox from 'library/common/commonComponents/Checkbox';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import Loader from 'library/common/commonComponents/Loader';
import {
  formatDate,
  getWeekNumber,
  getSunday,
  getPreviousMonday,
  getNextMonday,
  getCurrentFrom,
  isOrderAllowed,
  orderByDateASC,
  getWeekDayIndexForGermanDateString,
  getWeekDayByDate,
  getDateFromGermanDateString,
} from 'library/utilities/foodOrder';

import ParentsFoodOrderMealItem from './ParentsFoodOrderMealItem';
import ParentsFoodOrderPendingPaymentsPopup from './ParentsFoodOrderPendingPaymentsPopup';
import styles from './parentsFoodOrder.module.scss';

export default function ParentsFoodOrder({ selectedChild, groupId, showBottomNotification }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [message, setMessage] = useState('');
  const [pendingPayments, setPendingPayments] = useState([]);
  const [from, setFrom] = useState(getCurrentFrom());
  const [mealChanges, setMealChanges] = useState({});
  const [didInitialLoad, setDidInitialLoad] = useState(false);
  const [monthlyMail, setMonthlyMail] = useState(false);
  const [mailOnSubmitting, setMailOnSubmitting] = useState(false);
  const [checkBox, setCheckBox] = useState(false);

  const to = getSunday(from);

  const displayDateStrings = [];
  for (let d = new Date(from.getTime()); d.getTime() <= to.getTime(); d.setDate(d.getDate() + 1)) {
    displayDateStrings.push(formatDate(d));
  }

  const now = new Date();
  const nextMonthBeginning =
    now.getDate() < 31
      ? new Date(now.getFullYear() + (now.getMonth() === 11 ? 1 : 0), (now.getMonth() + 1) % 12, 1)
      : new Date(now.getFullYear() + (now.getMonth() >= 10 ? 1 : 0), (now.getMonth() + 2) % 12, 1);
  const nextMonthEnd = new Date(
    nextMonthBeginning.getFullYear() + (nextMonthBeginning.getMonth() === 11 ? 1 : 0),
    (nextMonthBeginning.getMonth() + 1) % 12,
    0,
  );

  const initialMeal = {
    id: Math.random(),
    day: '',
    date: '',
    foods: [],
    isActive: true,
  };

  const getInitalKWFoodOrderMealItem = data => {
    orderByDateASC(data.foodMenus);
    const { defaultPrice } = data;
    const foodOrderMealItems = [];
    for (let i = 0; i < data.foodMenus.length; i++) {
      const { date, id, inactive } = data.foodMenus[i];
      const day = getWeekDayByDate(date, t);
      const foods = data.foodMenus[i].foods.map(food =>
        food.id === -1
          ? {
              ...food,
              price: defaultPrice,
            }
          : food,
      );
      foodOrderMealItems.push({
        ...initialMeal,
        id,
        day,
        date,
        foods,
        inactive,
      });
    }
    return foodOrderMealItems;
  };

  const [mealItems, dispatchMealItems] = useReducer(mealItemReducer, []);
  const [config, setConfig] = useState({
    lastCancelFoodOrderTime: null,
    lastFoodOrderTime: null,
    useOnlinePayment: false,
    usePreorder: false,
    defaultPrice: null,
    useWeeklyPreorder: false,
    enableCancellation: false,
    enableRepetition: false,
    noOfRepetition: null,
    orderTimeToday: null,
    orderTimeYesterday: null,
    orderTimeTwoDaysAgo: null,
    cancelTimeToday: null,
    cancelTimeYesterday: null,
    cancelTimeTwoDaysAgo: null,
    sendMailOnSubmitting: false,
    sendMonthlyMail: false,
  });

  useEffect(() => {
    const checkIfFirstDayOfMonth = () => {
      const date = new Date();
      const dayOfMonth = date.getDate();

      if (dayOfMonth === 1) {
        // Call your function here
        sendEmailOnTheFirst();
      }

      // Schedule the next check at the beginning of the next day
      const tomorrow = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
      const timeUntilTomorrow = tomorrow - date;
      setTimeout(checkIfFirstDayOfMonth, timeUntilTomorrow);
    };

    checkIfFirstDayOfMonth(); // Initial check

    return () => {
      clearTimeout(); // Cleanup on component unmount
    };
  }, []);

  const sendEmailOnTheFirst = () => {
    const currentDate = new Date();

    // Get the current month and year
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    // Get the previous month
    const previousMonth = currentMonth === 0 ? 11 : currentMonth - 1;
    const previousYear = currentMonth === 0 ? currentYear - 1 : currentYear;

    // Set the date to the first day of the previous month
    const firstDayOfPreviousMonth = new Date(previousYear, previousMonth, 1);
    const firstDayFormatted = firstDayOfPreviousMonth.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });

    // Set the date to the last day of the previous month
    const lastDayOfPreviousMonth = new Date(currentYear, currentMonth, 0);
    const lastDayFormatted = lastDayOfPreviousMonth.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });

    getMenuWithOrders(groupId, firstDayFormatted, lastDayFormatted, selectedChild.id).then(res => {
      let foodEmailArray = [];
      if (res != null && res.foodMenus != null) {
        res.foodMenus.forEach(item => {
          item.foods.forEach(element => {
            if (element.ordered) {
              foodEmailArray.push({
                date: item.date,
                day: item.day,
                name: element.name == null ? 'Gericht' : element.name,
                description: element.description,
                price: element.price ? Number(element.price).toFixed(2) + '€' : 0,
              });
            }
          });
        });
        if (monthlyMail) {
          sendEmail(selectedChild.id, foodEmailArray, 'first');
        }
      }
    });
  };

  const submitOrder = () => {
    if (isSaving) {
      return;
    }
    setIsSaving(true);

    const filteredMealItems = mealItems.filter(item => item.id >= 1);

    parentsFoodOrder(groupId, selectedChild.id, filteredMealItems)
      .then(() => {
        const foodEmailArray = [];
        filteredMealItems.forEach(item => {
          item.foods.forEach(element => {
            if (element.ordered) {
              foodEmailArray.push({
                date: item.date,
                day: item.day,
                name: element.name == null ? 'Gericht' : element.name,
                description: element.description,
                price: element.price ? Number(element.price).toFixed(2) + '€' : 0,
              });
            }
          });
        });
        if (mailOnSubmitting) {
          sendEmail(selectedChild.id, foodEmailArray, 'submit');
        }
        showBottomNotification(t('FoodOrder.Your order has been saved'));
        setMealChanges({});
        setIsSaving(false);
      })
      .catch(err => {
        console.log(err);
        setIsSaving(false);
      });
  };

  const registerMealChangeHelper = (newMealChanges, mealId, active) => {
    if (mealId in newMealChanges) {
      if (newMealChanges[mealId] !== active) {
        delete newMealChanges[mealId];
      }
    } else {
      newMealChanges[mealId] = active;
    }
  };

  const registerMealChange = (mealId, active) => {
    const newMealChanges = { ...mealChanges };
    registerMealChangeHelper(newMealChanges, mealId, active);
    setMealChanges(newMealChanges);
  };

  const registerMealChangesBulk = changes => {
    const newMealChanges = { ...mealChanges };
    changes.forEach(c => registerMealChangeHelper(newMealChanges, c.mealId, c.active));
    setMealChanges(newMealChanges);
  };

  const totalFoodOrdered = () => {
    let count = 0;
    mealItems.forEach(meal => {
      meal.foods.forEach(food => {
        if (displayDateStrings.includes(meal.date) && food.ordered) count++;
      });
    });
    return count;
  };

  const checkNumberOfMealsAreNotActive = prevMealId => {
    let checkTheSame = false;
    Object.keys(mealChanges).forEach(id => {
      if (mealChanges[id] && id == prevMealId) {
        checkTheSame = true;
        return;
      }
    });
    if (checkTheSame) return totalFoodOrdered() - 1;
    else return totalFoodOrdered();
  };

  const onToggleOrdered = (id, mealId, active) => {
    dispatchMealItems({ type: 'toggleOrdered', foodId: id, mealId });

    registerMealChange(mealId, active);
  };

  const onDecreaseCW = () => {
    setFrom(getPreviousMonday(from));
  };

  const onIncreaseCW = () => {
    setFrom(getNextMonday(from));
  };

  const selectAll = () => {
    const newChanges = [];

    const newMealItems = mealItems.map(meal => {
      console.log(meal);
      const mealDate = getDateFromGermanDateString(meal.date).getTime();

      let fromTime = new Date(from.getTime());
      fromTime.setHours(0, 0, 0, 0);
      fromTime = fromTime.getTime();

      let toTime = new Date(to.getTime());
      toTime.setHours(0, 0, 0, 0);
      toTime = toTime.getTime();

      if (!isOrderAllowed(meal.date, config) || mealDate < fromTime || mealDate > toTime) {
        return meal;
      }

      if (
        meal.foods &&
        meal.foods.length > 0 &&
        !meal.foods[0].ordered &&
        meal.inactive === false
      ) {
        newChanges.push({ mealId: meal.id, active: true });
      }

      if (meal.inactive === false) {
        return {
          ...meal,
          foods: meal.foods.map((food, index) =>
            index === 0 ? { ...food, ordered: true } : { ...food, ordered: false },
          ),
        };
      } else {
        return {
          ...meal,
        };
      }
    });
    console.log(newChanges);
    registerMealChangesBulk(newChanges);

    dispatchMealItems({ type: 'selectAll', newMealItems });
  };

  const getSettingsConfig = () => {
    getSettings(groupId).then(res => {
      setConfig(res.data);
      setMailOnSubmitting(res.data.sendMailOnSubmitting);
      setMonthlyMail(res.data.sendMonthlyMail);
      if (res.data.useOnlinePayment && res.data.useOnlinePayment === true) {
        getUnpaidFoodOrders(groupId).then(data => {
          console.log(data.data);
          setPendingPayments(data.data);
        });
      }
    });
  };

  // Query config
  useEffect(() => {
    getSettings(groupId).then(res => {
      if (res.data) {
        setConfig(res.data);
        setMailOnSubmitting(res.data.sendMailOnSubmitting);
        setMonthlyMail(res.data.sendMonthlyMail);
        if (res.data.useOnlinePayment && res.data.useOnlinePayment === true) {
          getUnpaidFoodOrders(groupId).then(data => {
            console.log(data.data);
            setPendingPayments(data.data);
          });
        }
      } else {
        config.defaultPrice = 0;
        config.orderTimeYesterday = true;
        config.cancelTimeYesterday = true;
        config.lastCancelFoodOrderTime = '23:59';
        config.lastFoodOrderTime = '23:59';
        config.orderTimeToday = false;
        config.cancelTimeToday = false;
        config.orderTimeTwoDaysAgo = false;
        config.cancelTimeTwoDaysAgo = false;
        saveSettingsPayload(groupId, config).then(res => {
          getSettingsConfig();
        });
      }
    });
  }, []);

  const changeConfig = data => {
    if (data === 'month') {
      config.sendMonthlyMail = !config.sendMonthlyMail;
    } else if (data === 'submit') {
      config.sendMailOnSubmitting = !config.sendMailOnSubmitting;
    }
    setMonthlyMail(config.sendMonthlyMail);
    setMailOnSubmitting(config.sendMailOnSubmitting);
    saveSettingsPayload(groupId, config).then(res => {});
  };

  const loadOrders = forceInitial => {
    setIsLoading(true);
    setMessage('');

    if (selectedChild) {
      getMenuWithOrders(
        groupId,
        !config.usePreorder || from.getTime() <= nextMonthBeginning.getTime()
          ? formatDate(from)
          : formatDate(nextMonthBeginning),
        !config.usePreorder || to.getTime() >= nextMonthEnd.getTime()
          ? formatDate(to)
          : formatDate(nextMonthEnd),
        selectedChild.id,
      ).then(res => {
        const { data } = res;
        // Remove duplicate menus (same date multiple times)
        const ids = data.foodMenus.map(o => o.date);
        const filtered = data.foodMenus.filter(({ date }, index) => !ids.includes(date, index + 1));

        const fiteredDataWithoutSatAndSun = {
          ...data,
          foodMenus: filtered.filter(
            meal =>
              getWeekDayIndexForGermanDateString(meal.date) !== 0 &&
              getWeekDayIndexForGermanDateString(meal.date) !== 6,
          ),
        };

        dispatchMealItems({
          type: !didInitialLoad || forceInitial ? 'setMealItemsInitial' : 'setMealItems',
          items: getInitalKWFoodOrderMealItem(fiteredDataWithoutSatAndSun),
        });
        setIsLoading(false);
        setDidInitialLoad(true);
      });
    } else {
      setMessage(t('FoodOrder.Please register first a kid'));
    }
  };

  useEffect(() => {
    console.log(config);
    if (config.hasOwnProperty('naturalId')) {
      loadOrders(false);
    }
  }, [from, config]);

  useEffect(() => {
    if (config.hasOwnProperty('naturalId')) {
      loadOrders(true);
    }
  }, [selectedChild]);

  return (
    <div>
      {pendingPayments.length > 0 && (
        <ParentsFoodOrderPendingPaymentsPopup
          isOpened={pendingPayments.length > 0}
          closePopup={() => {
            window.location.reload();
          }}
          pendingPayments={pendingPayments}
          groupId={groupId}
        />
      )}
      <div className={styles.cwnavigationContainer}>
        <Button onClick={() => onDecreaseCW()}>
          <i className='fa fa-chevron-left' />
        </Button>
        <h3 className={styles.calendarWeek}>
          KW {getWeekNumber(from)[1]} : {formatDate(from)} - {formatDate(to)}
        </h3>
        <Button onClick={() => onIncreaseCW()}>
          <i className='fa fa-chevron-right' />
        </Button>
      </div>
      <br />
      <div className={styles.mailButton}>
        <h2> {t('FoodOrder.meal orders')}</h2>
        <div className={styles.checkBoxButton}>
          <Button onClick={() => setCheckBox(!checkBox)}>
            <i className='fa fa-envelope' />
            &nbsp;
            <i className='fa fa-caret-down' />
          </Button>
        </div>
        {checkBox && (
          <div className={styles.singleCheckbox}>
            <CheckBox
              className={styles.checkbox}
              name={t('FoodOrder.Send Mail On Submitting')}
              isChecked={mailOnSubmitting}
              onChange={() => changeConfig('submit')}
            />
            <CheckBox
              className={styles.checkbox}
              name={t('FoodOrder.Send Mail Monthly')}
              isChecked={monthlyMail}
              onChange={() => changeConfig('month')}
            />
          </div>
        )}
      </div>
      <br />
      {!config.usePreorder && (
        <Button type='secondary' onClick={() => selectAll()}>
          {t('FoodOrder.Select all')} <i className='fa fa-check' />
        </Button>
      )}
      <br />
      {message && (
        <Link
          className={styles.link}
          to={{
            pathname: '/account-settings/profile/general',
            automaticallyDisplayKidCreationScreen: true,
          }}
        >
          <p className={styles.error}>{message}</p>
        </Link>
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <br />
          <div className={styles.mealContainer}>
            {mealItems.length > 0 ? (
              mealItems.map(meal => {
                if (displayDateStrings.includes(meal.date)) {
                  return (
                    <ParentsFoodOrderMealItem
                      key={meal.id}
                      id={meal.id}
                      day={meal.day}
                      date={meal.date}
                      ordered={meal.ordered}
                      foods={meal.foods}
                      inactive={meal.inactive}
                      onToggleOrdered={onToggleOrdered}
                      config={config}
                      showBottomNotification={showBottomNotification}
                      checkNumberOfMealsAreNotActive={checkNumberOfMealsAreNotActive}
                      allowedNumberOfOrders={selectedChild.foodOrdersPerWeek}
                    />
                  );
                }

                return null;
              })
            ) : (
              <>
                <strong>
                  <i className={cn('fa fa-info-circle', styles.mr1)} />
                  {t('FoodOrder.No meals available')}
                </strong>
                <br /> <br />
              </>
            )}
          </div>
          <ButtonWithLoader
            type='primary'
            onClick={() => submitOrder()}
            isLoading={isSaving}
            disabled={Object.keys(mealChanges).length === 0}
          >
            {t('FoodOrder.Submit order')}
          </ButtonWithLoader>
        </>
      )}
    </div>
  );
}

export function mealItemReducer(state, action) {
  switch (action.type) {
    case 'setMealItemsInitial':
      return action.items;
    case 'setMealItems':
      return [
        ...state,
        ...action.items.filter(m => state.filter(m2 => m.date === m2.date).length === 0),
      ];
    case 'selectAll':
      return action.newMealItems;
    case 'selectAllMonthly':
      return action.newMealItems;
    case 'toggleOrdered':
      return state.map(meal =>
        meal.id === action.mealId
          ? {
              ...meal,
              foods: meal.foods.map(food =>
                food.id === action.foodId
                  ? { ...food, ordered: !food.ordered }
                  : { ...food, ordered: false },
              ),
            }
          : meal,
      );
    case 'insertMealItems':
      return state.map(meal =>
        meal.id === action.mealId
          ? {
              ...meal,
              foods: meal.foods.map(food =>
                food.id === action.foodId ? { ...food, ordered: !food.ordered } : food,
              ),
            }
          : meal,
      );
    default:
      return state;
  }
}

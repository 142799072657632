import React, { useState, useContext, useMemo, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import Speech from 'react-speech';

import { updateCalendarEvent } from 'library/api/calendar';
import { reportPost } from 'library/api/groups';
import {
  endParentSurvey,
  resetParentSurvey,
  publishParentSurvey,
  copyParentSurveyToGroups,
} from 'library/api/parentSurveys';
import {
  changePostNotifyStatus,
  unpinUserPost,
  unpinGroupPost,
  pinUserPost,
  pinGroupPost,
  updatePermanentStatus,
  updateReturnReceiptStatus,
  reportToSuperAdmin,
  createPostTemplate,
  deletePostTemplate,
  getPostTemplate,
  toggleForbidComments,
  updatePost,
  togglePublicVisible,
  toggleAllowMemberComment,
} from 'library/api/posts';
import { onSignaturePostEnabled } from 'library/api/signature';
import { unvoteForSurvey, updateSurvey } from 'library/api/surveys';
import { isAwoWW } from 'library/api/tenantConfig';
import { goToKita } from 'library/common/commonActions/kitaActions';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import MoreBtn from 'library/common/commonComponents/Buttons/MoreBtn';
import Checkbox from 'library/common/commonComponents/Checkbox';
import ContentAfterMount from 'library/common/commonComponents/ContentAfterMount';
import Input from 'library/common/commonComponents/Inputs/Input';
import Label from 'library/common/commonComponents/Label';
import Loader from 'library/common/commonComponents/Loader';
import ConfirmPopup from 'library/common/commonComponents/Popups/ConfirmPopup';
import DeletePopup from 'library/common/commonComponents/Popups/DeletePopup';
import MultiGroupSelectionPopup from 'library/common/commonComponents/Popups/MultiGroupSelectionPopup';
import MultiUserSignaturePopup from 'library/common/commonComponents/Popups/MultiUserSignaturePopup';
import Popup from 'library/common/commonComponents/Popups/Popup';
import ReturnReceiptPopup from 'library/common/commonComponents/Popups/ReturnReceiptPopup';
import ProfileAvatar from 'library/common/commonComponents/ProfileAvatar';
import Tooltip from 'library/common/commonComponents/Tooltip';
import TooltipWithChildren from 'library/common/commonComponents/TooltipWithChildren';
import { timeSince, getUpdatedTime, formatTimestampToGermanTime } from 'library/utilities/date';
import { getLetterInLogo } from 'library/utilities/groups';
import { createAndDownloadPDF } from 'library/utilities/parentSurveys';
import { getCanBePublicStatus } from 'library/utilities/posts';
import { changeOrderIfSurvey } from 'library/utilities/surveys';
import { getCuttedString, getUserLogoUrl, isPublicUser } from 'library/utilities/user';
import { GroupContext } from 'modules/Group/groupModule';
import store from 'main/store/configureStore';

import styles from './feedItemHeader.module.scss';

FeedItemHeader.defaultProps = {
  canBePublic: true,
};

export default function FeedItemHeader({
  groups,
  id,
  name,
  createdAt,
  editedAt,
  userlink,
  postlink,
  privatePost,
  updateFeedById,
  isEditing,
  setIsEditing,
  onDelete,
  isAuthoredByCurrentUser,
  group,
  postType,
  canceled,
  eventType,
  inCalendarModule,
  canUserVote,
  endTimeDate,
  endTimeStatus,
  startTimeDate,
  startTimeStatus,
  survey,
  parentSurvey,
  taskStatus,
  end,
  title,
  usersTitle,
  taskListId,
  description,
  isScheduling,
  allDay,
  start,
  addToCalendar,
  assignedUsersList,
  responsibleUsersList,
  reviewRequired,
  checklist,
  files,
  user,
  notify,
  firstName,
  lastName,
  pinned,
  isInProfileStream,
  isOnDashboard,
  userId,
  activeKita,
  permanent,
  returnReceipt,
  rrm,
  isSigned,
  text,
  commentsForbidden,
  kitaId,
  publicationTime,
  hidePost,
  onHidePost,
  publicVisible,
  isLikeFeatureAvailableInGroup,
  likeFeatureEnabled,
  reusableParentSurvey,
  setReusableParentSurvey,
  kitaName,
  showKitaInfo,
  hideDay,
  onHideDay,
  onReportToAdmin,
  isReported,
  unsetReported,
  allowMemberComment,
  repeatType,
  ...avatar
}) {
  const canBePublic = getCanBePublicStatus(group);
  const { t } = useTranslation();
  const [isOpened, setIsOpened] = useState(false);
  const [isGroupPopupOpened, setIsGroupPopupOpened] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isCopying, setIsCopying] = useState(false);
  const [isTemplate, setIsTemplate] = useState(false);
  const [isTemplateLoading, setIsTemplateLoading] = useState(false);
  const [templatePopupOpen, setTemplatePopupOpen] = useState(false);
  const [isSubmittingTemplate, setIsSubmittingTemplate] = useState(false);
  const [templateName, setTemplateName] = useState('');
  const [templateNameError, setTemplateNameError] = useState(false);
  const [isGlobalTemplate, setIsGlobalTemplate] = useState(false);
  const [showStart, setShowStart] = useState(
    startTimeStatus === true && startTimeDate !== null && Date.now() < startTimeDate,
  );

  const history = useHistory();

  const isResponsible = useMemo(
    () => responsibleUsersList && responsibleUsersList.find(item => item.id === user.id),
    // eslint-disable-next-line
    [responsibleUsersList],
  );
  const canEditTask =
    isAuthoredByCurrentUser ||
    (assignedUsersList && !assignedUsersList.length && group && group.processUnassignedTask) ||
    isResponsible;

  const canCreateCommentStatus = !group || (group && group.createCommentStatus);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        showStart &&
        startTimeStatus === true &&
        startTimeDate !== null &&
        Date.now() > startTimeDate
      ) {
        setShowStart(false);
      }
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  const togglePostPrivate = async currentStatus => {
    setIsSubmiting(true);

    await updateFeedById(
      id,
      {
        privatePost: !currentStatus,
      },
      { onlyUpdatePrivatePost: true },
    ).then(() => {
      setDropdownOptions(
        dropdownOptions(
          permanent,
          returnReceipt,
          commentsForbidden,
          !currentStatus,
          likeFeatureEnabled,
          publicVisible,
          allowMemberComment,
        ),
      );
    });

    setIsSubmiting(false);
  };

  const toggleLikeButtonEnabled = async currentStatus => {
    setIsSubmiting(true);

    await updateFeedById(id, {
      likeFeatureEnabled: !currentStatus,
    }).then(() => {
      setDropdownOptions(
        dropdownOptions(
          permanent,
          returnReceipt,
          commentsForbidden,
          privatePost,
          !currentStatus,
          publicVisible,
          allowMemberComment,
        ),
      );
    });

    setIsSubmiting(false);
  };

  const changePermanent = (currentStatus, returnReceiptStatus, post) => {
    let success = true;
    Promise.all([
      updatePermanentStatus(post),
      updateFeedById(id, { permanent: !currentStatus }, { withoutRequest: true }),
    ])
      .then(() => {
        setDropdownOptions(
          dropdownOptions(
            !currentStatus,
            returnReceiptStatus,
            commentsForbidden,
            privatePost,
            likeFeatureEnabled,
            publicVisible,
            allowMemberComment,
          ),
        );
      })
      .catch(() => {
        success = false;
      })
      .finally(() => {
        store.dispatch(
          showBottomNotification(
            <Trans i18nKey={`BottomNotifications.${success ? 'Saved' : 'Something went wrong'}`} />,
            { isFail: !success },
          ),
        );
      });
  };

  const changeReturnReceipt = (currentStatus, permanentStatus, post) => {
    let success = true;
    Promise.all([
      updateReturnReceiptStatus(post),
      updateFeedById(id, { returnReceipt: !currentStatus }, { withoutRequest: true }),
    ])
      .then(() => {
        setDropdownOptions(
          dropdownOptions(
            permanentStatus,
            !currentStatus,
            commentsForbidden,
            privatePost,
            likeFeatureEnabled,
            publicVisible,
            allowMemberComment,
          ),
        );
      })
      .catch(() => {
        success = false;
      })
      .finally(() => {
        store.dispatch(
          showBottomNotification(
            <Trans i18nKey={`BottomNotifications.${success ? 'Saved' : 'Something went wrong'}`} />,
            { isFail: !success },
          ),
        );
      });
  };

  const changeForbidComments = (currentStatus, postId) => {
    let success = true;
    Promise.all([
      toggleForbidComments(postId).then(() => {
        updateFeedById(id, { commentsForbidden: !currentStatus }, { withoutRequest: true });
      }),
    ])
      .then(() => {
        setDropdownOptions(
          dropdownOptions(
            permanent,
            returnReceipt,
            !currentStatus,
            privatePost,
            likeFeatureEnabled,
            publicVisible,
            allowMemberComment,
          ),
        );
      })
      .catch(() => {
        success = false;
      })
      .finally(() => {
        store.dispatch(
          showBottomNotification(
            <Trans i18nKey={`BottomNotifications.${success ? 'Saved' : 'Something went wrong'}`} />,
            { isFail: !success },
          ),
        );
      });
  };

  const changeAllowMemberComment  = (currentStatus, postId) => {
    let success = true;
    Promise.all([
      toggleAllowMemberComment(postId).then(() => {
        updateFeedById(id, { allowMemberComment: !currentStatus }, { withoutRequest: true });
      }),
    ])
      .then(() => {
        setDropdownOptions(
          dropdownOptions(
            permanent,
            returnReceipt,
            commentsForbidden,
            privatePost,
            likeFeatureEnabled,
            publicVisible,
            !currentStatus,
          ),
        );
      })
      .catch(() => {
        success = false;
      })
      .finally(() => {
        store.dispatch(
          showBottomNotification(
            <Trans i18nKey={`BottomNotifications.${success ? 'Saved' : 'Something went wrong'}`} />,
            { isFail: !success },
          ),
        );
      });
  }

  const handleHidePost = (currentStatus, postId) => {
    onHidePost(currentStatus, postId);
  };

  const handlePublicVisible = (currentStatus, postId) => {
    let success = true;
    Promise.all([
      togglePublicVisible(postId).then(() => {
        updateFeedById(id, { publicVisible: !currentStatus }, { withoutRequest: true });
      }),
    ])
      .then(() => {
        setDropdownOptions(
          dropdownOptions(
            permanent,
            returnReceipt,
            commentsForbidden,
            privatePost,
            likeFeatureEnabled,
            !currentStatus,
            allowMemberComment,
          ),
        );
      })
      .catch(() => {
        success = false;
      })
      .finally(() => {
        store.dispatch(
          showBottomNotification(
            <Trans i18nKey={`BottomNotifications.${success ? 'Saved' : 'Something went wrong'}`} />,
            { isFail: !success },
          ),
        );
      });
  };

  const handleUnsetReportedPost = (id) => {
    setDropdownOptions(pre => pre.filter(x => x.type !== 'unsetReportedPost'));
    unsetReported(id);
  }

  const initialPopup = {
    isOpen: false,
    onAccept: null,
    text: '',
  };
  const [popup, setPopup] = useState(initialPopup);

  const handleCopySurvey = async selectedGroups => {
    const groupIds = selectedGroups.map(group => `groupIds=${group.id}`).join('&');
    setIsCopying(true);
    copyParentSurveyToGroups(parentSurvey.id, groupIds)
      .then(() => {
        setIsCopying(false);
        store.dispatch(
          showBottomNotification(t('ParentSurvey.CopySuccess'), {
            isFail: false,
          }),
        );
      })
      .catch(() => {
        setIsCopying(false);
        store.dispatch(
          showBottomNotification(t('ParentSurvey.CopyError'), {
            isFail: true,
          }),
        );
      });
  };

  const handleCloseTemplatePopup = () => {
    setIsTemplate(false);
    setTemplatePopupOpen(false);
    setTemplateNameError(false);
    setTemplateName('');
    setIsGlobalTemplate(false);
  };

  const handleDeleteTemplate = () => {
    setIsSubmittingTemplate(true);
    deletePostTemplate(id)
      .then(() => {
        store.dispatch(
          showBottomNotification(t('PostTemplatePopup.DeleteSuccess'), {
            isFail: false,
          }),
        );
      })
      .catch(() => {
        store.dispatch(
          showBottomNotification(t('PostTemplatePopup.DeleteError'), {
            isFail: true,
          }),
        );
      })
      .finally(() => {
        setIsSubmittingTemplate(false);
        handleCloseTemplatePopup();
      });
  };

  const handleOpenTemplatePopup = async () => {
    setIsTemplateLoading(true);
    setTemplatePopupOpen(true);
    const res = await getPostTemplate(id);
    setIsTemplate(res.data !== null);
    setIsTemplateLoading(false);
  }

  const reportToAdmin = (id, groupID, name, kitaId) => {
    onReportToAdmin(id, groupID, name, kitaId);
  };

  const changeSurveyOpenStatus = async (postId) => {
    setIsSubmiting(true);
    const { data } = await updateSurvey(postId, {
      ...survey,
      surveyOpenStatus: !survey.surveyOpenStatus,
    });
    setIsSubmiting(false);
    const newSurvey = {
      ...survey,
      surveyOpenStatus: !survey.surveyOpenStatus,
    }
    setDropdownOptions(
      dropdownOptions(
        permanent,
        returnReceipt,
        commentsForbidden,
        privatePost,
        likeFeatureEnabled,
        publicVisible,
        allowMemberComment,
        newSurvey,
      ),
    );
    updateFeedById(postId, data, { withoutRequest: true });
  }

  const handleAddTemplateSave = () => {
    setIsSubmittingTemplate(true);

    if (templateName == null || templateName === '') {
      setTemplateNameError(true);
      setIsSubmittingTemplate(false);
      return;
    }
    setTemplateNameError(false);
    const templateData = {
      templateName,
      isGlobalTemplate,
    };

    createPostTemplate(id, templateData)
      .then(res => {
        setIsSubmittingTemplate(false);
        handleCloseTemplatePopup();
        store.dispatch(
          showBottomNotification(t('PostTemplatePopup.SaveSuccess'), {
            isFail: false,
          }),
        );
      })
      .catch(() => {
        setIsSubmittingTemplate(false);
        store.dispatch(
          showBottomNotification(t('PostTemplatePopup.SaveError'), {
            isFail: true,
          }),
        );
      });
  };

  const dropdownOptions = (
    permanento,
    returnReceipto,
    commentsForbiddenStatus,
    privatePostStatus,
    likeFeatureEnabledStatus,
    publicVisible,
    allowMemberComment,
    survey
  ) =>
    getDropdownOptions({
      isAuthoredByCurrentUser,
      setIsOpened,
      setIsGroupPopupOpened,
      setReusableParentSurvey,
      isEditing,
      setIsEditing,
      updateFeedById,
      privatePostStatus,
      id,
      canBePublic,
      t,
      postType,
      canUserVote,
      survey,
      parentSurvey,
      canceled,
      inCalendarModule,
      group,
      title,
      taskListId,
      description,
      isScheduling,
      allDay,
      start,
      end,
      addToCalendar,
      assignedUsersList,
      responsibleUsersList,
      reviewRequired,
      checklist,
      files,
      canEditTask,
      notify,
      pinned,
      user,
      isInProfileStream,
      isOnDashboard,
      setIsSubmiting,
      permanento,
      returnReceipto,
      changePermanent,
      changeReturnReceipt,
      setPopup,
      initialPopup,
      text,
      activeKita,
      isLikeFeatureAvailableInGroup,
      likeFeatureEnabledStatus,
      commentsForbiddenStatus,
      changeForbidComments,
      togglePostPrivate,
      toggleLikeButtonEnabled,
      handleOpenTemplatePopup,
      reportToAdmin,
      userId,
      changeSurveyOpenStatus,
      hidePost,
      handleHidePost,
      publicVisible,
      handlePublicVisible,
      hideDay,
      onHideDay,
      isReported,
      unsetReported: handleUnsetReportedPost,
      kitaId,
      canCreateCommentStatus,
      allowMemberComment,
      changeAllowMemberComment,
    });

  function stripHtml(html) {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }

  text = stripHtml(text);

  const [dropdownOptionsState, setDropdownOptions] = useState(
    dropdownOptions(
      permanent,
      returnReceipt,
      commentsForbidden,
      privatePost,
      likeFeatureEnabled,
      publicVisible,
      allowMemberComment,
      survey,
    ),
  );
  const [isReturnReceiptPopupOpened, setIsReturnReceiptOpened] = useState(false);

  const [isMultiUserSignatureOpened, setIsMultiUserSignatureOpened] = useState(false);

  const { langCode } = user;

  const date = timeSince({
    date: new Date(publicationTime || createdAt),
    langCode,
    isInTheFuture: !!publicationTime,
  });
  const dateNow = new Date();

  const groupContext = useContext(GroupContext);
  const isInGroupPage = group && !groupContext;

  const handleDeleteBtnClick = async () => {
    setIsSubmiting(true);
    await onDelete();
    setIsSubmiting(false);
    setIsOpened(false);
  };

  const profileLogo = {
    logoUrl: avatar ? avatar.logoUrl : user.logoUrl,
    profileLogoXCoordinate: avatar ? avatar.profileLogoXCoordinate : user.profileLogoXCoordinate,
    profileLogoYCoordinate: avatar ? avatar.profileLogoYCoordinate : user.profileLogoYCoordinate,
    profileLogoWidth: avatar ? avatar.profileLogoWidth : user.profileLogoWidth,
    profileLogoAspect: avatar ? avatar.profileLogoAspect : user.profileLogoAspect,
  };

  return (
    <div className={styles.header}>
      <Popup
        isOpened={templatePopupOpen}
        closePopup={handleCloseTemplatePopup}
        header={t('PostTemplatePopup.PostTemplatePopupHeader')}
        body={
          isTemplateLoading ? (
            <Loader />
          ) : isTemplate ? (
            <div>
              <p>{t('PostTemplatePopup.DeletePostTemplate')}</p>
            </div>
          ) : (
            <div>
              <p>{t('PostTemplatePopup.CreatePostTemplate')}</p>
              <Input
                value={templateName}
                placeholder={t('PostTemplatePopup.NamePlaceholder')}
                onChange={e => setTemplateName(e.target.value)}
              />
              {group && group.groupAdminStatus && (
                <Checkbox
                  isChecked={isGlobalTemplate}
                  onChange={() => setIsGlobalTemplate(!isGlobalTemplate)}
                  name={t('PostTemplatePopup.isTemplateGlobal')}
                />
              )}
              {templateNameError && (
                <p style={{ color: 'red' }}>{t('PostTemplatePopup.NameError')}</p>
              )}
            </div>
          )
        }
        footer={
          <div className={styles.popupBtns}>
            <Button
              onClick={handleCloseTemplatePopup}
            >
              {t('Popup.Cancel')}
            </Button>
            <ButtonWithLoader
              onClick={isTemplate ? handleDeleteTemplate : handleAddTemplateSave}
              type='primary'
              isLoading={isSubmittingTemplate}
            >
              {isTemplate ? t('Popup.Delete') : t('Popup.Save')}
            </ButtonWithLoader>
          </div>
        }
      />
      <DeletePopup
        isOpened={isOpened}
        closePopup={() => setIsOpened(false)}
        onDeleteBtnClick={handleDeleteBtnClick}
        headerText={<Trans i18nKey='Post.Confirm post deletion.Title' />}
        bodyText={t('Post.Confirm post deletion.Text')}
        isSubmiting={isSubmiting}
      />
      <MultiGroupSelectionPopup
        groups={groups?.filter(item => item.id !== group?.groupId) || []}
        isLoading={isCopying}
        isOpen={isGroupPopupOpened}
        setOpen={setIsGroupPopupOpened}
        handleCopy={handleCopySurvey}
      />
      {isReturnReceiptPopupOpened && (
        <div>
          <ReturnReceiptPopup
            isOpened={isReturnReceiptPopupOpened}
            closePopup={() => setIsReturnReceiptOpened(false)}
            title={t('Post.Return receipt')}
            users={rrm}
            group={group.groupId}
            postId={id}
          />
        </div>
      )}

      {isMultiUserSignatureOpened && (
        <div>
          <MultiUserSignaturePopup
            isOpened={isMultiUserSignatureOpened}
            activeKita={activeKita}
            closePopup={() => setIsMultiUserSignatureOpened(false)}
            title={t('Post.Signature')}
            group={group}
            files={files}
            currentUser={user}
          />
        </div>
      )}

      <ConfirmPopup
        isOpened={popup.isOpen}
        closePopup={() => setPopup(initialPopup)}
        onConfirm={popup.onAccept}
        text={popup.text}
      />

      {window.location.hostname.toLowerCase().includes('staging2.safe2connect.org') ||
        window.location.hostname.toLowerCase().includes('awo-immer-dabei.safe2connect.org') ? (
        <div className={styles.avatarContainer}>
          <ContentAfterMount>
            <Link to={'/groups/' + group?.groupId}>
              <div style={{ backgroundColor: group?.colorCode }} className={styles.groupAvatar}>
                {group && group.logoUrl ? (
                  <img src={group.logoUrl} alt='logo' />
                ) : (
                  <div className={styles.groupAvatarLetter}>
                    {group && group.groupName && getLetterInLogo(group.groupName)}
                  </div>
                )}
              </div>
            </Link>
          </ContentAfterMount>
        </div>
      ) : (
        <div className={styles.avatarContainer}>
          <div className={styles.avatar}>
            {!isPublicUser(user) ? (
              <ContentAfterMount>
                <ProfileAvatar
                  image={{ ...profileLogo, logoUrl: getUserLogoUrl(userId) }}
                  id={userId}
                />
              </ContentAfterMount>
            ) : (
              <ContentAfterMount>
                <ProfileAvatar url={group?.logoUrl ? group.logoUrl : activeKita.kitaLogoUrl} />
              </ContentAfterMount>
            )}
          </div>
          <ContentAfterMount>
            {isInGroupPage && (
              <Link to={'/groups/' + group.groupId}>
                <div style={{ backgroundColor: group.colorCode }} className={styles.avatarGroup}>
                  {group.logoUrl ? (
                    <img src={group.logoUrl} alt='logo' />
                  ) : (
                    <div className={styles.avatarGroupLetter}>
                      {group.groupName && getLetterInLogo(group.groupName)}
                    </div>
                  )}
                </div>
              </Link>
            )}
          </ContentAfterMount>
        </div>
      )}

      <div className={styles.wrapper}>
        <div className={styles.wrapperTop}>
          <div className={styles.name}>
            <TooltipWithChildren userId={userId}>
              {!isPublicUser(user) && (
                <Link to={userlink} className='notranslate'>
                  {getCuttedString(typeof name === 'undefined' ? user.name : name, 20) ||
                    `${getCuttedString(
                      typeof firstName === 'undefined' ? user.firstName : firstName,
                      20,
                    )} ${getCuttedString(
                      typeof lastName === 'undefined' ? user.lastName : lastName,
                      20,
                    )}`}
                </Link>
              )}
              &nbsp;
              {((usersTitle && usersTitle !== '') ||
                (user.title && user.title !== '' && user?.id == userId)
              ) && (
                window.location.hostname.toLowerCase().includes('indag.safe2connect.org') ||
                window.location.hostname.toLowerCase().includes('staging1.safe2connect.org') ||
                isAwoWW()) ?
                `(${getCuttedString(usersTitle ? usersTitle : user?.id == userId ? user?.title : "" , 80)})`
                : ''
              }
            </TooltipWithChildren>
            {(isInGroupPage || inCalendarModule) && (
              <div className={styles.groupName}>
                <i className='fa fa-caret-right' />
                <Link
                  className={isAwoWW() ? styles.blueLinkColor : styles.normal}
                  onClick={() => {
                    store.dispatch(goToKita(kitaId));
                    history.push(group && group.groupId ? '/groups/' + group.groupId : userlink);
                  }}
                >
                  {group && group.groupName ? group.groupName : name}
                </Link>
              </div>
            )}
            {showKitaInfo && <div className={styles.kitaName}>({kitaName})</div>}
          </div>
          <div>
            {(postType === 4 || postType === 9) && showStart && (
              <div>
                {t('Survey.Start')} {new Date(startTimeDate).toLocaleDateString('de-DE')}{' '}
                {new Date(startTimeDate).toLocaleTimeString('de-DE').replace(/(.*)\D\d+/, '$1')}
              </div>
            )}
          </div>
          <div className={styles.labels}>
            {isAwoWW() && (
              <Label type='info'>
                <i className='fa fa-lock' /> {t(publicVisible ? 'Post.Public' : 'Post.NotPublic')}
              </Label>
            )}
            {privatePost && (
              <Label type='info'>
                <i className='fa fa-lock' /> {t('Post.Private')}
              </Label>
            )}
            {isReported && (isAuthoredByCurrentUser || user.administrationAccess) && (
              <Label backgroundColor='#ff8989'>
                <i className='fa fa-flag' /> {t('Post.Reported')}
              </Label>
            )}
            {returnReceipt && isAuthoredByCurrentUser && (
              <Label type='info'>
                <div onClick={() => setIsReturnReceiptOpened(true)}>
                  <i className='fa fa-eye' /> {t('Post.Return receipt')}
                </div>
              </Label>
            )}
            {canceled && (
              <Label backgroundColor='#ff8989' className={styles.customLabel} type='default'>
                {t('Calendar.CANCELED')}
              </Label>
            )}
            {eventType && (
              <Label
                backgroundColor={eventType.colourCode}
                className={styles.customLabel}
                type='default'
              >
                {eventType.name}
              </Label>
            )}
            {taskStatus === 'pending' ? (
              <Label type='default' className={styles.pendingLabel}>
                <i className='fa fa fa-info-circle' /> {t('Tasks.PENDING')}
              </Label>
            ) : taskStatus === 'in_progress' ? (
              <Label type='default' className={styles.inProgressLabel}>
                <i className='fa fa fa-edit' /> {t('Tasks.IN PROGRESS')}
              </Label>
            ) : taskStatus === 'pending_review' ? (
              <Label type='default' className={styles.pendingReviewLabel}>
                <i className='fa fa fa-exclamation-triangle' /> {t('Tasks.PENDING REVIEW')}
              </Label>
            ) : taskStatus === 'completed' ? (
              <Label type='default' className={styles.completedLabel}>
                <i className='fa fa-check-square' /> {t('Tasks.COMPLETED')}
              </Label>
            ) : null}
            {postType === 5 && end && dateNow.getTime() > end && taskStatus !== 'completed' && (
              <Label type='default' className={styles.overdueLabel}>
                <i className='fa fa fa-exclamation-triangle' /> {t('Tasks.OVERDUE')}
              </Label>
            )}

            {pinned && (
              <Label type='danger'>
                <i className='fa fa-map-pin' /> {t('Post.Pinned')}
              </Label>
            )}

            {postType === 2 && repeatType && repeatType !== 'none' && (
              <Label backgroundColor='#ff8989'>
                <i className='fa fa-flag' /> {t('Post.Repetition')}
              </Label>
            )}

            {postType === 1 ? (
              <>
                <Label type='default'>
                  <i className='fa fa-file-image-o' /> {t('Post.File')}
                </Label>
                {(user.administrationAccess || user.superAdminStatus || (
                  group && group.groupAdminStatus) || user.id === userId) && (
                  <div className={styles.fileDescriptionEdit} onClick={() => setIsEditing(true)}>
                    <i className='fa fa-pencil' />
                  </div>
                )}
              </>
            ) : postType === 2 ? (
              <Label type='default'>
                <i className='fa fa-calendar' /> {t('Post.Event')}
              </Label>
            ) : postType === 3 ? (
              <Label type='default'>
                <i className='fa fa-picture-o' /> {t('Post.Media')}
              </Label>
            ) : postType === 4 ? (
              <Label type='default'>
                <i className='fa fa-question-circle' /> {t('Surveys.Question')}
              </Label>
            ) : postType === 5 ? (
              <Label type='default'>
                <i className='fa fa-tasks' /> {t('Tasks.Task')}
              </Label>
            ) : postType === 9 ? (
              <Label type='default'>
                <i className='fa fa-question-circle' /> {t('Surveys.Question')}
              </Label>
            ) : postType === 11 ? (
              <Label type='danger'>
                <div
                  onClick={() => {
                    if (
                      user.administrationAccess ||
                      user.superAdminStatus ||
                      (group && group.groupAdminStatus)
                    )
                      setIsMultiUserSignatureOpened(true);
                  }}
                >
                  <i className='fa fa-pencil-square-o' /> {t('Post.Signature')}
                </div>
              </Label>
            ) : isAwoWW() && !isPublicUser(user) ? (
              <Label type='default'>
                <i className='fa fa-square' /> {t('Post.Normal Post')}
              </Label>
            ) : null}

            {isSigned === true && (
              <Label type='success'>
                <i className='fa fa-check' /> {t('Post.Already signed')}
              </Label>
            )}

            {isAwoWW() && (
              <Link to={postlink}>
                <div className={styles.awoMobileDate}>
                  {publicationTime && <i className={'fa fa-clock-o'} />}
                  {formatTimestampToGermanTime(publicationTime || createdAt)}
                  {createdAt !== editedAt && (
                    <>
                      <span className={styles.updatedSeparator}>·</span>
                      <Tooltip text={getUpdatedTime(editedAt, user.langCode)} isVisibleCondition>
                        <span className={styles.updated}>Updated</span>
                      </Tooltip>
                    </>
                  )}
                </div>
              </Link>
            )}
          </div>
        </div>
        <Link to={postlink}>
          <div className={isAwoWW() ? styles.awoDesktopDate : styles.desktopDate}>
            {publicationTime && <i className={'fa fa-clock-o'} />}
            {formatTimestampToGermanTime(publicationTime || createdAt)}
            {createdAt !== editedAt && (
              <>
                <span className={styles.updatedSeparator}>·</span>
                <Tooltip text={getUpdatedTime(editedAt, user.langCode)} isVisibleCondition>
                  <span className={styles.updated}>Updated</span>
                </Tooltip>
              </>
            )}
          </div>
        </Link>
      </div>

      {!activeKita || (activeKita && !activeKita.virtual) || isAwoWW() ? (
        <div className={styles.headerBtns}>
          {isSubmiting ? (
            <Loader dotColor='#ededed' dotSize='10px' className={styles.submitting} />
          ) : (
            <div className={styles.moreBtn}>
              <MoreBtn dropdownOptions={dropdownOptionsState} />
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
}

export function getDropdownOptions({
  isAuthoredByCurrentUser,
  setIsOpened,
  setIsGroupPopupOpened,
  setReusableParentSurvey,
  isEditing,
  setIsEditing,
  updateFeedById,
  id,
  t,
  postType,
  canUserVote,
  survey,
  parentSurvey,
  canceled,
  inCalendarModule,
  group,
  files,
  canEditTask,
  notify,
  pinned,
  user,
  isInProfileStream,
  isOnDashboard,
  setIsSubmiting,
  permanento,
  changePermanent,
  returnReceipto,
  changeReturnReceipt,
  setPopup,
  initialPopup,
  text,
  activeKita,
  isLikeFeatureAvailableInGroup,
  likeFeatureEnabledStatus,
  commentsForbiddenStatus,
  changeForbidComments,
  privatePostStatus,
  togglePostPrivate,
  toggleLikeButtonEnabled,
  handleOpenTemplatePopup,
  reportToAdmin,
  userId,
  changeSurveyOpenStatus,
  hidePost,
  handleHidePost,
  publicVisible,
  handlePublicVisible,
  hideDay,
  onHideDay,
  isReported,
  unsetReported,
  kitaId,
  canCreateCommentStatus,
  allowMemberComment,
  changeAllowMemberComment,
}) {
  const signatureMod = store
    .getState()
    .kitaReducer.modules.find(mod => mod.moduleKey === 'signature');
  const signatureModuleActive = signatureMod ? signatureMod.activeStatus : false;

  const copyPost = {
    faIcon: 'fa-copy',
    title: t('Post.Copy'),
    onClick: () => {
      setIsGroupPopupOpened(true);
    },
  };

  const deletePost = {
    faIcon: 'fa-trash-o',
    title: t('Post.Delete'),
    onClick: () => {
      setIsOpened(true);
    },
  };
  let dropdownOptions =
    (isAuthoredByCurrentUser &&
      postType !== 9 &&
      (postType !== 2 || !group || (postType === 2 && group.editDeleteCalenderEntry))) ||
      (postType === 5 && canEditTask)
      ? [
        deletePost,
        {
          faIcon: 'fa-pencil',
          title: isEditing ? t('Post.Cancel edit') : t('Post.Edit'),
          onClick: () => {
            setIsEditing(!isEditing);
          },
        },
      ]
      : [];

  if (
    postType === 9 &&
    group.groupAdminStatus &&
    window.location.pathname.includes('groups') &&
    window.location.pathname.includes('parent-survey')
  ) {
    dropdownOptions.push(copyPost);
    dropdownOptions.push({
      faIcon: 'fa-copy',
      title: t('ParentSurvey.Reuse parent survey'),
      onClick: () => {
        const { id, ...parentSurveyData } = parentSurvey;
        setReusableParentSurvey(parentSurveyData);
      },
    });
    dropdownOptions.push(deletePost);
  }

  if (postType === 2 && isAuthoredByCurrentUser) {
    dropdownOptions.push({
      faIcon: !canceled ? 'fa-times' : 'fa-check',
      title: !canceled ? t('Calendar.Cancel Event') : t('Calendar.Reopen Event'),
      onClick: async () => {
        setIsSubmiting(true);
        if (!inCalendarModule) {
          await updateCalendarEvent(id, { canceled: !canceled });
        }
        await updateFeedById(id, { canceled: !canceled }, { withoutRequest: true });
        setIsSubmiting(false);
      },
    });
  }

  if (hideDay) {
    dropdownOptions.push({
      faIcon: 'fa-times',
      title: t('Calendar.CancelOneEntry'),
      onClick: async () => {
        onHideDay(hideDay);
      },
    });
  }

  if (isAuthoredByCurrentUser) {
    dropdownOptions.push({
      faIcon: !hidePost ? 'fa fa-eye-slash' : 'fa fa-eye',
      title: !hidePost ? t('Post.HidePost') : t('Post.ShowPost'),
      onClick: async () => {
        handleHidePost(hidePost, id);
      },
    });
  }

  if (isAuthoredByCurrentUser && isAwoWW()) {
    dropdownOptions.push({
      faIcon: 'fa fa-lock',
      title: publicVisible ? t('Post.UnsetPublic') : t('Post.SetPublic'),
      onClick: async () => {
        handlePublicVisible(publicVisible, id);
      },
    });
  }

  if (
    (!group ||
      ((postType === 3 && group && group.addModifyGallery) ||
        (postType === 2 && group && group.editDeleteCalenderEntry) ||
        (postType === 1 && group.manageFiles)) ||
      (postType === 5 && group.createTask) ||
      postType === 0 ||
      postType === 4 ||
      postType === 11) &&
    isAuthoredByCurrentUser
  ) {
    dropdownOptions.push({
      faIcon: permanento ? 'fa-unlock' : 'fa-lock',
      title: permanento ? t('Post.Revoke permanent') : t('Post.Make permanent'),
      onClick: () => changePermanent(permanento, returnReceipto, id),
    });
    if (
      ((group != null && group.globalPublicGroup != null && !group.globalPublicGroup) ||
        (isOnDashboard && group != null && !group.globalPublicGroup)) &&
      (user.administrationAccess || group.groupAdminStatus)
    ) {
      dropdownOptions.push({
        faIcon: returnReceipto ? 'fa-eye-slash' : 'fa-eye',
        title: returnReceipto ? t('Post.Revoke return receipt') : t('Post.Request return receipt'),
        onClick: () => changeReturnReceipt(returnReceipto, permanento, id),
      });
    }
  }

  if (!canCreateCommentStatus && group.groupAdminStatus) {
    dropdownOptions.push({
      faIcon: 'fa-commenting',
      title: allowMemberComment ? t('Post.Forbid Comments') : t('Post.Allow Comments'),
      onClick: async () => {
        changeAllowMemberComment(allowMemberComment, id);
      },
    });
  } else if (isAuthoredByCurrentUser) {
    dropdownOptions.push({
      faIcon: 'fa-commenting',
      title: commentsForbiddenStatus ? t('Post.Allow Comments') : t('Post.Forbid Comments'),
      onClick: async () => {
        changeForbidComments(commentsForbiddenStatus, id);
      },
    });
  }

  if (
    activeKita &&
    activeKita.kitaId &&
    (activeKita.kitaId === 1257 ||
      activeKita.kitaId === 1405 ||
      activeKita.kitaId === 1715 ||
      activeKita.kitaId === 1763 ||
      activeKita.kitaId === 1765 ||
      activeKita.kitaId === 1767)
  ) {
    dropdownOptions.push({
      faIcon: 'fa fa-phone',
      title: (
        <div style={{ width: '100%' }}>
          <Speech textAsButton displayText='Vorlesen' text={text} />
        </div>
      ),
    });
  }

  if (postType === 4 && !canUserVote && survey.surveyOpenStatus) {
    dropdownOptions.unshift({
      faIcon: 'fa-undo',
      title: t('Surveys.Reset my vote'),
      onClick: async () => {
        setIsSubmiting(true);
        const { data } = await unvoteForSurvey(id);
        updateFeedById(id, changeOrderIfSurvey(data), { withoutRequest: true });
        setIsSubmiting(false);
      },
    });
  }

  if (postType === 4 && isAuthoredByCurrentUser) {
    dropdownOptions.unshift(
      {
        faIcon: survey.surveyOpenStatus ? 'fa-times' : 'fa-check',
        title: survey.surveyOpenStatus ? t('Surveys.Complete Survey') : t('Surveys.Reopen Survey'),
        onClick: async () => {
          changeSurveyOpenStatus(id);
        },
      },
      {
        faIcon: 'fa-file-pdf-o',
        title: t('Surveys.Download PDF'),
        onClick: async () => {
          setIsSubmiting(true);
          const mappedSurveyOptions = survey.surveyOptions.map(option => {
            return {
              id: option.id,
              answer: option.optionName,
              counted_votes: option.surveyOptionVotes.length,
              surveyOptionVotes: option.surveyOptionVotes,
            };
          });
          const tmpParentSurvey = {
            title: 'Ergebnis der Umfrage',
            isNormalSurvey: true,
            questions: [
              {
                question: survey.question,
                parentSurveyQuestionOptions: mappedSurveyOptions,
              },
            ],
          };
          createAndDownloadPDF(tmpParentSurvey, 'Umfrage', t, survey.anonymousVotesStatus);
          setIsSubmiting(false);
        },
      },
    );
  }

  if (postType === 9 && group.groupAdminStatus) {
    if (isAuthoredByCurrentUser) {
      dropdownOptions.unshift(
        {
          faIcon: parentSurvey.isClosed ? 'fa-check' : 'fa-times',
          title: parentSurvey.isClosed ? t('Surveys.Reopen Survey') : t('Surveys.Complete Survey'),
          onClick: async () => {
            setPopup({
              isOpen: true,
              onAccept: async () => {
                setIsSubmiting(true);
                endParentSurvey(parentSurvey.id).then(data => {
                  updateFeedById(
                    id,
                    {
                      published: data.data.published,
                      closed: data.data.closed,
                      parentSurveyQuestions: data.data.parentSurveyQuestions,
                      updated: true,
                    },
                    { withoutRequest: false },
                  );
                  setIsSubmiting(false);
                  setPopup(initialPopup);
                  // window.location.reload();
                });
              },
              text: parentSurvey.isClosed
                ? t('ParentSurvey.Confirm open')
                : t('ParentSurvey.Confirm close'),
            });
          },
        },
        {
          faIcon: 'fa-bullhorn',
          title: t('ParentSurvey.Publish survey results'),
          onClick: async () => {
            setPopup({
              isOpen: true,
              onAccept: async () => {
                setIsSubmiting(true);
                publishParentSurvey(parentSurvey.id).then(data => {
                  updateFeedById(
                    id,
                    {
                      published: data.data.published,
                      closed: data.data.closed,
                      parentSurveyQuestions: data.data.parentSurveyQuestions,
                      updated: true,
                    },
                    { withoutRequest: false },
                  );
                  setIsSubmiting(false);
                  setPopup(initialPopup);
                });
              },
              text: t('ParentSurvey.Confirm publish'),
            });
          },
        },
        {
          faIcon: 'fa-refresh',
          title: t('ParentSurvey.Reset survey'),
          onClick: async () => {
            setPopup({
              isOpen: true,
              onAccept: async () => {
                setIsSubmiting(true);
                resetParentSurvey(parentSurvey.id).then(data => {
                  updateFeedById(
                    id,
                    {
                      closed: data.data.closed,
                      published: data.data.published,
                      parentSurveyQuestions: data.data.parentSurveyQuestions,
                      updated: true,
                    },
                    { withoutRequest: false },
                  );
                  setIsSubmiting(false);
                  setPopup(initialPopup);
                });
              },
              text: t('ParentSurvey.Confirm reset'),
            });
          },
        },
        {
          faIcon: 'fa fa-pencil',
          title: !isEditing ? 'Bearbeiten' : 'Nicht mehr bearbeiten',
          onClick: async () => {
            setIsEditing(state => !state);
            const index = dropdownOptions.findIndex(
              element =>
                (element.title === 'Bearbeiten' || element.title === 'Nicht mehr bearbeiten') &&
                element.faIcon === 'fa fa-pencil',
            );
            dropdownOptions[index] = {
              ...dropdownOptions[index],
              title:
                dropdownOptions[index].title === 'Bearbeiten'
                  ? 'Nicht mehr bearbeiten'
                  : 'Bearbeiten',
            };
          },
        },
      );
    }
  }

  if (!isAwoWW() || !isPublicUser(user)) {
    dropdownOptions.push({
      faIcon: notify ? 'fa-bell-slash-o' : 'fa-bell-o',
      title: notify ? t('Post.Turn off notifications') : t('Post.Turn on notifications'),
      onClick: async () => {
        setIsSubmiting(true);
        await changePostNotifyStatus(id, !notify);
        updateFeedById(id, { notify: !notify }, { withoutRequest: true });
        setIsSubmiting(false);
      },
    });
  }

  if (
    isAuthoredByCurrentUser &&
    (isInProfileStream ||
      (group &&
        ((group.isPrivate && group.groupAdminStatus) ||
          (!group.isPrivate && (group.groupAdminStatus || user.administrationAccess)))))
  ) {
    dropdownOptions.push({
      faIcon: 'fa-map-pin',
      title: pinned ? t('Post.Unpin') : t('Post.Pin to top'),
      isHidden: isOnDashboard,
      onClick: async () => {
        try {
          if (pinned) {
            if (isInProfileStream) {
              await unpinUserPost({ id });
            } else {
              await unpinGroupPost({ groupId: group.groupId, id });
            }
          } else {
            if (isInProfileStream) {
              await pinUserPost({ id });
            } else {
              await pinGroupPost({ groupId: group.groupId, id });
            }
          }

          updateFeedById(id, { pinned: !pinned }, { withoutRequest: true });
        } catch (ex) {
          if (ex && ex.response && ex.response.status === 400) {
            store.dispatch(
              showBottomNotification(t('Post.Maximum number of pinned items reached'), {
                isWarning: true,
              }),
            );
          }
        }
      },
    });
  }

  if (
    (user.administrationAccess || user.superAdminStatus || (group && group.groupAdminStatus)) &&
    (postType === 0 || postType === 1) &&
    files != null &&
    files.length === 1 &&
    files[0].mimeType === 'application/pdf' &&
    signatureModuleActive
  ) {
    dropdownOptions.push({
      faIcon: 'fa-pencil-square-o',
      title: t('Post.Enable signature'),
      onClick: async () => {
        await onSignaturePostEnabled(files[0].id, group.groupId);

        await updatePost(id, { postType: 11 });

        updateFeedById(id, { postType: 11 }, { withoutRequest: true });
      },
    });
  } else if (
    (user.administrationAccess || user.superAdminStatus || (group && group.groupAdminStatus)) &&
    postType === 11 &&
    signatureModuleActive
  ) {
    dropdownOptions.push({
      faIcon: 'fa-pencil-square-o',
      title: t('Post.Disable signature'),
      onClick: async () => {
        await updatePost(id, { postType: 0 });

        updateFeedById(id, { postType: 0 }, { withoutRequest: true });
      },
    });
  }

  if (
    (user.administrationAccess ||
      user.superAdminStatus ||
      (group && group.groupAdminStatus) ||
      user.employee) &&
    postType !== 5
  ) {
    dropdownOptions.push({
      faIcon: privatePostStatus ? 'fa-unlock' : 'fa-lock',
      title: privatePostStatus ? t('Post.Make public') : t('Post.Make private'),
      onClick: () => {
        if (privatePostStatus) {
          togglePostPrivate(privatePostStatus);
        } else {
          setPopup({
            isOpen: true,
            onAccept: async () => {
              togglePostPrivate(privatePostStatus).then(() => {
                setPopup(initialPopup);
              });
            },
            text: t('Post.MakePrivateConfirmText'),
          });
        }
      },
    });
  }

  if (postType === 0 || postType === 2) {
    if (!isAwoWW() || !isPublicUser(user)) {
      dropdownOptions.push({
        faIcon: 'fa-save',
        title: t('Post.PostTemplate'),
        onClick: () => handleOpenTemplatePopup(),
      });
    }
  }

  if (isReported && (isAuthoredByCurrentUser || user.administrationAccess)) {
    dropdownOptions.push({
      faIcon: 'fa-flag',
      title: t('Post.UnsetReported'),
      type: 'unsetReportedPost',
      onClick: () => {
        unsetReported(id);
      },
    });
  }

  //if (window.location.hostname.toLowerCase().includes('matchand.de')) {
  if (!user.superAdminStatus && userId !== user.id) {
    dropdownOptions.push({
      faIcon: 'fa-bell-slash-o',
      title: t('Post.Report to Kita-Admin'),
      type: 'reportToAdmin',
      onClick: () => {
        reportToAdmin(id, group.groupId, user.firstName + ' ' + user.lastName, kitaId);
      },
    });
  }
  //}

  if (
    user.administrationAccess ||
    user.superAdminStatus ||
    (group && group.groupAdminStatus) ||
    user.id === userId
  ) {
    dropdownOptions.push({
      faIcon: likeFeatureEnabledStatus ? 'fa-lock' : 'fa-unlock',
      title: likeFeatureEnabledStatus ? t('Post.DisableLikeButton') : t('Post.EnableLikeButton'),
      onClick: () => {
        if (!likeFeatureEnabledStatus) {
          toggleLikeButtonEnabled(likeFeatureEnabledStatus);
        } else {
          setPopup({
            isOpen: true,
            onAccept: async () => {
              toggleLikeButtonEnabled(likeFeatureEnabledStatus).then(() => {
                setPopup(initialPopup);
              });
            },
            text: t('Post.DisableLikeButtonConfirmText'),
          });
        }
      },
    });
  }

  if (isAwoWW() && activeKita?.virtual) {
    dropdownOptions = dropdownOptions.filter(item => item.type === 'reportToAdmin');
  }

  return dropdownOptions;
}

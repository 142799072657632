import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  getUserById,
  getUserProfile,
  sendFriendRequest,
  acceptFriendRequest,
  cancelFriendRequest,
  unFriend,
  uploadProfileImage,
  deleteProfileImage,
  editProfileImage,
} from 'library/api/user';
import FileManager from 'library/common/commonComponents/FileManager/FileManagerContainer';
import Gallery from 'library/common/commonComponents/Gallery/GalleryContainer';
import Bazaar from 'library/common/commonComponents/Bazaar/BazaarContainer';
import ProfileStream from 'modules/Profile/profileFrames/ProfileStream';
import ProfileCalendar from 'modules/Profile/profileFrames/ProfileCalendar';

export default function useProfileLoading({
  profileId,
  userId,
  match,
  history,
  isAccountSettingsPage,
  kitaId,
}) {
  const { t } = useTranslation();
  const [headerUploadProgress, setHeaderUploadProgress] = useState(null);
  const [avatarUploadProgress, setAvatarUploadProgress] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const defaultProfileInfo = {
    header: {
      xCoordinate: 25,
      yCoordinate: 25,
      imageWidth: 50,
      imageAspect: 5.9,
      imageUrl: null,
    },
    avatar: {
      xCoordinate: 25,
      yCoordinate: 25,
      imageWidth: 50,
      imageAspect: 1,
      imageUrl: null,
    },
    firstName: '',
    lastName: '',
    email: '',
    profileStatus: '',
    orderFavorites: false,
    modules: [],
    groups: [],
    title: ''
  };
  const [profileInfo, setProfileInfo] = useState(defaultProfileInfo);
  const [friendRequestList, setFriendRequestList] = useState([]);
  const [reloadProfileInfo, setReloadProfileInfo] = useState(false);
  const [reloadFriendRequestList, setReloadFriendRequestList] = useState(false);
  const defaultModule = [
    {
      title: t('ProfileBody.LeftNav.Stream'),
      faIcon: 'fa-bars',
      to: `${match.url}/stream`,
      component: ProfileStream,
    },
  ];
  const defaultFriendModule = [
    {
      title: t('ProfileBody.LeftNav.Stream'),
      faIcon: 'fa-bars',
      to: `${match.url}/stream`,
      component: ProfileStream,
    },
    {
      title: t('Bazaar.ModuleSelection.Title'),
      moduleKey: 'bazaar',
      faIcon: 'fa-shopping-cart',
      to: `${match.url}/bazaar`,
      component: Bazaar,
      props: {
        isProfilePage: true,
        isOwner: false,
        profileId,
      },
    },
  ];
  const modulesInfo = [
    {
      title: t('ProfileBody.LeftNav.Files'),
      moduleKey: 'files',
      faIcon: 'fa-files-o',
      to: `${match.url}/files`,
      component: FileManager,
      props: {
        isProfilePage: true,
        id: userId,
      },
    },
    {
      title: t('ProfileBody.LeftNav.Calendar'),
      moduleKey: 'calendar',
      faIcon: 'fa-calendar',
      to: `${match.url}/calendar`,
      component: ProfileCalendar,
    },
    {
      title: t('ProfileBody.LeftNav.Gallery'),
      moduleKey: 'gallery',
      faIcon: 'fa-picture-o',
      to: `${match.url}/gallery`,
      component: Gallery,
      props: {
        isProfilePage: true,
        isOwner: true,
        profileId,
      },
    },
    {
      title: t('Bazaar.ModuleSelection.Title'),
      moduleKey: 'bazaar',
      faIcon: 'fa-shopping-cart',
      to: `${match.url}/bazaar`,
      component: Bazaar,
      props: {
        isProfilePage: true,
        isOwner: true,
        profileId,
      },
    },
  ];

  const getModules = (modules, profileStatus) => {
    getFriendModules(modules, defaultFriendModule);
    switch (profileStatus) {
      case 'owner':
        return modules.reduce((activeModules, item) => {
          return (activeModules = item.activeStatus
            ? activeModules.concat(modulesInfo.find(info => info.moduleKey === item.moduleKey))
            : activeModules);
        }, defaultModule);
      case 'friend':
        return getFriendModules(modules, defaultFriendModule);
      default:
        return [];
    }
  };

  const getFriendModules = (modules, friendModules) => {
    const filteredModules = [];
    filteredModules.push(friendModules[0]);
    for (let i = 0; i < modules.length; i++) {
      const foundModule = friendModules.find(
        friendModule => modules[i].moduleKey === friendModule.moduleKey,
      );
      if (foundModule) {
        filteredModules.push(foundModule);
      }
    }

    return filteredModules;
  };

  const getProfileStatus = ({ superAdminStatus, friendStatus }) => {
    if (`${userId}` === `${profileId}`) {
      return 'owner';
    } else if (superAdminStatus) {
      return 'superAdminProfile';
    } else if (!friendStatus) {
      return 'notFriend';
    } else if (friendStatus.statusType === 'PENDING' && userId === friendStatus.receiverId) {
      return 'acceptFriendRequest';
    } else if (friendStatus.statusType === 'PENDING' && userId === friendStatus.requesterId) {
      return 'friendRequested';
    } else if (friendStatus.statusType === 'ACCEPTED') {
      return 'friend';
    }
  };

  useEffect(() => {
    if (!isAccountSettingsPage) {
      setIsLoading(true);
      getUserProfile(profileId)
        .then(res => {
          if (res.status === 200) {
            const profileStatus = getProfileStatus({
              superAdminStatus: res.data.superAdminStatus,
              friendStatus: res.data.friendStatus,
            });
            const modules = getModules(res.data.modules, profileStatus);
            setProfileInfo({
              requestId: res.data.friendStatus && res.data.friendStatus.id,
              firstName: res.data.firstName,
              lastName: res.data.lastName,
              email: res.data.email,
              orderFavorites: res.data.orderFavorites,
              profileStatus,
              modules,
              groups: res.data.groups,
              header: {
                xCoordinate: res.data.profileHeaderXCoordinate,
                yCoordinate: res.data.profileHeaderYCoordinate,
                imageWidth: res.data.profileHeaderWidth,
                imageAspect: res.data.profileHeaderAspect,
                imageUrl: res.data.profileHeaderUrl,
              },
              avatar: {
                xCoordinate: res.data.profileLogoXCoordinate,
                yCoordinate: res.data.profileLogoYCoordinate,
                imageWidth: res.data.profileLogoWidth,
                imageAspect: res.data.profileLogoAspect,
                imageUrl: res.data.profileLogoUrl,
                rotation: res.data.profileLogoRotation,
              },
              title: res.data.title,
            });
          }
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });

      return () => {
        setProfileInfo(defaultProfileInfo);
      };
    }
    // eslint-disable-next-line
  }, [profileId, userId, kitaId, reloadProfileInfo]);

  useEffect(() => {
    if (isAccountSettingsPage) {
      setIsLoading(true);
        getUserById(profileId).then(res => {
          const userKita = res.data.userKita
            ? res.data.userKita.find(uk => uk.kitaId === kitaId)
            : null;

          setProfileInfo({
            ...res.data,
            kitaAdminStatus: userKita ? userKita.kitaRoles.roles.adminRole : false,
            employee: userKita ? userKita.employee : false,
          });
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, kitaId, reloadFriendRequestList]);

  const handleAddFriend = () => {
    setIsSubmiting(true);
    sendFriendRequest(profileId, kitaId)
      .then(res => {
        if (res.status === 200) {
          setReloadProfileInfo(!reloadProfileInfo);
        }
        setIsSubmiting(false);
      })
      .catch(err => {
        console.log(err);
        setIsSubmiting(false);
        setReloadProfileInfo(!reloadProfileInfo);
      });
  };

  const handleAcceptFriendRequest = (requestId, clickedUserId) => {
    const reloadData = isAccountSettingsPage
      ? () => setReloadFriendRequestList(!reloadFriendRequestList)
      : () => setReloadProfileInfo(!reloadProfileInfo);
    setIsSubmiting(true);
    return acceptFriendRequest(requestId || profileInfo.requestId, kitaId)
      .then(res => {
        if (res.status === 200) {
          if (isAccountSettingsPage) {
            history.push(`/profile/${clickedUserId}`);
            return { seccess: true };
          } else {
            reloadData();
          }
        }
        setIsSubmiting(false);
        return { seccess: false };
      })
      .catch(err => {
        console.log(err);
        setIsSubmiting(false);
        reloadData();
        return { seccess: false };
      });
  };

  const handleCancelFriendRequest = (requestId, clickedUserId) => {
    const reloadData = isAccountSettingsPage
      ? () => setReloadFriendRequestList(!reloadFriendRequestList)
      : () => setReloadProfileInfo(!reloadProfileInfo);
    setIsSubmiting(true);
    return cancelFriendRequest(requestId || profileInfo.requestId, kitaId)
      .then(res => {
        if (res.status === 200) {
          if (isAccountSettingsPage) {
            history.push(`/profile/${clickedUserId}`);
            return { seccess: true };
          } else {
            setProfileInfo({
              ...profileInfo,
              profileStatus: 'notFriend',
              groups: [],
              modules: [],
            });
          }
        }
        setIsSubmiting(false);
        return { seccess: false };
      })
      .catch(err => {
        console.log(err);
        setIsSubmiting(false);
        reloadData();
        return { seccess: false };
      });
  };

  const handleUnFriend = (requestId, clickedUserId) => {
    const reloadData = isAccountSettingsPage
      ? () => setReloadFriendRequestList(!reloadFriendRequestList)
      : () => setReloadProfileInfo(!reloadProfileInfo);
    setIsSubmiting(true);
    return unFriend(requestId || profileInfo.requestId, kitaId)
      .then(res => {
        if (res.status === 200) {
          if (isAccountSettingsPage) {
            history.push(`/profile/${clickedUserId}`);
            return { seccess: true };
          } else {
            setProfileInfo({
              ...profileInfo,
              profileStatus: 'notFriend',
              groups: [],
              modules: [],
            });
          }
        }
        setIsSubmiting(false);
        return { seccess: false };
      })
      .catch(err => {
        console.log(err);
        setIsSubmiting(false);
        reloadData();
        return { seccess: false };
      });
  };

  const changeProfileImg = (type, img) => {
    setProfileInfo({
      ...profileInfo,
      header: type === 'header' ? img : profileInfo.header,
      avatar: type === 'avatar' ? img : profileInfo.avatar,
    });
  };

  const handleUploadProfileImg = (image, type) => {
    const imageAspect = type === 'avatar' ? 1 : 5.9;
    const imageWidth = 50;
    const setUploadProgress = type === 'avatar' ? setAvatarUploadProgress : setHeaderUploadProgress;
    return uploadProfileImage({
      image,
      imageAspect,
      imageWidth,
      type,
      setUploadProgress,
    })
      .then(res => {
        if (res.status === 200) {
          setUploadProgress(null);
          window.location.reload();
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleEditProfileImage = (image, type) => {
    return editProfileImage({ ...image })
      .then(res => {
        if (res.status === 200) {
          changeProfileImg(type, {
            ...image,
            imageUrl: type === 'header' ? profileInfo.header.imageUrl : profileInfo.avatar.imageUrl,
          });
          window.location.reload();
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleDeleteProfileImg = type => {
    deleteProfileImage(type)
      .then(res => {
        if (res.status === 200) {
          window.location.reload();
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  return {
    isLoading,
    isSubmiting,
    profileInfo,
    setProfileInfo,
    friendRequestList,
    onAddFriend: handleAddFriend,
    onAcceptFriendRequest: handleAcceptFriendRequest,
    onCancelFriendRequest: handleCancelFriendRequest,
    onUnFriend: handleUnFriend,
    headerUploadProgress,
    avatarUploadProgress,
    onUploadProfileImg: handleUploadProfileImg,
    onEditProfileImage: handleEditProfileImage,
    onDeleteProfileImg: handleDeleteProfileImg,
  };
}

import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import PageWithSidebar from 'library/common/commonComponents/PageWithSidebar';
import KitaPanelWrapper from 'library/common/commonComponents/KitaPanelWrapper';
import Wrapper from 'library/common/commonComponents/Wrapper';
import Loader from 'library/common/commonComponents/Loader';
import SanitizedTranslation from 'library/common/commonComponents/SanitizedTranslation';
import OnlyForKitaMember from 'library/common/commonComponents/OnlyForKitaMember/OnlyForKitaMemberContainer';

import ProfileFrame from './accountSettingsFrames/ProfileFrame';
import EmailSummariesFrame from './accountSettingsFrames/EmailSummariesFrame/EmailSummariesFrameContainer';
import NotificationsFrame from './accountSettingsFrames/NotificationsFrame';
import SettingsFrame from './accountSettingsFrames/SettingsFrame';
import ModulesFrame from './accountSettingsFrames/ModulesFrame';
import FriendsFrame from './accountSettingsFrames/FriendsFrame';
import LanguagesFrame from './accountSettingsFrames/LanguagesFrame/LanguagesFrameContainer';
import AbsenceFrame from './accountSettingsFrames/AbsenceFrame/AbsenceFrameContainer';

export default function AccountSettings({
  history,
  match,
  user,
  showBottomNotification,
  activeKita,
  setGoogleTranslateLang,
  setGoogleTranslateAgree,
  isGoogleTranslateAgree,
  patchUserInfo,
  isGoogleTranslatorActive,
  modules,
}) {
  const absenceMod = modules.find(mod => mod.moduleKey === 'absence');
  const absenceModuleActive = absenceMod ? absenceMod.activeStatus : false;
  const { t } = useTranslation();

  const menuItems = {
    profile: {
      title: t('AccountSettings.Profile.Profile'),
      bodyTitle: <SanitizedTranslation value={t('AccountSettings.ProfileSection.Your profile')} />,
      faIcon: 'fa-user',
      to: '/account-settings/profile/general',
      path: 'profile',
      component: (
        <ProfileFrame
          history={history}
          match={match}
          activeKita={activeKita}
          showBottomNotification={showBottomNotification}
          user={user}
        />
      ),
    },
    'email-summaries': {
      title: t('AccountSettings.Email summaries'),
      bodyTitle: (
        <SanitizedTranslation value={t('AccountSettings.EmailSummariesSection.Email summaries')} />
      ),
      faIcon: 'fa-envelope',
      to: '/account-settings/email-summaries',
      path: 'email-summaries',
      component: (
        <OnlyForKitaMember>
          <EmailSummariesFrame />
        </OnlyForKitaMember>
      ),
    },
    notifications: {
      title: t('AccountSettings.Notifications'),
      bodyTitle: (
        <SanitizedTranslation
          value={t('AccountSettings.NotificationsSection.Notification settings')}
        />
      ),
      faIcon: 'fa-bell',
      to: '/account-settings/notifications',
      path: 'notifications',
      component: (
        <OnlyForKitaMember>
          <NotificationsFrame />
        </OnlyForKitaMember>
      ),
    },
    settings: {
      title: t('AccountSettings.Settings'),
      bodyTitle: <SanitizedTranslation value={t('AccountSettings.SettingsSection.Settings')} />,
      faIcon: 'fa-wrench',
      to: '/account-settings/settings',
      path: 'settings',
      component: (
        <SettingsFrame
          history={history}
          match={match}
          activeKita={activeKita}
          showBottomNotification={showBottomNotification}
          user={user}
          setGoogleTranslateLang={setGoogleTranslateLang}
          setGoogleTranslateAgree={setGoogleTranslateAgree}
          isGoogleTranslateAgree={isGoogleTranslateAgree}
          patchUserInfo={patchUserInfo}
        />
      ),
    },
    modules: {
      title: t('AccountSettings.Modules'),
      bodyTitle: <SanitizedTranslation value={t('AccountSettings.ModulesSection.Modules')} />,
      faIcon: 'fa-rocket',
      to: '/account-settings/modules',
      path: 'modules',
      component: (
        <OnlyForKitaMember>
          <ModulesFrame user={user} match={match} showBottomNotification={showBottomNotification} />
        </OnlyForKitaMember>
      ),
    },
    friends: {
      title: t('AccountSettings.Friends'),
      bodyTitle: <SanitizedTranslation value={t('AccountSettings.FriendsSection.My friends')} />,
      faIcon: 'fa-users',
      to: '/account-settings/friends',
      path: 'friends',
      component: (
        <OnlyForKitaMember>
          <FriendsFrame
            history={history}
            match={match}
            user={user}
            showBottomNotification={showBottomNotification}
            activeKita={activeKita}
          />
        </OnlyForKitaMember>
      ),
    },
    languages: {
      title: t('AccountSettings.Languages'),
      bodyTitle: <SanitizedTranslation value={t('AccountSettings.Languages settings')} />,
      faIcon: 'fa-flag',
      to: '/account-settings/languages',
      path: 'languages',
      component: <LanguagesFrame />,
    },
  };

  if (
    user.superAdminStatus ||
    window.location.hostname.toLowerCase().includes('ivs.safe2connect.org') || 1 === 1
  ) {
    delete menuItems.friends;
  }
  if (!isGoogleTranslatorActive || window.location.hostname.toLowerCase().includes('ekitanet.safe2connect.org')) {
    delete menuItems.languages;
  }
  if (!absenceModuleActive) {
    delete menuItems.absence;
  }

  return (
    <KitaPanelWrapper>
      <Wrapper>
        {user.id ? (
          <PageWithSidebar
            currentPage={match.params.page}
            menuItems={menuItems}
            menuTitle={<Trans i18nKey='AccountSettings.Account sidebar title' />}
          />
        ) : (
          <Loader />
        )}
      </Wrapper>
    </KitaPanelWrapper>
  );
}

AccountSettings.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
    url: PropTypes.string,
  }).isRequired,
};

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  addNewChat,
  postEmployeeAbsenceTimeRange,
  getEmployeeAbsenceTimeRange,
  disableEmployeeAbsenceTimeRange,
} from 'library/api/chat';
import { getCheckinoutOrDevelopmentDiaryActiveForKitaParents } from 'library/api/kids';
import { logout } from 'library/api/logout';
import { isAwoWW } from 'library/api/tenantConfig';
import { resetNewChatWithRecipientSignalRecipient } from 'library/common/commonActions/chatActions';
import {
  setTimeRangeAbsence,
  setTimeRangeAbsenceLoading,
  setTimeRangeAbsenceEndLoading,
  disableTimeRangeAbsenceLoading,
} from 'library/common/commonActions/timerangeAbsenceAction';
// eslint-disable-next-line max-len
import CreateNewAbsence from 'library/common/commonComponents/Absence';
import AppLogo from 'library/common/commonComponents/AppLogo';
import CreateNewChat from 'library/common/commonComponents/Chat/ChatList/ChatListFrames/CreateNewChat/CreateNewChatContainer';
import KitaPanelWrapper from 'library/common/commonComponents/KitaPanelWrapper';
import Wrapper from 'library/common/commonComponents/Wrapper';
import { CONTRACT_ONLY_KITAS } from 'library/common/commonConstants/kitaConstants';
import { useWindowSize } from 'library/common/commonHooks/useWindowSize';
import { isPublicUser } from 'library/utilities/user';
import store from 'main/store/configureStore';

import TopbarCheckAbsence from './topbarFrames/TopbarCheckAbsence';
import TopbarNotifications from './topbarFrames/TopbarNotifications/TopbarNotificationsContainer';
import TopbarUserframe from './topbarFrames/TopbarUserframe';
import styles from './topbar.module.scss';

export default function Topbar({
  history,
  user,
  setSelectedChatId,
  showBottomNotification,
  activeKita,
  modules,
  checkInOutActiveForParentsSignal,
  openNewChatWithRecipient,
  timeRangeAbsenceInfo,
}) {
  const [isCreateNewChatOpened, setIsCreateNewChatOpened] = useState(false);
  const [isCreateNewAbsenceOpened, setIsCreateNewAbsenceOpened] = useState(false);
  const [createNewChatRecipientsPreset, setCreateNewChatRecipientsPreset] = useState(null);
  const [curCheckInOutActiveForParents, setCurCheckInOutActiveForParents] = useState(false);

  const { width: screenWidth } = useWindowSize();
  const { t } = useTranslation();

  const isNoPanel = screenWidth <= 845;
  useEffect(() => {
    const fetchTimeRangeAbsence = () => {
      store.dispatch(setTimeRangeAbsenceLoading());
      getEmployeeAbsenceTimeRange(activeKita.kitaId)
        .then(({ data }) => {
          store.dispatch(setTimeRangeAbsence(data));
        })
        .finally(() => {
          store.dispatch(setTimeRangeAbsenceEndLoading());
        });
    };
    if (activeKita.kitaId) {
      fetchTimeRangeAbsence();
    }
  }, [activeKita.kitaId]);

  useEffect(() => {
    getCheckinoutOrDevelopmentDiaryActiveForKitaParents().then(res => {
      if (res && res.data !== null) {
        setCurCheckInOutActiveForParents(res.data);
      }
    });
  }, [checkInOutActiveForParentsSignal]);

  useEffect(() => {
    if (openNewChatWithRecipient.recipient != null) {
      setIsCreateNewChatOpened(true);
      setCreateNewChatRecipientsPreset([openNewChatWithRecipient.recipient]);
      store.dispatch(resetNewChatWithRecipientSignalRecipient());
    }
  }, [openNewChatWithRecipient.signal]);

  const handleAddNewChat = (newChatInfo, files, setProgress) => {
    return addNewChat(newChatInfo, activeKita.kitaId, files, setProgress)
      .then(res => {
        if (res.status === 200) {
          history.push('/chat/1');
        }
      })
      .catch(error => {
        if (error && error.response && error.response.data) {
          showBottomNotification(t(`Chat.${error.response.data.message}`), { isFail: true });
        }
      });
  };

  const handleAddNewAbsence = (startDateFormat, endDateFormat, absenceMessage) => {
    store.dispatch(setTimeRangeAbsenceLoading());
    return postEmployeeAbsenceTimeRange({
      startDate: startDateFormat,
      endDate: endDateFormat,
      absenceMessage,
    })
      .then(({ data }) => {
        store.dispatch(setTimeRangeAbsence(data));
        showBottomNotification(t(`Chat.CreateAbsenceSuccess`), { isFail: false });
      })
      .catch(() => {
        showBottomNotification(t(`Chat.CreateAbsenceError`), { isFail: true });
      })
      .finally(() => {
        store.dispatch(setTimeRangeAbsenceEndLoading());
      });
  };

  const handleDeactiveTimeRangeAbsence = () => {
    if (timeRangeAbsenceInfo.timeRangeAbsence) {
      store.dispatch(setTimeRangeAbsenceLoading());
      disableEmployeeAbsenceTimeRange(timeRangeAbsenceInfo.timeRangeAbsence.id)
        .then(() => {
          store.dispatch(disableTimeRangeAbsenceLoading());
        })
        .finally(() => {
          store.dispatch(setTimeRangeAbsenceEndLoading());
        });
    }
  };

  const handleNavigation = () => {
    if (isAwoWW()) {
      //logout();
    } else {
      history.push('/dashboard');
    }
  };

  return (
    <div className={styles.topbar}>
      <div className={isNoPanel ? styles.topbarNoPanel : styles.topbarPanel}>
        <KitaPanelWrapper className={isNoPanel ? styles.topbarNoStylePanel : ''}>
          <Wrapper>
            <div className={styles.topbarWrapper}>
              <div
                className={styles.topbarBrand}
                onClick={handleNavigation}
                style={{ cursor: 'pointer' }}
              >
                <AppLogo />
              </div>

              {activeKita.kitaId &&
                user.lastName !== 'Caterer' &&
                !isPublicUser(user) &&
                !CONTRACT_ONLY_KITAS.includes(activeKita.kitaId) &&
                !activeKita.kitaName.includes('Betreuungsverträge') && (
                  <>
                    <TopbarNotifications
                      history={history}
                      setSelectedChatId={setSelectedChatId}
                      setIsCreateNewChatOpened={setIsCreateNewChatOpened}
                      setIsCreateNewAbsenceOpened={setIsCreateNewAbsenceOpened}
                      kitaColor={activeKita.kitaColorCode}
                      modules={modules}
                      timeRangeAbsenceInfo={timeRangeAbsenceInfo}
                      onDeactiveTimeRangeAbsence={handleDeactiveTimeRangeAbsence}
                    />

                    <TopbarCheckAbsence
                      kitaColor={activeKita.kitaColorCode}
                      modules={modules}
                      curCheckInOutActiveForParents={curCheckInOutActiveForParents}
                      kitaId={activeKita.kitaId}
                    />
                  </>
                )}
              <TopbarUserframe user={user} kitaColor={activeKita.kitaColorCode} />
            </div>
          </Wrapper>
        </KitaPanelWrapper>
      </div>
      <CreateNewChat
        isOpened={isCreateNewChatOpened}
        onSubmit={handleAddNewChat}
        onClose={() => setIsCreateNewChatOpened(false)}
        user={user}
        createNewChatRecipientsPreset={createNewChatRecipientsPreset}
        setCreateNewChatRecipientsPreset={setCreateNewChatRecipientsPreset}
      />
      <CreateNewAbsence
        isOpened={isCreateNewAbsenceOpened}
        onSubmit={handleAddNewAbsence}
        onClose={() => setIsCreateNewAbsenceOpened(false)}
      />
    </div>
  );
}

Topbar.propTypes = {
  history: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({}).isRequired,
};

import calendarModule from 'resources/images/modules/calendar_module.png';
import filesModule from 'resources/images/modules/files_module.png';
import galleryModule from 'resources/images/modules/gallery_module.png';
import surveysModule from 'resources/images/modules/surveys_module.png';
import parentSurveyModule from 'resources/images/modules/parent_survey_module.png';
import tasksModule from 'resources/images/modules/tasks_module.png';
import translationsModule from 'resources/images/modules/translations_module.png';
import foodOrderModule from 'resources/images/modules/food_order_module.png';
import portfolioModule from 'resources/images/modules/portfolio_module.png';
import bazaarModule from 'resources/images/modules/bazaar_module.png';
import checkInOutModule from 'resources/images/modules/checkinout_module.png';
import wikiModule from 'resources/images/modules/wiki.png';
import coronaTestModule from 'resources/images/modules/corona_test.png';
import chatModule from 'resources/images/modules/chat_module.png';
import whiteboardModule from 'resources/images/modules/whiteboard_module.png';
import kitalinoInterfaceModule from 'resources/images/modules/kitalino_interface_module.png';
import tableTemplatesModule from 'resources/images/modules/table_templates_module.png';

export const URLS = {
  groups: '/groups',
  invite: '/group/member',
  inviteEmail: '/group/member/email',
  joinPublicGroup: '/group/join',
  joinPolicies: '/group-join-policies/page',
  modules: '/group/modules',
  search: '/group/invite',
  transferGroups: '/userTransfer/groups/',
  transferGroupMembers: '/userTransfer/transfer/',
  copyGroupMembers: '/userTransfer/copy/',
  inviteDefaultGroup: '/group-default/member',
};

export const SET_CREATE_GROUP_MODE = '@@SET_CREATE_GROUP_MODE';
export const SET_GROUP_DATA = '@@SET_GROUP_DATA';
export const CLEAR_GROUP_DATA = '@@CLEAR_GROUP_DATA';

export const GROUP_MODULES = [
  {
    id: 1,
    img: filesModule,
    moduleKey: 'files',
    icon: 'fa-files-o',
  },
  {
    id: 2,
    img: calendarModule,
    moduleKey: 'calendar',
    icon: 'fa-calendar',
  },
  {
    id: 3,
    img: galleryModule,
    moduleKey: 'gallery',
    icon: 'fa-picture-o',
  },
  {
    id: 4,
    img: surveysModule,
    moduleKey: 'survey',
    icon: 'fa-question-circle',
  },
  {
    id: 5,
    img: tasksModule,
    moduleKey: 'tasks',
    icon: 'fa-tasks',
  },
  {
    id: 6,
    img: translationsModule,
    moduleKey: 'translation',
    icon: 'fa-book',
  },
  {
    id: 7,
    img: portfolioModule,
    moduleKey: 'portfolio',
    icon: 'fa-address-card',
  },
  {
    id: 8,
    img: checkInOutModule,
    moduleKey: 'checkinout',
    icon: 'fa-address-book',
  },
  {
    id: 9,
    img: parentSurveyModule,
    moduleKey: 'parent_survey',
    icon: 'fa-check',
  },
  {
    id: 14,
    img: bazaarModule,
    moduleKey: 'bazaar',
    icon: 'fa-shopping-cart',
  },
  {
    id: 11,
    img: parentSurveyModule,
    moduleKey: 'signature',
    icon: 'fa-pencil-square-o',
  },
  {
    id: 16,
    img: foodOrderModule,
    moduleKey: 'food_order',
    icon: 'fa-cutlery',
  },
  {
    id: 17,
    img: wikiModule,
    moduleKey: 'wiki',
    icon: 'fa-book',
  },
  {
    id: 18,
    img: coronaTestModule,
    moduleKey: 'corona_test',
    icon: 'fa-certificate',
  },
  {
    id: 19,
    img: chatModule,
    moduleKey: 'chat',
    icon: 'fa-envelope-o',
  },
  {
    id: 20,
    img: whiteboardModule,
    moduleKey: 'whiteboard',
    icon: 'fa-pencil',
  },
  {
    id: 21,
    img: checkInOutModule,
    moduleKey: 'employee_checkinout',
    icon: 'fa-address-card',
  },
  {
    id: 22,
    img: calendarModule,
    moduleKey: 'working_hours',
    icon: 'fa-calendar',
  },
  {
    id: 26,
    img: kitalinoInterfaceModule,
    moduleKey: 'kitalino_interface',
    icon: 'fa-circle-o',
  },
  {
    id: 27,
    img: tableTemplatesModule,
    moduleKey: 'table_templates',
    icon: 'fa-table',
  },
];

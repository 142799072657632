import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { editConfig, getConfig } from 'library/api/kita';
import CheckBox from 'library/common/commonComponents/Checkbox';
import styles from './authentication2fa.module.scss';
import Button from 'library/common/commonComponents/Buttons/Button';
import Input from 'library/common/commonComponents/Inputs/Input';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import { setKitaConfig } from 'library/common/commonActions/kitaActions';

import store from 'main/store/configureStore';

function Authentication2FA() {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const [configs, setConfigs] = useState({
    lastLoginVisible: false,
    signatureVisible: false,
    communicationVisible: false,
    receivePrivateMessage: false,
    groupMandatoryField: false,
    allowParentsAddingChild: false,
    kidContactAvailableForEmployee: false,
    specificPrivacyVisible: false,
    memberOptionVisible: false,
    ageOfChild: false,
    ageOfChildValue: 1,
    childAbsenceExportGreyedOut: false,
  });

  useEffect(() => {
    const fetchConfigData = async () => {
      try {
        const res = await getConfig();
        const kitaConfig = res.data;

        let fetchedAgeValue = kitaConfig.ageOfChildValue || 1;
        if (fetchedAgeValue < 1) {
          fetchedAgeValue = 1;
        }

        setConfigs(prev => ({
          ...prev,
          lastLoginVisible: kitaConfig.lastLoginVisible,
          signatureVisible: kitaConfig.signatureVisible,
          communicationVisible: kitaConfig.communicationVisible,
          receivePrivateMessage: kitaConfig.receivePrivateMessage,
          groupMandatoryField: kitaConfig.groupMandatoryField,
          allowParentsAddingChild: kitaConfig.allowParentsAddingChild,
          kidContactAvailableForEmployee: kitaConfig.kidContactAvailableForEmployee,
          specificPrivacyVisible: kitaConfig.specificPrivacyVisible,
          memberOptionVisible: kitaConfig.memberOptionVisible,
          ageOfChild: kitaConfig.ageOfChild,
          ageOfChildValue: fetchedAgeValue,
          childAbsenceExportGreyedOut: kitaConfig.childAbsenceExportGreyedOut,
        }));
      } catch (error) {
        console.error(error);
      }
    };
    fetchConfigData();
  }, []);

  const handleCheckboxChange = key => {
    setConfigs(prev => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const handleIncrementAge = () => {
    setConfigs(prev => ({
      ...prev,
      ageOfChildValue: prev.ageOfChildValue + 1,
    }));
  };

  const handleDecrementAge = () => {
    setConfigs(prev => {
      const newValue = Math.max(prev.ageOfChildValue - 1, 1);
      return {
        ...prev,
        ageOfChildValue: newValue,
      };
    });
  };

  const handleAgeInputChange = e => {
    const rawValue = e.target.value.replace(/[^\d]/g, '');
    let intValue = parseInt(rawValue, 10);

    if (isNaN(intValue) || intValue < 1) {
      intValue = 1;
    }

    setConfigs(prev => ({
      ...prev,
      ageOfChildValue: intValue,
    }));
  };

  const handleSave = () => {
    setIsLoading(true);

    const payload = {
      lastLoginVisible: configs.lastLoginVisible,
      signatureVisible: configs.signatureVisible,
      communicationVisible: configs.communicationVisible,
      receivePrivateMessage: configs.receivePrivateMessage,
      groupMandatoryField: configs.groupMandatoryField,
      allowParentsAddingChild: configs.allowParentsAddingChild,
      kidContactAvailableForEmployee: configs.kidContactAvailableForEmployee,
      specificPrivacyVisible: configs.specificPrivacyVisible,
      memberOptionVisible: configs.memberOptionVisible,
      ageOfChild: configs.ageOfChild,
      ageOfChildValue: configs.ageOfChild ? configs.ageOfChildValue : 1,
      childAbsenceExportGreyedOut: configs.childAbsenceExportGreyedOut,
    };

    editConfig(payload)
      .then(() => {
        setIsLoading(false);
        store.dispatch(setKitaConfig(payload));

        store.dispatch(
          showBottomNotification(t('Settings.Settings has been saved'), {
            isFail: false,
          }),
        );
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className={styles.form2faGroups}>
      <div className={styles.form2faLabel}>{t('GeneralSetting.Authentication2FA')}</div>

      <CheckBox
        className={styles.checkboxSpace}
        name={t('Administration.Enable Last Login')}
        isChecked={configs.lastLoginVisible}
        isLoading={isLoading}
        onChange={() => handleCheckboxChange('lastLoginVisible')}
      />
      <CheckBox
        className={styles.checkboxSpace}
        name={t('Administration.Enable Signature Tab')}
        isChecked={configs.signatureVisible}
        isLoading={isLoading}
        onChange={() => handleCheckboxChange('signatureVisible')}
      />
      <CheckBox
        className={styles.checkboxSpace}
        name={t('Administration.Enable Communication')}
        isChecked={configs.communicationVisible}
        isLoading={isLoading}
        onChange={() => handleCheckboxChange('communicationVisible')}
      />
      <CheckBox
        className={styles.checkboxSpace}
        name={t('Administration.Enable Receive Private Message')}
        isChecked={configs.receivePrivateMessage}
        isLoading={isLoading}
        onChange={() => handleCheckboxChange('receivePrivateMessage')}
      />
      <CheckBox
        className={styles.checkboxSpace}
        name={t('Administration.EnableGroupMandatoryField')}
        isChecked={configs.groupMandatoryField}
        isLoading={isLoading}
        onChange={() => handleCheckboxChange('groupMandatoryField')}
      />
      <CheckBox
        className={styles.checkboxSpace}
        name={t('AllowParentsAddingChild')}
        isChecked={configs.allowParentsAddingChild}
        isLoading={isLoading}
        onChange={() => handleCheckboxChange('allowParentsAddingChild')}
      />
      <CheckBox
        className={styles.checkboxSpace}
        name={t('Administration.KidContactAvailableForEmployee')}
        isChecked={configs.kidContactAvailableForEmployee}
        isLoading={isLoading}
        onChange={() => handleCheckboxChange('kidContactAvailableForEmployee')}
      />
      <CheckBox
        className={styles.checkboxSpace}
        name={t('Administration.SpecificPrivacyVisible')}
        isChecked={configs.specificPrivacyVisible}
        isLoading={isLoading}
        onChange={() => handleCheckboxChange('specificPrivacyVisible')}
      />
      <CheckBox
        className={styles.checkboxSpace}
        name={t('Administration.MemberOptionVisible')}
        isChecked={configs.memberOptionVisible}
        isLoading={isLoading}
        onChange={() => handleCheckboxChange('memberOptionVisible')}
      />

      <CheckBox
        className={styles.checkboxSpace}
        name={t('Administration.Set Age of Child')}
        isChecked={configs.ageOfChild}
        isLoading={isLoading}
        onChange={() => handleCheckboxChange('ageOfChild')}
      />

      <CheckBox
        className={styles.checkboxSpace}
        name={t('Administration.ChildAbsenceExportGreyedOut')}
        isChecked={configs.childAbsenceExportGreyedOut}
        isLoading={isLoading}
        onChange={() => handleCheckboxChange('childAbsenceExportGreyedOut')}
      />

      {configs.ageOfChild && (
        <div className={styles.ageCounter}>
          <Button type='button' className={styles.counterBtn} onClick={handleDecrementAge}>
            <i className='fa fa-minus' />
          </Button>

          <div className={styles.counterInput}>
            <Input
              className={styles.counterValue}
              type='text'
              value={configs.ageOfChildValue}
              onChange={handleAgeInputChange}
            />
          </div>

          <Button type='button' className={styles.counterBtn} onClick={handleIncrementAge}>
            <i className='fa fa-plus' />
          </Button>
          <div className={styles.inMonths}>{t('(Basic.In months)')}</div>
        </div>
      )}

      <div className={styles.saveButtonWrapper}>
        <Button
          type='primary'
          className={styles.saveButton}
          onClick={handleSave}
          disabled={isLoading}
        >
          {t('Confirm.Save')}
        </Button>
      </div>
    </div>
  );
}

export default Authentication2FA;

import './polyfills';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';

import ErrorBoundary from './sentry';
import configureI18n from './i18n';
import App from './App';
import { ServerCheckPanel } from 'library/common/commonComponents/serverCheckPanel';

const location = window.location.toString();
if (location.includes('www')) {
  window.location.href = window.location.toString().replace('www.', '');
}

const Main = () => {
  const [serverStatus, setServerStatus] = useState('');

  const checkServerStatus = useCallback(async () => {
    const result = await configureI18n();
    if (result) {
      setServerStatus('up');
    } else {
      setServerStatus('down');
    }
  }, []);

  useEffect(() => {
    checkServerStatus();
  }, [checkServerStatus]);

  const handleServerBack = async () => {
    await checkServerStatus();
  }

  return (
    <>
      {serverStatus === 'up' ? (
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      ) : serverStatus === 'down'
        ? (
          <ServerCheckPanel onServerUp={handleServerBack} />
        ) : ''
      }
    </>
  );
};

ReactDOM.render(<Main />, document.getElementById('root'));

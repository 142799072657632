import React, { useEffect } from 'react';

import serverCheckIcon from 'resources/images/server-check.svg';
import styles from './serverCheckPanel.module.scss';
import { getAppName } from 'library/api/tenant';

export const ServerCheckPanel = ({ onServerUp }) => {

  useEffect(() => {
    const interval = setInterval(async () => {
      const data = await getAppName();

      if (data.status === 200) {
        clearInterval(interval);
        onServerUp();
      }
    }, 30000);

    return () => clearInterval(interval);
  }, [onServerUp]);

  return (
    <div className={styles.serverCheckPanel}>
      <img
        className={styles.bannerImage}
        src={serverCheckIcon}
      />
      <span className={styles.description}>Wir führen gerade ein Update durch - das System steht innerhalb von 30 Minuten wieder zur Verfügung. Wir bedanken uns für Ihr Verständnis.</span>
    </div>
  )
}

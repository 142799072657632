import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from 'library/common/commonComponents/Checkbox';
import styles from './foodOrderSettings.module.scss';
import Input from '../../../Inputs/Input';
import Button from '../../../Buttons/Button';
import FinAPISettings from '../FinAPISettings';
import { getSettings, saveSettings } from '../../../../../api/foodOrder';
import { formatPrice } from '../../../../../utilities/foodOrder';
import cn from 'classnames';
import Loader from 'library/common/commonComponents/Loader';

export default function FoodOrderSettings({
  showBottomNotification,
  setIsSettingsOpen,
  groupId,
  activeKita,
}) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [lastFoodOrderTime, setLastFoodOrderTime] = useState(null);
  const [lastCancelFoodOrderTime, setLastCancelFoodOrderTime] = useState(null);
  const [defaultPrice, setDefaultPrice] = useState('');
  const [useOnlinePayment, setUseOnlinePayment] = useState(false);
  const [usePreorder, setUsePreorder] = useState(false);
  const [useWeeklyPreorder, setUseWeeklyPreorder] = useState(false);
  const [enableCancellation, setEnableCancellation] = useState(false);
  const [enableRepetition, setEnableRepition] = useState(false);
  const [noOfRepetition, setNumberOfRepetition] = useState(1);
  const [orderTimeToday, setOrderTimeToday] = useState(false);
  const [orderTimeYesterday, setOrderTimeYesterday] = useState(false);
  const [orderTimeTwoDaysAgo, setOrderTimeTwoDaysAgo] = useState(false);
  const [cancelTimeToday, setCancelTimeToday] = useState(false);
  const [cancelTimeYesterday, setCancelTimeYesterday] = useState(false);
  const [cancelTimeTwoDaysAgo, setCancelTimeTwoDaysAgo] = useState(false);
  const [monthlyMail, setMonthlyMail] = useState(false);
  const [mailOnSubmitting, setMailOnSubmitting] = useState(false);
  const [useAutoFoodOrder, setUseAutoFoodOrder] = useState(false);
  const [sicknessCancelsOrders, setSicknessCancelsOrders] = useState(false);

  useEffect(() => {
    getSettings(groupId).then(res => {
      setLastFoodOrderTime(res.data.lastFoodOrderTime);
      setLastCancelFoodOrderTime(res.data.lastCancelFoodOrderTime);
      setDefaultPrice(formatPrice(res.data.defaultPrice));
      setUseOnlinePayment(res.data.useOnlinePayment);
      setUsePreorder(res.data.usePreorder);
      setUseWeeklyPreorder(res.data.useWeeklyPreorder);
      setEnableCancellation(res.data.enableCancellation);
      setEnableRepition(res.data.enableRepetition);
      setNumberOfRepetition(res.data.noOfRepetition ? res.data.noOfRepetition : 1);
      setOrderTimeToday(res.data.orderTimeToday ? res.data.orderTimeToday : false);
      setOrderTimeYesterday(res.data.orderTimeYesterday ? res.data.orderTimeYesterday : false);
      setOrderTimeTwoDaysAgo(res.data.orderTimeTwoDaysAgo ? res.data.orderTimeTwoDaysAgo : false);
      setCancelTimeToday(res.data.cancelTimeToday ? res.data.cancelTimeToday : false);
      setCancelTimeYesterday(res.data.cancelTimeYesterday ? res.data.cancelTimeYesterday : false);
      setCancelTimeTwoDaysAgo(
        res.data.cancelTimeTwoDaysAgo ? res.data.cancelTimeTwoDaysAgo : false,
      );
      setMailOnSubmitting(res.data.sendMailOnSubmitting);
      setMonthlyMail(res.data.sendMonthlyMail);
      setUseAutoFoodOrder(res.data.useAutoFoodOrder ? res.data.useAutoFoodOrder : false);
      setSicknessCancelsOrders(res.data.sicknessCancelsOrders);
      setIsLoading(false);
    });
  }, []);

  const submitSettings = () => {
    setIsLoading(true);
    saveSettings({
      groupId,
      lastFoodOrderTime,
      lastCancelFoodOrderTime,
      defaultPrice,
      useOnlinePayment,
      usePreorder,
      useWeeklyPreorder,
      enableCancellation,
      enableRepetition,
      noOfRepetition,
      orderTimeToday,
      orderTimeYesterday,
      orderTimeTwoDaysAgo,
      cancelTimeToday,
      cancelTimeYesterday,
      cancelTimeTwoDaysAgo,
      mailOnSubmitting,
      monthlyMail,
      useAutoFoodOrder,
      sicknessCancelsOrders,
    })
      .then(res => {
        showBottomNotification(t('FoodOrder.Save'));
      })
      .catch(err => {
        showBottomNotification(t('FoodOrder.Notification.Error'), { isFail: true });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const changeOrderTime = day => {
    if (day == 'today') {
      if (!orderTimeToday && orderTimeYesterday) {
        setOrderTimeYesterday(!orderTimeYesterday);
      }
      if (!orderTimeToday && orderTimeTwoDaysAgo) {
        setOrderTimeTwoDaysAgo(!orderTimeTwoDaysAgo);
      }
      setOrderTimeToday(!orderTimeToday);
    } else if (day == 'yesterday') {
      if (!orderTimeYesterday && orderTimeToday) {
        setOrderTimeToday(!orderTimeToday);
      }
      if (!orderTimeYesterday && orderTimeTwoDaysAgo) {
        setOrderTimeTwoDaysAgo(!orderTimeTwoDaysAgo);
      }
      setOrderTimeYesterday(!orderTimeYesterday);
    } else if (day == 'twoDaysAgo') {
      if (!orderTimeTwoDaysAgo && orderTimeToday) {
        setOrderTimeToday(!orderTimeToday);
      }
      if (!orderTimeTwoDaysAgo && orderTimeYesterday) {
        setOrderTimeYesterday(!orderTimeYesterday);
      }
      setOrderTimeTwoDaysAgo(!orderTimeTwoDaysAgo);
    }
  };

  const changeCancelTime = day => {
    if (day == 'today') {
      if (!cancelTimeToday && cancelTimeYesterday) {
        setCancelTimeYesterday(!cancelTimeYesterday);
      }
      if (!cancelTimeToday && cancelTimeTwoDaysAgo) {
        setCancelTimeTwoDaysAgo(!cancelTimeTwoDaysAgo);
      }
      setCancelTimeToday(!cancelTimeToday);
    } else if (day == 'yesterday') {
      if (!cancelTimeYesterday && cancelTimeToday) {
        setCancelTimeToday(!cancelTimeToday);
      }
      if (!cancelTimeYesterday && cancelTimeTwoDaysAgo) {
        setCancelTimeTwoDaysAgo(!cancelTimeTwoDaysAgo);
      }
      setCancelTimeYesterday(!cancelTimeYesterday);
    } else if (day == 'twoDaysAgo') {
      if (!cancelTimeTwoDaysAgo && cancelTimeToday) {
        setCancelTimeToday(!cancelTimeToday);
      }
      if (!cancelTimeTwoDaysAgo && cancelTimeYesterday) {
        setCancelTimeYesterday(!cancelTimeYesterday);
      }
      setCancelTimeTwoDaysAgo(!cancelTimeTwoDaysAgo);
    }
  };

  return !isLoading ? (
    <>
      <div>
        <h2>{t('FoodOrder.Settings')}</h2>

        <h3 className={styles.mt3}>{t('FoodOrder.ordertime')}</h3>
        <p className={styles.mt1}>{t('FoodOrder.Last time to order a meal')}</p>
        <Input
          className={styles.timeSelect}
          type='time'
          placeholder='HH:mm'
          onChange={e => {
            setLastFoodOrderTime(e.target.value);
          }}
          value={lastFoodOrderTime}
        />
        <Checkbox
          className={styles.checkbox}
          name={t('FoodOrder.OrderTimeToday')}
          onChange={() => changeOrderTime('today')}
          isChecked={orderTimeToday}
        />
        <Checkbox
          className={styles.checkbox}
          name={t('FoodOrder.OrderTimeYesterday')}
          onChange={() => changeOrderTime('yesterday')}
          isChecked={orderTimeYesterday}
        />
        <Checkbox
          className={styles.checkbox}
          name={t('FoodOrder.OrderTimeTwoDaysAgo')}
          onChange={() => changeOrderTime('twoDaysAgo')}
          isChecked={orderTimeTwoDaysAgo}
        />
        <p className={styles.mt2}>{t('FoodOrder.Last time to cancel a meal order')}</p>
        <Input
          className={styles.timeSelect}
          placeholder='HH:mm'
          type='time'
          onChange={e => {
            setLastCancelFoodOrderTime(e.target.value);
          }}
          value={lastCancelFoodOrderTime}
        />
        <Checkbox
          className={styles.checkbox}
          name={t('FoodOrder.CancelTimeToday')}
          onChange={() => changeCancelTime('today')}
          isChecked={cancelTimeToday}
        />
        <Checkbox
          className={styles.checkbox}
          name={t('FoodOrder.OrderTimeYesterday')}
          onChange={() => changeCancelTime('yesterday')}
          isChecked={cancelTimeYesterday}
        />
        <Checkbox
          className={styles.checkbox}
          name={t('FoodOrder.OrderTimeTwoDaysAgo')}
          onChange={() => changeCancelTime('twoDaysAgo')}
          isChecked={cancelTimeTwoDaysAgo}
        />

        <h3 className={styles.mt3}>{t('FoodOrder.Default meal price')}</h3>
        <div className={styles.timeSelect}>
          <Input
            type='number'
            value={defaultPrice}
            onChange={e => {
              setDefaultPrice(e.target.value);
            }}
            min={0.0}
            onBlur={e => setDefaultPrice(formatPrice(e.target.value))}
            faIcon='fa-eur'
          />
        </div>
        <p>
          {t(
            'FoodOrder.The default price will be used if no meal price is entered while creating a meal',
          )}
        </p>

        {/*<h3 className={styles.mt3}>{t('FoodOrder.OnlinePayment')}</h3>
                <Checkbox
                  className={styles.checkbox}
                  name={t('FoodOrder.UseOnlinePayment')}
                  onChange={() => setUseOnlinePayment(!useOnlinePayment)}
                  isChecked={useOnlinePayment}
                />*/}
        <h3 className={styles.mt3}>{t('FoodOrder.Preorder')}</h3>
        <Checkbox
          className={styles.checkbox}
          name={t('FoodOrder.UsePreorder')}
          onChange={() => {
            setUsePreorder(!usePreorder);
          }}
          isChecked={usePreorder}
        />
        <h3 className={styles.mt3}>{t('FoodOrder.WeeklyPreorder')}</h3>
        <Checkbox
          className={styles.checkbox}
          name={t('FoodOrder.UseWeeklyPreorder')}
          onChange={() => setUseWeeklyPreorder(!useWeeklyPreorder)}
          isChecked={useWeeklyPreorder}
        />

        <h3 className={styles.mt3}>{t('FoodOrder.UseAutoFoodOrder')}</h3>
        <Checkbox
          className={styles.checkbox}
          name={t('FoodOrder.Auto food order for parents')}
          onChange={() => setUseAutoFoodOrder(!useAutoFoodOrder)}
          isChecked={useAutoFoodOrder}
        />

        <h3 className={styles.mt3}>{t('FoodOrder.EnableCancellationHeader')}</h3>
        <Checkbox
          className={styles.checkbox}
          name={t('FoodOrder.EnableCancellation')}
          onChange={() => setEnableCancellation(!enableCancellation)}
          isChecked={enableCancellation}
        />
        <h3 className={styles.mt3}>{t('FoodOrder.UseRepetationOfMenuplan')}</h3>
        <Checkbox
          className={styles.checkbox}
          name={t('FoodOrder.UseRepetationOfMenuplan')}
          onChange={() => setEnableRepition(!enableRepetition)}
          isChecked={enableRepetition}
        />

        <br />
        {enableRepetition && (
          <div class={styles.repetitionContainer}>
            <div
              className={cn(styles.changeStatusCheckOut, styles.changeStatus)}
              onClick={() => {
                if (noOfRepetition > 1) {
                  setNumberOfRepetition(prevState => {
                    return prevState - 1;
                  });
                }
              }}
            >
              <i className='fa fa-minus' />
            </div>
            <div className={styles.wickelCounter}>{noOfRepetition}</div>
            <div
              className={cn(styles.changeStatusCheckIn, styles.changeStatus)}
              onClick={() => {
                setNumberOfRepetition(prevState => {
                  return prevState + 1;
                });
              }}
            >
              <i className='fa fa-plus' />
            </div>{' '}
            {t('Food Order.(No. Of Repetitions)')}
          </div>
        )}
        <br />
        <h3 className={styles.mt3}>{t('FoodOrderSettings.SicknessCancelsOrdersCheckboxHeader')}</h3>
        <Checkbox
          className={styles.checkbox}
          name={t('FoodOrderSettings.SicknessCancelsOrdersCheckboxLabel')}
          onChange={() => setSicknessCancelsOrders(!sicknessCancelsOrders)}
          isChecked={sicknessCancelsOrders}
        />
        <br />
        <Button className={styles.mr1} type='primary' onClick={() => submitSettings()}>
          {t('FoodOrder.Save')}
        </Button>
        <Button type='secondary' onClick={() => setIsSettingsOpen(false)}>
          {t('FoodOrder.Back to the Dashboard')}
        </Button>
        <br />
        <br />
      </div>
    </>
  ) : (
    <Loader />
  );
}

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useSelector } from 'react-redux';

// import { SketchPicker } from 'react-color';
import { getTimeZoneList } from 'library/api/calendar';
import { isAwoWW } from 'library/api/tenantConfig';
import { getUserById } from 'library/api/user';

import Button from 'library/common/commonComponents/Buttons/Button';
import ColorPickerButton from 'library/common/commonComponents/Buttons/ColorPickerButton';
import PostLoadTemplateButton from 'library/common/commonComponents/Buttons/PostLoadTemplateButton';
import CheckBox from 'library/common/commonComponents/Checkbox';
import DatePicker from 'library/common/commonComponents/DatePicker';
import Input from 'library/common/commonComponents/Inputs/Input';
import Label from 'library/common/commonComponents/Label';
import RadioButton from 'library/common/commonComponents/RadioButton';
import InputErrorMsg from 'library/common/commonComponents/to-refactor/commonInputErrorMsg';
import Select from 'library/common/commonComponents/Inputs/Select';
import useEditor from 'library/common/commonHooks/useEditor';
import { checkLink } from 'library/utilities/checkLink';
import { useOutsideEvent } from 'library/common/commonHooks/useOutsideEvent';
import GroupsTagInput from 'library/common/commonComponents/Inputs/TagInput/GroupsTagInput';
import SelectTagInput from 'library/common/commonComponents/Inputs/TagInput/SelectTagInput';
import * as moment from 'moment';
import { getGroupMembersWithoutFilterSorting } from 'library/common/commonActions/groupActions';
import { getGroupById } from 'library/api/groups';

import styles from './createEventBasic.module.scss';
import TagInput from 'library/common/commonComponents/Inputs/TagInput';
import { G } from '@react-pdf/renderer';

export default function CreateEventBasic({
  createEventState,
  createEventDispatch,
  eventTypes,
  langCode,
  convertDaysByteToArray,
  groupId,
  updateCreateEventState,
}) {
  const { t } = useTranslation();
  const awoWW = isAwoWW();
  const [isColorPickerVisible, setIsColorPickerVisible] = useState(false);
  const [timeZoneIsOpen, setTimeZoneIsOpen] = useState(false);
  const [timeZoneOptions, setTimeZoneOptions] = useState([
    {
      value: createEventState.timeZone,
      label: createEventState.timeZone,
    },
  ]);
  const [timeZoneOption, setTimeZoneOption] = useState(timeZoneOptions[0]);
  const [timeZoneListIsDownloaded, setTimeZoneListIsDownloaded] = useState(false);
  const [participantsObj, setParticipantsObj] = useState({
    users: [],
  });
  const [groupParticipants, setGroupParticipants] = useState({
    groups: [],
    groupNames: [],
    users: [],
    selectedGroups: [],
  });

  const user = useSelector(state => state.userReducer);

  const handleOpenTimeZoneList = () => {
    if (!timeZoneListIsDownloaded) {
      getTimeZoneList()
        .then(res => {
          if (res.status === 200) {
            setTimeZoneOptions(res.data.map(item => ({ value: item, label: item })));
            const opton = res.data.find(item => item === createEventState.timeZone) || res.data[0];
            setTimeZoneOption({ value: opton, label: opton });
            setTimeZoneListIsDownloaded(true);
            setTimeZoneIsOpen(true);
          }
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      setTimeZoneIsOpen(!timeZoneIsOpen);
    }
  };
  const handleSetTimeZone = option => {
    createEventDispatch({ type: 'setTimeZone', payload: option });
    setTimeZoneOption(option);
    setTimeZoneIsOpen(false);
  };

  const eventTypeOptions =
    eventTypes && eventTypes.length
      ? [{ value: '0', label: t('Calendar.Select event type'), color: '#6fdbe8' }].concat(
        eventTypes.map(item => {
          return { value: `${item.id}`, label: item.name, color: item.colourCode };
        }),
      )
      : [{ value: '0', label: t('Calendar.Select event type'), color: '#6fdbe8' }];
  const eventTypeIndex = eventTypeOptions.findIndex(
    item => item.value === `${createEventState.eventType}`,
  );
  const [eventTypeOption, setEventTypeOption] = useState(
    eventTypeIndex !== -1 ? eventTypeOptions[eventTypeIndex] : eventTypeOptions[0],
  );

  const reminderTypeOptions = [
    {
      value: 'days',
      label: t('Calendar.ReminderDaysBefore'),
    },
    {
      value: 'hours',
      label: t('Calendar.ReminderHoursBefore'),
    },
  ];

  const outsideEventContainerRef = useOutsideEvent({
    mouseDown: true,
    touchStart: true,
    handler: () => setIsColorPickerVisible(false),
  });

  const wrappedMessage = document.createElement('div');
  wrappedMessage.innerHTML = createEventState.description;
  const { Editor, getEditorValue, updateValue } = useEditor({
    message: wrappedMessage,
    placeholder: t('Calendar.Description'),
    onChange: () => {
      const value = getEditorValue();
      createEventDispatch({ type: 'setDescription', payload: checkLink(value) });
    },
    className: styles.textareaContent,
  });

  const setEventType = option => {
    createEventDispatch({ type: 'setEventType', payload: option });
    createEventDispatch({ type: 'setColor', payload: option.color });
    setEventTypeOption(option);
  };

  const handleChange = value => {
    createEventDispatch({ type: 'setError', payload: '' });
    createEventDispatch({ type: 'setTitle', payload: value });
  };

  const loadCalendarEventFromTemplate = (template, calendarTemplate) => {
    const descWrapped = document.createElement('div');
    descWrapped.innerHTML = calendarTemplate.description;

    createEventDispatch({ type: 'setTitle', payload: calendarTemplate.title });
    createEventDispatch({ type: 'setDescription', payload: calendarTemplate.description });
    updateValue(descWrapped);
    createEventDispatch({ type: 'setColor', payload: calendarTemplate.color });
    createEventDispatch({ type: 'setAllDayToFixedValue', payload: calendarTemplate.allDay });
    createEventDispatch({ type: 'setRepeatType', payload: calendarTemplate.repeatType });
    createEventDispatch({
      type: 'setRepeatWeeklyInterval',
      payload: calendarTemplate.repeatWeeklyInterval,
    });
    createEventDispatch({
      type: 'setRepeatMonthlyInterval',
      payload: calendarTemplate.repeatMonthlyInterval,
    });
    createEventDispatch({
      type: 'setRepeatDays',
      payload: convertDaysByteToArray(calendarTemplate.repeatDays),
    });
    createEventDispatch({ type: 'setRepeatEnd', payload: calendarTemplate.repeatEndDate });
    createEventDispatch({ type: 'setLocation', payload: calendarTemplate.location });
    createEventDispatch({ type: 'setStreetMap', payload: calendarTemplate.streetMap });
    createEventDispatch({ type: 'setReminders', payload: calendarTemplate.reminders });
  };

  const handleAddParentParticipant = data => {
    setParticipantsObj(prev => ({ ...prev, users: data.users }));
    createEventDispatch({ type: 'setParentParticipants', payload: data.users.map(u => u.id) });
    createEventDispatch({
      type: 'setCanParticipate',
      payload: {
        value: 'INVITED_PARTICIPANTS_ONLY',
        label: t('Calendar.Invited participants only'),
      },
    });
  };

  const addGroupParticipant = async (groups, parentParticipants) => {
    const allUsers = await Promise.all(
      groups.map(async groupItem => {
        const res = await getGroupMembersWithoutFilterSorting(groupItem.id);
        return res.data.content
          .filter(({ user }) => user?.id && (!parentParticipants || parentParticipants.includes(user.id)))
          .map(u => ({
            ...u.user,
            id: u.user.id.toString(),
          }));
      }),
    );

    setGroupParticipants(prevState => {
      const updatedGroups = [...prevState.groups, ...allUsers];
      const updatedGroupNames = Array.from(
        new Set([...prevState.groupNames, ...groups.map(g => g.groupName)]),
      );
      const selectedGroups = [...prevState.selectedGroups, ...groups.map(g => g.id)];

      return {
        ...prevState,
        groups: updatedGroups,
        groupNames: updatedGroupNames,
        selectedGroups: selectedGroups,
      };
    });

    const users = [...groupParticipants.groups, ...allUsers].flat().map(user => user.id);
    const selectedGroups = [...groupParticipants.selectedGroups, ...groups.map(g => g.id)];

    createEventDispatch({
      type: 'setParentParticipants',
      payload: [...new Set(users)],
    });

    createEventDispatch({
      type: 'setGroupParticipants',
      payload: [...new Set(selectedGroups)],
    });
  };

  const handleAddGroupsParticipant = data => {
    const groups = data.users.filter(g => g.id);
    addGroupParticipant(groups);
  };

  const handleGroupChange = (data) => {
    const users = data.groups.flat().map(user => user.id);
    createEventDispatch({
      type: 'setParentParticipants',
      payload: [...new Set(users)],
    });
  }

  useEffect(() => {
    const fetchGroupParticipants = async () => {
      if (createEventState.groupParticipants) {
        const groupParticipants = createEventState.groupParticipants.split(',');
        const parentParticipants = (createEventState.parentParticipants || []).map(x => Number(x));

        const groupPromises = groupParticipants.map(g => getGroupById(g));

        try {
          const results = await Promise.all(groupPromises);

          const groups = results
            .map(r => r.data)
            .map(g => ({
              ...g,
              id: g.groupId,
            }));

          addGroupParticipant(groups, parentParticipants);
        } catch (error) {
          console.error('Error fetching group data:', error);
        }
      }
    };
    fetchGroupParticipants();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.postLoadTemplateButtonContainer}>
        <PostLoadTemplateButton
          type='calendar'
          loadCalendarEventFromTemplate={loadCalendarEventFromTemplate}
        />
      </div>
      <div className={styles.inputGroup}>
        <Label type='input'>{t('Calendar.Color')}</Label>
        <div className={styles.inputWithColorPicker} ref={outsideEventContainerRef}>
          <Input
            error={createEventState.error === 'title'}
            value={createEventState.title}
            placeholder={t('Calendar.Title')}
            onChange={e => handleChange(e.target.value)}
            maxLength={255}
          />
          {createEventState.error === 'title' && (
            <div className={styles.error_msg}>
              <InputErrorMsg errorMsg={t('Calendar.Title cannot be blank')} />
            </div>
          )}

          <div className={styles.colorPicker}>
            <div
              className={styles.label}
              onClick={() => setIsColorPickerVisible(!isColorPickerVisible)}
            >
              <div className={styles.current} style={{ background: createEventState.color }} />
            </div>
            {isColorPickerVisible && (
              <ColorPickerButton
                color={createEventState.color}
                className={styles.pickerComponent}
                onClick={c => createEventDispatch({ type: 'setColor', payload: c.hex })}
              />
            )}
          </div>
        </div>
      </div>
      <div className={styles.eventTypeContainer}>
        <Select options={eventTypeOptions} onSelect={setEventType} selected={eventTypeOption} />
      </div>
      <div className={styles.descriptionContainer}>
        <Editor />
      </div>
      <div className={styles.checkboxContainer}>
        {/*
        <CheckBox
          isDisabled={!canBePublic}
          className={styles.checkbox}
          name={t('Calendar.Public')}
          isChecked={createEventState.isPublic}
          onChange={() => createEventDispatch({ type: 'setPublic' })}
        />
        */}
        <CheckBox
          className={styles.checkbox}
          name={t('Calendar.All Day')}
          isChecked={createEventState.allDay}
          onChange={() => createEventDispatch({ type: 'setAllDay' })}
        />
      </div>
      <div className={styles.dateAndTimeInputsContainer}>
        <div className={styles.dateInputsContainer}>
          <div className={styles.dateStart}>
            <Label type='input'>{t('Calendar.Start Date')}</Label>
            <DatePicker
              selected={createEventState.start}
              onChange={date => {
                createEventDispatch({ type: 'setStartDate', payload: date });
                if (createEventState.repeat.type !== 'none') {
                  const repeatEndDate = moment(date)
                    .add(2, 'years')
                    .toDate();
                  createEventDispatch({ type: 'setRepeatEnd', payload: repeatEndDate });
                  if (date > createEventState.end) {
                    createEventDispatch({ type: 'setEndDate', payload: date });
                  }
                }
              }}
              langCode={langCode}
            />
          </div>
          {!['weekly', 'monthly'].includes(createEventState.repeat.type) && (
            <div
              className={cn(
                styles.dateEnd,
                createEventState.error === 'date' && styles.dateEndError,
              )}
            >
              <Label type='input'>{t('Calendar.End Date')}</Label>
              <DatePicker
                selected={
                  createEventState.end === createEventState.start
                    ? createEventState.end
                    : createEventState.end - 1
                }
                onChange={date => {
                  createEventDispatch({ type: 'setError', payload: '' });
                  createEventDispatch({ type: 'setEndDate', payload: date });
                }}
                langCode={langCode}
              />
              {createEventState.error === 'date' && (
                <div className={styles.error_msg}>
                  <InputErrorMsg errorMsg={t('Calendar.End time must be after start time')} />
                </div>
              )}
            </div>
          )}
        </div>
        <div
          className={cn(
            styles.timeInputsContainer,
            createEventState.allDay && styles.timeInputsContainerDisabled,
          )}
        >
          <div className={styles.timeStart}>
            <Label type='input'>{t('Calendar.Start Time')}</Label>
            <DatePicker
              disabled={createEventState.allDay}
              selected={createEventState.start}
              onChange={date =>
                date && createEventDispatch({ type: 'setStartHoursDate', payload: date })
              }
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption='Time'
              timeFormat='HH:mm'
              langCode={langCode}
            />
          </div>
          <div className={styles.timeEnd}>
            <Label type='input'>{t('Calendar.End Time')}</Label>
            <DatePicker
              disabled={createEventState.allDay}
              selected={createEventState.end - 1}
              onChange={date => {
                if (date) {
                  createEventDispatch({ type: 'setError', payload: '' });
                  createEventDispatch({ type: 'setEndHoursDate', payload: date });
                }
              }}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption='Time'
              timeFormat='HH:mm'
              langCode={langCode}
            />
          </div>
        </div>
      </div>
      <div className={styles.timezoneContainer}>
        <div className={styles.timezoneTitle} onClick={handleOpenTimeZoneList}>
          {createEventState.timeZone}
        </div>
        {timeZoneIsOpen && (
          <div className={styles.timezoneDropdown}>
            <Select
              options={timeZoneOptions}
              onSelect={handleSetTimeZone}
              selected={timeZoneOption}
              className={styles.timezoneSelect}
              openedClassName={styles.timezoneSelectOpen}
            />
          </div>
        )}
      </div>
      <div>
        <div className={styles.checkboxContainer}>
          <CheckBox
            className={styles.checkbox}
            name={t('Calendar.Repeat')}
            isChecked={createEventState.repeat.type !== 'none'}
            onChange={() => {
              const isChecked = createEventState.repeat.type === 'none';
              createEventDispatch({ type: 'toggleRepeat' });
              if (isChecked) {
                const repeatEndDate = moment(createEventState.start)
                  .add(2, 'years')
                  .toDate();
                createEventDispatch({ type: 'setRepeatEnd', payload: repeatEndDate });
              }
            }}
          />
        </div>
        {createEventState.repeat.type !== 'none' && (
          <div>
            <Label type='input'>{t('Calendar.RepeatType')}</Label>
            <RadioButton
              checked={createEventState.repeat.type === 'daily'}
              label={t('Calendar.RepeatDaily')}
              onClick={() => createEventDispatch({ type: 'setRepeatType', payload: 'daily' })}
            />
            <RadioButton
              checked={createEventState.repeat.type === 'weekly'}
              label={t('Calendar.RepeatWeekly')}
              onClick={() => createEventDispatch({ type: 'setRepeatType', payload: 'weekly' })}
            />
            {createEventState.repeat.type === 'weekly' && (
              <div>
                <Label type='input'>{t('Calendar.RepeatEveryXWeeks')}</Label>
                <Input
                  type='number'
                  error={createEventState.error === 'repeatWeeklyInterval'}
                  value={createEventState.repeat.weekInterval}
                  onChange={e =>
                    createEventDispatch({
                      type: 'setRepeatWeeklyInterval',
                      payload: e.target.value,
                    })
                  }
                  maxLength={2}
                />
                {createEventState.error === 'repeatWeeklyInterval' && (
                  <div className={styles.error_msg}>
                    <InputErrorMsg errorMsg={t('Calendar.RepeatWeeklyIntervalInvalid')} />
                  </div>
                )}
                <Label type='input'>{t('Calendar.RepeatDays')}</Label>
                <CheckBox
                  className={styles.checkbox}
                  name={t('Calendar.RepeatMonday')}
                  isChecked={createEventState.repeat.days.includes(1)}
                  onChange={() => createEventDispatch({ type: 'toggleRepeatDay', payload: 1 })}
                />
                <CheckBox
                  className={styles.checkbox}
                  name={t('Calendar.RepeatTuesday')}
                  isChecked={createEventState.repeat.days.includes(2)}
                  onChange={() => createEventDispatch({ type: 'toggleRepeatDay', payload: 2 })}
                />
                <CheckBox
                  className={styles.checkbox}
                  name={t('Calendar.RepeatWednesday')}
                  isChecked={createEventState.repeat.days.includes(3)}
                  onChange={() => createEventDispatch({ type: 'toggleRepeatDay', payload: 3 })}
                />
                <CheckBox
                  className={styles.checkbox}
                  name={t('Calendar.RepeatThursday')}
                  isChecked={createEventState.repeat.days.includes(4)}
                  onChange={() => createEventDispatch({ type: 'toggleRepeatDay', payload: 4 })}
                />
                <CheckBox
                  className={styles.checkbox}
                  name={t('Calendar.RepeatFriday')}
                  isChecked={createEventState.repeat.days.includes(5)}
                  onChange={() => createEventDispatch({ type: 'toggleRepeatDay', payload: 5 })}
                />
                <CheckBox
                  className={styles.checkbox}
                  name={t('Calendar.RepeatSaturday')}
                  isChecked={createEventState.repeat.days.includes(6)}
                  onChange={() => createEventDispatch({ type: 'toggleRepeatDay', payload: 6 })}
                />
                <CheckBox
                  className={styles.checkbox}
                  name={t('Calendar.RepeatSunday')}
                  isChecked={createEventState.repeat.days.includes(0)}
                  onChange={() => createEventDispatch({ type: 'toggleRepeatDay', payload: 0 })}
                />
                {createEventState.error === 'repetitionDay' && (
                  <div className={styles.error_msg}>
                    <InputErrorMsg errorMsg={t('Calendar.RepetitionNoDay')} />
                  </div>
                )}
              </div>
            )}
            <RadioButton
              checked={createEventState.repeat.type === 'monthly'}
              label={t('Calendar.RepeatMonthly')}
              onClick={() => createEventDispatch({ type: 'setRepeatType', payload: 'monthly' })}
            />
            {createEventState.repeat.type === 'monthly' && (
              <div>
                <Label type='input'>{t('Calendar.RepeatEveryXthDayOfMonth')}</Label>
                <Input
                  type='number'
                  error={createEventState.error === 'repeatMonthlyInterval'}
                  value={createEventState.repeat.monthInterval}
                  onChange={e =>
                    createEventDispatch({
                      type: 'setRepeatMonthlyInterval',
                      payload: e.target.value,
                    })
                  }
                  maxLength={2}
                />
                {createEventState.error === 'repeatMonthlyInterval' && (
                  <div className={styles.error_msg}>
                    <InputErrorMsg errorMsg={t('Calendar.RepeatMonthlyIntervalInvalid')} />
                  </div>
                )}
                <Label type='input'>{t('Calendar.RepeatDays')}</Label>
                <CheckBox
                  className={styles.checkbox}
                  name={t('Calendar.RepeatMonday')}
                  isChecked={createEventState.repeat.days.includes(1)}
                  onChange={() => createEventDispatch({ type: 'toggleRepeatDay', payload: 1 })}
                />
                <CheckBox
                  className={styles.checkbox}
                  name={t('Calendar.RepeatTuesday')}
                  isChecked={createEventState.repeat.days.includes(2)}
                  onChange={() => createEventDispatch({ type: 'toggleRepeatDay', payload: 2 })}
                />
                <CheckBox
                  className={styles.checkbox}
                  name={t('Calendar.RepeatWednesday')}
                  isChecked={createEventState.repeat.days.includes(3)}
                  onChange={() => createEventDispatch({ type: 'toggleRepeatDay', payload: 3 })}
                />
                <CheckBox
                  className={styles.checkbox}
                  name={t('Calendar.RepeatThursday')}
                  isChecked={createEventState.repeat.days.includes(4)}
                  onChange={() => createEventDispatch({ type: 'toggleRepeatDay', payload: 4 })}
                />
                <CheckBox
                  className={styles.checkbox}
                  name={t('Calendar.RepeatFriday')}
                  isChecked={createEventState.repeat.days.includes(5)}
                  onChange={() => createEventDispatch({ type: 'toggleRepeatDay', payload: 5 })}
                />
                <CheckBox
                  className={styles.checkbox}
                  name={t('Calendar.RepeatSaturday')}
                  isChecked={createEventState.repeat.days.includes(6)}
                  onChange={() => createEventDispatch({ type: 'toggleRepeatDay', payload: 6 })}
                />
                <CheckBox
                  className={styles.checkbox}
                  name={t('Calendar.RepeatSunday')}
                  isChecked={createEventState.repeat.days.includes(0)}
                  onChange={() => createEventDispatch({ type: 'toggleRepeatDay', payload: 0 })}
                />
                {createEventState.error === 'repetitionDay' && (
                  <div className={styles.error_msg}>
                    <InputErrorMsg errorMsg={t('Calendar.RepetitionNoDay')} />
                  </div>
                )}
              </div>
            )}
            <RadioButton
              checked={createEventState.repeat.type === 'annually'}
              label={t('Calendar.RepeatAnnually')}
              onClick={() => createEventDispatch({ type: 'setRepeatType', payload: 'annually' })}
            />
            <div className={styles.dateAndTimeInputsContainer}>
              <div className={styles.dateInputsContainer}>
                <div className={styles.dateStart}>
                  <Label type='input'>{t('Calendar.RepeatEnd')}</Label>
                  <DatePicker
                    selected={createEventState.repeat.end}
                    onChange={date => createEventDispatch({ type: 'setRepeatEnd', payload: date })}
                    langCode={langCode}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={styles.locationInputContainer}>
        <Label type='input'>{t('Calendar.Location')}</Label>
        <Input
          value={createEventState.location}
          placeholder={t('Calendar.Location')}
          onChange={e => createEventDispatch({ type: 'setLocation', payload: e.target.value })}
        />
      </div>
      {/*<div className={styles.locationInputContainer}>
        <Label type='input'>{t('Calendar.Groups')}</Label>
        <SelectTagInput
          value={groupParticipants}
          onDataChange={handleAddGroupsParticipant}
          onGroupChange={handleGroupChange}
          target='groups'
          errors={{ invite: '' }}
          showLabel={false}
          userId={user.id}
        />
      </div>*/}
      {awoWW && (
        <div className={styles.locationInputContainer}>
          <CheckBox
            name={t('Calendar.OpenStreetMap')}
            isChecked={createEventState.streetMap}
            onChange={e =>
              createEventDispatch({ type: 'setStreetMap', payload: !createEventState.streetMap })
            }
          />
        </div>
      )}
      {groupId !== 0 && (
        <>
          <div>
            <Label type='input'>{t('Calendar.Participants')}</Label>
            <TagInput
              value={participantsObj}
              onDataChange={handleAddParentParticipant}
              errors={{ invite: '' }}
              showLabel={false}
              userId={user.id}
              groupId={groupId}
              isGroupMembersOnly={true}
              fetchUser={createEventState ? createEventState.parentParticipants : []}
              hasGroupValue={!!createEventState.groupParticipants}
            />
          </div>
          <div className={styles.checkboxContainer}>
            <CheckBox
              className={styles.checkbox}
              name={t('Calendar.Reminder')}
              isChecked={createEventState?.reminders?.length > 0}
              onChange={() => createEventDispatch({ type: 'toggleReminder' })}
            />
            <div className={styles.reminderContainer}>
              {createEventState.reminders.map((r, i) => {
                return (
                  <div className={styles.reminder}>
                    <Input
                      value={r.value}
                      onChange={e =>
                        createEventDispatch({
                          type: 'updateReminderValue',
                          payload: { index: i, value: e.target.value },
                        })
                      }
                      className={styles.reminderValueInput}
                    />
                    <Select
                      options={reminderTypeOptions}
                      onSelect={option =>
                        createEventDispatch({
                          type: 'updateReminderType',
                          payload: { index: i, type: option.value },
                        })
                      }
                      selected={reminderTypeOptions.find(o => o.value === r.type)}
                      className={styles.reminderTypeSelect}
                    />
                    <Button
                      onClick={() => createEventDispatch({ type: 'removeReminder', payload: i })}
                      className={styles.reminderRemoveButton}
                    >
                      <i className='fa fa-minus' />
                    </Button>
                  </div>
                );
              })}
            </div>
            {createEventState.reminders.length > 0 && (
              <Button onClick={() => createEventDispatch({ type: 'addReminder' })}>
                {t('Calendar.ReminderAdd')}
              </Button>
            )}
          </div>
          <div className={styles.checkboxContainer}>
            <CheckBox
              className={styles.checkbox}
              name={t('Post.MakePublicVisible')}
              isChecked={createEventState.publicVisible}
              onChange={e =>
                createEventDispatch({
                  type: 'setPublicVisible',
                  payload: !createEventState.publicVisible,
                })
              }
            />
          </div>
        </>
      )}
    </div>
  );
}

import React, { useEffect, useState } from 'react';

import defaultAvatar from 'resources/images/user/default-avatar.jpg';
import { formatDateNoTime } from 'library/utilities/dateMoment';
import { getKidHistory } from 'library/api/kidDevelopmentDiary';
import DateRow from './DateRow';

import styles from './childRow.module.scss';
import { Document, Page, pdf, Text } from '@react-pdf/renderer';
import {
  allDateRows,
  getAbsencesForDay,
  getChildRowData,
  getGroupedObjectByTime,
  getWrapsForDay,
} from 'library/utilities/checkInOut';
import { useTranslation } from 'react-i18next';

/**
 *
 * @param {Object} param0
 * @param {Object} [ param0.prefetchedData ]
 * @param {Boolean} [ returnPDF ]
 * @param {Date} [ param0.filterDateRangeStart ]
 * @param {Date} [ param0.filterDateRangeEnd ]
 * @returns
 */
const ChildRow = ({
  childObj,
  wrapActive,
  foodActive,
  sleepActive,
  pedagogicalObservation,
  kidAbsences,
  prefetchedData,
  returnPDF,
  filterDateRangeStart,
  filterDateRangeEnd,
}) => {
  const { t } = useTranslation();
  const [historie, setHistorie] = useState([]);
  const [groupedHistory, setGroupedHistory] = useState([]);
  // 'wraps' refers to the number of times the child has been changed
  const [wraps, setWraps] = useState([]);
  const [hideChildDetails, setHideChildDetails] = useState(true);

  useEffect(() => {
    if (prefetchedData) {
      setHistorie(prefetchedData.historie);
      setGroupedHistory(prefetchedData.groupedHistory);
      setWraps(prefetchedData.wraps);
    } else {
      getChildRowData(childObj).then(data => {
        setHistorie(data.historie);
        setGroupedHistory(data.groupedHistory);
        setWraps(data.wraps);
      });
    }
  }, []);

  const PDFDocument = () => (
    <Document>
      <Page size='A4' style={{ padding: 10 }}>
        <Text style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 8, marginTop: 10 }}>
          {childObj.name || `${childObj.firstName} ${childObj.lastName}`}
        </Text>
        {filterDateRangeStart && filterDateRangeEnd && (
          <Text style={{ fontSize: 18, marginBottom: 20, color: '#555' }}>
            {`${formatDateNoTime(filterDateRangeStart)} - ${formatDateNoTime(filterDateRangeEnd)}`}
          </Text>
        )}
        {!filterDateRangeStart && filterDateRangeEnd && (
          <Text style={{ fontSize: 18, marginBottom: 20, color: '#555' }}>
            {`${t('ExportDiaryPDF.until')} ${formatDateNoTime(filterDateRangeEnd)}`}
          </Text>
        )}
        {allDateRows(historie, kidAbsences).map(obj => {
          const date = new Date(
            obj
              .split('.')
              .reverse()
              .join('-'),
          );
          if (filterDateRangeStart && filterDateRangeEnd) {
            if (date < filterDateRangeStart || date > filterDateRangeEnd) return null;
          }
          return (
            <DateRow
              returnPDF={true}
              date={obj}
              groupedHistory={groupedHistory[obj] ?? []}
              wrapActive={wrapActive}
              foodActive={foodActive}
              sleepActive={sleepActive}
              pedagogicalObservation={pedagogicalObservation}
              absences={getAbsencesForDay(
                new Date(
                  obj
                    .split('.')
                    .reverse()
                    .join('-'),
                ),
                kidAbsences,
              )}
              wraps={getWrapsForDay(
                new Date(
                  obj
                    .split('.')
                    .reverse()
                    .join('-'),
                ),
                wraps,
              )}
            />
          );
        })}
        <Text
          style={{
            textAlign: 'center',
            backgroundColor: '#808080',
            color: 'white',
            fontWeight: 'bold',
            fontSize: 10,
            padding: 8,
            borderRadius: 5,
            width: 40,
            display: 'block',
            margin: 'auto',
            marginBottom: 0,
          }}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  );

  return returnPDF ? (
    <PDFDocument />
  ) : (
    <div>
      <div
        className={styles.wrapper}
        onClick={() => {
          setHideChildDetails(!hideChildDetails);
        }}
      >
        <div className={styles.photo}>
          <img src={childObj.groupLogoUrl || defaultAvatar} alt='' />
        </div>
        <div className={styles.name}>
          {childObj.name || `${childObj.firstName} ${childObj.lastName}`}
          {childObj.parent1Name !== null && (
            <span className={styles.parent}>{childObj.parent1Name}</span>
          )}
          {childObj.parent2Name !== null && (
            <span className={styles.parent}>{childObj.parent2Name}</span>
          )}
        </div>
        <div>
          <i className={hideChildDetails ? 'fa fa-arrow-down' : 'fa fa-arrow-up'} />
        </div>
      </div>
      {hideChildDetails === false && (
        <div>
          {allDateRows(historie, kidAbsences).map((obj, idx) => {
            return (
              <div key={childObj.kidId + ':' + obj}>
                <DateRow
                  date={obj}
                  groupedHistory={groupedHistory[obj] ?? []}
                  wrapActive={wrapActive}
                  foodActive={foodActive}
                  sleepActive={sleepActive}
                  pedagogicalObservation={pedagogicalObservation}
                  absences={getAbsencesForDay(
                    new Date(
                      obj
                        .split('.')
                        .reverse()
                        .join('-'),
                    ),
                    kidAbsences,
                  )}
                  wraps={getWrapsForDay(
                    new Date(
                      obj
                        .split('.')
                        .reverse()
                        .join('-'),
                    ),
                    wraps,
                  )}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ChildRow;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Checkbox from 'library/common/commonComponents/Checkbox';
import Button from 'library/common/commonComponents/Buttons/Button';
import { getKitaPortalKidsToImport, importKitaportalForCurrentKita } from 'library/api/kitaportal';
import Loader from 'library/common/commonComponents/Loader';
import CheckBox from 'library/common/commonComponents/Checkbox';
import Input from 'library/common/commonComponents/Inputs/Input';
import styles from './usersFrameImportPopup.module.scss';


export default function ImportToKitaportalByInterface({ showBottomNotification }) {
  const { t } = useTranslation();

  const [kidsList, setKidsList] = useState([]);
  const [selectedKids, setSelectedKids] = useState([]); // State to store selected KIND_ID_EXTERN values
  const [dontSendInvitationMail, setDontSendInvitationMail] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [searchTerm, setSearchTerm] = useState(''); // State to store the search term
  const [sortConfig, setSortConfig] = useState({ key: 'KIND_NACHNAME', direction: 'asc' }); // Default sort by lastName

  useEffect(() => {
    setIsLoading(true);
    getKitaPortalKidsToImport().then(data => {
      if (data.data) {
        setKidsList(data.data);
      }
    }).catch(() => {
      showBottomNotification(t('BottomNotifications.Something went wrong'), {
        isFail: true,
      });
    }).finally(() => {
      setIsLoading(false);
    });
  }, []);

  // Handler to update the state when a checkbox is toggled
  const handleCheckboxChange = (kidId) => {
    setSelectedKids((prevSelectedKids) => {
      if (prevSelectedKids.includes(kidId)) {
        // If the kidId is already in the array, remove it (uncheck)
        return prevSelectedKids.filter(id => id !== kidId);
      } else {
        // If the kidId is not in the array, add it (check)
        return [...prevSelectedKids, kidId];
      }
    });
  };

  // Filter kidsList based on search term (supports combination of firstName and lastName)
  const filteredKidsList = kidsList.filter(kid => {
    const searchTerms = searchTerm.toLowerCase().split(' '); // Split search term into parts
    const firstName = kid.KIND_VORNAME.toLowerCase();
    const lastName = kid.KIND_NACHNAME.toLowerCase();

    // Check if all parts of the search term match either firstName or lastName
    return searchTerms.every(term =>
      firstName.includes(term) || lastName.includes(term)
    );
  });

  // Sorting functionality
  const sortedKidsList = React.useMemo(() => {
    if (!sortConfig.key) return filteredKidsList;

    return [...filteredKidsList].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }, [filteredKidsList, sortConfig]);

  // Handler for sorting when a column header is clicked
  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  return (
    <>
      <h2>{t('Administration.Import to kitaportal by interface')}</h2>
      <br />
      <div>{t('Administration.Import to kitaportal by interface description')}</div>
      <br />
      {!isLoading ?
        <div>
          {errorMessages.length > 0 && (
            <>
              {t('Administration.Error messages')}
              <br />
              {errorMessages.map(data => {
                return <div key={data}>{data}</div>;
              })}
            </>
          )}
          <div >
            <CheckBox
              name={t('Administration.Do not send Invitation Mail')}
              isChecked={dontSendInvitationMail}
              onChange={() => setDontSendInvitationMail(!dontSendInvitationMail)}
            />
          </div>
          <br />
          {/* Search input field */}
          <Input
            type="text"
            placeholder={t('Administration.Search by first name or last name')}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <br />
          <table>
            <thead>
              <tr>
                <th></th>
                <th
                  onClick={() => handleSort('KIND_ID_EXTERN')}
                  className={styles.tableHeader}
                >
                  Id {sortConfig.key === 'KIND_ID_EXTERN' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}
                </th>
                <th
                  onClick={() => handleSort('KIND_VORNAME')}
                  className={styles.tableHeader}
                >
                  Vorname {sortConfig.key === 'KIND_VORNAME' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}
                </th>
                <th
                  onClick={() => handleSort('KIND_NACHNAME')}
                  className={styles.tableHeader}
                >
                  Nachname {sortConfig.key === 'KIND_NACHNAME' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}
                </th>
                <th
                  onClick={() => handleSort('KIND_GEBDATUM')}
                  className={styles.tableHeader}
                >
                  Geburtstag {sortConfig.key === 'KIND_GEBDATUM' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedKidsList.map((obj, index) => (
                <tr key={obj.KIND_ID_EXTERN}>
                  <td>
                    <Checkbox
                      isChecked={selectedKids.includes(obj.KIND_ID_EXTERN)} // Check if the kid is selected
                      onChange={() => handleCheckboxChange(obj.KIND_ID_EXTERN)} // Update state on change
                    />
                  </td>
                  <td>{obj.KIND_ID_EXTERN}</td>
                  <td>{obj.KIND_VORNAME}</td>
                  <td>{obj.KIND_NACHNAME}</td>
                  <td>{obj.KIND_GEBDATUM}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <br />
          <Button
            type='primary'
            onClick={() => {
              setIsLoading(true);
              importKitaportalForCurrentKita(selectedKids, dontSendInvitationMail).then(data => {
                const errors = data.data.errors.map(error => {
                  const msg = t(`ImportError.${error.key}`, error.args);
                  return msg;
                });
                setErrorMessages(errors);
                showBottomNotification(t('BottomNotifications.Success'));
              }).catch(() => {
                showBottomNotification(t('BottomNotifications.Something went wrong'), {
                  isFail: true,
                });
              }).finally(() => {
                setIsLoading(false);
              })
            }}>{t('Administration.Start import')}</Button>
        </div>
        : <Loader />}
    </>
  );
}
import axios from 'library/api/axios';
import config, { getPort } from 'main/config';
import store from 'main/store/configureStore';
import { jsPDF } from 'jspdf';
import { checkInOutActiveForParentsSettingChanged } from '../common/commonActions/moduleSettingsActions';
import { uploadFilesToGroup } from './files';

export const URL = `${config.API_BASE_URI}${getPort(8080)}/api`;

export const getUserKids = () => axios.get(`${URL}/kidCheckInOut/kids`);

export const getKidsByGroup = groupId => axios.get(`${URL}/kidCheckInOut/kids/${groupId}`);

export const getKidsStatusAndAbsencesByGroup = groupId =>
  axios.get(`${URL}/kidCheckInOutAbsence/kids/${groupId}`);

export const getUserKidsHistory = params =>
  axios.get(`${URL}/kidCheckInOut/history`, {
    params,
  });

export const getKidsHistoryByGroup = (groupId, params) =>
  axios.get(`${URL}/kidCheckInOut/history/${groupId}`, {
    params,
  });

export const checkInOutKid = checkInOutData => axios.post(`${URL}/kidCheckInOut`, checkInOutData);

export const getAllUnapprovedPickUpPersonsOfKita = () =>
  axios.get(`${URL}/getallunapprovedpickuppersonsofkita`);

export const checkInOutChangePickUpPerson = checkInOutData =>
  axios.post(`${URL}/kidCheckInOut/changePickUpPerson`, checkInOutData);

export const createKid = payload => axios.post(`${URL}/kid`, payload);

export const getKids = groupsId => axios.get(`${URL}/group/${groupsId}`);

export const getKidsCountByGroupId = groupId => axios.get(`${URL}/kidsCountByGroupId/${groupId}`);

export const getKidsByGroupId = groupId => axios.get(`${URL}/kidsByGroupId/${groupId}`);

export const getKidsByGroupIdAndArrival = groupId =>
  axios.get(`${URL}/kidsByGroupIdAndArrival/${groupId}`);

export const changeKidCanGoHomeOnTheirOwnData = (id, homeFlag) =>
  axios.post(`${URL}/changeKidCanGoHomeOnTheirOwn/${id}/${homeFlag}`);

export const changeArrivedData = (id, arriveFlag) =>
  axios.post(`${URL}/changeKidInKitaData/${id}/${arriveFlag}`);

export const getKidsByGroupPortfolio = groupId =>
  axios.post(`${URL}/parent/getkidsbygroupportfolio/${groupId}`);

export const addPerKidCode = (kidCode, kitaId, userId) =>
  axios.get(`${URL}/parent/kidCode/${kidCode}/${kitaId}/${userId}`);

export const deleteParent2 = kidId => axios.get(`${URL}/parent/delete/${kidId}`);

export const getAllKidsForCurrentUser = () => {
  return axios.get(`${URL}/getallkidsforcurrentuser`);
};

export const createKidPortfolioGroupIfNeeded = kidId =>
  axios.post(`${URL}/parent/createKidPortfolioGroupIfNeeded/${kidId}`);

export const getCheckInOutConfig = groupId => axios.get(`${URL}/kidCheckInOut/config/${groupId}`);

export const buyWrap = id => {
  return axios.post(`${URL}/kidCheckInOut/buyWrap/${id}`);
};

export const sendWrapMessage = (id, payload) => {
  return axios.post(`${URL}/kidCheckInOut/sendWrapMessage/${id}`, payload);
};

export const saveCheckInOutConfig = (
  groupId,
  wrapActive,
  foodActive,
  sleepActive,
  pedagogicalObservationActive,
  developmentDiaryActiveForParents,
  internalRemarkActive,
  presenceDayOverviewActive,
  arrivalListOverviewActive,
  arrivalTimeActive,
  leavingTimeActive,
) => {
  const payload = {
    wrapActive,
    foodActive,
    sleepActive,
    pedagogicalObservationActive,
    developmentDiaryActiveForParents,
    internalRemarkActive,
    presenceDayOverviewActive,
    arrivalListOverviewActive,
    arrivalTimeActive,
    leavingTimeActive,
  };
  return axios.post(`${URL}/kidCheckInOut/config/${groupId}`, payload);
};

export const getDevelopmentDiaryActiveForParents = () =>
  axios.get(`${URL}/kidCheckInOut/developmentDiaryActiveForKitaParents`);

export const getCheckInOutActiveForParents = () =>
  axios.get(`${URL}/kidCheckInOut/checkinoutActiveForKitaParents`);

export const getCheckinoutOrDevelopmentDiaryActiveForKitaParents = () =>
  axios.get(`${URL}/kidCheckInOut/checkinoutOrDevelopmentDiaryActiveForKitaParents`);

export const setCheckInOutActiveForParents = active =>
  axios.post(`${URL}/kidCheckInOut/checkinoutActiveForKitaParents`, active);

export const setCheckinGroupSelectionActive = active =>
  axios.post(`${URL}/kidCheckInOut/checkinGroupSelectionActive`, active);

export const setCheckInOutNotificationsActiveToParent = active =>
  axios.post(`${URL}/kidCheckInOut/notificationsActive/parent`, active);

export const addAllParentsToPortfolioGroups = () =>
  axios.put(`${URL}/addallparentstoportfoliogroups`);

export const addAllAdminEmployeeGroupMembersToPortfolioGroups = () =>
  axios.put(`${URL}/addalladminemployeegroupmemberstoportfoliogroups`);

export const giveUsersInPortfolioGroupsPermissionsToUploadFile = () =>
  axios.put(`${URL}/giveUsersInPortfolioGroupsPermissionsToUploadFile`);

export const removeLinkitisiUsersFromKidPortfolios = () =>
  axios.put(`${URL}/removelinkitisiusersfromkidportfolios`);

export const addQRCodeRole = () => axios.post(`${URL}/addQRCodeRole`);

export const deleteCheckinoutEntry = (groupId, id) => {
  return axios.delete(`${URL}/kidCheckInOut/delete/${groupId}/${id}`);
};

export const findCountOfTenant = () => {
  return axios.get(`${URL}/findCountOfTenant`);
};

export const autoCheckoutButtonAllKids = () => {
  return axios.get(`${URL}/kidCheckInOut/autoCheckoutButton`);
};

export const editCheckinoutEntryTime = (groupId, id, newDate) => {
  return axios.post(`${URL}/kidCheckInOut/editTime/${groupId}/${id}`, newDate);
};

export const getKidHolidayTimes = (kitaId, kidId) =>
  axios.get(`/kitas/${kitaId}/kids/${kidId}/holidayTimes`);

export const setKidHolidayTimes = (kitaId, kidId, holidayTimes) =>
  axios.post(`/kitas/${kitaId}/kids/${kidId}/holidayTimes`, holidayTimes);

export const getLeavingTimes = () => axios.get(`/kidLeavingTimes`);

export const createKidLeavingTime = kidLeavingTime =>
  axios.post(`/kidLeavingTimes`, kidLeavingTime);

export const kidExitNotification = () =>
  axios.post(`/kidexitnotification`);

export const updateKidLeavingTime = (id, kidLeavingTime) =>
  axios.put(`/kidLeavingTimes/${id}`, kidLeavingTime);

export const updateKidGroup = (kidId, groupId) => {
  return axios.post(`${URL}/parent/kid/${kidId}/setGroup/${groupId}`);
};

export const deleteKidLeavingTime = id => axios.delete(`/kidLeavingTimes/${id}`);

export const getCheckInOutKitaConfig = () => axios.get(`${URL}/kidCheckInOut/kitaConfig`);

export const getBookingTimesExcel = () =>
  axios.get(`${URL}/bookingTimes/excel`, { responseType: 'blob' });

export const generateQRCodeForUser = (t, user, uploadToFilesModuleData, qrCodeImage) => {
  return new Promise((resolve, reject) => {
    const doc = new jsPDF();
    doc.setFontSize(14);
    doc.text(t('Administration.QRCode') + ' - ' + user.firstName + ' ' + user.lastName, 10, 10);
    doc.setFontSize(12);
    doc.setFontSize(11);
    let width = 10;
    const fontSize = 12;
    doc.setFontSize(fontSize);
    let page = 1;
    addPDFFooter(doc, page, fontSize, user.kidGroup != null ? user.kidGroup.groupName : 'No Group');

    // Content
    width = 10;
    doc.addImage(qrCodeImage, 'JPEG', 60, 60, 80, 80);
    if (uploadToFilesModuleData.uploadToFilesModule) {
      const formData = new FormData();
      const blobPDF = doc.output('blob');
      formData.append(
        'files',
        new File(
          [blobPDF],
          `Essensbestellungsübersicht_${new Date().toLocaleDateString('de-DE')}.pdf`,
        ),
      );
      formData.append('userId', uploadToFilesModuleData.userId);
      formData.append('privatePost', true);
      formData.append('groupId', uploadToFilesModuleData.groupId);
      uploadFilesToGroup(formData, uploadToFilesModuleData.setProgress).then(data => {
        resolve(null);
      });
    } else {
      doc.save(`${t('Administration.QRCode')} - ${user.firstName} ${user.lastName}.pdf`);
    }
  });
};

function addPDFFooter(doc, page, fontSize, groupName) {
  doc.text(page + '', 195, 285);
  doc.setFontSize(fontSize - 2);
  doc.setFont(undefined, 'italic');
  doc.setLineWidth(0.2);
  doc.line(10, 275, 200, 275);
  doc.text(groupName, 10, 280);
  doc.text('Generated by Link IT isi GmbH', 10, 285);
  doc.setFont(undefined, 'normal');
  doc.setFontSize(fontSize);
}

export const transferKidGroupToKidMainGroup = () =>
  axios.post(`${URL}/transferKidGroupToKidMainGroup`);

export const transferAllKidsToKitalino = () => axios.post(`${URL}/transferallkidstokitalino`);

export const updateKidContractEndDate = (kidId, contractEndDate) =>
  axios.post(`${URL}/updateKidContractEndDate/${kidId}`, null, {
    params: {
      contractEndDate,
    },
  });

export const deactivateKidsContractEndWithinAMonth = () =>
  axios.post(`${URL}/kids-contract-end/deactivate-all/`);

export const migrateAbsences = kitaId => axios.post(`${URL}/migrateAbsences/${kitaId}`);

export const deleteDuplicateAuthorizedToPickUpChild = () =>
  axios.post(`/deleteDuplicateAuthorizedToPickUpChild`);

export const fixBirthays = () => axios.post(`${URL}/fix-birthdays`);

import Select from 'library/common/commonComponents/Inputs/Select';
import Popup from 'library/common/commonComponents/Popups/Popup';
import DatePicker from 'library/common/commonComponents/DatePicker';
import React, { useEffect, useRef, useState } from 'react';
import Label from 'library/common/commonComponents/Label';
import { useTranslation } from 'react-i18next';
import styles from './exportDiaryPopup.module.scss';
import Button from 'library/common/commonComponents/Buttons/Button';
import { PDFDownloadLink, Document, Page, Image, StyleSheet, pdf, Text } from '@react-pdf/renderer';
import ChildRow from '../ChildRow';
import { getChildRowData } from 'library/utilities/checkInOut';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import store from 'main/store/configureStore';

/**
 *
 * @param {Object} param0
 * @param {Boolean} param0.isOpened
 * @param {Function} param0.closePopup
 * @param {Array} param0.childRowData
 * @returns
 */
const ExportDiaryPopup = ({ isOpened, closePopup, childRowData }) => {
  const { t } = useTranslation();

  const selectableChildren = childRowData.map(child => child.childObj);

  const [selectedChild, setSelectedChild] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);

  const exportPDF = async () => {
    setIsLoading(true);

    if (selectedChild === null) {
      setIsLoading(false);
      store.dispatch(showBottomNotification('Please select a child', { isFail: true }));
      return;
    }

    const selected = childRowData.find(child => child.childObj.id === selectedChild.value);
    const prefetchedData = await getChildRowData(selected.childObj);
    const ChildRowEl = (
      <ChildRow
        childObj={selected.childObj}
        wrapActive={selected.wrapActive}
        foodActive={selected.foodActive}
        sleepActive={selected.sleepActive}
        pedagogicalObservation={selected.pedagogicalObservationActive}
        key={selected.childObj.kidId}
        kidAbsences={selected.kidAbsences}
        returnPDF={true}
        prefetchedData={prefetchedData}
        filterDateRangeStart={startDate}
        filterDateRangeEnd={endDate}
      />
    );
    const doc = ChildRowEl;
    const asPdf = pdf(doc);

    const onRender = async () => {
      const blob = await asPdf.toBlob();

      // Create a URL for the blob and trigger download
      const blobUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = 'diary-export.pdf';
      document.body.appendChild(a);
      a.click();
      a.remove();
      URL.revokeObjectURL(blobUrl);

      asPdf.removeListener('change', onRender);
      setIsLoading(false);
      closePopup();
    };

    asPdf.on('change', onRender);
  };

  const options = selectableChildren.map(child => ({
    value: child.id,
    label: child.name,
  }));

  return (
    <div>
      <Popup
        isOpened={isOpened}
        closePopup={closePopup}
        size='small'
        header={t('ExportDiaryPopup.ExportDiary')}
        footer={
          <div>
            <Button
              type='primary'
              disabled={isLoading}
              onClick={() => {
                exportPDF();
              }}
            >
              {t('ExportDiaryPopup.Export')}
            </Button>
          </div>
        }
      >
        <div className={styles.container}>
          <Select
            options={options}
            onSelect={option => {
              setSelectedChild(option);
            }}
            selected={selectedChild}
          />
          <div className={styles.datePickerContainer}>
            <div className={styles.dateStart}>
              <Label type='input'>{t('Calendar.Start Date')}</Label>
              <DatePicker
                selected={startDate}
                onChange={date => {
                  setStartDate(date);
                }}
              />
            </div>
            <div className={styles.dateStart}>
              <Label type='input'>{t('Calendar.End Date')}</Label>
              <DatePicker
                selected={endDate}
                onChange={date => {
                  setEndDate(date);
                }}
              />
            </div>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default ExportDiaryPopup;

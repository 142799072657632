import Button from 'library/common/commonComponents/Buttons/Button';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Input from 'library/common/commonComponents/Inputs/Input';
import { saveOrUpdateKitaPortalSettings, getKitaPortalSettings, deleteKitaPortalSettings } from 'library/api/kitaportal';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import store from 'main/store/configureStore';
import Loader from 'library/common/commonComponents/Loader';

export default function KitaportalSettingsFrame() {
  const { t } = useTranslation();

  const emptySettings = {
    url: '',
    protocol: '',
    traegerName: '',
    interfacePassword: '',
    certificatePassword: '',
    externalId: '',
    filePath: null,
  };

  const [kitaPortalSettings, setKitaportalSettings] = useState(emptySettings);
  const [isLoading, setIsLoading] = useState(false);
  const [certificateFile, setCertificateFile] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    getKitaPortalSettings().then(data => {
      if (data.data) {
        setKitaportalSettings(data.data);
      }
    }).finally(() => {
      setIsLoading(false);
    });
  }, [])

  return (
    !isLoading ?
      <>
        <h2>Kitaportal Einstellungen</h2>
        <br />
        <table>
          <tr>
            <td>URL</td>
            <td>
              <Input
                type='text'
                maxLength={500}
                value={kitaPortalSettings.url}
                onChange={({ target }) => {
                  setKitaportalSettings(prev => ({
                    ...prev,
                    url: target.value,
                  }));
                }}
              />
            </td>
          </tr>
          <tr>
            <td>Protokoll</td>
            <td>
              <Input
                type='text'
                maxLength={500}
                value={kitaPortalSettings.protocol}
                onChange={({ target }) => {
                  setKitaportalSettings(prev => ({
                    ...prev,
                    protocol: target.value,
                  }));
                }}
              />
            </td>
          </tr>
          <tr>
            <td>Träger Name</td>
            <td>
              <Input
                type='text'
                maxLength={500}
                value={kitaPortalSettings.traegerName}
                onChange={({ target }) => {
                  setKitaportalSettings(prev => ({
                    ...prev,
                    traegerName: target.value,
                  }));
                }}
              />
            </td>
          </tr>
          <tr>
            <td>Externe Id Einrichtung</td>
            <td>
              <Input
                type='text'
                maxLength={500}
                value={kitaPortalSettings.externalId}
                onChange={({ target }) => {
                  setKitaportalSettings(prev => ({
                    ...prev,
                    externalId: target.value,
                  }));
                }}
              />
            </td>
          </tr>
          <tr>
            <td>Schnittstellen-Passwort</td>
            <td>
              <Input
                type='password'
                maxLength={500}
                value={kitaPortalSettings.interfacePassword}
                onChange={({ target }) => {
                  setKitaportalSettings(prev => ({
                    ...prev,
                    interfacePassword: target.value,
                  }));
                }}
              />
            </td>
          </tr>
          <tr>
            <td>Zertifikats-Passwort</td>
            <td>
              <Input
                type='password'
                maxLength={500}
                value={kitaPortalSettings.certificatePassword}
                onChange={({ target }) => {
                  setKitaportalSettings(prev => ({
                    ...prev,
                    certificatePassword: target.value,
                  }));
                }}
              />
            </td>
          </tr>
          {(kitaPortalSettings.filePath === null || typeof kitaPortalSettings.filePath === 'undefined') ?
            <tr>
              <td>Zertifikat</td>
              <td>
                <Input
                  type='file'
                  accept='.p12'
                  maxLength={500}
                  onChange={({ target }) => {
                    setCertificateFile(target.files[0]);
                  }}
                />
              </td>
            </tr> :
            <tr>
              <td>Zertifikat</td>
              <td>
                <i className='fa fa-file' />
                &nbsp;.p12 Kitaportalzertifikat
              </td>
            </tr>
          }
        </table>
        <br />
        <Button type='primary' onClick={() => {
          setIsLoading(true);
          const formData = new FormData();
          let settingsTemp = kitaPortalSettings;
          delete settingsTemp.filePath;
          formData.append("data", JSON.stringify({ ...settingsTemp }));
          formData.append("file", certificateFile);
          saveOrUpdateKitaPortalSettings(formData)
            .then(data => {
              store.dispatch(
                showBottomNotification(t('BottomNotification.SuccessfullySaved'), {
                  isFail: false,
                }),
              );
              setKitaportalSettings(data.data);
            })
            .catch(() => {
              store.dispatch(
                showBottomNotification(t('BottomNotification.ErrorSavingTryAgain'), {
                  isFail: true,
                }),
              );
            }).finally(() => {
              setIsLoading(false);
            })
        }}>Speichern</Button>&nbsp;
        <Button type='danger' onClick={() => {
          setIsLoading(true);
          setKitaportalSettings(emptySettings);
          deleteKitaPortalSettings()
            .then(() => {
              store.dispatch(
                showBottomNotification(t('BottomNotification.SuccessfullySaved'), {
                  isFail: false,
                }),
              );

            })
            .catch(() => {
              store.dispatch(
                showBottomNotification(t('BottomNotification.ErrorSavingTryAgain'), {
                  isFail: true,
                }),
              );
            }).finally(() => {
              setIsLoading(false);
            })
        }}>Alles löschen und speichern</Button>
        <br />
      </> : <Loader />);
}
import React, { useState } from 'react';
import styles from './employeeAbsenceExport.module.scss';
import { useTranslation } from 'react-i18next';
import { downloadEmployeeAbsenceExport } from 'library/api/employee';
import { useDropdown } from 'library/common/commonHooks/useDropdown';
import Dropdown from 'library/common/commonComponents/Dropdowns/Dropdown';
import Button from 'library/common/commonComponents/Buttons/Button';
import DatePicker from 'library/common/commonComponents/DatePicker';

const EmployeeAbsenceExport = () => {
  const { t } = useTranslation();
  const { isOpened, DropdownWrapper } = useDropdown(Dropdown);
  // set export month to first day of current month
  const [exportMonth, setExportMonth] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
  );
  const selectedMonth = exportMonth.getMonth() + 1;
  const selectedYear = exportMonth.getFullYear();

  const exportAbsences = async format => {
    downloadEmployeeAbsenceExport(
      format,
      `${selectedMonth.toString().padStart(2, 0)}.${selectedYear}`,
    );
  };

  const dropdownOptions = [
    {
      faIcon: 'fa-file-pdf-o',
      title: 'Calendar.exportEmployeeAbsencesAsPdf',
      onClick: () => exportAbsences('pdf'),
    },
    {
      faIcon: 'fa-file-excel-o',
      title: 'Calendar.exportEmployeeAbsencesAsExcel',
      onClick: () => exportAbsences('xlsx'),
    },
  ];

  return (
    <div className={styles.container}>
      <h2>{t('Calendar.exportEmployeeAbsences')}</h2>
      <div className={styles.monthSelectionContainer}>
        <Button
          onClick={() => {
            setExportMonth(prev => new Date(prev.getFullYear(), prev.getMonth() - 1, 1));
          }}
          isFullWidth={false}
        >
          <i className='fa fa-chevron-left' aria-hidden='true' />
        </Button>

        <div className={styles.datePickerWrapper}>
          <DatePicker
            selected={exportMonth}
            onChange={date => setExportMonth(date)}
            dateFormat='MM/yyyy'
            dropdownMode='select'
            showMonthYearPicker
            showFullMonthYearPicker
          />
        </div>

        <Button
          onClick={() => {
            setExportMonth(prev => new Date(prev.getFullYear(), prev.getMonth() + 1, 1));
          }}
          isFullWidth={false}
        >
          <i className='fa fa-chevron-right' aria-hidden='true' />
        </Button>
      </div>
      <div className={styles.actionsContainer}>
        <div className={styles.action} title={t('Calendar.exportEmployeeAbsences')}>
          <DropdownWrapper
            dropdownOptions={dropdownOptions}
            openToTheLeft={false}
            closeOnClick
            theme='dark'
            render={dropdown => (
              <div className={styles.moreBtn}>
                <div>{t('Calendar.exportEmployeeAbsencesBtn')}</div>
                {dropdown}
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default EmployeeAbsenceExport;
